/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef} from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request } from '../../../scripts/Request';
import { HubContext } from '../../../Context/HubContext.js';
import { InvalidatorContext } from '../../../Context/InvalidatorContext';
import { WaitObject } from '../../../scripts/Wait';
import { FormGenerator } from '../../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ServerURL } from '../../../scripts/Loadbalancer';
import { Currency, UnCurrency, Uuid } from '../../../scripts/StringUtils';
import { UploadService } from '../../../scripts/UploadService';
import ReactGA from 'react-ga';
import $ from 'jquery';
import { Button } from "react-bootstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import './Cadastro.css';

import { MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';


  


export function Modelo({...props}) {
    const component_name = "admin/modeloa_cadastro";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Invalidator, Invalidate, InvalidateStream } = useContext(InvalidatorContext);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showAddComboModal, setShowAddComboModal] = useState(false);
    const [AddComboTitle, SetAddComboTitle] = useState(false);
    const [AddComboInfo, SetAddComboInfo] = useState({ table: "", id_field: "", name_field: "" });
    const [AddComboList, SetAddComboList] = useState([]);
    const [showFotoEditModal, setShowFotoEditModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [showPhotoModal, setShowPhotoModal] = useState(false);

    const _formUserRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const [cameras, setCameras] = useState([]);
    const [imageList, setImageList] = useState([]);
    const _uploadedImages = useRef([]);
    const [image, setImage] = useState("");
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState();
    const onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const hiddenFileInput = useRef(null);
    const videoRef = useRef();
    const canvasRef = useRef();

    const navigate = useNavigate();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const { FMarcaId, Id } = props;


    //#region Effects
     useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);

    //#endregion Effects


    //#region Init
    const Init = () => {
        
    }
    //#endregion Init



    //#region Loaders
    
    //#endregion Loaders



    //#region Form Handlers
    const indice = (value) => {
        if(value !== "") {
            let compra = _formUserRef.current.GetValue('compra');
            compra = UnCurrency(compra);
            value = parseFloat(value);
            let venda = compra + (compra * value) / 100 ;
            _formUserRef.current.Value('venda', Currency(venda));
        }
    }

    const compra = (value) => {
        let indice = _formUserRef.current.GetValue('indice');
        indice = parseFloat(indice);
    }


    const venda = (value) => {
        let compra = _formUserRef.current.GetValue('compra');
        compra = UnCurrency(compra);
        value = UnCurrency(value);

        let indice = ((value / compra) * 100) - 100;
        _formUserRef.current.Value('indice', indice);
    }


    const OnFormLoaded = async(fields) => {
        setPageLoading(true);

        let acessorio_data;
        WaitObject(_formUserRef.current).then(async() => {
            _formUserRef.current.CDN("https://easylens-cdn.s3.sa-east-1.amazonaws.com/db/acessorios/");
            if(Id) {
                acessorio_data = await new Request().Run("api/Acessorios/LoadModelo?id="+Id, "GET", "", user.token);
                if(acessorio_data !== undefined && acessorio_data.id !== null) {
                    await _formUserRef.current.Load(acessorio_data);
                    setImage(acessorio_data.foto)
                    if(user.nivel > 1) {
                        _formUserRef.current.DisableFields();
                    } 

                } 
            } 

            setPageLoading(false);
            window.scrollTo({top: 0, behavior: 'smooth'});
  
        });
        
    }

    
    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        let validade = _formUserRef.current.RequiredValidation();

       
        if(validade) {
           
            setPageLoading(true);
            let result = await _formUserRef.current.Post("api/Acessorios/SaveModelo", {acessorio_id: Id, fornecedor_marca_id: FMarcaId, foto: image}, user.token);
            setPageLoading(false);

            if(result === "error") {
                window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
                return;
            } 

            props.onSave();
        } else {
            window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
        }
        
    }


    const _HandleBack = () => {
        props.onClose();
    }

    //#endregion Handlers
    

    //#region AddCombo Handlers
    const _handleCloseAddComboModal = async() => {
        setShowAddComboModal(false);
    }

    const _handleSaveAddComboModal = () => {
        
    }


    const _EditAddCombo = (title, table, id_field, name_field, parms={}) => {
        SetAddComboTitle(title);
        SetAddComboInfo({ table: table, id_field: id_field, name_field: name_field });
        setShowAddComboModal(true);

       
        Promise.resolve(new Request().Run("api/Acessorios/AddComboList", "POST",{ table: table, id_field: id_field, name_field: name_field, parms}, user.token))
            .then((data) => {
                SetAddComboList(data);
            });
    }


    const _AddComboBlur = (item) => {
        let _data = Object.assign(AddComboInfo, { id: item.id, nome: item.name });
        Promise.resolve(new Request().Run("api/Acessorios/AddComboUpdate", "POST", _data, user.token))
            .then((data) => {
                let _list = [...AddComboList];
                let _listItem = _list.find(l => l.id === item.id);
                _listItem = item;
                SetAddComboList(_list);
            });
    }


    const _DeleteAddCombo = (item) => {
        let _data = Object.assign(AddComboInfo, { id: item.id });
        Promise.resolve(new Request().Run("api/Acessorios/AddComboDelete", "POST", _data, user.token))
            .then((data) => {
                let _list = [...AddComboList];
                let _listIndex = _list.findIndex(l => l.id == item.id);
               _list.splice(_listIndex, 1);
                SetAddComboList(_list);
            });
    }


    const _AddComboFieldChange = (item, event) => {
        let _list = [...AddComboList];
        let _listItem = _list.find(l => l.id == item.id);
        _listItem.name = event.target.value;
        SetAddComboList(_list);
    }
    //#endregion AddCombo Handlers


    //#region Upload
    const HandlerImageError = (e) => {
        e.target.src = "/images/acessorios/0.jpg";
	}


    const _handlerUploadClick = () => {
        hiddenFileInput.current.click();
    }


    const _HandleUpload = event => {
        UploadImages(event);
    }


    const UploadImages = async(event) => {
        setPageLoading(true);
        let upload = new UploadService();
        let c = 0;

        upload.OnEnd = (result) => {
            let arr = JSON.parse(result.img);
            _uploadedImages.current.push({foto: arr[0], edited: 0});
            c++;

            if(c === event.target.files.length) {
                setImageList(_uploadedImages.current);
                setPageLoading(false);
            }
        }

        for(let i=0; i<event.target.files.length; i++) {
            upload.Queue(event.target.files[i]);
        }

        await upload.PopUpload(ServerURL("/api/Acessorios/UploadFotos"), user.token);
    }


    const _handleCloseFotoEditModal= async() => {
        setShowFotoEditModal(false);
      
    }
    //#endregion Upload


    //#region Cropper
    const _handleEditFoto = (foto) => {
        setShowFotoEditModal(true);

        if(foto === "" ||  foto === null ||  foto === undefined) {
            foto = { foto: "0.jpg" }
        } else {
            foto = { foto: foto }
        }
        
        setSelectedImage(foto);

        //console.log(ServerURL("/images/acessorios/")+foto.foto);

        let request = new XMLHttpRequest();
        //request.open('GET', ServerURL("/images/acessorios/")+foto.foto, true);
        request.open('GET', "https://easylens-cdn.s3.sa-east-1.amazonaws.com/db/acessorios/"+foto.foto+"?"+(new Date()).getTime(), true);
        request.responseType = 'blob';
        request.onload = function() {
            try {
                let reader = new FileReader();
                reader.onload =  function(e){
                    //console.log(e.target.result)
                    setImage(e.target.result);
                };
                reader.readAsDataURL(request.response);
            } catch(e) {}
        };
        request.send();
        
        
    }


    const getCropData = (foto) => {
        if (typeof cropper !== "undefined") {
            foto = foto.replace(ServerURL("images/acessorios/"), "");
            foto = foto.replace("https://easylens-cdn.s3.sa-east-1.amazonaws.com/db/acessorios/", "");

            let ilist = [...imageList];
            //ilist.find(f => f.foto === foto).edited = 1;

            setPageLoading(true);

            let cropped = cropper.getCroppedCanvas({ 
                width: 550,
                height: 550,
                minWidth: 550,
                minHeight: 550,
                maxWidth: 550,
                maxHeight: 550,
                fillColor: '#fff',
                imageSmoothingEnabled: true,
                imageSmoothingQuality: 'high'});

                
            try {
                setCropData(cropper.getCroppedCanvas().toDataURL());

            
                let blob = cropped.toBlob(async function (blob) {
                    let file = new File([blob], foto, { type: 'image/jpeg' });
                    let upload = new UploadService();
                    upload.Data = {foto: foto, acessorio_id: Id}
                    upload.OnEnd = async(image) => {
                        //console.log(image.img);
                        setShowFotoEditModal(false);
                        setImage(image.img);
                        setPageLoading(false);
                    }
                    await upload.Upload(file, ServerURL("api/Acessorios/Crop"), user.token);
                }, 'image/jpeg');

            } catch(e) {
                setPageLoading(false);
            }
        }
    }
    //#endregion Cropper


    //#region Modal Fotos
    const _handleClosePhotoModal = () => {
        StopCamera();
        setShowPhotoModal(false);
    }


    const _handleOpenPhotoModal = () => {
        //ListCameras();
        setShowPhotoModal(true);

        /*setTimeout(() => {
            let video = document.querySelector("#foto1");
            if (navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices.getUserMedia({ video: true })
                    .then(function (stream) {
                        video.srcObject = stream;
                    })
                    .catch(function (err0r) {
                        console.log("Something went wrong!");
                    });
                }
        }, 1000);*/

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices.getUserMedia({ audio: false, video: { facingMode: "environment" }}).then(stream => {
                    videoRef.current.srcObject = stream;
                    videoRef.current.play();
                    return new Promise((resolve, reject) => {
                        videoRef.current.onloadedmetadata = () => {
                            resolve();
                        };
                    });
                });
        }
    }




    const ListCameras = () => {
        let _cameras = [];
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.enumerateDevices().then(function (devices) {
                for(var i = 0; i < devices.length; i ++){
                    var device = devices[i];
                    //console.log(device);
                    if (device.kind === 'videoinput') {
                       /* var option = document.createElement('option');
                        option.value = device.deviceId;
                        option.text = device.label || 'camera ' + (i + 1);
                        document.querySelector('select#videoSource').appendChild(option);
                        */
                        _cameras.push({deviceId: device.deviceId, label: (device.label || 'camera ' + (i + 1))});
                    }
                };

                //console.log(_cameras)
                setCameras(_cameras);
            });
        }
    }


    const StopCamera = () => {
        try {
            let stream = videoRef.current.srcObject;
            let tracks = stream.getVideoTracks();
            tracks.forEach(track => {
                track.stop();
            }); 
        } catch(e) { }
        //videoRef.current = null;
    }



    const _handleSnapPhotoModal = () => {
        let video = document.getElementById('foto1');
        let canvas = document.getElementById('canvas1');
        let context = canvas.getContext('2d');
        
        let rate = (video.width-canvas.width)/2;
        let rate1 = rate * (canvas.width/video.width) + 12;
        console.log(rate);
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(video, 0, 0, 550, 550, -rate, 0, 550+rate, 550+rate1);

       
        let blob = document.querySelector("#canvas1").toBlob(async function (blob) {
            let file = new File([blob], Uuid() + '.jpg', { type: 'image/jpeg' });
            let upload = new UploadService();
            //setShowPhotoModal(false);
            upload.Data = {foto:  Uuid() + '.jpg', acessorio_id: Id}
            upload.OnEnd = (image) => {
                console.log(image.img);
                setShowFotoEditModal(false);
                setImage(image.img);
                setPageLoading(false);
                _formUserRef.current.Value("foto", image.img);
            }
            await upload.Upload(file, ServerURL("api/Acessorios/Crop"), user.token);
        }, 'image/jpeg');
        
    }
    //#endregion Modal Fotos



    return(
        <div className="AcessoriosModelo">
            <div>
                <div className="card-header justify-content-between d-flex align-items-center">
                    <h4 className="card-title">Cadastro de Modelo</h4>
                    <div className="align-right mr-10">
                        <button type="button" className="btn btn-outline-primary mr-10" onClick={_HandleBack}>
                            <i className="mdi mdi-arrow-left"></i> &nbsp;Lista &nbsp;
                        </button>

                        <button type="button" className="btn btn-primary mr-10" onClick={() => _handleEditFoto(image)}>
                            <i className="mdi mdi-image"></i> &nbsp;Foto &nbsp;
                        </button>

                        <button type="button" className="btn btn-primary " onClick={_handleOpenPhotoModal}>
                            <i className="mdi mdi-camera"></i> &nbsp;Camera &nbsp;
                        </button>
                    </div>

                </div>

                <form name="fuser" onSubmit={(event) => _HandleSubmit('acessorio', event)} autoComplete="off" id="form-contact" className="" noValidate>
                    <div className="row">
                        <div className="col-md-12 pt-1 pb-1">
                            <FormGenerator ref={_formUserRef} name={'acessorio'} url={'/assets/forms/modelo_acessorio.json'} execRef={Exec}></FormGenerator>
                            
                            <center>
                                <button id="btn_sv" className={'btn btn-primary btn-lg btn-rounded mb-2 ' + (user.nivel > 1?'hide':'')} type="submit">Salvar informações</button>
                                </center>
                            <div style={{clear:'both'}}></div>
                            <br />
                    
                        </div> 
                    </div>
                </form>
            </div>


            <MDBModal show={showAddComboModal} setShow={setShowAddComboModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle><h4>{AddComboTitle}</h4></MDBModalTitle>
                        <Button className='btn btn-primary' onClick={_handleCloseAddComboModal} style={{width:'25px',height:'25px', padding:0}}>x</Button>
                    </MDBModalHeader>
                    <MDBModalBody>
                    {AddComboList.length > 0 ? (<div>
                        <table className="table table-striped table-hover">
                            <tbody>
                                {AddComboList.map((item, i) => (
                                    <tr key={'addc'+i}>
                                        <td style={{ padding: 0 }}> <input type="text" className="form-control inputfull" value={item.name} onChange={(event) => _AddComboFieldChange(item, event)} onBlur={() => _AddComboBlur(item)} /></td>
                                        <td style={{ padding: '4px', margin: 0, width: '35px' }}><button className="btn btn-white btn-sm" type="button" onClick={() => _DeleteAddCombo(item)} style={{margin:'1px',padding:'1px',paddingLeft:'7px',paddingRight:'7px'}}><i className="fa fa-trash"></i></button></td>
                                    </tr>
                                 ))}
                            </tbody>
                        </table>
                        </div>) : (<div>Nenhum dado Encontrado</div>)}
                    </MDBModalBody>

                    <MDBModalFooter>
                            <Button variant="secondary" onClick={_handleCloseAddComboModal}>
                            Fechar
                            </Button>
                    </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>


            <MDBModal staticBackdrop show={showFotoEditModal} setShow={setShowFotoEditModal} tabIndex='-1'>
                <MDBModalDialog size='fullscreen'>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle><h4>Editar Imagem do Acessório</h4> </MDBModalTitle>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className="row">
                                <div className="col col-sm-6">
                                    <div className="box">
                                    <Cropper
                                        style={{ height: '550px', width: '100%' }}
                                        //zoomTo={1}
                                        //initialAspectRatio={1/1}
                                        //aspectRatio={1/1}
                                        preview=".img-preview"
                                        src={image}
                                        //scaleX={1}
                                        //scaleY={1}
                                        viewMode={2}
                                        center={true}
                                        minCropBoxHeight={10}
                                        minCropBoxWidth={10}
                                        background={false}
                                        responsive={true}
                                        //autoCropArea={1}
                                        checkOrientation={false}
                                        dragMode="move"
                                        /*checkCrossOrigin={true}
                                        crossOrigin={'anonymous'}*/
                                      
                                        onInitialized={(instance) => {
                                            setCropper(instance);
                                        }}
                                        guides={true}
                                        cropmove={(instance) => {
                                            let _h = $(".img-preview").height();
                                            let _w = $(".img-preview").width();
                                            let _t = (550/2-_h/2)
                                            console.log(_t)
                                            $(".img-preview").css({top: _t + 'px'});
                                        }}
                                        
                                        />
                                    </div>
                                </div>
                                <div className="col col-sm-6">
                                    
                                    <div className="box" style={{ width: "555px", height: "555px"}}>
                                        <div id="foto" className="img-preview" style={{ width: "550px", height: "550px"}} />
                                        <canvas id="canvas" style={{ width: "550px", height: "550px"}} width="550" height="550"></canvas>
                                    </div>
                                    <br />

                                    
                                   
                                   
                                </div>
                            </div>
                            
                        </MDBModalBody>

                        <MDBModalFooter>
                        <   button className="btn btn-info btn-height btn-rounded mb-2" type="button" onClick={_handlerUploadClick} style={{marginLeft:'7px'}}>Enviar nova foto</button>
                            <button className="btn btn-primary btn-height btn-rounded mb-2" type="button" onClick={() => getCropData(selectedImage.foto)} >Salvar Recorte <small>(550x550)</small></button>
                                    <input type="file" ref={hiddenFileInput} onChange={onChange} style={{display: 'none'}} accept=".jpg,.png"/>
                            <button className="btn btn-danger btn-height btn-rounded mb-2" type="button" onClick={_handleCloseFotoEditModal}>Fechar</button>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>


            
            <MDBModal staticBackdrop show={showPhotoModal} setShow={setShowPhotoModal} tabIndex='-1'>
                <MDBModalDialog size='fullscreen'>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle><h4>Camera </h4></MDBModalTitle>
                            {/*<Button className='btn btn-primary' onClick={_handleClosePhotoModal} style={{width:'25px',height:'25px', padding:0}}>x</Button>*/}
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className="mediaContent">
                                <div className="videoContainer">
                                    <video id="foto1" autoPlay ref={videoRef} style={{ width: '733px', height: '550px' }}  width="733" height="550"></video>
                                </div>
                                <div className="canvasContainer">
                                    <canvas id="canvas1" style={{ width: '550px', height: '550px', _display:'none' }} width="550" height="550"></canvas>
                                </div>
                            </div>
                        </MDBModalBody>

                        <MDBModalFooter>
                        <Button variant="primary" onClick={_handleSnapPhotoModal}>
                            Tirar Foto
                        </Button>
                        <Button variant="secondary" onClick={_handleClosePhotoModal}>
                            Fechar
                        </Button>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    )
}
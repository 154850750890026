/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef} from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request } from '../../../scripts/Request';
import { HubContext } from '../../../Context/HubContext.js';
import { WaitObject } from '../../../scripts/Wait';
import { FormGenerator } from '../../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Currency, UnCurrency, Number } from '../../../scripts/StringUtils';
import ReactGA, { set } from 'react-ga';
import { Button } from "react-bootstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import './Modelo.css';

import { MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';
import Lista from './Lista';
import { Modelo } from './Modelo';

  


export function Cadastro() {
    const component_name = "admin/acessorios_cadastro";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showAddComboModal, setShowAddComboModal] = useState(false);
    const [AddComboTitle, SetAddComboTitle] = useState(false);
    const [AddComboInfo, SetAddComboInfo] = useState({ table: "", id_field: "", name_field: "" });
    const [AddComboList, SetAddComboList] = useState([]);
    
    const linhasPrestineRef = useRef(false);
    const _formUserRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const navigate = useNavigate();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    let { Id } = useParams();
    
    const [itemId, setItemId] = useState(Id);
    const [editable, setEditable] = useState(false);
    const [editId, setEditId] = useState("");
    const editIdRef = useRef("");

    //#region Effects
     useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);

    //#endregion Effects


    //#region Init
    const Init = () => {
        
    }
    //#endregion Init



    //#region Loaders
    const LoadMarcas  = async(fornecedor_id, marca_id) => {
        Promise.resolve(new Request().Run("api/Acessorios/Marcas?fid=" + fornecedor_id, "GET", "", user.token, 0*24*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            
            if(data.toString() !== "error") {
                await _formUserRef.current.Select("marca_id", data);
                if(data.length > 0) {
                    LoadLinhas(marca_id ? marca_id : data[0].id);
                    if(user.nivel <= 1) _formUserRef.current.DisableFields(["linha_id"], true);
                }
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }


    const LoadLinhas = async(marca_id) => {
        if(marca_id === undefined || marca_id === '' || marca_id === 0) {
            await _formUserRef.current.Select("linha_id", []);
            linhasPrestineRef.current = true;
            return;
        }
        Promise.resolve(new Request().Run("api/Acessorios/Linhas?mid=" + marca_id, "GET", "", user.token, 0*24*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            linhasPrestineRef.current = true;
            await _formUserRef.current.Select("linha_id", data);
            if(data.toString() !== "error") {
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }

    //#endregion Loaders



    //#region Form Handlers
    const fornecedor_id = (value) => {
        if(linhasPrestineRef.current) LoadMarcas(value);
        if(user.nivel <= 1) _formUserRef.current.DisableFields(["marca_id"], true);
    }


    const marca_id = (value) => {
        if(linhasPrestineRef.current) LoadLinhas(value);
        if(user.nivel <= 1) _formUserRef.current.DisableFields(["linha_id"], true);
    }


    const new_marca_id = async(value) => {
        let p = prompt("Digite o nome da Marca");
        if (p != "" && p != null && p != undefined) {
            let fornecedor = await _formUserRef.current.GetValue("fornecedor_id");
            await Promise.resolve(new Request().Run("api/Acessorios/SaveMarca", "POST", { name: p, fornecedor_id: fornecedor.id }, user.token))
                .then(async(data) => {
                   await _formUserRef.current.Load({ id: data, name: p }, "marca_id", data, "append");
                   LoadLinhas(data);
                });
        }
    }


    const sup_marca_id = async () => {
        let fornecedor = await _formUserRef.current.GetValue("fornecedor_id");
        _EditAddCombo("Marcas", "acessorios_marcas", "marca_id", "nome", [{key: 'fornecedor_id', value: fornecedor.id}]);
    }


    const new_linha_id = async(value) => {
        let p = prompt("Digite o nome da Linha");
        if (p != "" && p != null && p != undefined) {
            let marca = await _formUserRef.current.GetValue("marca_id");
            await Promise.resolve(new Request().Run("api/Acessorios/SaveLinha", "POST", { name: p, marca_id: marca.id }, user.token))
                .then(async(data) => {
                   await _formUserRef.current.Load({ id: data, name: p }, "linha_id", data, "append");
                });
        }
    }


    const sup_linha_id = async () => {
        let marca = await _formUserRef.current.GetValue("marca_id");
        _EditAddCombo("Linhas", "acessorios_marcas_linhas", "linha_id", "nome", [{key: 'marca_id', value: marca.id}]);
    }





    const OnFormLoaded = async(fields) => {
        setPageLoading(true);
 
        let acessorio_data;
        WaitObject(_formUserRef.current).then(async() => {
            await _formUserRef.current.Select("fornecedor_id", [], "api/Acessorios/Fornecedores", "GET", "", user.token);
            let fornecedores = await _formUserRef.current.GetOptions("fornecedor_id");

            if(Id) {
                acessorio_data = await new Request().Run("api/Acessorios/Load?id="+itemId, "GET", "", user.token);
                //console.log(armacao_data);
                if(acessorio_data !== undefined && acessorio_data.id !== null) {
                    await _formUserRef.current.Load(acessorio_data);
                    
                    if(user.nivel > 1) {
                        _formUserRef.current.DisableFields();
                    } 

                    if(fornecedores.length > 0 && acessorio_data.fornecedor_id !== "" && acessorio_data.fornecedor_id !== 0) {
                        LoadMarcas(acessorio_data.fornecedor_id);
                        if(user.nivel <= 1) _formUserRef.current.DisableFields(["marca_id"], true);
                        if(acessorio_data.marca_id !== "" && acessorio_data.marca_id !== 0) {
                            LoadLinhas(acessorio_data.marca_id);
                            if(user.nivel <= 1) _formUserRef.current.DisableFields(["linha_id"], true);
                        }
                    }
                } 
            } else {
                let wait_fornecedor = setInterval(async() => {
                    let _fornecedores = await _formUserRef.current.GetOptions("fornecedor_id");
                    if(_fornecedores.length > 0) {
                        clearInterval(wait_fornecedor);
                        LoadMarcas(fornecedores[0].id);
                        _formUserRef.current.DisableFields(["marca_id"], true);
                    } else {
                        //console.log(1);
                    }
                }, 100);
            }

            setPageLoading(false);
  
        });
        
    }

    
    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        let validade = _formUserRef.current.RequiredValidation();

       
        if(validade) {
           
            setPageLoading(true);
            let result = await _formUserRef.current.Post("api/Acessorios/Save", {id: itemId}, user.token);
            setPageLoading(false);

            if(result === "error") {
                window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
                return;
            } 

            setItemId(result);

            window.scrollTo({top: 0, behavior: 'smooth'});
            //navigate("/Armacoes");
        } else {
            window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
        }
        
    }


    const _HandleBack = () => {
        navigate("/Acessorios");
    }


    const _HandlAddModelo = (id) => {
        editIdRef.current = id;
        setEditId(id?id:"");
        setEditable(true);
        _formUserRef.current.DisableFields();
    }

    const _HandleModeloSave = () => {
        setEditable(false);
        if(user.nivel <= 1) _formUserRef.current.DisableFields(undefined, true);
    }


    //#endregion Handlers
    

    //#region AddCombo Handlers
    const _handleCloseAddComboModal = async() => {
        setShowAddComboModal(false);
        await _formUserRef.current.Select("marcas_id", [], "api/Acessorios/Marcas", "GET", "", user.token);
    }

    const _handleSaveAddComboModal = () => {
        
    }


    const _EditAddCombo = (title, table, id_field, name_field, parms={}) => {
        SetAddComboTitle(title);
        SetAddComboInfo({ table: table, id_field: id_field, name_field: name_field });
        setShowAddComboModal(true);

       
        Promise.resolve(new Request().Run("api/Acessorios/AddComboList", "POST",{ table: table, id_field: id_field, name_field: name_field, parms}, user.token))
            .then((data) => {
                SetAddComboList(data);
            });
    }


    const _AddComboBlur = (item) => {
        let _data = Object.assign(AddComboInfo, { id: item.id, nome: item.name });
        Promise.resolve(new Request().Run("api/Acessorios/AddComboUpdate", "POST", _data, user.token))
            .then((data) => {
                let _list = [...AddComboList];
                let _listItem = _list.find(l => l.id == item.id);
                _listItem = item;
                SetAddComboList(_list);
            });
    }


    const _DeleteAddCombo = (item) => {
        let _data = Object.assign(AddComboInfo, { id: item.id });
        Promise.resolve(new Request().Run("api/Acessorios/AddComboDelete", "POST", _data, user.token))
            .then(async(data) => {
                let _list = [...AddComboList];
                let _listIndex = _list.findIndex(l => l.id == item.id);
               _list.splice(_listIndex, 1);
                SetAddComboList(_list);

                let options = await _formUserRef.current.GetOptions(_data.id_field);
                let otionIndex = options.findIndex(l => l.id === item.id);
                options.splice(otionIndex, 1);
                _formUserRef.current.Select(_data.id_field, options);
            });
    }


    const _AddComboFieldChange = (item, event) => {
        let _list = [...AddComboList];
        let _listItem = _list.find(l => l.id == item.id);
        _listItem.name = event.target.value;
        SetAddComboList(_list);
    }
    //#endregion AddCombo Handlers




    return(
        <div className="main-content AcessoriosCadastro">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Cadastro de Marcas e Acessórios</h4>
                                    <div className="align-right mr-10">
                                        <button type="button" className="btn btn-outline-primary" onClick={_HandleBack}>
                                            <i className="mdi mdi-arrow-left"></i> &nbsp;Voltar
                                        </button>
                                    </div>
                                </div>

                                <div className="card-body pb-2">
                                   
                                    <form name="fuser" onSubmit={(event) => _HandleSubmit('acessorio', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                        <div className="row">
                                            <div className="col-md-12 pt-1 pb-1">
                                                <FormGenerator ref={_formUserRef} name={'acessorio'} url={'/assets/forms/acessorio_fornecedor.json'} execRef={Exec}></FormGenerator>
                                                
                                                <center>
                                                    <button id="btn_sv" className={'btn btn-primary btn-lg btn-rounded mb-2 ' + (user.nivel > 1 || editable?'hide':'')} type="submit">Salvar informações</button>
                                                 </center>
                                                <div style={{clear:'both'}}></div>
                                                <br />
                                          
                                            </div> 
                                        </div>
                                    </form>

                                    <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-bs-toggle="tab" href="#home2" role="tab">
                                                <span className="d-block d-sm-none"><i className="fa fa-home"></i></span>
                                                <span className="d-none d-sm-block"> <i className="mdi mdi-filter-plus"></i> &nbsp; Modelos de Acessórios &nbsp;  </span> 
                                            </a>
                                        </li>
                                
                                    </ul>

                            
                                    <div className="tab-content text-muted">
                                        <div className="tab-pane active" id="home2" role="tabpanel">
                                            {
                                                (itemId === undefined || itemId === "" || itemId === 0) && (<>
                                                    <div>
                                                        <br /><br />
                                                        <center>Cadastro individual dos acessórios não disponível</center>
                                                        <br /><br />
                                                    </div>
                                                </>) 
                                            }

                                            {
                                                itemId && itemId !== "" && itemId !== 0 && (<>
                                                    { !editable && (<Lista FMarcaId={itemId} onEdit={_HandlAddModelo}/>)}
                                                    { editable && (<Modelo FMarcaId={itemId} Id={editIdRef.current} onSave={_HandleModeloSave} onClose={_HandleModeloSave} />)}
                                                    </>
                                                ) 
                                            }
                                        </div>

                                    </div>

                                    <br />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <MDBModal show={showAddComboModal} setShow={setShowAddComboModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle><h4>{AddComboTitle}</h4></MDBModalTitle>
                            <Button className='btn btn-primary' onClick={_handleCloseAddComboModal} style={{width:'25px',height:'25px', padding:0}}>x</Button>
                        </MDBModalHeader>
                        <MDBModalBody>
                        {AddComboList.length > 0 ? (<div>
                            <table className="table table-striped table-hover">
                                <tbody>
                                    {AddComboList.map((item, i) => (
                                        <tr key={'addc'+i}>
                                            <td style={{ padding: 0 }}> <input type="text" className="form-control inputfull" value={item.name} onChange={(event) => _AddComboFieldChange(item, event)} onBlur={() => _AddComboBlur(item)} /></td>
                                            <td style={{ padding: '4px', margin: 0, width: '35px' }}><button className="btn btn-white btn-sm" type="button" onClick={() => _DeleteAddCombo(item)} style={{margin:'1px',padding:'1px',paddingLeft:'7px',paddingRight:'7px'}}><i className="fa fa-trash"></i></button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div>) : (<div>Nenhum dado Encontrado</div>)}
                        </MDBModalBody>

                        <MDBModalFooter>
                                <Button variant="secondary" onClick={_handleCloseAddComboModal}>
                                Fechar
                                </Button>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    )
}
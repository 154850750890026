/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import ReactGA from 'react-ga';
import $ from 'jquery';
import './Gallery.css';


export default function Gallery(props) {
    const component_name = "admin/espelho_gallery";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [photos, setPhotos] = useState([]);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);


    const { Stream } = props;

 return (
        <img className="EspelhoGallery" src={Stream} alt="" />
    )
}
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useImperativeHandle, useEffect, useRef, forwardRef} from 'react';
import { Context } from '../../../../Context/AuthContext';
import { HubContext } from '../../../../Context/HubContext.js';
import { Request, Req } from '../../../../scripts/Request';
import { WaitObject } from '../../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import { FormGenerator } from '../../../Form/FormGenerator';
import ReactGA from 'react-ga';
import { Currency, UnCurrency, Number } from '../../../../scripts/StringUtils';
import './index.css';



let current_element;


export const Acessorios = forwardRef((props, ref) => {
    const component_name = "admin/atendimento_acessorios";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [selectedList, setSelectedList] = useState([]);
    const [list, setList] = useState([]);
    const [marcas, setMarcas] = useState([]);
    const [fornecedores, setFornecedores] = useState([]);
    const [search, setSearch] = useState({ word: "", marca_id: "", fornecedor_id:""});
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: "", marca_id: "", fornecedor_id:""});



    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(current_element? current_element :".main-content")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects


    //#region Ref
    useImperativeHandle(ref, (url, args, token) => ({
        async Init() { return Init() },
        async Clear() { return ClearComponent() }
    }));
    //#endregion Ref


    //#region Init
    const Init = async() => {
        let current_atend = await Hub.Get("current_atend", true);
        if(current_atend.acessorios) {
            setSelectedList(current_atend.acessorios);
        }

        LoadFornecedores();
        _HandlerSearchChange();
    }


    const ClearComponent = () => {
        setSearch([]);
        setSelectedList([]);
    }
    //#endregion Init



    //#region Loaders
    const LoadFornecedores = () => {
        Promise.resolve(new Request().Run("api/AtendimentoAcessorios/Fornecedores", "GET", "", user.token, 0*24*60*60*1000))
        .then(async(data) => {
            if(data.toString() !== "error") {
                setFornecedores(data);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATACE001)", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATACE002)", "error");
        });
    }


    const LoadMarcas  = async(fornecedor_id) => {
        Promise.resolve(new Request().Run("api/AtendimentoAcessorios/Marcas?fid=" + fornecedor_id, "GET", "", user.token, 10*1000))
        .then(async(data) => {
            if(data.toString() !== "error") {
                setMarcas(data)
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATACE003)", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATACE004)", "error");
        });
    }
    //#endregion Loaders



    //#region Handlers
    const _HandleChange = (event) => {
        setSearch(prev => prev = {[event.target.id]: event.target.value});
        searchRef.current = {[event.target.id]: event.target.value};

        if(event.target.id ==="fornecedor_id") {
            LoadMarcas(event.target.value);
        }
    }


    const _HandleClear = (event) => {
        setSearch(prev => prev = {word: ""});
        searchRef.current = {word: ""};
        _HandlerSearchChange(event);
    }


    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setPageLoading(true);
        setHasMore(true);

        current_element = "search-list";
        Promise.resolve(new Request().Run("api/AtendimentoAcessorios/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
            .then(async(data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];
                listRef.current = listRef.current.concat(_list);
                setList(listRef.current);
                setPageLoading(false);
                current_element = "";
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setPageLoading(true);

            current_element = "search-list";
            Promise.resolve(new Request().Run("api/AtendimentoAcessorios/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    listRef.current = listRef.current.concat(_list);
                    setList(listRef.current);
                    setPageLoading(false);
                    current_element = "";
                });
        } catch (e) { }
    }



    const _HandleItemChange = (event, item) => {

    }


    const _HandleAcessorioClick = async(item) => {
        let _sel =  [...selectedList];
        _sel.unshift(item);
        //if(_sel.findIndex(s => s.acessorio_id===item.acessorio_id) === -1) {
            setSelectedList(_sel);
        //}


        let _list = [...list];
        let _index = _list.findIndex(l => l.acessorio_id === item.acessorio_id);
        if(_list[_index].qtd>0) {
            _list[_index].qtd--;
            setList(_list);
        }

        Hub.Update("current_atend", "acessorios", _sel, true);
    }


    const _HandleDelete = (item, index) => {
        let _sel = [...selectedList];
        _sel.splice(index, 1);
        setSelectedList(_sel);


        let _list = [...list];
        let _index = _list.findIndex(l => l.acessorio_id === item.acessorio_id);
        _list[_index].qtd++;
        setList(_list);
        
        Hub.Update("current_atend", "acessorios", _sel, true);
    }


    const _HandleNext = (item, index) => {
        props.OnNext();
    }
    //#endregion Handlers


    return (
        <div className="AtendimentoAcessorios">
            <br />
            <div className="row"> 
                <div className="col-sm-3"> 
                    <div className="mr-10">
                        <button type="button" className="btn btn-outline-primary" onClick={_HandleNext}>
                            <i className="bx bx-search"></i> &nbsp;Avançar para finalizar
                        </button>
                    </div>
                </div>

                <div className="col-sm-9"> 
                    <div className="align-right mr-10">
                        <button type="button" className="btn btn-primary" onClick={_HandlerSearchChange}>
                            <i className="mdi mdi-share-outline"></i> &nbsp;Buscar
                        </button>
                    </div>

                    <div className="search-box align-right mr-10">
                        <div className="position-relative">
                            <select id="marca_id" className="form-select" value={search.marca_id} onChange={_HandleChange} disabled={search.fornecedor_id===''}>
                                <option value="">Qualquer marca</option>
                                {
                                        Array.isArray(marcas) && marcas.length > 0 && marcas.map((item, i) => (
                                            <option value={item.id}>{item.name}</option>
                                        ))
                                }
                            </select>
                        </div>
                    </div> 

                    <div className="search-box align-right mr-10">
                        <div className="position-relative">
                            <select id="fornecedor_id" className="form-select" value={search.fornecedor_id} onChange={_HandleChange}>
                                <option value="">Qualquer fornecedor</option>
                                {
                                        Array.isArray(fornecedores) && fornecedores.length > 0 && fornecedores.map((item, i) => (
                                            <option value={item.id}>{item.name}</option>
                                        ))
                                }
                            </select>
                        </div>
                    </div> 

                    <div className="search-box align-right mr-10">
                        <div className="position-relative">
                            <input id="word" typeName="text" value={search.word} className="form-control rounded bg-light border-0" placeholder="" onChange={_HandleChange}/>
                            <i className="bx bx-search search-icon"></i>
                        </div>
                    </div> 
                </div>
            </div>
            <br />

            <div className="row">
                <div className="col-sm-4">
                    <div className="card-body pb-0">
                        <div className="table-responsive selected-list">
                            <table className="table table-bordered mb-0">
                                <thead>
                                    <tr>
                                        <th>Modelo</th>
                                        <th>Valor</th>
                                        <th style={{width:'40px'}}></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        Array.isArray(selectedList) && selectedList.length > 0 && selectedList.map((item, i) => (
                                            <tr key={'slist'+i}>
                                                <td>{item.modelo}</td>
                                                <td>{Currency(item.venda)}</td>
                                                <td><button type="button" className="btn btn-sm btn-primary" onClick={() =>_HandleDelete(item, i)}><i class="fa fa-trash"></i></button></td>
                                            </tr>
                                        ))
                                    }

                                    {
                                        selectedList.length === 0 && (
                                            <tr>
                                                <td colSpan={3}>
                                                    <br/><br/>
                                                    <center>Nenhum acessório adicionado</center>
                                                    <br/><br/>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>

                                <tfoot>
                                    <tr>
                                        <td colSpan={3}>TOTAL &nbsp; {Currency(selectedList.reduce((p, c) => p + c.venda, 0))} &nbsp;</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="col-sm-8"> 
                
                    <div className="card-body pb-0">
                        <div className="table-responsive search-list">
                            <InfiniteScroll
                                dataLength={list.length}
                                next={_fetchMoreData}
                                hasMore={hasMore}
                                loader={
                                    <p style={{ textAlign: "center", padding:'25px' }}>
                                        <b>Carregando...</b>
                                    </p>
                                }
                                style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                endMessage={
                                    <p style={{ textAlign: "center", padding:'25px' }}>
                                        <b>Não há mais dados</b>
                                    </p>
                                }>    

                                <table className="table table-bordered table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th>Modelo</th>
                                            <th>Marca</th>
                                            <th>Fornecedor</th>
                                            <th>Valor</th>
                                            <th>Qtd</th>
                                        </tr>
                                    </thead>


                                    <tbody>
                                    {list.map((item, i) => (
                                        <tr key={'tr' + i} className={'pointer '} onClick={() => _HandleAcessorioClick(item)}>  
                                            <td>
                                                {item.modelo}
                                            </td>  
                                            <td>
                                                {item.marca}
                                            </td> 
                                            <td>
                                                {item.fornecedor}
                                            </td>
                                            <td>
                                                {Currency(item.venda)}
                                            </td>
                                            <td>
                                                {item.qtd}
                                            </td>
                                        </tr>
                                    ))}
                                    
                                    </tbody>
                                </table>
                            </InfiniteScroll>
                        </div>
                    </div>
                
                </div>
            </div>
        </div>
    )
})
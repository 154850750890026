/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef} from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request, Req } from '../../../scripts/Request';
import { HubContext } from '../../../Context/HubContext.js';
import { WaitObject } from '../../../scripts/Wait';
import { FormGenerator } from '../../Form/FormGenerator';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from "react-bootstrap";
import ReactGA from 'react-ga';


export function Cadastro() {
    const component_name = "admin/coloracao_cadastro";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [unidades, setUnidades] = useState([]);
    const _formUserRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const navigate = useNavigate();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    let { Id } = useParams();



    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                    
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        
    }
    //#endregion Init



    //#region Loaders

    //#endregion Loaders



     //#region Form Handlers

    const OnFormLoaded = async(fields) => {
        setPageLoading(true);
        
 
        WaitObject(_formUserRef.current).then(async() => {

            if(Id) {
                let color_data = await new Request().Run("api/Coloracoes/Load?id="+Id, "GET", "", user.token);
                if(color_data !== undefined && color_data.user_id !== null) {
                    await _formUserRef.current.Load(color_data);
                }
            } 
            setPageLoading(false);
        });
        
    }

    
    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        let validade = _formUserRef.current.RequiredValidation();

        if(validade) {
            setPageLoading(true);

           

            let result = await _formUserRef.current.Post("api/Coloracoes/Save", {color_id: Id}, user.token);
            setPageLoading(false);

            if(result === "error") {
                window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
                return;
            } else {
                window.swal("Sucesso", "Coloração salvo com sucesso!", "success");
                navigate("/Coloracoes");
            }

       } else {
           window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
       }
    }
    //#endregion Handlers


    return (
        <div className="main-content Coloracao">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Cadastro de Coloração</h4>
                                </div>

                                <div className="card-body pb-2">
                                   
                                    <form name="fcoloracoes" onSubmit={(event) => _HandleSubmit('coloracoes', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                        <div className="row">
                                            <div className="col-md-12 pt-1 pb-1">
                                                <FormGenerator ref={_formUserRef} name={'coloracoes'} url={'/assets/forms/coloracoes.json'} execRef={Exec}></FormGenerator>
                                                <div style={{clear:'both'}}></div>
                                                <center>
                                                    <hr />
                                                    <button id="btn_sv" className={'btn btn-primary btn-lg btn-rounded mb-2 ' } type="submit">Salvar informações</button>
                                                </center>
                                                <br />
                                            </div> 
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>


            
        </div>
    )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request } from '../../../scripts/Request';
import { HubObjects } from '../../../scripts/HubObjects';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import { Currency, UnCurrency, Number } from '../../../scripts/StringUtils';
import { Form, useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import './Lista.css';


export default function Lista({...props}) {
    const component_name = "admin/armacoes_lista";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedItems, setSelectedItems] =  useState([]);
    const [list, setList] = useState([]);
    const [cores, setCores] = useState([]);
    const [fornecedores, setFornecedores] = useState([]);
    const [search, setSearch] = useState({ word: "", fmarca_id: ""});
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: "", fornecedor_id: "", selected: "" });

    const navigate = useNavigate();
    const {FMarcaId} = props;

    
    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects


     //#region Init
     const Init = () => {
        LoadCores(FMarcaId);
        _HandlerSearchChange();
     }
    //#endregion Init



    //#region Loaders
    const LoadCores  = async(marca_id) => {
        Promise.resolve(new Request().Run("api/Armacoes/Cores?mid=" + marca_id, "GET", "", user.token, 0*24*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            
            if(data.toString() !== "error") {
                setCores(data);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }
    //#endregion Loaders



    //#region Handlers
    const _HandleChange = (event) => {
        setSearch(prev => prev = {[event.target.id]: event.target.value});
        searchRef.current = {[event.target.id]: event.target.value};
    }


    const _HandleClear = (event) => {
        setSearch(prev => prev = {word: ""});
        searchRef.current = {word: ""};
        _HandlerSearchChange(event);
    }


    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setPageLoading(true);
        setHasMore(true);

        searchRef.current.fmarca_id = FMarcaId;

        Promise.resolve(new Request().Run("api/Armacoes/Modelos/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
            .then(async(data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];
                listRef.current = listRef.current.concat(_list);
                setList(listRef.current);
                setPageLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setPageLoading(true);

            searchRef.current.fmarca_id = FMarcaId;

            Promise.resolve(new Request().Run("api/Armacoes/Modelos/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    listRef.current = listRef.current.concat(_list);
                    setList(listRef.current);
                    setPageLoading(false);
                });
        } catch (e) { }
    }






    const _HandleEdit = (e, id) => {
        navigate("/Armacao/"+id);
    }


    const _HandleAddArmacao = () => {
        navigate("/Armacao/");
    }


    const _HandleItemChange = (event, field) => {
        let _list = [...list];

        let _index = parseInt(event.target.id.split('_')[1]);
        let _old = Object.assign({},  _list[_index]);
       

        if(field === "compra") {
            _list[_index][field] = UnCurrency(event.target.value);
        } else if(field === "venda") {
            _list[_index][field] = UnCurrency(event.target.value);
            let compra = list[_index]["compra"];
            compra = UnCurrency(compra);
            let value =_list[_index][field];
            let indice = ((value / compra) * 100) - 100;
            _list[_index]['indice'] = indice;
        } else if(field === "indice") {
            let compra = list[_index]["compra"];
            compra = UnCurrency(compra);
            let value = parseFloat(event.target.value);
            let venda = compra + (compra * value) / 100 ;
            _list[_index]['indice'] = Currency(venda);
        } else {
            if(event.target.type==="checkbox") {
                _list[_index][field] = (event.target.checked ? 1 : 0);
            } else {
                _list[_index][field] = event.target.value;
            }
        }

        setList(_list);

        if(_index > -1) {
            let _selectedItems = [...selectedItems];
            let _arrindex = _selectedItems?.findIndex(s => s.index === _index);
            if(_arrindex === -1) {
                _selectedItems.push({index: _index, data: _list[_index], old: _old });
            } else {
                //_selectedItems[_arrindex] = {index: _index, data: _list[_index], old: _old };
            }
            setSelectedItems(_selectedItems);
        }
    }


    const _HandleSaveItem = (e, i , item) => {
        Promise.resolve(new Request().Run("api/Armacoes/UpdateModelo", "POST", item, user.token))
        .then((data) => {
            let _selectedItems = [...selectedItems];
            let _index = _selectedItems?.findIndex(s => s.index === i);
            _selectedItems.splice(_index, 1);
            setSelectedItems(_selectedItems);
        });
    }


    const _HandleCancelItem = (e, i , item) => {
        let _list = [...list];
        let _selectedItems = [...selectedItems];

        let _index = _selectedItems?.findIndex(s => s.index === i);

        if(_index > -1) {
            _list[i] = Object.assign({},  _selectedItems[_index].old);
            _selectedItems.splice(_index, 1);
            setSelectedItems(_selectedItems);
            setList(_list);
        }
    }


    const _HandleReset = (event, field) => {
        let _list = [...list];
        let _index = parseInt(event.target.id.split('_')[1]);

        let _old = (_list[_index]) ? _list[_index] : Object.assign({},  _list[_index]);

        if(field === "adicao1" || field === "adicao2") {
            _list[_index][field] = '0,00';
        } else if(field === "valor") {
            _list[_index][field] = Currency(0);
        } else {
            _list[_index][field] = "";
        }
        setList(_list);

        if(_index > -1) {
            let _selectedItems = [...selectedItems];
            _selectedItems.push({index: _index, data: _list[_index], old: _old });
            setSelectedItems(_selectedItems);
        }
    }


    const _HandleAddModelo = (id) => {
        props.onEdit(id?id:"");
    }
    //#endregion Handlers


    return (
        <div className="_card ALista">
            <div className="card-header _justify-content-between _d-flex _align-items-center">
                <h4 className="card-title"> </h4>

                <div className="row"> 
                    <div className="col-xl-12"> 
                        <div className={'align-right ' + (user.nivel>1?'hide':'')}>
                            <button type="button" className="btn btn-primary" onClick={() => _HandleAddModelo("")}>
                                <i className="mdi mdi-star-plus"></i> &nbsp;Adicionar
                            </button>
                        </div>

                        <div className="align-right mr-10">
                            <button type="button" className="btn btn-outline-primary" onClick={_HandlerSearchChange}>
                                <i className="mdi mdi-share-outline"></i> &nbsp;Buscar
                            </button>
                        </div>

                        <div className="search-box align-right mr-10">
                            <div className="position-relative">
                                <input id="word" typeName="text" value={search.word} className="form-control rounded bg-light border-0" placeholder="" onChange={_HandleChange}/>
                                <i className="bx bx-search search-icon"></i>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>

            <div className="card-body pb-0">
                <div className="table-responsive">
                    <InfiniteScroll
                        dataLength={list.length}
                        next={_fetchMoreData}
                        hasMore={hasMore}
                        loader={
                            <p style={{ textAlign: "center", padding:'25px' }}>
                                <b>Carregando...</b>
                            </p>
                        }
                        style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                        endMessage={
                            <p style={{ textAlign: "center", padding:'25px' }}>
                                <b>Não há mais dados</b>
                            </p>
                        }>    

                        <table className="table table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th className="x1335">Cod.Fab.</th>
                                    <th className="x1140">Cor</th>
                                    <th>Modelo</th>
                                    <th className="infantil">Infantil</th>
                                    <th className="jovem">Jovem</th>
                                    <th className="adulto">Adulto</th>
                                    <th className="jovpres">Jov.Prés</th>
                                    <th className="presb">Présb.</th>
                                    <th className="x1065">Nasal</th>
                                    <th className="x1280">Tipo</th>
                                    <th className="x1370">Sexo</th>
                                    <th>Material</th>
                                    <th>Formato</th>
                                    <th>Aro</th>
                                    <th>Altura</th>
                                    <th>Diag.</th>
                                    <th>Ponte</th>
                                    <th>Compra</th>
                                    <th>Venda</th>
                                    <th className="x1210">Indice</th>
                                    <th className="x1210">Qtd</th>
                                    <th style={{width:'50px'}} className={(user.nivel>1?'hide':'')}></th>
                                </tr>
                            </thead>


                            <tbody>
                            {list.map((item, i) => (
                                <tr key={'tr' + i} className={'pointer '+  (selectedItems?.findIndex(s => s.index === i) > -1 ? 'trfocus':'')}>
                                    <td className="inputtext cfab x1335">
                                        <input id={'codfab_'+i} type="text" value={item.cod_fabricacao} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'cod_fabricacao')}/>
                                    </td>       

                                    <td className="inputtext cor x1140">
                                        <select id={"cor_"+i} value={item.cor_id} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'cor_id')}>
                                            {
                                                Array.isArray(cores) && cores.length > 0 && cores.map((cor, j) =>(
                                                    <option key={'cor'+i+'_'+j} value={cor.id}>{cor.name}</option>
                                                ))
                                            }
                                        </select>
                                    </td> 

                                    <td className="inputtext modelo">
                                        <input id={'modelo_'+i} type="text" value={item.modelo} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'modelo')}/>
                                    </td> 

                                    <td className="inputcheckbox infantil">            
                                        <input id={"infantil_"+i} type="checkbox" checked={item.infantil===1} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'infantil')}/>
                                    </td>

                                    <td className="inputcheckbox jovem">            
                                        <input id={"jovem_"+i} type="checkbox" checked={item.jovem===1} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'jovem')}/>
                                    </td>

                                    <td className="inputcheckbox adulto">            
                                        <input id={"adulto_"+i} type="checkbox" checked={item.adulto===1} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'adulto')}/>
                                    </td>

                                    <td className="inputcheckbox jovpres">            
                                        <input id={"jovpres_"+i} type="checkbox" checked={item.jovpres===1} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'jovpres')}/>
                                    </td>

                                    <td className="inputcheckbox presb">            
                                        <input id={"presb_"+i} type="checkbox" checked={item.presb===1} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'presb')}/>
                                    </td>

                                    <td className="inputtext nasal x1065">
                                        <select id={"nasal_"+i} value={item.nasal} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'nasal')}>
                                            <option value="1">Superior</option>
                                            <option value="2">Intermediario</option>
                                            <option value="3">Médio</option>
                                            <option value="4">Inferior</option>
                                        </select>
                                    </td>

                                    <td className="inputtext tipo x1280">
                                        <select id={"tipo_"+i} value={item.tipo} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'tipo')}>
                                            <option value="1">Fechada</option>
                                            <option value="2">Nylon</option>
                                            <option value="3">Parafusada</option>
                                            <option value="4">Solar</option>
                                        </select>
                                    </td>

                                    <td className="inputtext genero x1370">
                                        <select id={"genero_"+i} value={item.genero} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'genero')}>
                                            <option value="1">M</option>
                                            <option value="2">F</option>
                                            <option value="3">U</option>
                                        </select>
                                    </td>

                                    <td className="inputtext material">
                                        <select id={"material_"+i} value={item.material} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'material')}>
                                            <option value="1">Metal</option>
                                            <option value="2">Acetato</option>
                                        </select>
                                    </td>

                                    <td className="inputtext formato">
                                        <select id={"formato_"+i} value={item.formato} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'formato')}>
                                            <option value="1">Aviador</option>
                                            <option value="2">Browline</option>
                                            <option value="3">Olho de gato</option>
                                            <option value="4">Oval</option>
                                            <option value="5">Quadrado</option>
                                            <option value="6">Redondo</option>
                                            <option value="7">Retangular</option>
                                            <option value="8">Wayfarer</option>
                                        </select>
                                    </td>

                                    <td className="inputtext aro">
                                        <input id={'aro_'+i} type="text" value={item.aro} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'aro')}/>
                                    </td>  

                                    <td className="inputtext altura">
                                        <input id={'altura_'+i} type="text" value={item.altura} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'altura')}/>
                                    </td> 

                                    <td className="inputtext diag">
                                        <input id={'diag_'+i} type="text" value={item.diag} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'diag')}/>
                                    </td> 

                                    <td className="inputtext ponte">
                                        <input id={'ponte_'+i} type="text" value={item.ponte} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'ponte')}/>
                                    </td> 

                                    <td className="inputtext compra ">
                                        <input id={"compra_"+i}  type="text" value={Currency(item.compra)} onChange={(event) => _HandleItemChange(event, 'compra')} onDoubleClick={(event) => _HandleReset(event, 'compra')} disabled={(user.nivel>1)}/>
                                    </td>
                                    
                                    <td className="inputtext venda">
                                        <input id={"venda_"+i} type="text" value={Currency(item.venda)} onChange={(event) => _HandleItemChange(event, 'venda')} onDoubleClick={(event) => _HandleReset(event, 'venda')} disabled={(user.nivel>1)}/>
                                    </td>

                                    <td className="inputtext indice x1210">
                                        <input id={"indice_"+i}  type="text" value={item.indice} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'indice')} onDoubleClick={(event) => _HandleReset(event, 'indice')}/>
                                    </td>

                                    <td className="inputtext qtd x1210">
                                        <input id={"qtd_"+i}  type="text" value={item.qtd} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'qtd')} onDoubleClick={(event) => _HandleReset(event, 'qtd')}/>
                                    </td>

                                    <td className={'edit ' + ((selectedItems?.findIndex(s => s.index === i) > -1)||(user.nivel>6)?'hide':'')} > 
                                        <button type="button" className={'btn btn-sm btn-info '} onClick={() => _HandleAddModelo(item.armacao_id)}><i className="fa fa-edit"></i></button>
                                    </td>

                                    <td className={'edit ' +  ((selectedItems?.findIndex(s => s.index === i) === -1)||(user.nivel>1)?'hide':'')} > 
                                        <button type="button" className={'btn btn-sm btn_ativo btn-danger '} onClick={(e) => _HandleSaveItem(e, i, item)}><i className="fa fa-check"></i></button>
                                    </td>

                                    <td className={'edit ' +  ((selectedItems?.findIndex(s => s.index === i) === -1)||(user.nivel>1)?'hide':'')} > 
                                        <button type="button" className={'btn btn-sm btn-warning '} onClick={(e) => _HandleCancelItem(e, i, item)}><i className="fa fa-reply"></i></button>
                                    </td>
                                </tr>
                            ))}
                            
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    )
}
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Context } from '../../Context/AuthContext';
import { HubContext } from '../../Context/HubContext.js';
import { Request, Req } from '../../scripts/Request';
import { WaitObject } from '../../scripts/Wait';
import ReactGA from 'react-ga';
import { Currency, UnCurrency, Number } from '../../scripts/StringUtils';
import moment from 'moment/moment';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import './index.css';



export const Orcamento = forwardRef((props, ref) => {
    const component_name = "admina/orcamento";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [atend, setAtend] = useState({});
    const [unidade, setUnidade]= useState({});
    const [orNumber, setOrNumber] = useState("000000");
    const [prazo, setPrazo] = useState("");
    const [valores, setValores] = useState({compra:0, venda:0, total:0, tratamentos:0, desconto: 0});
    const [saved, setSaved] = useState(false);

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const atendRef = useRef({});


    //#region Effects
    useEffect(() => {
        if(!loadPrestineRef.current) {
            loadPrestineRef.current = true;
            WaitObject(user.nome).then(() => {
                Init();
                LoadUnidadeInfo();
            });
        }

        return () => {
            
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);
    //#region Effects


    //#region Init
    const Init = async() => {
        let current_atend = await Hub.Get("current_atend", true);
        if(!current_atend) return;

        //console.log(current_atend);

        let dt_od = undefined;
        let dt_oe = undefined;

        if(current_atend?.orcamento_id > 0) {
            setSaved(true);
        } else {
            setSaved(false);
        }

        if(current_atend?.or_numero) setOrNumber(current_atend?.or_numero);

        if(current_atend?.os?.od?.prazo && current_atend?.os?.od?.prazo !== "") {
            dt_od = moment(current_atend?.os?.od?.prazo);
        }

        if(current_atend?.os?.oe?.prazo && current_atend?.os?.oe?.prazo !== "") {
            dt_oe = moment(current_atend?.os?.oe?.prazo);
        }

        if(dt_od && !dt_oe) {
            setPrazo(current_atend?.os?.od?.prazo);
        } else if(!dt_od && dt_oe) {
            setPrazo(current_atend?.os?.oe?.prazo);
        } else if(dt_od && dt_oe) {
            if(dt_od > dt_oe) {
                setPrazo(current_atend?.os?.od?.prazo);
            } else {
                setPrazo(current_atend?.os?.oe?.prazo);
            }
        }

        let _venda = (current_atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0)+(current_atend?.os?.od?.lente?.venda/2||0)+(current_atend?.os?.oe?.lente?.venda/2||0)+(UnCurrency(atend?.armacao?.form?.venda)||0);
        let _compra = (current_atend?.acessorios?.reduce((p, c) => p + c.compra, 0)||0)+(current_atend?.os?.od?.lente?.compra/2||0)+(current_atend?.os?.oe?.lente?.compra/2||0)+(current_atend?.armacao?.form?.compra||0);
        
        let _total = (current_atend?.os?.oe?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
        (current_atend?.os?.od?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+ 
        (current_atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0)+
        (current_atend?.os?.od?.lente?.venda/2||0)+(current_atend?.os?.oe?.lente?.venda/2||0)+
        (current_atend?.os?.od?.lente?.color?.valor||0)+(current_atend?.os?.oe?.lente?.color?.valor||0)+
        (UnCurrency(current_atend?.armacao?.form?.venda)||0);

        let _desconto = (current_atend?.desconto||0);
        let _tratamentos = (current_atend?.os?.oe?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+(current_atend?.os?.od?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0);

        setValores({compra:_compra, venda:_venda , total:_total, desconto: _desconto, tratamentos: _tratamentos});
        //console.log({compra:_compra, venda:_venda , total: _total, desconto: _desconto});

        current_atend = {...current_atend, valores: {compra:_compra, venda:_venda , total:_total, desconto: _desconto, tratamentos: _tratamentos}};
        setAtend(current_atend);
        await Hub.Set("current_atend", current_atend, undefined, true);
    }


    const Update = async(patend) => {
        setTimeout(async()=> {
            let _atend = patend ? patend : atend;

            //console.log(_atend);

            if(_atend?.orcamento_id && _atend?.orcamento_id > 0) {
                let data = await Req("api/AtendimentoFinalizar/UpdateOrcamento", "POST", _atend, user.token, 0);
            }
        }, 300);

    }
    //#endregion Init


    
    //#region Ref
    useImperativeHandle(ref, (url, args, token) => ({
        async Init() { return Init() },
        async Update(atend_data) { return Update() }
    }));
    //#endregion Ref



    //#region Loaders 
    const LoadUnidadeInfo = async() => {
        let data = await Req("api/AtendimentoLentes/Unidade?q=" + user.user_id, "GET", "", user.token, 300*1000);

        if(data.toString() !== "error") {
            setUnidade(data);
        } else {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde ", "error");
        }
    }
    //#endregion Loaders


    //#region Handlers
    const _HandleSave = async() => {
        let data = await Req("api/AtendimentoFinalizar/SaveOrcamento", "POST", atend, user.token, 0);

        if(data && data?.numero && data.toString() !== "error") {
            setOrNumber(data?.numero);
            setSaved(true);

            window.swal("Sucesso", "Orçamento salvo com sucesso", "success");
            await Hub.Update("current_atend", "or_numero", data?.numero, true);
            await Hub.Update("current_atend", "orcamento_id", data?.orcamento_id, true);
        } else {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde ", "error");
        }
    }


    const _HandlePrint = () => {
        window.$(".or_body").printThis({
            debug:false,                   // show the iframe for debugging
            importCSS: true,                // import parent page css
            importStyle: true,             // import style tags
            printContainer: true,           // grab outer container as well as the contents of the selector
            //loadCSS: "/assets/css/orcamento_print.css",      // path to additional css file - use an array [] for multiple
            pageTitle: "ORÇAMENTO",                  // add title to print page
            removeInline: false,            // remove all inline styles from print elements
            //removeInlineSelector: "body *", // custom selectors to filter inline styles. removeInline must be true
            printDelay: 333,                // variable print delay
            header: null,                   // prefix to html
            footer: null,                   // postfix to html
            base: false,                    // preserve the BASE tag, or accept a string for the URL
            formValues: true,               // preserve input/form values
            canvas: false,                  // copy canvas elements
            //doctypeString: '...',           // enter a different doctype for older markup
            removeScripts: false,           // remove script tags from print content
            copyTagClasses: false,          // copy classes from the html & body tag
            beforePrintEvent: null,         // callback function for printEvent in iframe
            beforePrint: null,              // function called before iframe is filled
            afterPrint: null                // function called before iframe is removed
        });

        props.OnSave();
    }


    const _HandleFinalizar = () => {
        Hub.Exec("CLEAR_SESSION", component_name);
    }
    //#endregion Handlers



    //#region Whatsapp
      const _HandleWhatsapp = async() => {
        let current_atend = await Hub.Get("current_atend", true);

        if(current_atend?.cliente?.celular) {
            let cel = current_atend?.cliente?.celular.replace('-','').replace('(','').replace(')','').replace('.','').replace(' ','');

            if(cel.substr(0,2) !== "55") cel = "55" + cel;

            if(cel.length < 13) {
                window.swal("Alerta", "Número de Whatsapp inválido", "error");
                return;
            }



            
            const quality = 1;
            html2canvas(document.querySelector('#or_body'),
                { 	
                    allowTaint: true,
                    scale: 1 
                }
            ).then(async canvas => {
                const pdf = new jsPDF();
                pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, canvas.width*0.2, canvas.height*0.2);
                //pdf.save();
    
                
                let blobPDF = new Blob([pdf.output('blob')], { type: 'application/pdf'});
                let blobUrl = URL.createObjectURL(blobPDF);
    

                let base64 = await convertBase64(blobPDF);
                let files_list = [];
                files_list.push({ "name": orNumber + ".pdf", "size": blobPDF.size, "type": blobPDF.type, "base64":  base64 });
    
                let _contacts = [];
                _contacts.push( {number: cel, name: current_atend?.cliente?.nome, message: "Olá " + current_atend?.cliente?.nome + ", orçamento Nº " + orNumber +  " emitido em: " + unidade?.nome + ", " + unidade?.endereco + " " + unidade?.numero + " - " + unidade?.bairro + " " + unidade?.cidade + "/" + unidade?.uf})
                
            
                //console.log(files_list[0]);

                let event = new CustomEvent('Event', { 'detail': {
                    message:"Olá @name, seu pedido está pronto para retirada ", 
                    attachments:[], 
                    files: files_list, 
                    list: _contacts
                }});
                event.initEvent('whatsnap_api_broadcast');
                document.dispatchEvent(event);

                props.OnSave();
            });
        }


    }
    //#endregion Whatsapp


    //#region Functions
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            try {
                fileReader.readAsDataURL(file);
            } catch(e) {
                console.log("try", e);
                reject(e);
            }
    
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
    
            fileReader.onerror = (error) => {
                console.log(error);
                reject(error);
            };
        });
    };
    //#endregion Functions



    return (
        <div className={'OR'}>
            <div id="or_body" className={'or_body'}>
               
                <div className={'or_painel'}>
                    <div className={'or_head'}>
                        <div className={'or_head_title'}>
                            <div><span>ORÇAMENTO</span></div>
                            <div>{moment(new Date()).format('DD/MM/YYYY')}</div>
                            <div>Ref: <span>{orNumber}</span></div>
                        </div>
                        <div className="or_stakeholders os_datas">
                            Cliente(a): <span>{atend?.cliente?.nome}</span> &nbsp;-&nbsp; CPF: <span>{atend?.cliente?.cpf}</span><br />
                            Telefone: <span>{atend?.cliente?.telefone}</span> &nbsp;-&nbsp; Celular: <span>{atend?.cliente?.celular}</span><br />
                            Vendedor(a): <span>{user.nome}</span> &nbsp;-&nbsp; Loja: <span>{unidade?.nome}</span>
                        </div>
                    </div>

                    <div className={'or_receita bt'}>
                        <div><span>RECEITA</span></div>
                        <div>
                            <table className="table ">
                                <thead>
                                    <tr>
                                        <th colSpan={2}></th>
                                        <th className={'th_null'} >esf.</th>
                                        <th className={'th_null'} >cil.</th>
                                        <th className={'th_null'} >eixo</th>
                                        <th className={'th_null'} >dnp</th>
                                        <th className={'th_null'} >adi.</th>
                                        <th className={'th_null'} >alt.</th>
                                    </tr>
                                </thead>
                                
                                <tbody>
                                    <tr>
                                        <td className={'td_dist'} rowSpan={2}>LONGE</td>
                                        <td>OD</td>
                                        <td>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_od_esf}</td>
                                        <td>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_od_cil}</td>
                                        <td>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_od_eixo}</td>
                                        <td>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_od_dnp}</td>
                                        <td>{atend?.receita?.distancia_focal!==3?'':atend?.receita?.longe_od_adc}</td>
                                        <td>{atend?.armacao?.altura_od===0?'':atend?.armacao?.altura_od}</td>
                                    </tr>
                                    <tr>
                                       
                                        <td>OE</td>
                                        <td>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_oe_esf}</td>
                                        <td>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_oe_cil}</td>
                                        <td>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_oe_eixo}</td>
                                        <td>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_oe_dnp}</td>
                                        <td>{atend?.receita?.distancia_focal!==3?'':atend?.receita?.longe_oe_adc}</td>
                                        <td>{atend?.armacao?.altura_oe===0?'':atend?.armacao?.altura_oe}</td>
                                    </tr>

                                    <tr>
                                        <td className={'td_dist'} rowSpan={2}>PERTO</td>
                                        <td>OD</td>
                                        <td>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_od_esf}</td>
                                        <td>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_od_cil}</td>
                                        <td>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_od_eixo}</td>
                                        <td>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_od_dnp}</td>
                                        <td>{atend?.receita?.perto_od_adc}</td>
                                        <td></td>
                                    </tr>
                                    <tr>   
                                        <td>OE</td>
                                        <td>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_oe_esf}</td>
                                        <td>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_oe_cil}</td>
                                        <td>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_oe_eixo}</td>
                                        <td>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_oe_dnp}</td>
                                        <td>{atend?.receita?.perto_oe_adc}</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className={'or_datas os_medico bt'}>
                        MÉDICO: <span>{atend?.medico?.nome}</span> &nbsp;-&nbsp; TEL: <span>{(atend?.medico?.telefone!==''?atend?.medico?.telefone:atend?.medico?.celular)}</span>
                    </div>

                    <div className={'or_armacao bt'}>
                        <div><span>ARMAÇÃO</span></div>
                        <div>{atend?.armacao?.form?.marca} - {atend?.armacao?.form?.modelo} <span className="right">{Currency(atend?.armacao?.form?.venda)}</span></div>
                        <div>
                        Ponte: <span>{atend?.armacao?.form?.ponte}</span> &nbsp;-&nbsp; Aro: <span>{atend?.armacao?.form?.aro}</span> &nbsp;-&nbsp; Diagonal: <span>{atend?.armacao?.form?.diag}</span> &nbsp;-&nbsp; Vertical: <span>{atend?.armacao?.form?.altura}</span>
                        </div>
                        
                    </div>

                    <div className={'or_lentes bt'}>
                        <div><span>LENTES</span></div>
                        <div>OD: <span>{atend?.os?.od?.lente?.nome}</span> <span className="right">{Currency(atend?.os?.od?.lente?.venda/2)}</span></div>
                        <div>OE: <span>{atend?.os?.oe?.lente?.nome}</span> <span className="right">{Currency(atend?.os?.oe?.lente?.venda/2)}</span></div>
                    </div>


                    <div className={'or_tratamentos bt'}>
                        <div><span>TRATAMENTOS</span></div>
                        {
                            Array.isArray(atend?.os?.od?.tratamentos) && atend?.os?.od?.tratamentos.length > 0 && atend?.os?.od?.tratamentos.map((item, i) => (
                                <div><b>OD:</b> {item.titulo} <small className={(item.tipo_id!==2?'hide':'hide')}>({Currency(item.valor)})</small> <span className={'right ' + (item.tipo_id===2?'hide':'')}>{Currency(item.valor/2)}</span></div>
                            ))
                        }

                        {
                            Array.isArray(atend?.os?.oe?.tratamentos) && atend?.os?.oe?.tratamentos.length > 0 && atend?.os?.oe?.tratamentos.map((item, i) => (
                                <div><b>OE:</b> {item.titulo} <small className={(item.tipo_id!==2?'hide':'hide')}>({Currency(item.valor)})</small> <span className={'right ' + (item.tipo_id===2?'hide':'')}>{Currency(item.valor/2)}</span></div>
                            ))
                        }
                    </div>


                    { Array.isArray(atend?.acessorios) && atend?.acessorios.length > 0 && (
                        <div className={'or_acessorios bt'}>
                            <div><span>ACESSORIOS</span></div>
                            {
                                Array.isArray(atend?.acessorios) && atend?.acessorios.length > 0 && atend?.acessorios.map((item, i) => (
                                    <div>{item.modelo} <span className="right">{Currency(item.venda)}</span></div>
                                ))
                            }
                        </div>
                    )}


                    {(atend?.os?.od?.lente?.color || atend?.os?.oe?.lente?.color) && (
                    <div className={'os_coloracaoes bt '}>
                        <div><span>COLORAÇÕES</span></div>
                        {
                            atend?.os?.od?.lente?.color && (<div>OD: <span>{atend?.os?.od?.lente?.color?.name}</span> <span className="right">{Currency(atend?.os?.od?.lente?.color?.valor)}</span></div>)
                        }

                        {
                            atend?.os?.oe?.lente?.color && (<div>OE: <span>{atend?.os?.oe?.lente?.color?.name}</span> <span className="right">{Currency(atend?.os?.oe?.lente?.color?.valor)}</span></div>)
                        }
                    </div>
                    )}

                    <div className={'or_total bt'}>
                        <div className={'' + (!atend?.desconto||atend?.desconto === 0 ? ' hide': '')}>
                            &nbsp;<span className="right">DESCONTO: {Currency(atend?.desconto)}</span>
                        </div>

                        <div>&nbsp;<span className="right"> TOTAL: {Currency(
                            (atend?.os?.oe?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                            (atend?.os?.od?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                            (atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0)+
                            (atend?.os?.od?.lente?.venda/2||0)+(atend?.os?.oe?.lente?.venda/2||0)+
                            (atend?.os?.od?.lente?.color?.valor||0)+ (atend?.os?.oe?.lente?.color?.valor||0)+
                            (UnCurrency(atend?.armacao?.form?.venda)||0)-(atend?.desconto||0)
                        )}</span></div>

                        <div className={'' + (!atend?.parcelas||atend?.parcelas <= 1 ? ' hide': '')}>
                            &nbsp;<span className="right">Parcelas: {atend?.parcelas}x {Currency(
                                ((atend?.os?.oe?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                (atend?.os?.od?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                (atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0)+
                                (atend?.os?.od?.lente?.venda/2||0)+(atend?.os?.oe?.lente?.venda/2||0)+
                                (atend?.os?.od?.lente?.color?.valor||0)+ (atend?.os?.oe?.lente?.color?.valor||0)+
                                (UnCurrency(atend?.armacao?.form?.venda)||0)-(atend?.desconto||0)-(atend?.entrada||0)) / (atend?.parcelas)
                            )}</span>
                        </div>

                        <div className={'' + (!atend?.entrada ? ' hide': '')}>
                            &nbsp;<span className="right">Entrada: {Currency(atend?.entrada)}</span>
                        </div>

                    </div>


                    <div className={'or_alert bt'}>
                    <br />
                        <div><center>Orçamento válido por 15 dias.</center></div>
                        <br /><br />
                      
                    </div>

                </div>
            </div>


            <div className="mt-4">
                <center>
                    <a className={'pointer btn btn-primary ' + (saved?'hide':'')} onClick={_HandleSave}>Salvar Orçamento</a>

                    <div className={'btn-group btn-group-lg ' + (saved?'':'hide')} role="group" aria-label="orcamento">
                        <a className={'pointer btn btn-primary '} onClick={_HandleFinalizar}>Novo Atendimento</a>
                        <a className={'pointer btn btn-outline-primary '} onClick={_HandlePrint}><i className="fa fa-print"></i> Imprimir</a>
                        <button className={'btn btn-primary '} onClick={_HandleWhatsapp} style={{backgroundColor:'#128C7E',border:'0px'}}><i className="mdi mdi-whatsapp mdi-18px" style={{padding:'0 !important',margin:'0 !important'}}></i> </button>
                    </div>
                </center>
            </div>
        </div>
    )
});
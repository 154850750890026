import { useState, useEffect, useRef } from 'react';
import { WaitObject } from '../../scripts/Wait';

const _objects = [];
const _observers = [];

/*
export default function useHub() {
    //const _objects = useRef([]);
    //const _observers = useRef([]);


    //#region Object
    const Hub = {
        Set: (key, value, caller) => {
            _objects.current[key] = value;
            console.log(key, value);
            if(caller) _objects.current[key+'_caller'] = caller;
        },

        
        Get: (key) => {
            try {
                return _objects.current[key];
            } catch (e) {
                console.log("Get Error: " + e.message);
            }
        },

        Exec: (key, ...parms) => {
            try {
                return _objects.current[key](...parms);
            } catch (e) {
                console.log("Exec Error: " + key + " / " + e.message);
            }
        },


        ExecAsync: (key, ...parms) => {
            let c = 0;
            let wait_exist = setInterval(() => {
                if(Hub.Exists(key)) {
                    clearInterval(wait_exist);
                    try {
                        return _objects.current[key](...parms);
                    } catch (e) {
                        console.log("Exec Error: " + key + " / " + e.message);
                    }
                } else {
                    c++;
                    if(c > 3000) {
                        console.log("Exec Timeout: " + key);
                    }
                }
            }, 100);

        },


        Exists: (key) => {
            return _objects.current[key] !== undefined ? true : false;
        },


        List: () => {
            Object.entries(_objects.current).forEach(([key, value]) => {
                console.log(key, value);
            });
        },


        Push: (key, value) => {
            _objects.current[key].push(value);
        },

        Clear: (caller) => {
            try {
                if(caller) { //For Disposable Object
                    let fcallers = Object.entries(_objects.current);
                    let fcallers_list = fcallers.filter(item =>  { return item[1] === caller });

                    for(let i=fcallers.length-1; i>=0; i--) {
                        let fcaller = fcallers[i];
                        fcallers_list.map ((item, i) => {
                            if(item[0].replace("_caller", "") === fcaller[0].replace("_caller", "")) {
                                fcallers.splice(i, 1);
                            }
                        });
                    }

                    _objects.current = [];
                    fcallers.map((item, i) => {
                        _objects.current[item[0]] = item[1];
                    });
                } else {
                    return _objects.current = [];
                }
            } catch (e) {
                
            }
        },
        //#endregion Object


        //#region Observers

        Observer: (key, caller) => {
            //console.log("Observer", key);
            _observers.current[key] = [];
            if(caller) _observers.current[key+'_caller'] = caller;
        },


        Subscribe: (key, obj) => {
            Hub.Unsubscribe(key, obj);
            _observers.current[key].push(obj);
        },


        Unsubscribe: (key, obj) => {
            if(obj) {
                _observers.current[key] = _observers.current[key].filter(subscriber => subscriber !== obj);
            } else {
                _observers.current[key] = [];
            }
        },

        UnsubscribeAll: (key) => Hub.Observer(key),


        ObserverExists: (key) => {
            return _observers.current[key] !== undefined ? true : false;
        },


        Notify: (key, data) => {
            _observers.current[key].forEach(observer => observer(data));
        }
        //#endregion Observers
    }

    return { Hub }
}
*/


export default function useHub() {
    //const _objects = useRef([]);
    //const _observers = useRef([]);


    //#region Object
    const Hub = {
        Set: async(key, value, caller, useDB = false) => {
            //console.log(key, value);
            _objects[key] = value;
            if(caller) _objects[key+'_caller'] = caller;

            if(useDB) {
                localStorage.setItem(key, JSON.stringify(value));
            }

            return;
        },

        
        Get: async(key, useDB = false) => {
            try {
                if(useDB) {
                    _objects[key] = await JSON.parse(localStorage.getItem(key));
                    return _objects[key];
                } else {
                    return _objects[key];
                }
            } catch (e) {
                console.log("Get Error: " + e.message);
            }
        },

        Exec: (key, ...parms) => {
            try {
                return _objects[key](...parms);
            } catch (e) {
                console.log("Exec Error: " + key + " / " + e.message);
            }
        },


        ExecAsync: async(key, ...parms) => {
            let c = 0;
            let wait_exist = setInterval(async() => {
                if(await Hub.Exists(key)) {
                    clearInterval(wait_exist);
                    try {
                        return _objects[key](...parms);
                    } catch (e) {
                        console.log("Exec Error: " + key + " / " + e.message);
                    }
                } else {
                    c++;
                    if(c > 3000) {
                        console.log("Exec Timeout: " + key);
                    }
                }
            }, 100);

        },


        Exists: async(key, useDB = false) => {
            let r = _objects[key] !== undefined ? true : false;
            if(!r && useDB)
            {
                let tmp = await Hub.Get(key, useDB);
                r = tmp !== "" && tmp !== null && tmp !== undefined;
            }
            return r;
        },


        List: () => {
            Object.entries(_objects).forEach(([key, value]) => {
                console.log(key, value);
            });
        },


        Push: (key, value) => {
            _objects[key].push(value);
        },

        
        Clear: (caller) => {
            try {
                if(caller) { //For Disposable Object
                    let fcallers = Object.entries(_objects);
                    let fcallers_list = fcallers.filter(item =>  { return item[1] === caller });

                    for(let i=fcallers.length-1; i>=0; i--) {
                        let fcaller = fcallers[i];
                        fcallers_list.map ((item, i) => {
                            if(item[0].replace("_caller", "") === fcaller[0].replace("_caller", "")) {
                                fcallers.splice(i, 1);
                            }
                        });
                    }

                    _objects = [];
                    fcallers.map((item, i) => {
                        _objects[item[0]] = item[1];
                    });
                } else {
                    return _objects = [];
                }
            } catch (e) {
                
            }
        },

        //Object Value
        Update: async (name, key, value, useDB = false) => {
            //console.log("Hub update content", name, key, value);
            //console.log(name, _objects[name+'_lock'], key, value, _objects[name]);

            //delete _objects[name+'_lock'];

            if(!_objects[name]) 
            {
                _objects[name] = await Hub.Get(name,useDB);
                _objects[name+'_lock'] = undefined;
            }

                
            if(!_objects[name]) 
            {
                Hub.Set(name, {[key]: value});
            }


           await WaitObject(() => _objects[name+'_lock'] === undefined).then(async() => {
                _objects[name+'_lock'] = true;
                //console.log(name, key, value, _objects[name]);
                _objects[name] = await Hub.Get(name,useDB);
                //console.log(name, key, value, _objects[name]);
                
                if(!_objects[name]) 
                {
                    _objects[name] = await Hub.Get(name,useDB);
                }
    
                _objects[name] = {..._objects[name], [key]: value};
                //console.log(_objects[name]);

                if(useDB) {
                    let data = {..._objects[name]};
                    localStorage.setItem(name, JSON.stringify(data));
                    delete _objects[name+'_lock'];
                    return true;
                } else {
                    delete _objects[name+'_lock'];
                    return true;
                }
               
            }).catch(() => {
                delete _objects[name+'_lock'];
            });
        },


        DeleteKey: async(name, key, useDB = false) => {
            //console.log("Hub deletekey content", name, key);
            await WaitObject(() => _objects[name+'_lock'] === undefined).then(async() => {
                _objects[name+'_lock'] = true;
                try {
                    delete _objects[name][key];
                } catch(e) { 
                    console.log(e);
                }

                if(useDB) {
                    let data = _objects[name];
                    //console.log(name, key, data);
                    localStorage.setItem(name, JSON.stringify(data));
                    delete _objects[name+'_lock'];
                    return true;
                } else {
                    delete _objects[name+'_lock'];
                    return false;
                }
            }).catch(() => {
                delete _objects[name+'_lock'];
            })
        },


        Delete: async(name, useDB = false) => {
            //console.log("Hub delete content", name);
            await WaitObject(() => _objects[name+'_lock'] === undefined).then(async() => {
                _objects[name+'_lock'] = true;
                try {
                    delete _objects[name];
                } catch(e) { console.log("error");}

                if(useDB) {
                    localStorage.removeItem(name);
                    delete _objects[name+'_lock'];
                    return true;
                } else {
                    delete _objects[name+'_lock'];
                    return true;
                }
            }).catch(() => {
                delete _objects[name+'_lock'];
            })
        },


        Debug: (name) => {
            if(name) {
                console.log(_objects[name]);
            } else {
                Hub.List();
            }
        },
        //#endregion Object


        //#region Observers

        Observer: (key, caller) => {
            //console.log("Observer", key);
            _observers[key] = [];
            if(caller) _observers[key+'_caller'] = caller;
        },


        Subscribe: (key, obj) => {
            Hub.Unsubscribe(key, obj);
            _observers[key].push(obj);
        },


        Unsubscribe: (key, obj) => {
            if(obj) {
                _observers[key] = _observers[key].filter(subscriber => subscriber !== obj);
            } else {
                _observers[key] = [];
            }
        },

        UnsubscribeAll: (key) => Hub.Observer(key),


        ObserverExists: (key) => {
            return _observers[key] !== undefined ? true : false;
        },


        Notify: (key, data) => {
            _observers[key].forEach(observer => observer(data));
        }
        //#endregion Observers
    }

    return { Hub }
}

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useImperativeHandle, useEffect, useRef, forwardRef} from 'react';
import { Context } from '../../../../Context/AuthContext';
import { HubContext } from '../../../../Context/HubContext.js';
import { Request, Req } from '../../../../scripts/Request';
import { WaitObject } from '../../../../scripts/Wait';
import { Currency, UnCurrency, Number } from '../../../../scripts/StringUtils';
import { OS } from '../../../Os';
import moment from "moment/moment";
import ReactGA from 'react-ga';
import './index.css';
import { Orcamento } from '../../../Orcamento';


let current_element;
const forma_pagamento = [
    {id: 1, name: "Dinheiro"},
    {id: 2, name: "Pix"},
    {id: 3, name: "Cartão de Crédito"},
    {id: 4, name: "Cartão de Débito"},
    {id: 5, name: "Carnê"},
]


export const Finalizar = forwardRef((props, ref) => {
    const component_name = "admin/atendimento_finalizar";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [atend, setAtend] = useState({});
    const [entrada, setEntrada] = useState("");
    const [desconto, setDesconto] = useState("");
    const [pagamento, setPagamento] = useState(1);
    const [descontoPercent, setDescontoPercent] = useState(0);
    const [parcelas, setParcelas] = useState(1);
    const [carneDate, setCarneDate] = useState(moment(new Date()).add(1, "months").format("YYYY-MM-DD"));
    const [selectedType, setSelectedType] = useState(0); // 0-Orçamento, 1-Os
    const [blocked, setBlocked] = useState(false);

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const osRef = useRef();
    const orRef = useRef();

  

    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(current_element? current_element :".main-content")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects


    //#region Ref
    useImperativeHandle(ref, (url, args, token) => ({
        async Init() { return Init() },
        async Clear() { return ClearComponent() }
    }));
    //#endregion Ref


    //#region Init
    const Init = async() => {
        setSelectedType(0);


        let current_atend = await Hub.Get("current_atend", true);
        setAtend(current_atend);
        if(current_atend?.desconto) setDesconto(current_atend?.desconto);
        if(current_atend?.desconto_percent) setDescontoPercent(current_atend?.desconto_percent);
        if(current_atend?.entrada) setEntrada(current_atend?.entrada);
        if(current_atend?.parcelas) {
            setParcelas(current_atend?.parcelas||1);
        } else {
            await Hub.Update("current_atend", "parcelas", 1, true);
        }

        if(current_atend?.forma_pagamento) {
            setPagamento(current_atend?.forma_pagamento||1);
        } else {
            await Hub.Update("current_atend", "forma_pagamento", 1, true);
        }

        if(!current_atend?.carne_date) {
            current_atend.carne_date = moment(new Date()).add(1, "months").format("YYYY-MM-DD");
        } 
        
        setCarneDate(moment(current_atend?.carne_date).format("YYYY-MM-DD"));
        await Hub.Update("current_atend", "carne_date", moment(current_atend?.carne_date).format("YYYY-MM-DD"), true);
        

        if(selectedType === 0) {
            orRef?.current?.Init(); 
        } else {
            osRef?.current?.Init();
        }
      
    }


    const ClearComponent = () => {
        setAtend({});
        setParcelas(1);
        setDesconto("");
        setEntrada("");
        setDescontoPercent(0);
        setSelectedType(0);
        setBlocked(false);
    }
    //#endregion Init


    //#region Handlers
    const _HandleEntrada = async(event) => {
        setEntrada(event.target.value);

        await Hub.Update("current_atend", "entrada", UnCurrency(event.target.value), true);

        if(selectedType === 0) {
            await orRef?.current?.Init(); 
            await orRef?.current?.Update();
        } else {
            await osRef?.current?.Init();
            await osRef?.current?.Update();
        }
    }


    const _HandleDesconto = async(event) => {
        setDesconto(event.target.value);

        await Hub.Update("current_atend", "desconto", UnCurrency(event.target.value), true);

        let _total = (atend?.os?.oe?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
        (atend?.os?.od?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
        (atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0)+
        (atend?.os?.od?.lente?.venda/2||0)+(atend?.os?.oe?.lente?.venda/2||0)+
        (atend?.os?.od?.lente?.color?.valor||0)+ (atend?.os?.oe?.lente?.color?.valor||0)+
        (UnCurrency(atend?.armacao?.form?.venda)||0);

        let _percent = 100 - (_total - parseFloat(UnCurrency(event.target.value))) * 100/_total;
        setDescontoPercent(_percent);

        await Hub.Update("current_atend", "desconto_percent", parseFloat(_percent), true);

        if(selectedType === 0) {
            await orRef?.current?.Init(); 
            await orRef?.current?.Update();
        } else {
            await osRef?.current?.Init();
            await osRef?.current?.Update();
        }
    }


    const _HandleDescontoPercent = async(event) => {
        let _percent = parseFloat(event.target.value)
        setDescontoPercent(_percent);

        await Hub.Update("current_atend", "desconto_percent", parseFloat(_percent), true);

        let _total = (atend?.os?.oe?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
        (atend?.os?.od?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
        (atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0)+
        (atend?.os?.od?.lente?.venda/2||0)+(atend?.os?.oe?.lente?.venda/2||0)+
        (atend?.os?.od?.lente?.color?.valor||0)+ (atend?.os?.oe?.lente?.color?.valor||0)+
        (UnCurrency(atend?.armacao?.form?.venda)||0);

        let _desconto = _total * (_percent/100);
        setDesconto(_desconto);

        await Hub.Update("current_atend", "desconto", UnCurrency(_desconto), true);

        if(selectedType === 0) {
            await orRef?.current?.Init(); 
            await orRef?.current?.Update();
        } else {
            await osRef?.current?.Init();
            await osRef?.current?.Update();
        }
    }
    


    const _HandleParcelas = async(event) => {
        if(event.target.value > 10) event.target.value = parseInt(event.target.value) - 10;
        if(event.target.value < 1) event.target.value = 1;
        setParcelas(event.target.value);

        await Hub.Update("current_atend", "parcelas", parseInt(event.target.value), true);


        if(selectedType === 0) {
            await orRef?.current?.Init(); 
            await orRef?.current?.Update();
        } else {
            await osRef?.current?.Init();
            await osRef?.current?.Update();
        }
    }


    const _HandleDouble = async(event) => {
        if(event.target.id === "desconto") {
            setDesconto(0);
            await Hub.Update("current_atend", "desconto", 0, true);
             if(selectedType === 0) {
                await orRef?.current?.Init(); 
                await orRef?.current?.Update();
            } else {
                await osRef?.current?.Init();
                await osRef?.current?.Update();
            }
        } else if(event.target.id === "parcelas") {
            setParcelas(1);
            event.target.select();
            await Hub.Update("current_atend", "parcelas", 1, true);
            if(selectedType === 0) {
                await orRef?.current?.Init(); 
                await orRef?.current?.Update(); 
            } else {
                await osRef?.current?.Init();
                await osRef?.current?.Update();
            }
        }
    }

    const _HandleChangeType = async(sel) => {
        if(sel === 0) {
            await orRef?.current?.Init(); 
            await orRef?.current?.Update(); 
        } else {
            await osRef?.current?.Init();
            await osRef?.current?.Update();
        }
        setSelectedType(sel);
    }


    const _HandlePagamentoChange = async(event) => {
        setPagamento(parseInt(event.target.value));
        await Hub.Update("current_atend", "forma_pagamento", parseInt(event.target.value), true);
        let current_atend = await Hub.Get("current_atend", true);

        if(selectedType === 0) {
            await orRef?.current?.Init(); 
            await orRef?.current?.Update(current_atend); 
        } else {
            await osRef?.current?.Init();
            await osRef?.current?.Update(current_atend);
        }
    }


    const _HandleCarneDate = async(event) => {
        setCarneDate(event.target.value);
        await Hub.Update("current_atend", "carne_date", moment(event.target.value).format("YYYY-MM-DD"), true);
        let current_atend = await Hub.Get("current_atend", true);

        if(selectedType === 0) {
            await orRef?.current?.Init(); 
            await orRef?.current?.Update(current_atend); 
        } else {
            await osRef?.current?.Init();
            await osRef?.current?.Update(current_atend);
        }
    }


    const OnSave = () => {
        setBlocked(true);
    }
    //#endregion Handlers

    
    return (
        <div className="AtendimentoFinalizar">
            <br /><br />
            <div className="row">
                <div className="col col-sm-4">
                    <div className="card pricing-box">
                        <div className="card-body p-4">
                            <div className="text-center">
                                <h5 className="mb-1 font-size-20">Valor Total</h5>
                                <div className="pt-4 pb-2">
                                    <h2 className="month" style={{color:'red'}}>{Currency(
                                            (atend?.os?.oe?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                            (atend?.os?.od?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                            (atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0)+
                                            (atend?.os?.od?.lente?.venda/2||0)+(atend?.os?.oe?.lente?.venda/2||0)+
                                            (atend?.os?.od?.lente?.color?.valor||0)+(atend?.os?.oe?.lente?.color?.valor||0)+
                                            (UnCurrency(atend?.armacao?.form?.venda)||0)-UnCurrency(desconto||0)
                                        )}</h2>

                                <span className={'' + (!parcelas||parcelas <= 1 ? ' _hide': '')}>
                                    <b>Parcelas: {parcelas}x {Currency(
                                        ((atend?.os?.oe?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                        (atend?.os?.od?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                        (atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0)+
                                        (atend?.os?.od?.lente?.venda/2||0)+(atend?.os?.oe?.lente?.venda/2||0)+
                                        (atend?.os?.od?.lente?.color?.valor||0)+(atend?.os?.oe?.lente?.color?.valor||0)+
                                        (UnCurrency(atend?.armacao?.form?.venda)||0)-UnCurrency(desconto||0)-UnCurrency(entrada||0)) / (parcelas)
                                    )}</b>
                                </span> 
                                </div>
                               
                                <p className="text-muted">Confira as opções de pagamento e parcelamento</p>

                              
                            </div>

                                <hr class="my-4 text-muted" />
                                
                                <ul className="list-unstyled pricing-features mb-0 ps-4">
                                    <li><i className="uil uil-check-circle text-success font-size-18 align-middle me-2"></i> <b>{Currency(atend?.armacao?.form?.venda||0)}</b>  - Armação </li>
                                    <li><i className="uil uil-check-circle text-success font-size-18 align-middle me-2"></i> <b>{Currency((atend?.os?.od?.lente?.venda/2||0)+(atend?.os?.oe?.lente?.venda/2||0))}</b>  - Lentes</li>
                                    <li><i className="uil uil-check-circle text-success font-size-18 align-middle me-2"></i> <b>{Currency((atend?.os?.od?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+(atend?.os?.oe?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0))}</b>  - Tratamentos </li>
                                    <li><i className="uil uil-check-circle text-success font-size-18 align-middle me-2"></i> <b>{Currency((atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0))}</b>  - Acessórios </li>
                                    <li><i className="uil uil-check-circle text-success font-size-18 align-middle me-2"></i> <b>{Currency((atend?.os?.od?.lente?.color?.valor||0)+(atend?.os?.oe?.lente?.color?.valor||0))}</b>  - Colorações </li>    
                                </ul>

                                <hr class="my-4 text-muted" /> 


                                <div className="position-relative">
                                    Entrada Valor
                                    <input id="entrada" type="text" value={Currency(entrada)} className="form-control rounded bg-light border-0" onChange={_HandleEntrada} onDoubleClick={_HandleDouble} disabled={blocked}/>
                                </div>

                                <br />          
                                <div className="position-relative">
                                    Desconto Valor
                                    <input id="desconto" type="text" value={Currency(desconto)} className="form-control rounded bg-light border-0" onChange={_HandleDesconto} onDoubleClick={_HandleDouble} disabled={blocked}/>
                                </div>

                                <br />
                                <div className="position-relative">
                                    Desconto Percentual
                                    <input id="desconto" type="text" value={Number(descontoPercent, undefined, 0)} className="form-control rounded bg-light border-0" onChange={_HandleDescontoPercent} onDoubleClick={_HandleDouble} disabled={blocked}/>
                                </div>

                                <br />
                                <div className="position-relative">
                                    Parcelas
                                    <input id="parcelas" type="number" value={parcelas} className="form-control rounded bg-light border-0" min={1} max={10} step={1} onChange={_HandleParcelas} onDoubleClick={_HandleDouble} disabled={blocked}/>
                                </div>

                                <br />
                                <div className="position-relative">
                                    Forma de pagamento
                                    <select className="form-control rounded bg-light border-0" value={pagamento} onChange={_HandlePagamentoChange} disabled={blocked}>
                                        {forma_pagamento && forma_pagamento.map((item, i) => (
                                            <option value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>

                                {selectedType === 1 && pagamento === 5 && (    
                                    <>         
                                    <br />
                                    <div className="position-relative">
                                        Data para ínicio do pagamento
                                        <input id="carneDate" type="date" value={carneDate} className="form-control rounded bg-light border-0" onChange={_HandleCarneDate} disabled={blocked}/>
                                    </div>
                                    </> 
                                )} 

                                <br />
                                <div className="btn-group btn-group-lg" role="group" aria-label="view_os">
                                    <button typeName="button" className={'btn ' + (selectedType === 0?'btn-primary':'btn-outline-primary')} onClick={() => _HandleChangeType(0)}><i className={'mdi mdi-check '+ (selectedType === 0?'':'hide')}></i> Orçamento</button>
                                    <button typeName="button" className={'btn ' + (selectedType === 1?'btn-primary':'btn-outline-primary')} onClick={() => _HandleChangeType(1)}><i className={'mdi mdi-check '+ (selectedType === 1?'':'hide')}></i> Ordem de serviço</button>
                                </div>
                            </div>
                        </div>
                    </div>

                <div className="col col-sm-8 devgrid pricing-box">
                    {selectedType === 0 && (<Orcamento ref={orRef} OnSave={OnSave}/>)}
                    {selectedType === 1 && (<OS ref={osRef} OnSave={OnSave}/>)}
                   

                    <br />
                </div>
            </div>
        </div>
    )
})
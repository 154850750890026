/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef} from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Request } from '../../../scripts/Request';
import { HubObjects } from '../../../scripts/HubObjects';
import { WaitObject } from '../../../scripts/Wait';
import { FormGenerator } from '../../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";
import ReactGA from 'react-ga';


import { MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';


export function Cadastro() {
    const component_name = "admin/unidade_cadastro";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [showAddComboModal, setShowAddComboModal] = useState(false);
    const [AddComboTitle, SetAddComboTitle] = useState(false);
    const [AddComboInfo, SetAddComboInfo] = useState({ table: "", id_field: "", name_field: "" });
    const [AddComboList, SetAddComboList] = useState([]);

    const _formUserRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const navigate = useNavigate();

    let { Id } = useParams();


    //#region Effects
     useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {
       
    }
    //#endregion Init


    //#region Loaders
    const LoadUfs = (uf = 26) => {
        Promise.resolve(new Request().Run("/assets/json/estados.json", "GET", "", "", 0*24*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            await _formUserRef.current.Select("uf_codigo", data);
            await _formUserRef.current.Value("uf_codigo", uf);
            LoadCidades(uf)

            if(data.toString() !== "error") {
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }


    const LoadCidades = (uf_codigo) => {
        Promise.resolve(new Request().Run("api/Unidades/Cidades?uf_codigo=" + uf_codigo, "GET", "", user.token, 0*24*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            await _formUserRef.current.Select("cidade_codigo", data);
            if(data.toString() !== "error") {
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }


    const LoadRedes = () => {
        Promise.resolve(new Request().Run("api/Unidades/Redes", "GET", "", user.token, 0*24*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            await _formUserRef.current.Select("rede_id", data);
            if(data.toString() !== "error") {
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }
    //#endregion Loaders


    //#region Form Handlers
    const new_rede_id = async(value) => {
        let p = prompt("Digite o nome da Rede");
        if (p != "" && p != null && p != undefined) {
            await Promise.resolve(new Request().Run("api/Unidades/SaveRede", "POST", { name: p }, user.token))
                .then(async(data) => {
                   await _formUserRef.current.Load({ id: data, name: p }, "rede_id", data, "append");
                });
        }
    }


    const sup_rede_id = async () => {
        _EditAddCombo("Redes", "redes", "rede_id", "nome");
    }


    const uf_codigo = value => {
        LoadCidades(value);
    }

    const OnFormLoaded = async(fields) => {
        setPageLoading(true);
 
        WaitObject(_formUserRef.current).then(async() => {
            if(Id) {
                let unidade_data = await new Request().Run("api/Unidades/Load?id="+Id, "GET", "", user.token);
                if(unidade_data !== undefined && unidade_data.unidade_id !== null) {
                    await _formUserRef.current.Load(unidade_data);
                }
            }

            setTimeout(()=> {
                LoadUfs();
                LoadRedes();
            }, 100);

            setPageLoading(false);
        });
        
    }

    
    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        let validade = _formUserRef.current.RequiredValidation();

       
        if(validade) {
             setPageLoading(true);
            //_formUserRef.current.Test("","");
            let result = await _formUserRef.current.Post("api/Unidades/Save", {unidade_id: Id}, user.token);
            setPageLoading(false);

            if(result === "error") {
                window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
                return;
            } else if(result === "exists") {
                window.swal("Alerta", "Unidade já registrada", "error");
                return;
            }

            window.scrollTo({top: 0, behavior: 'smooth'});
            navigate("/Unidades");
        } else {
            window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
        }

    }
    //#endregion Handlers
    

    //#region AddCombo Handlers
    const _handleCloseAddComboModal = async() => {
        setShowAddComboModal(false);
        await _formUserRef.current.Select("rede_id", [], "api/Unidades/Redes", "GET", "", user.token);
    }

    const _handleSaveAddComboModal = () => {
        
    }


    const _EditAddCombo = (title, table, id_field, name_field) => {
        SetAddComboTitle(title);
        SetAddComboInfo({ table: table, id_field: id_field, name_field: name_field });
        setShowAddComboModal(true);

       
        Promise.resolve(new Request().Run("api/Unidades/AddComboList", "POST", { table: table, id_field: id_field, name_field: name_field }, user.token))
            .then((data) => {
                SetAddComboList(data);
            });
    }


    const _AddComboBlur = (item) => {
        let _data = Object.assign(AddComboInfo, { id: item.id, nome: item.name });
        Promise.resolve(new Request().Run("api/Unidades/AddComboUpdate", "POST", _data, user.token))
            .then((data) => {
                let _list = [...AddComboList];
                let _listItem = _list.find(l => l.id == item.id);
                _listItem = item;
                SetAddComboList(_list);
            });
    }


    const _DeleteAddCombo = (item) => {
        let _data = Object.assign(AddComboInfo, { id: item.id });
        Promise.resolve(new Request().Run("api/Unidades/AddComboDelete", "POST", _data, user.token))
            .then((data) => {
                let _list = [...AddComboList];
                let _listIndex = _list.findIndex(l => l.id == item.id);
               _list.splice(_listIndex, 1);
                SetAddComboList(_list);
            });
    }


    const _AddComboFieldChange = (item, event) => {
        let _list = [...AddComboList];
        let _listItem = _list.find(l => l.id == item.id);
        _listItem.name = event.target.value;
        SetAddComboList(_list);
    }
    //#endregion AddCombo Handlers


    return(
        <div className="main-content Unidades">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Cadastro de Unidade</h4>
                                </div>

                                <div className="card-body pb-2">
                                   
                                    <form name="fuser" onSubmit={(event) => _HandleSubmit('unidade', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                        <div className="row">
                                            <div className="col-md-12 pt-1 pb-1">
                                                <FormGenerator ref={_formUserRef} name={'unidade'} url={'/assets/forms/unidade.json'} execRef={Exec}></FormGenerator>
                                                <div style={{clear:'both'}}></div>
                                                <center>
                                                    <hr />
                                                    <button id="btn_sv" className="btn btn-primary btn-lg btn-rounded mb-2" type="submit">Salvar informações</button>
                 
                                                </center>
                                                <br />
                                            </div> 
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>


            <MDBModal show={showAddComboModal} setShow={setShowAddComboModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle><h4>{AddComboTitle}</h4></MDBModalTitle>
                        <Button className='btn btn-primary' onClick={_handleCloseAddComboModal} style={{width:'25px',height:'25px', padding:0}}>x</Button>
                    </MDBModalHeader>
                    <MDBModalBody>
                    {AddComboList.length > 0 ? (<div>
                        <table className="table table-striped table-hover">
                            <tbody>
                                {AddComboList.map((item, i) => (
                                    <tr key={'addc'+i}>
                                        <td style={{ padding: 0 }}> <input type="text" className="form-control inputfull" value={item.name} onChange={(event) => _AddComboFieldChange(item, event)} onBlur={() => _AddComboBlur(item)} /></td>
                                        <td style={{ padding: '4px', margin: 0, width: '35px' }}><button className="btn btn-white btn-sm" type="button" onClick={() => _DeleteAddCombo(item)} style={{margin:'1px',padding:'1px',paddingLeft:'7px',paddingRight:'7px'}}><i className="fa fa-trash"></i></button></td>
                                    </tr>
                                 ))}
                            </tbody>
                        </table>
                        </div>) : (<div>Nenhum dado Encontrado</div>)}
                    </MDBModalBody>

                    <MDBModalFooter>
                            <Button variant="secondary" onClick={_handleCloseAddComboModal}>
                            Fechar
                            </Button>
                    </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    )
}
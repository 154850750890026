/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useImperativeHandle, useEffect, useRef, forwardRef} from 'react';
import { Context } from '../../../../Context/AuthContext';
import { HubContext } from '../../../../Context/HubContext.js';
import { Request, Req } from '../../../../scripts/Request';
import { WaitObject } from '../../../../scripts/Wait';
import { Currency, Number } from '../../../../scripts/StringUtils';
import { FormGenerator } from '../../../Form/FormGenerator';
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment/moment';
import ReactGA, { set } from 'react-ga';
import './Lista.css';


let current_element;
let tipos =  [
    {id: 1, name: "Multi"}, 
    {id: 2, name: "Bi"}, 
    {id: 3, name: "Mono"}, 
    {id: 4, name: "Ocup."}, 
    {id: 5, name: "Solar"}
];
let materiais =  [
    {id: 1, name: "Acrílico"},
    {id: 2, name: "Resina"},
    {id: 3, name: "Poly"},
    {id: 4, name: "H.L"},
    {id: 5, name: "Mineral"}, 
    {id: 6, name: "Trivex"}
];


let linhas  = [
    {id: 1, name: "A" },
    {id: 2, name: "B" },
    {id: 3, name: "C" },
    {id: 4, name: "D" },
];


export const Lista = forwardRef((props, ref) => {
    const component_name = "admin/lentes_lista";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);
    const [tratamentos, setTratamentos] = useState([]);
    const [tipostratamentos, setTiposTratamentos] = useState([]);
    const [colors, setColors] = useState([]);
    const [color, setColor] = useState();
    const [lente, setLente] = useState({});
    const [search, setSearch] = useState({ word: "" });
    const [info, setInfo] = useState({});
    const [valorTotal, setValorTotal] = useState(0);
    const [prazo, setPrazo] = useState(0);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [hasOs, setHasOs] = useState(false);
    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();


    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: "" });
    const infoRef = useRef({ side: "" });


    //#region Effects
    useEffect(() => {
        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    //Init();
                });
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(current_element? current_element :".main-content")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects


    //#region Ref
    useImperativeHandle(ref, (url, args, token) => ({
        async Init(parms, info) { return Init(parms, info) },
        async Clear() { return ClearComponent() }
    }));
    //#endregion Ref



    //#region Init
    const Init = async(parms, info) => {
        let current_atend = await Hub.Get("current_atend", true);
        if(current_atend && current_atend.cliente_id && current_atend.medico_id) {
        } else {
            Hub.Exec("CLEAR_SESSION", component_name);
            return;
        }

        infoRef.current = info;
        searchRef.current = parms;
        setInfo(info);
        setSearch(searchRef.current);
        LoadTiposTratamentos();
        LoadColoracoes();
        _HandlerSearchChange();

        if(parms) {
            console.log(parms);
            console.log(parms?.os[infoRef.current.side.toLowerCase()]?.color?.color_id);
            setColor(parseInt(parms?.os[infoRef.current.side.toLowerCase()]?.color?.color_id));
        }
    }


    const ClearComponent = () => {
        setList([]);
        setInfo({});
        setTiposTratamentos([]);
    }
    //#endregion Init


    //#region Loaders
    const LoadLente = async(id) => {
        let lente_data = await Req("api/AtendimentoLentes/Load?id=" + id, "GET", "", user.token, 0*24*60*60*1000);
        setLente(lente_data);
    }


    const LoadTiposTratamentos = () => {
        Promise.resolve(new Request().Run("api/AtendimentoLentes/TiposTratamentos", "GET", "", user.token, 0*24*60*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            
            if(data.toString() !== "error") {
                setTiposTratamentos(data);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATLEN001)", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATLEN002)", "error");
        });
    }


     const LoadTratamentos = (lid, fid = "", surf = "", item) => {
        Promise.resolve(new Request().Run("api/AtendimentoLentes/Tratamentos", "POST", {lente_id: lid, fornecedor_id: fid, surf: surf}, user.token, 0*24*60*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            
            if(data.toString() !== "error") {
                //data[1].tipos = [{tipo_tratamento_id: 4},  {tipo_tratamento_id: 2}]
                Calc(item, data);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATLEN003)", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATLEN004)", "error");
        });
    }


    const LoadFeriados = async prazo => {
        let feriados = await Req("api/AtendimentoLentes/Feriados?prazo=" + prazo, "GET", "", user.token, 300*1000);
        let new_prazo = (prazo+feriados);
        let c = 0;

        for(let i=0; i<new_prazo;i++) {
            let dt = moment().add(i, 'days').toDate();
            if(dt.getDay() === 6 || dt.getDay() === 0) {
                c++;
            }
        }
        
        let prazy = moment().add((new_prazo+c), 'days').format('DD/MM/YYYY');
        setPrazo(prazy);
  
        let current_atend = await Hub.Get("current_atend", true);
        if(current_atend?.os?.od) {
            let _os = {...current_atend?.os, [infoRef.current.side.toLowerCase()]: {...current_atend?.os[infoRef.current.side.toLowerCase()], prazo: prazy}};
            Hub.Update("current_atend", "os", _os, true);
        }
    }


    const LoadColoracoes = async() => {
        let color_data = await Req("api/AtendimentoLentes/Coloracoes", "GET", "", user.token, 0*24*60*60*1000).then(data => {
            setColors(data);
        }).catch(err => { });
    }
    //#endregion Loaders



    //#region Handlers
    const _HandleChange = (event) => {
        setSearch(prev => prev = {[event.target.id]: event.target.value});
        searchRef.current = {...searchRef.current, [event.target.id]: event.target.value};
    }


    const _HandleClear = (event) => {
        setSearch(prev => prev = {word: ""});
        searchRef.current = {...searchRef.current, word: ""};
        //_HandlerSearchChange(event);
    }



    const _HandlerSearchChange = () => {
        setLente({});
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setPageLoading(true);
        setHasMore(true);

        Promise.resolve(new Request().Run("api/AtendimentoLentes/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];
                listRef.current = listRef.current.concat(_list);
                setList(listRef.current);
                setPageLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 

                Preload();
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setPageLoading(true);

            Promise.resolve(new Request().Run("api/AtendimentoLentes/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    listRef.current = listRef.current.concat(_list);
                    setList(listRef.current);
                    setPageLoading(false);
                });
        } catch (e) { }
    }


    const _HandleLente = async(item, auto) => {
        setLente(item);
        LoadLente(item.lente_id);
        LoadTratamentos(item.lente_id, item.fornecedor_id, item.surf, item);

        //console.log(item);
        let current_atend = await Hub.Get("current_atend", true);
        setHasOs(current_atend?.os && current_atend?.os[infoRef.current.side.toLowerCase()] !==""); 

        if(!auto) {
            setColor("");
            let _os = {...current_atend?.lentes?.os, [infoRef.current.side.toLowerCase()]: item};
            Hub.Update("current_atend", "lentes", {...current_atend.lentes, os: _os}, true);
            //Hub.Update("current_atend", "lentes", item, true);
        }
    }


    const _HandleTratamentoClick = async(event, item, lente) => {
        let _tratamentos = [...tratamentos];

        //console.log(infoRef.current.side.toLowerCase(), item, _tratamentos);

        let _index = _tratamentos.findIndex(t => t.tratamento_id === item.tratamento_id);
        _tratamentos[_index] = { ..._tratamentos[_index], selected: (event.target.checked ? 1: 0) };


        /*for(let i=0; i<_tratamentos.length; i++) {
            _tratamentos[i].disabled = 0;

            for(let j=0; j<_tratamentos.length; j++) {
                if(_tratamentos[i].tratamento_id !== _tratamentos[j].tratamento_id && (_tratamentos[i].selected === 1 || _tratamentos[j].selected === 1)) {
                    console.log(_tratamentos[i].titulo, "|", _tratamentos[j].titulo, "|", _tratamentos[i].selected, _tratamentos[j].selected);

                    for(let k=0; k<_tratamentos[i].tipos.length; k++) {
                        for(let n=0; n<_tratamentos[j].tipos.length; n++) {
                            console.log(">>>> ", _tratamentos[i].tipos[k].tipo_tratamento_id, _tratamentos[j].tipos[n].tipo_tratamento_id, _tratamentos[i].tipos[k].tipo_tratamento_id === _tratamentos[j].tipos[n].tipo_tratamento_id);
                            if(_tratamentos[i].tipos[k].tipo_tratamento_id === _tratamentos[j].tipos[n].tipo_tratamento_id && _tratamentos[i]?.disabled !== 1 && _tratamentos[i].selected !== 1) {
                                _tratamentos[i].disabled = 1;
                                break;
                            }
                        }

                        if(_tratamentos[i].disabled === 1) break;
                    }
                    if(_tratamentos[i].disabled === 1) break;
                }
                if(_tratamentos[i].disabled === 1) break;
            }
        }*/


        setTratamentos(_tratamentos);
        Calc(lente, _tratamentos, false);
        
        let current_atend = await Hub.Get("current_atend", true);
        let _side = {};

        try {
            _side = current_atend?.lentes?.os[infoRef.current.side.toLowerCase()]
        } catch(e) {
        }

        let _os = {...current_atend?.lentes?.os, [infoRef.current.side.toLowerCase()]: {..._side, tratamentos: _tratamentos}};
        await Hub.Update("current_atend", "lentes", {...current_atend.lentes, os: _os}, true);
    }


    const _HandleSave = async(item) => {
        let _tratamentos = [...tratamentos];
        let _trats = [];

        _tratamentos.map((item, i) => {
            if(item?.selected === 1 || item?.obrigatorio) {
                _trats.push(item);
            };
        });

        let current_atend = await Hub.Get("current_atend", true);
        setHasOs(true); 

        let _side = {};

        try {
            _side = current_atend?.lentes?.os[infoRef.current.side.toLowerCase()];
            item = _side;
        } catch(e) { }

        let _result = {tratamentos: _trats, valor: valorTotal, prazo: prazo, lente: item }
        props.OnSave(_result, info.side);
    }


    const _HandleColorChange = async(event) => {
        setColor(event.target.value);

        let current_atend = await Hub.Get("current_atend", true);
        let _side = {};

        try {
            _side = current_atend?.lentes?.os[infoRef.current.side.toLowerCase()];
        } catch(e) {

        }
        
        let _colors = [...colors];
        let _os = {...current_atend?.lentes?.os, [infoRef.current.side.toLowerCase()]: {..._side, color: {color_id: event.target.value, name: _colors.find(c => c.color_id === parseInt(event.target.value))?.nome, valor: _colors.find(c => c.color_id === parseInt(event.target.value))?.valor, prazo: _colors.find(c => c.color_id === parseInt(event.target.value))?.prazo}}};
        console.log(_os);
        await Hub.Update("current_atend", "lentes", {...current_atend.lentes, os: _os}, true);
    }
    //#endregion Handlers


    //#region Functions
    const Preload = async() => {
        let current_atend = await Hub.Get("current_atend", true);

        //console.log(current_atend?.os[infoRef.current.side.toLowerCase()]);

        if(current_atend && current_atend?.os && current_atend?.os[infoRef.current.side.toLowerCase()]?.lente?.lente_id) {
            _HandleLente(current_atend?.os[infoRef.current.side.toLowerCase()]?.lente, true);

        }
    }


    const Calc = async(lent, trats, useCache = true) => {
        let _lente = lent ? lent : {...lente};
        let _tratamentos = trats ? trats : {...tratamentos};
        let _total = _lente?.venda/2 || 0;
        let _prazo = _lente?.prazo || 0;

        //console.log( _lente, _tratamentos);
        let current_atend = await Hub.Get("current_atend", true);
        
        for(let i=0; i<_tratamentos.length; i++) {
            if(useCache && current_atend && current_atend?.os && Array.isArray(current_atend?.os[infoRef.current.side.toLowerCase()]?.tratamentos)) {
                if(current_atend?.os[infoRef.current.side.toLowerCase()]?.tratamentos?.find(t => t.tratamento_id === _tratamentos[i].tratamento_id && t.selected === 1)) {
                    _tratamentos[i].selected = 1;
                } 
            }

            _tratamentos[i].disabled = 0;

            if(_tratamentos[i]?.selected === 1 || _tratamentos[i]?.obrigatorio) {
                if(_lente.surf === 1) _total += _tratamentos[i]?.valor/2;
                if((_tratamentos[i]?.prazo||0) > _prazo) _prazo = _tratamentos[i]?.prazo;
            } else if(_tratamentos?.findIndex(t => t.selected === 1) > -1){
                _tratamentos[i].disabled = 1;
            }

           
        }



        /*
        for(let i=0; i<_tratamentos.length; i++) {
            if(useCache && current_atend && current_atend?.os && Array.isArray(current_atend?.os[infoRef.current.side.toLowerCase()]?.tratamentos)) {
                if(current_atend?.os[infoRef.current.side.toLowerCase()]?.tratamentos?.find(t => t.tratamento_id === _tratamentos[i].tratamento_id && t.selected === 1)) {
                    _tratamentos[i].selected = 1;
                } 
            }

            if(_tratamentos[i]?.selected === 1 || _tratamentos[i]?.obrigatorio) {
                if(_lente.surf === 1) _total += _tratamentos[i]?.valor/2;
                if((_tratamentos[i]?.prazo||0) > _prazo) _prazo = _tratamentos[i]?.prazo;
            }


            _tratamentos[i].disabled = 0;

            for(let j=0; j<_tratamentos.length; j++) {
                if(_tratamentos[i].tratamento_id !== _tratamentos[j].tratamento_id && (_tratamentos[i].selected === 1 || _tratamentos[j].selected === 1)) {
                    //console.log(_tratamentos[i].titulo, "|", _tratamentos[j].titulo, "|", _tratamentos[i].selected, _tratamentos[j].selected);

                    for(let k=0; k<_tratamentos[i].tipos.length; k++) {
                        for(let n=0; n<_tratamentos[j].tipos.length; n++) {
                            //console.log(">>>> ", _tratamentos[i].tipos[k].tipo_tratamento_id, _tratamentos[j].tipos[n].tipo_tratamento_id, _tratamentos[i].tipos[k].tipo_tratamento_id === _tratamentos[j].tipos[n].tipo_tratamento_id);
                            if(_tratamentos[i].tipos[k].tipo_tratamento_id === _tratamentos[j].tipos[n].tipo_tratamento_id && _tratamentos[i]?.disabled !== 1 && _tratamentos[i].selected !== 1) {
                                _tratamentos[i].disabled = 1;
                                break;
                            }
                        }

                        if(_tratamentos[i].disabled === 1) break;
                    }
                    if(_tratamentos[i].disabled === 1) break;
                }
                if(_tratamentos[i].disabled === 1) break;
            }
        }*/

        setTratamentos(_tratamentos);
        setValorTotal(_total);
        LoadFeriados(_prazo);
    }
    //#endregion Functions




    return (
        <>
            <div className={'LentesList table-responsive ' + (list.length===0?'hide':'')}>
                <InfiniteScroll
                    dataLength={list.length}
                    next={_fetchMoreData}
                    hasMore={hasMore}
                    loader={
                        <p style={{ textAlign: "center", padding:'25px' }}>
                            <b>Carregando...</b>
                        </p>
                    }
                    style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                    endMessage={
                        <p style={{ textAlign: "center", padding:'25px' }}>
                            
                        </p>
                    }>    


                    <table className="table mb-0 table-bordered">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Cod</th>
                                <th>L</th>
                                <th>Descrição</th>
                                <th>Tipo</th>
                                <th>Material</th>
                                <th>Fabricante</th>
                                <th>Tratamento</th>
                                <th>Trat.Opcional</th>
                                <th>Refr</th>
                                <th>Alt</th>
                                <th>Armação</th>
                                <th>Preço</th>
                                <th>Pz</th>
                            </tr>
                        </thead>


                        <tbody>
                        {list.map((item, i) => (
                            <>
                                <tr key={'tr' + i} className="pointer">
                                    <td style={{width:'28px'}}><input type="radio" name={'list_'+info?.side} value={item.lente_id} checked={item.lente_id===lente.lente_id} onChange={() => _HandleLente(item)}/></td>
                                    <td>{item.cod_loja}</td>
                                    <td>{linhas.find(l => l.id === item.linha).name}</td>
                                    <td className={'trat_opc'}>{item.nome}</td>
                                    <td>{tipos.find(t => t.id === item.tipo)?.name}</td>
                                    <td>{materiais.find(m => m.id === item.material)?.name}</td>
                                    <td className={'trat_opc'}>{item.fabricante}</td>
                                    <td className={'trat_obrig'}>
                                        {
                                            Array.isArray(item.obrigatorios) && item.obrigatorios.length>0 && item.obrigatorios.map((trat, j) => (
                                                <span key={'tratb_'+i+'_'+j}>{trat.nome} </span>
                                            ))
                                        }
                                    </td>
                                    <td className={'trat_opc'}>
                                        {
                                            Array.isArray(item.opcionais) && item.opcionais.length>0 && item.opcionais.map((trat, j) => (
                                                <span key={'tratp_'+i+'_'+j}>{trat.nome} </span>
                                            ))
                                        }
                                    </td>
                                    <td>{item.refr}</td>
                                    <td>{item.alt}</td>
                                    <td className={'trat_opc'}>{item.co===1&&('CO ')}{item.ny===1&&('NY ')} {item.pa===1&&('PA ')} {item.so===1&&('SO')}</td>
                                    <td>{Currency(item.venda/2)}</td>
                                    <td>{item.prazo}</td>
                                </tr>
                                <tr className={(item.lente_id===lente?.lente_id?'':'hide')}>
                                    <td colSpan={14} className="table-quest mb-0">
                                        <table className="table table-quest mb-0">
                                            <thead>
                                                <tr>
                                                    <td colSpan={(tipostratamentos.length+2)}>{item.nome} </td>
                                                    <td>&nbsp; <span style={{marginLeft:'15px'}}><b>{Currency(item.venda/2)}</b></span></td>
                                                    <td>{item.prazo} dias</td>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr className={(tratamentos?.length===0?'hide':'')}>
                                                    <td></td>
                                                    <td></td>
                                                    {
                                                        Array.isArray(tipostratamentos) && tipostratamentos.length > 0 && tipostratamentos.map((tipo, j) => (
                                                            <td key={"tipo_j"+j} id={"td_j"+j} style={{width:'35px', padding:'0.75rem 0'}} className="pointer th_abrev ctipo"><span>{tipo.titulo_abrev}</span></td>
                                                        ))
                                                    }
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                {
                                                    Array.isArray(tratamentos) && tratamentos.length > 0 && tratamentos.map((trat, n) => (
                                                        <tr>
                                                             <td style={{width:'30px', padding:'3px !important'}}><input type="checkbox" checked={trat?.selected===1||trat.obrigatorio===1} disabled={trat?.disabled === 1 || trat.tipo_id===1 || (trat.obrigatorio===1||(!(item.surf===1) && user.nivel > 0))} onClick={(event) => _HandleTratamentoClick(event, trat, item)}/></td>
                                                             <td>{trat.titulo} {<small>{trat?.tipo_id===2?'('+Currency(trat.valor)+')':''}</small>}</td>
                                                             {
                                                                    Array.isArray(tipostratamentos) && tipostratamentos.length > 0 && tipostratamentos.map((tipok, k) => (
                                                                        <td key={"ctipo_"+ i + "_" + k} className={'ctipo'}><center><span>{(trat.tipos.findIndex(t => t.tipo_tratamento_id === tipok.tipo_tratamento_id) > -1)?'X':''} </span></center></td>
                                                                    ))
                                                                }
                                                             <td className={trat?.selected!==1/*&&(tratamentos.findIndex(t => t.selected === 1) > -1)*/?'trat_unselected':''}> &nbsp; <span style={{marginLeft:'15px'}}><b>{trat?.tipo_id!==2?Currency(trat.valor/2):''}</b></span></td>
                                                            <td>{trat.prazo} dias</td>
                                                        </tr>
                                                    ))
                                                }

                                                <tr>
                                                    <td colSpan={(2)}></td>
                                                    <td colSpan={(tipostratamentos.length)}>
                                                        <select id={'colors'+i} className="form-control color_select" value={color} onChange={_HandleColorChange}>
                                                            <option value="">Escolha um Coloração</option>
                                                            {
                                                                Array.isArray(colors) && colors.length > 0 && colors.map((color, k) => (
                                                                    <option key={'color'+k} value={color.color_id}>{color.nome} {Currency(color.valor)}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                    <td colSpan={(1)}>&nbsp; <span style={{marginLeft:'15px'}}><b>{color!==''&&color!==0?Currency(colors.find(c=>c.color_id===parseInt(color))?.valor):''}</b></span></td>
                                                    <td colSpan={(1)}>{colors.find(c=>c.color_id===parseInt(color))?.prazo} {color!==''&&color!==0?'dias':''}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={(tipostratamentos.length+4)}><hr style={{border:'1px dashed #000000'}}/></td>
                                                </tr>
                                                <tr className={'total'}>
                                                    <td>&nbsp;</td>
                                                    <td colSpan={(tipostratamentos.length-2)}>
                                                        <b>Entrega em: {prazo}</b>
                                                    </td>
                                                    <td colSpan={3}><b>Subtotal:</b></td>
                                                    <td>&nbsp; <span style={{marginLeft:'12px'}}><b>{Currency(valorTotal+(colors.find(c=>c.color_id===parseInt(color))?.valor||0))}</b></span></td>
                                                    <td><button type="button" className={'btn btn-sm ' + (hasOs?'btn-success':'btn-danger')} onClick={() => _HandleSave(item)}>CONFIRMAR {info?.side}</button></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={(tipostratamentos.length+4)}>&nbsp;</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </td>
                                </tr>
                            </>
                        ))}
                        
                        </tbody>
                    </table>
                </InfiniteScroll>
            </div>
        </>
    )
});
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef} from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request } from '../../../scripts/Request';
import { HubContext } from '../../../Context/HubContext.js';
import { WaitObject } from '../../../scripts/Wait';
import { FormGenerator } from '../../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Currency, UnCurrency } from '../../../scripts/StringUtils';
import ReactGA, { set } from 'react-ga';

const tipo_model = {tipo_id: "", fornecedor_id: "", titulo:"", descricao:"", fabricacao_id: "", valor: 0, prazo: 0};

export function Cadastro() {
    const component_name = "admin/tipostratamentos_cadastro";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [block, setBlock] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [filtros, setFiltros] = useState([]);
    const [fornecedores, setFornecedores] = useState([]);
    //const [tipos, setTipos] = useState([]);
    const [tratamento, setTratamento] = useState({});
    const _formUserRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const navigate = useNavigate();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    let { Id } = useParams();


    //#region Effects
     useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);

    //#endregion Effects


    //#region Init
    const Init = () => {
        //LoadFornecedores();
    }
    //#endregion Init



     //#region Loaders
     const LoadFiltros = (arr) => {
        Promise.resolve(new Request().Run("/assets/json/tratamentos_filtros.json", "GET", "", "", 0*24*60*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            
            if(data.toString() !== "error") {
                let list = data.particulares.concat(data.profissionais.filter((item) => data.particulares.findIndex(p => p.filtro_id === item.filtro_id) === -1)); //[...data.particulares, ...data.profissionais];
                if(arr && arr.filtros) {
                    list.map((item, i) => {
                        item.selected = arr.filtros.findIndex(a => a.filtro_id === item.filtro_id) > -1 ? 1 : 0;
                    });
                }
                setFiltros(list);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde 1 ", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }


    /*const LoadFornecedores = () => {
        Promise.resolve(new Request().Run("api/TipoTratamentos/Fornecedores", "GET", "", user.token, 0*24*60*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            
            if(data.toString() !== "error") {
                setFornecedores(data);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }*/



    const UpdateFiltro = (item, filtro, selected) => Promise.resolve(new Request().Run("api/TipoTratamentos/Filtro", "POST", { tipo_tratamento_id: item.tipo_tratamento_id, filtro_id: filtro.filtro_id, selected: selected }, user.token)).then((data) => {});
    //#endregion Loaders



    //#region Form Handlers
    const OnFormLoaded = async(fields) => {
        setPageLoading(true);
 
        let tratamento_data;
        WaitObject(_formUserRef.current).then(async() => {
            if(Id) {
                tratamento_data = await new Request().Run("api/TiposTratamentos/Load?id="+Id, "GET", "", user.token);
                if(tratamento_data !== undefined && tratamento_data.tipo_tratamento_id !== null) {
                    await _formUserRef.current.Load(tratamento_data);
                    setTratamento(tratamento_data);

                    if(user.nivel > 0) {
                        _formUserRef.current.DisableFields();
                        setBlock(true);
                    } else {
                        setBlock(false);
                    }
                }
            } else {
                setBlock(false);
            }

            LoadFiltros(tratamento_data);
            /*if(tratamento_data.tipos && tratamento_data.tipos.length > 0) {
                setTipos(tratamento_data.tipos);
            } else {
                _HandleMore();
            }*/
            
         
            setPageLoading(false);
        });
        
    }

    
    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleFiltroChange = (event, item) => {
        let _filtros = [...filtros];
        let _index = _filtros.findIndex(u => u.filtro_id === item.filtro_id);

        if(_index > -1) {
            _filtros[_index].selected = event.target.checked ? 1: 0;
        }
        setFiltros(_filtros);
    }


    /*const _HandleReset = (event, field) => {
        let _tipos = [...tipos];
        let _index = parseInt(event.target.id.split('_')[1]);

        if(field === "valor") {
            _tipos[_index][field] = Currency(0);
        } else {
            _tipos[_index][field] = "";
        }
        setTipos(_tipos);
    }

    const _HandleChange = (event, field) => {
        let _tipos = [...tipos];

        let _index = parseInt(event.target.id.split('_')[1]);
        //console.log(_index, field, event.target.id, _tipos);
        if(field === "valor") {
            _tipos[_index][field] = Currency(event.target.value);
        } else {
            _tipos[_index][field] = event.target.value;
        }
        setTipos(_tipos);
    }


    const _HandleMore = () => {
        let _tipos = [...tipos];
        let _fornecedores = [...fornecedores];
        let _len = _tipos.length;
        let _tmp = Object.assign({},tipo_model);
        _tmp.fabricacao_id = 2;
        _tmp.fornecedor_id = _fornecedores[0].id
        _tipos.push(_tmp);
        setTipos(_tipos);
    }


    const _HandleRemove = (i) => {
        let _tipos = [...tipos];
        if(_tipos[i].tipo_id !== "") {
            Promise.resolve(new Request().Run("api/Tratamentos/DeleteTipo", "POST", { tipo_id: _tipos[i].tipo_id }, user.token)).then((data) => {});
        }
        _tipos.splice(i, 1);
        setTipos(_tipos);
    }*/


    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        let validade = _formUserRef.current.RequiredValidation();

        let _filtros = [...filtros];
        let tmp_filtros = _filtros.filter(f => {return f.selected === 1});


        /*let chk = true;
        let _tipos = [...tipos];

        //console.log(_tipos);

        _tipos.map(item => {
            //console.log(item.valor, UnCurrency(item.valor))
            if(item.titulo === "" || item.descricao === "") {
                chk = false;
                window.swal("Alerta", "Preencha os campos de Título e Descriçâo", "error");
                validade = false;
                return;
            }
        });*/

       //validade = false;
       
        if(validade) {
             setPageLoading(true);
            //_formUserRef.current.Test("","");

            let result = await _formUserRef.current.Post("api/TiposTratamentos/Save", {tipo_tratamento_id: Id, filtros: tmp_filtros/*, tipos: _tipos*/}, user.token);
            setPageLoading(false);

            if(result === "error") {
                window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
                return;
            } 

            window.scrollTo({top: 0, behavior: 'smooth'});
            navigate("/TiposTratamentos");
        } else {
            window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
        }
        
    }
    //#endregion Handlers
    


    return(
        <div className="main-content Tratamentos">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Cadastro de Tipos Tratamento</h4>
                                </div>

                                <div className="card-body pb-2">
                                   
                                    <form name="fuser" onSubmit={(event) => _HandleSubmit('tratamento', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                        <div className="row">
                                            <div className="col-md-12 pt-1 pb-1">
                                                <FormGenerator ref={_formUserRef} name={'tratamento'} url={'/assets/forms/tipos_tratamento.json'} execRef={Exec}></FormGenerator>
                                                <div style={{clear:'both'}}></div>
                                                <br />
                                         
                                                <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                                    <li className="nav-item">
                                                        <a className="nav-link active" data-bs-toggle="tab" href="#home2" role="tab">
                                                            <span className="d-block d-sm-none"><i className="fa fa-home"></i></span>
                                                            <span className="d-none d-sm-block"> <i className="mdi mdi-filter-plus"></i> &nbsp; Caracteristicas  &nbsp;  </span> 
                                                        </a>
                                                    </li>
                                                    {/*<li className="nav-item">
                                                        <a className="nav-link" data-bs-toggle="tab" href="#profile2" role="tab">
                                                            <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                                            <span className="d-none d-sm-block"> <i className="mdi mdi-clipboard-check-multiple-outline"></i> &nbsp; Tipos para <b>{tratamento && tratamento.titulo_resumido && (tratamento.titulo_resumido)}</b> &nbsp;  </span> 
                                                        </a>
                                                    </li>*/}
                                                </ul>
        
                                      
                                                <div className="tab-content p-3 text-muted">
                                                    <div className="tab-pane active" id="home2" role="tabpanel">
                                                        <div className="table-responsive">
                                                            <table className="table mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{width:'40px'}}></th>
                                                                        <th style={{width:'40px'}}>Id</th>
                                                                        <th>Nome do Filtro</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                {
                                                                        filtros.length > 0 && filtros.map((item, i) => (
                                                                            <tr key={'filtro'+i}>
                                                                                <td style={{ padding:'11px 0 0 3px'}}>
                                                                                    <input id={"filtro_i"+i} type="checkbox" style={{width:'22px', height: '22px', padding:'0 0 0 0', margin:'0'}} value={item.filtro_id} checked={item.selected} onClick={(event) => _HandleFiltroChange(event, item)} disabled={user.nivel>0}/>
                                                                                </td>
                                                                                <td>
                                                                                    {item.filtro_id}
                                                                                </td>
                                                                                <td>
                                                                                    {item.name}
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    {/*<div className="tab-pane" id="profile2" role="tabpanel">
                                                        <div className="table-responsive">
                                                            <table className="tipos table mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{width:'180px'}}>Fornecedor</th>
                                                                        <th>Título</th>
                                                                        <th>Descrição</th>
                                                                        <th style={{width:'130px'}}>Origem</th>
                                                                        <th style={{width:'130px'}}>Valor</th>
                                                                        <th style={{width:'30px'}}>Prazo</th>
                                                                        <th style={{width:'30px'}}></th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {
                                                                        tipos && tipos.length > 0 && tipos.map((item, i) => (
                                                                            <tr key={'tipo'+i}>
                                                                                <td>
                                                                                    <select id={'fornecedor_'+i} className="form-control" value={item.fornecedor_id !== "" ? item.fornecedor_id : fornecedores[0].id} disabled={(user.nivel>0)} onChange={(event) => _HandleChange(event, 'fornecedor_id')}>
                                                                                        {
                                                                                            Array.isArray(fornecedores) && fornecedores.length > 0 && fornecedores.map((item, j) => (
                                                                                                <option value={item.id}>{item.name}</option>
                                                                                            ))
                                                                                        } 
                                                                                    </select>
                                                                                 
                                                                                    
                                                                                </td>
                                                                                <td>
                                                                                    <input id={'titulo_'+i} type="text" className="form-control" value={item.titulo} disabled={(user.nivel>0)} onChange={(event) => _HandleChange(event, 'titulo')}/>
                                                                                </td>
                                                                                <td>
                                                                                    <input id={'descricao_'+i} type="text" className="form-control" value={item.descricao} disabled={(user.nivel>0)} onChange={(event) => _HandleChange(event, 'descricao')}/>
                                                                                </td>
                                                                                <td>
                                                                                    <select id={'fabricacao_'+i} className="form-control" value={item.fabricacao_id !== "" ? item.fabricacao_id : 2} disabled={(user.nivel>0)} onChange={(event) => _HandleChange(event, 'fabricacao_id')}>
                                                                                        <option value="2">Fabricação</option>
                                                                                        <option value="3">Surfassada</option>
                                                                                        <option value="1">Própria</option>
                                                                                    </select>
                                                                                </td>
                                                                                <td>
                                                                                    <input id={'valor_'+i}  type="text" className="form-control" value={Currency(item.valor)} onChange={(event) => _HandleChange(event, 'valor')} onDoubleClick={(event) =>_HandleReset(event, 'valor')}/>
                                                                                </td>
                                                                                <td>
                                                                                    <input id={'prazo_'+i} type="text" className="form-control" value={item.prazo} onChange={(event) => _HandleChange(event, 'prazo')}/>
                                                                                </td>
                                                                                <td style={{padding:'5px 0 0 5px'}}>
                                                                                    {
                                                                                        i > 0 && (<button type="button" className={'btn btn-sm btn-primary '} disabled={(user.nivel > 0)}  onClick={() => _HandleRemove(i)}><i className="fa fa-trash"></i></button>)
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <th colSpan={5}></th>                                                                    
                                                                        <th colSpan={2} style={{padding:'5px 0 0 5px'}}>
                                                                        {
                                                                            (user.nivel === 0 && fornecedores.length > 0) && (<button type="button" className={'btn btn-sm btn-success'} disabled={(user.nivel > 0)} style={{width:'100%'}} onClick={_HandleMore}><i className="fa fa-plus"></i> Adicionar</button>)
                                                                        }
                                                                        </th> 
                                                                    </tr> 
                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                    </div>*/}
                                                   
                                                </div>


                                                
                                                

                                                <center>
                                                    <hr />
                                                    <button id="btn_sv" className={'btn btn-primary btn-lg btn-rounded mb-2 '} type="submit">Salvar informações</button>
                                                 </center>
                                                <br />
                                            </div> 
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>


            
        </div>
    )
}
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef} from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request } from '../../../scripts/Request';
import { HubObjects } from '../../../scripts/HubObjects';
import { WaitObject } from '../../../scripts/Wait';
import { FormGenerator } from '../../Form/FormGenerator';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import moment from 'moment';
import './index.css';

const tipos_feriados = [
    {id: 1, name: "Nacional"},
    {id: 2, name: "Estadual"},
    {id: 3, name: "Municipal"}
];


export function Parametros() {
    const component_name = "admin/parametros";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [feriado, setFeriado] = useState({data: "", nome:"", tipo:(user.nivel>1?3:1)});
    const [list, setList] = useState([]);
    const [search, setSearch] = useState({ word: "" });
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();

    const _formUserRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: "" });


    const [tipos, setTipos] =  useState(
        user.nivel>1?[
        {id: 2, name: "Estadual"},
        {id: 3, name: "Municipal"}
    ]: [
        {id: 1, name: "Nacional"},
        {id: 2, name: "Estadual"},
        {id: 3, name: "Municipal"}
    ]);


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        _HandlerSearchChange();
    }
    //#endregion Init


    //#region Loaders

    //#endregion Loaders


    //#region Form Handlers
    const OnFormLoaded = async(fields) => {
        setPageLoading(true);
 
        WaitObject(_formUserRef.current).then(async() => {
            let parametros_data = await new Request().Run("api/Parametros/Load", "GET", "", user.token);
            if(parametros_data !== undefined && parametros_data.parametro_id !== null) {
                await _formUserRef.current.Load(parametros_data);

                if(user.nivel > 1) {
                    _formUserRef.current.DisableFields();
                } 
            }

            setPageLoading(false);
        });
        
    }

    
    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleChange = (event) => {
        console.log(event.target.id, event.target.value);
        setFeriado(prev => prev = {...prev, [event.target.id]: event.target.value});
    }


    const Clear = () => {
        setFeriado({data: "", nome:"", tipo:(user.nivel>1?3:1)});
    }


    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setPageLoading(true);
        setHasMore(true);

        Promise.resolve(new Request().Run("api/Parametros/Feriados/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", { word: searchRef.current.word }, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];
                listRef.current = listRef.current.concat(_list);
                setList(listRef.current);
                setPageLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setPageLoading(true);

            Promise.resolve(new Request().Run("api/Parametros/Feriados/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", { word: searchRef.current.word }, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    listRef.current = listRef.current.concat(_list);
                    setList(listRef.current);
                    setPageLoading(false);
                });
        } catch (e) { }
    }


    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        let validade = _formUserRef.current.RequiredValidation();

        if(validade) {
            setPageLoading(true);
            let result = await _formUserRef.current.Post("api/Parametros/Save", {}, user.token);
            setPageLoading(false);

            if(result === "error") {
                window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
                return;
            } 

            window.scrollTo({top: 0, behavior: 'smooth'});
            window.swal("Sucesso", "Parâmetros salvos com sucesso!", "success");
        } else {
            window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
        }

    }


    const _HandleAddFeriado = async() => {
        setPageLoading(true);
        
        let _feriado = {...feriado};
        console.log(_feriado);

        Promise.resolve(new Request().Run("api/Parametros/SaveFeriado", "POST", { nome: _feriado.nome, data: moment(_feriado.data).format('YYYY-MM-DD'), tipo: _feriado.tipo }, user.token))
            .then((data) => {
                _HandlerSearchChange();
                setPageLoading(false);
                Clear();
            });
    }


    const _HandleDeleteFeriado = (id) => {
        setPageLoading(true);

        Promise.resolve(new Request().Run("api/Parametros/DeleteFeriado", "POST", { feriado_id: id }, user.token))
            .then((data) => {
                _HandlerSearchChange();
                setPageLoading(false);
            });
    }
    //#endregion Handlers


    return (
        <div className="main-content Parametros">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Cadastro de Parametros</h4>
                                </div>

                                <div className="card-body pb-2">
                                   
                                    <form name="fuser" onSubmit={(event) => _HandleSubmit('parametros', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                        <div className="row">
                                            <div className="col-md-12 pt-1 pb-1">
                                                <FormGenerator ref={_formUserRef} name={'parametros'} url={'/assets/forms/parametros.json'} execRef={Exec}></FormGenerator>
                                                <div style={{clear:'both'}}></div>
                                                <center>
                                                    <hr />
                                                    {user.nivel <= 1 && (
                                                    <button id="btn_sv" className="btn btn-primary btn-lg btn-rounded mb-2" type="submit">Salvar informações</button>
                                                    )}       
                                                </center>
                                                <br />
                                            </div> 
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>


                        <div className="col-xl-6">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Cadastro de feriados</h4>
                                </div>

                                <div className="card-body pb-2">
                                    <div style={{width:'20%',float:'left', padding:'3px'}}>
                                        <div className={'form-floating mb-3'}>
                                            <input type="date" className="form-control" id="data" name="data" required="true" value={feriado.data} onChange={_HandleChange}/>
                                            <label for="input-username">Data <em>*</em></label>
                                        </div>
                                    </div>

                                    <div style={{width:'45%',float:'left', padding:'3px'}}>
                                        <div className={'form-floating mb-3'}>
                                            <input type="text" className="form-control" id="nome" name="nome" required="true" value={feriado.nome} placeholder="Nome do feriado" onChange={_HandleChange}/>
                                            <label for="input-username">Nome <em>*</em></label>
                                        </div>
                                    </div>

                                    <div style={{width:'30%',float:'left', padding:'3px'}}>
                                        <div className={'form-floating mb-3'}>
                                            <select id="tipo" name="tipo" className={'form-control fg-input pointer '} aria-describedby="basic-addon2" value={feriado.tipo} onChange={_HandleChange}>
                                                {tipos.map((option, i) => (
                                                    <option key={'opti_' + i} value={option.id} selected={option.selected === true}>{option.name}</option>
                                                ))}
                                            </select>
                                            <label for="input-username">Tipo <em>*</em></label>
                                        </div>
                                    </div>

                                    <div style={{width:'5%',float:'left', padding:'3px'}}>
                                        <div className="_form-floating-btn" style={{marginTop:'9px'}}>
                                            <button type="button" className="btn btn-outline-primary" onClick={() => _HandleAddFeriado()}>
                                                <i className="fa fa-plus"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div style={{clear:'both'}}></div>


                                    <div className="table-responsive">
                                        <InfiniteScroll
                                            dataLength={list.length}
                                            next={_fetchMoreData}
                                            hasMore={hasMore}
                                            loader={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Carregando...</b>
                                                </p>
                                            }
                                            style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                            endMessage={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Não há mais dados</b>
                                                </p>
                                            }>    


                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Data</th>
                                                        <th>Nome</th>
                                                        <th>Tipo</th>
                                                        <th style={{width:'45px'}}></th>
                                                    </tr>
                                                </thead>


                                                <tbody>
                                                {list.map((item, i) => (
                                                    <tr key={'tr' + i} className="pointer">
                                                        <td>{moment(item.data).format('DD/MM/YYYY')}</td>
                                                        <td>{item.nome}</td>
                                                        <td>{tipos_feriados.find(t => t.id === item.tipo)?.name}</td>
                                                        <td><button type="button" className={'btn btn-sm btn_ativo btn-danger '} onClick={() => _HandleDeleteFeriado(item.feriado_id)} disabled={(user.nivel>1&&(user.unidade_id!==item.unidade_id)||(user.nivel===1&&(user.rede_id!==item.rede_id)))}><i className="fa fa-trash"></i></button></td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </table>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}
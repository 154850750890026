/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Request } from '../../../scripts/Request';
import { HubObjects } from '../../../scripts/HubObjects';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import { Currency, UnCurrency, Number } from '../../../scripts/StringUtils';
import { Form, useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import './index.css';


export default function Lista() {
    const component_name = "admina/cessorios_lista";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedItems, setSelectedItems] =  useState([]);
    const [list, setList] = useState([]);
    const [fornecedores, setFornecedores] = useState([]);
    const [search, setSearch] = useState({ word: "", fornecedor_id: "", selected: "" });
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: "", fornecedor_id: "", selected: "" });

    const navigate = useNavigate();


    
    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects


     //#region Init
     const Init = () => {
        LoadFornecedores();
        _HandlerSearchChange();
     }
    //#endregion Init



    //#region Loaders
    const LoadFornecedores = () => {
        Promise.resolve(new Request().Run("api/Acessorios/Fornecedores", "GET", "", user.token, 0*24*60*60*1000))
        .then(async(data) => {
            if(data.toString() !== "error") {
                setFornecedores(data);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }


    const LoadMarcas  = async(fornecedor_id) => {
        return Promise.resolve(new Request().Run("api/Acessorios/Marcas?fid=" + fornecedor_id, "GET", "", user.token, 10*1000))
        .then(async(data) => {
            if(data.toString() !== "error") {
                return data;
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }


    const LoadLinhas = async(marca_id) => {
        if(marca_id === undefined || marca_id === '' || marca_id === 0) return [];
        return Promise.resolve(new Request().Run("api/Acessorios/Linhas?mid=" + marca_id, "GET", "", user.token, 10*1000))
        .then(async(data) => {
            if(data.toString() !== "error") {
                return data;
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }
    //#endregion Loaders



    //#region Handlers
    const _HandleChange = (event) => {
        setSearch(prev => prev = {[event.target.id]: event.target.value});
        searchRef.current = {[event.target.id]: event.target.value};
    }


    const _HandleClear = (event) => {
        setSearch(prev => prev = {word: ""});
        searchRef.current = {word: ""};
        _HandlerSearchChange(event);
    }


    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setPageLoading(true);
        setHasMore(true);

        Promise.resolve(new Request().Run("api/Acessorios/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
            .then(async(data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];
                listRef.current = listRef.current.concat(_list);
                //setList(listRef.current);
                ProcMarcas(listRef.current);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setPageLoading(true);

            Promise.resolve(new Request().Run("api/Acessorios/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    listRef.current = listRef.current.concat(_list);
                    ProcMarcas(listRef.current);
                    //setList(listRef.current);
                });
        } catch (e) { }
    }


    const ProcMarcas = async(list) => {
        let r = [];
        list.map(async(item, i) => {
            if(item.marcas === undefined)  item = Object.assign(item, {marcas: await LoadMarcas(item.fornecedor_id), linhas: await LoadLinhas(item.marca_id)});
            r.push(item);
        });

        let wait_r = setInterval(() => {
            if(r.length === list.length) {
                clearInterval(wait_r);
                listRef.current = r;
                setPageLoading(false);
                setList(listRef.current);
            }
        }, 100);
       
    }


    const ProcMarcasByIndex = async(index, field) => {
        let _list = [...list];
        _list[index].marcas = await LoadMarcas(_list[index].fornecedor_id);

        if(_list[index].marcas.length > 0) {
            if(field === "fornecedor_id") {
                _list[index].linhas = await LoadLinhas(_list[index].marcas[0].id);
            } else if(field === "marca_id") {
                _list[index].linhas = await LoadLinhas(_list[index].marca_id);
            }
        } else {
            _list[index].linhas = [];
        }
        setList(_list);
    }


    const _HandleEdit = (e, id) => {
        navigate("/Acessorio/"+id);
    }


    const _HandleAddArmacao = () => {
        navigate("/Acessorio/");
    }


    const _HandleItemChange = (event, field) => {
        let _list = [...list];

        let _index = parseInt(event.target.id.split('_')[1]);
        let _old = Object.assign({},  _list[_index]);
        //console.log(_old);

        if(event.target.type==="checkbox") {
            _list[_index][field] = (event.target.checked ? 1 : 0);
        } else {
            _list[_index][field] = event.target.value;
        }

        if(field === "fornecedor_id" || field === "marca_id")
        {
            ProcMarcasByIndex(_index, field);
        } else {
            setList(_list);
        }
       

        if(_index > -1) {
            let _selectedItems = [...selectedItems];
            let _arrindex = _selectedItems?.findIndex(s => s.index === _index);
            if(_arrindex === -1) {
                _selectedItems.push({index: _index, data: _list[_index], old: _old });
            } else {
                //_selectedItems[_arrindex] = {index: _index, data: _list[_index], old: _old };
            }
            setSelectedItems(_selectedItems);
        }
    }


    const _HandleSaveItem = (e, i , item) => {
        Promise.resolve(new Request().Run("api/Acessorios/Update", "POST", item, user.token))
        .then((data) => {
            let _selectedItems = [...selectedItems];
            let _index = _selectedItems?.findIndex(s => s.index === i);
            _selectedItems.splice(_index, 1);
            setSelectedItems(_selectedItems);
        });
    }


    const _HandleCancelItem = (e, i , item) => {
        let _list = [...list];
        let _selectedItems = [...selectedItems];

        let _index = _selectedItems?.findIndex(s => s.index === i);

        if(_index > -1) {
            _list[i] = Object.assign({},  _selectedItems[_index].old);
            _selectedItems.splice(_index, 1);
            setSelectedItems(_selectedItems);
            setList(_list);
        }
    }


    const _HandleReset = (event, field) => {
        let _list = [...list];
        let _index = parseInt(event.target.id.split('_')[1]);

        let _old = (_list[_index]) ? _list[_index] : Object.assign({},  _list[_index]);

        if(field === "adicao1" || field === "adicao2") {
            _list[_index][field] = '0,00';
        } else if(field === "valor") {
            _list[_index][field] = Currency(0);
        } else {
            _list[_index][field] = "";
        }
        setList(_list);

        if(_index > -1) {
            let _selectedItems = [...selectedItems];
            _selectedItems.push({index: _index, data: _list[_index], old: _old });
            setSelectedItems(_selectedItems);
        }
    }
    //#endregion Handlers


    return (
        <div className="main-content Acessorios">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Lista de Acessórios</h4>

                                    <div className="row"> 
                                        <div className="col-xl-12"> 
                                            <div className={'align-right ' + (user.nivel>1?'hide':'')}>
                                                <button type="button" className="btn btn-primary" onClick={_HandleAddArmacao}>
                                                    <i className="mdi mdi-star-plus"></i> &nbsp;Adicionar
                                                </button>
                                            </div>

                                            <div className="align-right mr-10">
                                                <button type="button" className="btn btn-outline-primary" onClick={_HandlerSearchChange}>
                                                    <i className="mdi mdi-share-outline"></i> &nbsp;Buscar
                                                </button>
                                            </div>

                                            <div className="search-box align-right mr-10">
                                                <div className="position-relative">
                                                    <select id="fornecedor_id" className="form-select" value={search.fornecedor_id} onChange={_HandleChange}>
                                                        <option value="">Todos fornecedores</option>
                                                        {
                                                            Array.isArray(fornecedores) && fornecedores.length > 0 && fornecedores.map((item, i) =>(
                                                                <option key={'forn'+i} value={item.id}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div> 

                                            <div className="search-box align-right mr-10">
                                                <div className="position-relative">
                                                    <input id="word" typeName="text" value={search.word} className="form-control rounded bg-light border-0" placeholder="" onChange={_HandleChange}/>
                                                    <i className="bx bx-search search-icon"></i>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body pb-0">
                                    <div className="table-responsive">
                                        <InfiniteScroll
                                            dataLength={list.length}
                                            next={_fetchMoreData}
                                            hasMore={hasMore}
                                            loader={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Carregando...</b>
                                                </p>
                                            }
                                            style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                            endMessage={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Não há mais dados</b>
                                                </p>
                                            }>    

                                            <table className="table _table-bordered mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Fornecedor</th>
                                                        <th>Marca</th>
                                                        <th>Linha</th>
                                                        <th>Representante</th>
                                                        <th>Telefone</th>
                                                        <th>Observação</th>
                                                        <th style={{width:'50px'}} className={(user.nivel>6?'hide':'')}></th>
                                                    </tr>
                                                </thead>


                                                <tbody>
                                                {list.map((item, i) => (
                                                    <tr key={'tr' + i} className={'pointer '+  (selectedItems?.findIndex(s => s.index === i) > -1 ? 'trfocus':'')}>
                                                        <td className="inputtext fornecedor">
                                                            <select id={"fornecedorid_"+i} value={item.fornecedor_id} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'fornecedor_id')}>
                                                                {
                                                                    Array.isArray(fornecedores) && fornecedores.length > 0 && fornecedores.map((forn, j) =>(
                                                                        <option key={'forn'+i+'_'+j} value={forn.id}>{forn.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </td>

                                                        <td className="inputtext marca">
                                                            <select id={"marca_"+i} value={item.marca_id} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'marca_id')}>
                                                                {
                                                                    Array.isArray(item.marcas) && item.marcas.length > 0 && item.marcas.map((marc, j) =>(
                                                                        <option key={'marca'+i+'_'+j} value={marc.id}>{marc.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </td>

                                                        <td className="inputtext marca">
                                                            <select id={"linha_"+i} value={item.linha_id} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'linha_id')}>
                                                                {
                                                                    Array.isArray(item.linhas) && item.linhas.length > 0 && item.linhas.map((linh, j) =>(
                                                                        <option key={'linh'+i+'_'+j} value={linh.id}>{linh.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </td>

                                                        <td className="inputtext representante">
                                                            <input id={"representante_"+i} type="text" value={item.representante} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'representante')}/>
                                                        </td>

                                                        <td className="inputtext telefone">
                                                            <input id={"telefone_"+i} type="text" value={item.telefone} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'telefone')}/>
                                                        </td>
                      
                                                        <td className="inputtext obs">
                                                            <input id={"obs_"+i} type="text" value={item.obs} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'obs')}/>
                                                        </td>              

                                                        <td className={'edit ' + ((selectedItems?.findIndex(s => s.index === i) > -1)||(user.nivel>6)?'hide':'')} > 
                                                            <button type="button" className={'btn btn-sm btn-info '} onClick={(e) => _HandleEdit( e, item.id)}><i className="fa fa-edit"></i></button>
                                                        </td>

                                                        <td className={'edit ' +  ((selectedItems?.findIndex(s => s.index === i) === -1)||(user.nivel>1)?'hide':'')} > 
                                                            <button type="button" className={'btn btn-sm btn_ativo btn-danger '} onClick={(e) => _HandleSaveItem(e, i, item)}><i className="fa fa-check"></i></button>
                                                        </td>

                                                        <td className={'edit ' +  ((selectedItems?.findIndex(s => s.index === i) === -1)||(user.nivel>1)?'hide':'')} > 
                                                             <button type="button" className={'btn btn-sm btn-warning '} onClick={(e) => _HandleCancelItem(e, i, item)}><i className="fa fa-reply"></i></button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </table>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>
        </div>
    )
}
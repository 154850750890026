/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request } from '../../../scripts/Request';
import { HubObjects } from '../../../scripts/HubObjects';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Currency, UnCurrency } from '../../../scripts/StringUtils';
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";



export default function Lista() {
    const component_name = "admin/tratamentos_lista";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const [tipos, setTipos] = useState([]);
    const [selectedItems, setSelectedItems] =  useState([]);
    const [fornecedores, setFornecedores] = useState([]);
    const [search, setSearch] = useState({ word: "", fornecedor_id: "", tipo_tratamento_id: "" });
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: "" });

    const navigate = useNavigate();


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
     const Init = () => {
        LoadTipos();
        LoadFornecedores();
        _HandlerSearchChange();
     }
    //#endregion Init


    //#region Loaders
    const LoadTipos = () => {
        Promise.resolve(new Request().Run("api/Tratamentos/Tipos", "GET", "", user.token, 0*24*60*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            
            if(data.toString() !== "error") {
                setTipos(data);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }



    const LoadFornecedores = () => {
        Promise.resolve(new Request().Run("api/TiposTratamentos/Fornecedores", "GET", "", user.token, 0*24*60*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            
            if(data.toString() !== "error") {
                setFornecedores(data);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }



    const UpdateTipo = (item, tipo, selected) => Promise.resolve(new Request().Run("api/Tratamentos/Tipo", "POST", { tratamento_id: item.tratamento_id, tipo_tratamento_id: tipo.tipo_tratamento_id, selected: selected }, user.token)).then((data) => {});
    //#endregion Loaders


    //#region Handlers
    const _HandleChange = (event) => {
        setSearch(prev => prev = {[event.target.id]: event.target.value});
        searchRef.current = {[event.target.id]: event.target.value};
    }


    const _HandleItemChange = (event, field) => {
        let _list = [...list];
        let _index = parseInt(event.target.id.split('_')[1]);
        let _old = Object.assign({},  _list[_index]);

        if(field === "valor") {
            _list[_index][field] = UnCurrency(event.target.value);
        } else {
            _list[_index][field] = event.target.value;
        }
        setList(_list);

        if(_index > -1) {
            let _selectedItems = [...selectedItems];
            _selectedItems.push({index: _index, data: _list[_index], old: _old });
            //console.log( _selectedItems);
            setSelectedItems(_selectedItems);
        }
    }


    
    const _HandleSaveItem = (e, i , item) => {
        Promise.resolve(new Request().Run("api/Tratamentos/Update", "POST", item, user.token))
        .then((data) => {
            let _selectedItems = [...selectedItems];
            let _index = _selectedItems?.findIndex(s => s.index === i);

            _selectedItems.splice(_index, 1);
            setSelectedItems([]);
        });
    }


    const _HandleCancelItem = (e, i , item) => {
        let _list = [...list];
        let _selectedItems = [...selectedItems];

        let _index = _selectedItems?.findIndex(s => s.index === i);

        if(_index > -1) {
            _list[i] = Object.assign({},  _selectedItems[_index].old);
            _selectedItems.splice(_index, 1);
            setSelectedItems([]);
            setList(_list);
        }
    }

    

    const _HandleReset = (event, field) => {
        let _list = [...list];
        let _index = parseInt(event.target.id.split('_')[1]);

        if(field === "valor") {
            _list[_index][field] = Currency(0);
        } else {
            _list[_index][field] = "";
        }
        setList(_list);
    }


    const _HandleClear = (event) => {
        setSearch(prev => prev = {word: ""});
        searchRef.current = {word: ""};
        _HandlerSearchChange(event);
    }



    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setPageLoading(true);
        setHasMore(true);

        Promise.resolve(new Request().Run("api/Tratamentos/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];
                listRef.current = listRef.current.concat(_list);
                setList(listRef.current);
                setPageLoading(false);
               
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setPageLoading(true);

            Promise.resolve(new Request().Run("api/Tratamentos/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    listRef.current = listRef.current.concat(_list);
                    setList(listRef.current);
                    setPageLoading(false);
                });
        } catch (e) { }
    }


    const _HandleEdit = (e, id) => {
        navigate("/Tratamento/"+id);
    }


    const _HandleAddFornecedor = () => {
        navigate("/Tratamento/");
    }


    const _HandleAtivo = (item) => {
        Promise.resolve(new Request().Run("api/Tratamentos/Ativo", "POST", { tratamento_id: item.tratamento_id, ativo: item.ativo}, user.token))
        .then((data) => {
            let _list = [...list];
            _list.find(u => u.tratamento_id === item.tratamento_id).ativo = data;
            setList(_list);
        });
    }




    const  _HandleTipoChange = (event, item, tipo) => {
        let _list = [...list];
        let _index = _list.findIndex(u => u.tratamento_id === item.tratamento_id);

        //console.log(event, item, tipo);

        if(_index > -1) {
            if(_list[_index].tipos !== null ) {
                let _findex =  _list[_index].tipos.findIndex(f => f.tipo_tratamento_id === tipo.tipo_tratamento_id);

                if(_findex > -1) {
                    if(!event.target.checked) {
                        _list[_index].tipos.splice(_findex, 1);
                    }
                } else {
                    if(event.target.checked) _list[_index].tipos.push({tipo_tratamento_id: tipo.tipo_tratamento_id, selected: (event.target.checked ? 1 : 0)});
                }
                UpdateTipo(item, tipo, (event.target.checked ? 1 : 0));
            } else {
                _list[_index].tipos = [];
                if(event.target.checked) _list[_index].tipos.push({tipo_tratamento_id: tipo.tipo_tratamento_id, selected: (event.target.checked ? 1 : 0)});
                UpdateTipo(item, tipo, (event.target.checked ? 1 : 0));
            }
        }

        setList(_list);
    }
    //#endregion Handlers



    return (
        <div className="main-content Tratamentos">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Lista de Tratamentos</h4>

                                    <div className="row"> 
                                        <div className="col-xl-12"> 
                                            <div className={'align-right ' + (user.nivel>0?'hide':'')}>
                                                <button type="button" className="btn btn-primary" onClick={_HandleAddFornecedor}>
                                                    <i className="mdi mdi-star-plus"></i> &nbsp;Adicionar
                                                </button>
                                            </div>

                                            <div className="align-right mr-10">
                                                <button type="button" className="btn btn-outline-primary" onClick={_HandlerSearchChange}>
                                                    <i className="mdi mdi-share-outline"></i> &nbsp;Buscar
                                                </button>
                                            </div>

                                            <div className="search-box align-right mr-10">
                                                <div className="position-relative">
                                                    <select id="tipo_tratamento_id" className="form-select" value={search.tipo_tratamento_id} onChange={_HandleChange}>
                                                        <option value="">Qualquer tratamento</option>
                                                        {
                                                            Array.isArray(tipos) && tipos.length > 0 && tipos.map((item, i) => (
                                                                <option key={'tipo_trat'+i} value={item.tipo_tratamento_id}>{item.titulo_abrev}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div> 

                                            <div className="search-box align-right mr-10">
                                                <div className="position-relative">
                                                    <select id="fornecedor_id" className="form-select" value={search.fornecedor_id} onChange={_HandleChange}>
                                                        <option value="">Todos fornecedores</option>
                                                        {
                                                            Array.isArray(fornecedores) && fornecedores.length > 0 && fornecedores.map((item, i) =>(
                                                                <option key={'forn'+i} value={item.id}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div> 

                                            <div className="search-box align-right mr-10">
                                                <div className="position-relative">
                                                    <input id="word" typeName="text" value={search.word} className="form-control rounded bg-light border-0" placeholder="" onChange={_HandleChange}/>
                                                    <i className="bx bx-search search-icon"></i>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body pb-2">
                                    <div className="table-responsive">
                                        <InfiniteScroll
                                            dataLength={list.length}
                                            next={_fetchMoreData}
                                            hasMore={hasMore}
                                            loader={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Carregando...</b>
                                                </p>
                                            }
                                            style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                            endMessage={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Não há mais dados</b>
                                                </p>
                                            }>    


                                            { tipos.length > 0 && tipos.map((item, i) => (
                                            <ReactTooltip key={"tooltip"+i} anchorId={"td_i"+i} variant="info" place="top" content={item.titulo}/>))
                                            }

                                            <table className="tipos table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th style={{width:'150px'}}>Fornecedor</th>
                                                        <th>Título</th>
                                                        <th>Descrição</th>
                                                        <th style={{width:'130px'}}>Tipo</th>
                                                        <th style={{width:'130px'}}>Valor</th>
                                                        <th style={{width:'30px'}}>Prazo</th>
                                                        {
                                                            tipos.length > 0 && tipos.map((item, i) => (
                                                              
                                                                <th key={"tipo_i"+i} id={"td_i"+i} style={{width:'20px', padding:'0.75rem 0'}} className="pointer th_abrev"><center><span>&nbsp;{item.titulo_abrev}</span></center>
                                                                </th>
                                                             
                                                            ))
                                                        }
                                                        <th style={{width:'50px'}} className={(user.nivel>=0?'hide':'')}></th>
                                                        <th style={{width:'40px'}} className={(user.nivel>=0?'hide':'')}></th>
                                                    </tr>
                                                </thead>


                                                <tbody>
                                                {list.map((item, i) => (
                                                    <tr key={'tr' + i} className={'pointer ' + (selectedItems?.findIndex(s => s.index === i) > -1 ? 'trfocus':'')}>
                                                        <td> 
                                                            <select id={'fornecedor_'+i} className="form-control" value={item.fornecedor_id !== "" ? item.fornecedor_id : fornecedores[0].id} disabled={(user.nivel>0)} onChange={(event) => _HandleItemChange(event, 'fornecedor_id')}>
                                                                {
                                                                    Array.isArray(fornecedores) && fornecedores.length > 0 && fornecedores.map((item, j) => (
                                                                        <option key={'forneck'+i} value={item.id}>{item.name}</option>
                                                                    ))
                                                                } 
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <input id={'titulo_'+i} type="text" className="form-control" value={item.titulo} disabled={(user.nivel>0)} onChange={(event) => _HandleItemChange(event, 'titulo')}/>
                                                        </td>
                                                        <td>
                                                            <input id={'descricao_'+i} type="text" className="form-control" value={item.descricao} disabled={(user.nivel>0)} onChange={(event) => _HandleItemChange(event, 'descricao')}/>
                                                        </td>
                                                        <td>
                                                            <select id={'fabricacao_'+i} className="form-control" value={item.tipo_id !== "" ? item.tipo_id : 2} disabled={(user.nivel>0)} onChange={(event) => _HandleItemChange(event, 'fabricacao_id')}>
                                                                <option value="1">Fabricação</option>
                                                                <option value="2">Pronta</option>
                                                                <option value="3">Surfassada</option>
                                                                
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <input id={'valor_'+i}  type="text" className="form-control" value={Currency(item.valor)} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'valor')} onDoubleClick={(event) =>_HandleReset(event, 'valor')}/>
                                                        </td>
                                                        <td>
                                                            <input id={'prazo_'+i} type="text" className="form-control" value={item.prazo} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'prazo')}/>
                                                        </td>

                                                                
                                                        {
                                                            tipos.length > 0 && tipos.map((tipo, j) => (
                                                                <td key={"tipo_i"+i+"_j"+j} style={{ padding:'7px 0 0 3px'}}>
                                                                    <input id={"tipo_i"+i+"_j"+j} type="checkbox" style={{width:'22px', height: '22px', padding:'0 0 0 0', margin:'0'}} value={tipo.tipo_tratamento_id} checked={item.tipos?.findIndex(f => f.tipo_tratamento_id === tipo.tipo_tratamento_id) > -1} onClick={(event) => _HandleTipoChange(event, item, tipo)} disabled={(user.nivel>0)}/>
                                                                </td>
                                                            ))
                                                        }
                                                        <td style={{padding:'3px 0 0 3px'}} className={(user.nivel>0||(selectedItems?.findIndex(s => s.index === i) > -1)?'hide':'')}><button type="button" className={'btn btn-sm btn_ativo ' + (item.ativo===1?'btn-primary ':'btn-danger')} onClick={() => _HandleAtivo(item)} disabled={(user.nivel>0)}>{item.ativo===1?'Ativo':'Inativo'}</button></td>
                                                        <td style={{padding:'3px 0 0 3px'}} className={(user.nivel>0||(selectedItems?.findIndex(s => s.index === i) > -1)?'hide':'')}>
                                                            <button type="button" className={'btn btn-sm btn-info '} onClick={(e) => _HandleEdit( e, item.tratamento_id)}><i className="fa fa-edit"></i></button>
                                                        </td>

                                                        <td style={{padding:'3px 2px 0 3px',width:'30px'}} className={((selectedItems?.findIndex(s => s.index === i) === -1)?'hide':'')}>
                                                            <button type="button" className={'btn btn-sm btn-danger '} onClick={(e) => _HandleSaveItem( e, i, item)}><i className="fa fa-save"></i></button>
                                                        </td>

                                                        <td style={{padding:'3px 2px 0 3px',width:'30px'}} className={((selectedItems?.findIndex(s => s.index === i) === -1)?'hide':'')}>
                                                            <button type="button" className={'btn btn-sm btn-warning '} onClick={(e) => _HandleCancelItem( e, i, item)}><i className="fa fa-reply"></i></button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </table>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>
        </div>
    )
}
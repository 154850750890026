/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef} from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request } from '../../../scripts/Request';
import { HubContext } from '../../../Context/HubContext.js';
import { WaitObject } from '../../../scripts/Wait';
import { FormGenerator } from '../../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";
import ReactGA from 'react-ga';



export function Cadastro() {
    const component_name = "admin/usuarios_cadastro";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [unidades, setUnidades] = useState([]);
    const _formUserRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const navigate = useNavigate();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    let { Id } = useParams();


    //#region Effects
     useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                    
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        
    }
    //#endregion Init



    //#region Loaders
    const LoadNiveis = () => {
        Promise.resolve(new Request().Run("api/Usuarios/Niveis", "GET", "", user.token, 0*24*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            await _formUserRef.current.Select("nivel", data);

            if(data.toString() !== "error") {
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }

    //#endregion Loaders


    //#region Form Handlers
    const OnFormLoaded = async(fields) => {
        setPageLoading(true);
 
        let user_data;
        WaitObject(_formUserRef.current).then(async() => {
            if(Id) {
                await _formUserRef.current.SetObject("senha", {phvisible: true});
                await _formUserRef.current.SetObject("resenha", {phvisible: true});
                user_data = await new Request().Run("api/Usuarios/Load?id="+Id, "GET", "", user.token);
                if(user_data !== undefined && user_data.user_id !== null) {
                    await _formUserRef.current.Load(user_data);
                }
            } else {
                await _formUserRef.current.SetObject("senha", {phvisible: false});
                await _formUserRef.current.SetObject("resenha", {phvisible: false});
                await _formUserRef.current.Value("nome", "a");
                await _formUserRef.current.Value("email", "a");
                setTimeout(async() => {
                    await _formUserRef.current.Value("nome", "");
                    await _formUserRef.current.Value("email", "");
                }, 100);
            }

            setTimeout(async() => {
                LoadNiveis();
                await _formUserRef.current.Value("senha", "a");
                await _formUserRef.current.Value("resenha", "");
                setTimeout(async() => {
                    await _formUserRef.current.Value("senha", "");
                }, 100);

                let unidades;
                let wait_unidades = setInterval(() => {
                    unidades = Hub.Exec("HEADER_UNIDADES");
                    if(unidades && unidades.length > 0) {
                        clearInterval(wait_unidades);
                        if(user_data) {
                            let _myUnidades = user_data.unidades?.split(',');

                            unidades.map((item, i) => {
                                //console.log(_myUnidades, unidades, _myUnidades.findIndex(v => parseInt(v) === item.id) );
                                if(_myUnidades.find(v => parseInt(v) === item.id) > -1) {
                                    item.selected = true;
                                } else {
                                    item.selected = false;
                                }
                            });
                        }
                        setUnidades(unidades);
                    } 
                }, 100);

                forceUpdate();
              
            }, 300);

            setPageLoading(false);
        });
        
    }

    
    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleUnidadeChange = (e, item) => {
        let _unidades = [...unidades];
        try {
            _unidades.find(u => u.id === item.id).selected = e.target.checked;
        } catch(e) { }
        setUnidades(_unidades);
    }


    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        let validade = _formUserRef.current.RequiredValidation();

       
        if(validade) {
             setPageLoading(true);
            //_formUserRef.current.Test("","");

            let _unidades = [...unidades];
            let unidades_selected = [];
          
            _unidades.map((item, i) => {
                if(item.selected) unidades_selected.push({unidade_id: item.id});
            });

            let result = await _formUserRef.current.Post("api/Usuarios/Save", {user_id: Id, unidades: unidades_selected}, user.token);
            setPageLoading(false);

            if(result === "error") {
                window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
                return;
            } else if(result === "exists") {
                window.swal("Alerta", "E-mail já registrado por outro usuário", "error");
                return;
            }

            window.scrollTo({top: 0, behavior: 'smooth'});
            navigate("/Usuarios");
        } else {
            window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
        }

    }
    //#endregion Handlers
    


    return(
        <div className="main-content Usuarios">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Cadastro de Usuario</h4>
                                </div>

                                <div className="card-body pb-2">
                                   
                                    <form name="fuser" onSubmit={(event) => _HandleSubmit('user', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                        <div className="row">
                                            <div className="col-md-12 pt-1 pb-1">
                                                <FormGenerator ref={_formUserRef} name={'user'} url={'/assets/forms/user.json'} execRef={Exec}></FormGenerator>
                                                <div style={{clear:'both'}}></div>

                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <table className="table mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{width:'50px'}}></th>
                                                                    <th>Unidade</th>
                                                                    <th>Rede</th>
                                                                </tr>
                                                            </thead>


                                                            <tbody>
                                                            {unidades.map((item, i) => (
                                                                <tr key={'unidade' + i} className="pointer">
                                                                    <td style={{ padding:'11px 0 0 11px'}}><input id={"unidade_"+i} type="checkbox" style={{width:'22px', height: '22px', padding:'0 0 0 0', margin:'0'}} value={item.unidade_id} checked={item.selected} onClick={(event) => _HandleUnidadeChange(event, item)}/></td>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.rede}</td>
                                                                </tr>
                                                            ))}
                                                            
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                <center>
                                                    <hr />
                                                    <button id="btn_sv" className="btn btn-primary btn-lg btn-rounded mb-2" type="submit">Salvar informações</button>
                                                             </center>
                                                <br />
                                            </div> 
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>


            
        </div>
    )
}
import React, { useState, useEffect, useRef,  forwardRef, useContext, useImperativeHandle, useCallback } from 'react';
import { Context } from '../../../../Context/AuthContext';
import { HubContext } from '../../../../Context/HubContext.js';
import { useNavigate } from 'react-router-dom';
import './camera.css';
import { Wait, WaitObject } from '../../../../scripts/Wait';

//const winpeer = new window.Peer();
let startLandMarksVar = false;
let viewLandMarksVar = false;
let timeout_face_reconition;
let videoElement = null;
let canvasElement = null;
let captureElement = null;
let canvasCtx = null;
let captureCtx = null;
let isVideo = true;



export const Camera = forwardRef((props, ref) => {
    const component_name = "camera";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [state, setState] = useState({connected: false})
    const timerIdleRef = useRef(new Date());
    const prestineRef = useRef(false);
    const peerRef = useRef();
    const callRef = useRef();
    const myPeerIdRef = useRef("");
    const remotePeerIdRef = useRef("");
    const connRef = useRef();
    const videoRef = useRef();
    const canvasRef = useRef();
    const webCamPromise = useRef();
    const facesModelRef = useRef();

    const navigate = useNavigate();

    const [viewLandMarks, setViewLandMarks] = useState(false);
    const [startLandMarks, setStartLandMarks] = useState(false);

   
    //#region Effects
    useEffect(()=> {
        if(!prestineRef.current) {
             prestineRef.current = true;
             setTimeout(async() => {
                facesModelRef.current = await window.tf.loadLayersModel('./models/easylens_faces_dist.json');
             }, 30)

             Init();
             peerRef.current = new window.Peer();
        }
  
        return () => {
             if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                 try {
                    callRef.current.close();
                    connRef.current.close();
                    peerRef.current.disconnect();
                    peerRef.current.destroy();
                    callRef.current = null;
                    peerRef.current = null;
                    connRef.current = null;
                    webCamPromise.current = null;
                    console.log("unmount");
                 } catch(e) {}
             }
        }
    }, []);
    //#endregion Effects
 
 
    //#region Ref
     useImperativeHandle(ref, (args) => ({
        async InitCamera(camId) { InitCamera(camId) },
        async Connect(camId) { Connect(camId) },
        async Snapshot(id) { Snapshot(id) },
        async SelectMe(id) { SelectMe(id) },
        async Clear() { Clear() },
        async EndCall() { EndCall() },
        async Recognize(checked) { return Recognize(checked) },
        async AddPhoto(image) { AddPhoto(image) }

    }));
    //#endregion Ref

 
    //#region Init
    const Init = () => {
        videoElement = videoRef.current;
        canvasElement = canvasRef.current;
        canvasCtx = canvasElement.getContext('2d');
    }


    const Connect = async(CameraId) => {
        console.log("Connect", CameraId, peerRef.current, connRef.current);

        if(peerRef.current === null) peerRef.current = new window.Peer();

        remotePeerIdRef.current = CameraId;

        peerRef.current.on('connection', function(conn) {
            conn.on('data', function(data) {
                console.log(data);
                Command(data);
            });
        });

        
        peerRef.current.on("open", function (id) {
            console.log(id);
            myPeerIdRef.current = id;
        });


        connRef.current = peerRef.current.connect(CameraId);

        connRef.current.on('open', function() {
            console.log("Conn Open", CameraId);
            connRef.current.send({cmd: 'connected', data:connRef.current.peer});
        });
    

    }


    const Command = (json) => {
        if(json.cmd === "connection_status" && json.data === 200) {
            connRef.current.send({cmd: 'start', data: ""});
        } else if(json.cmd === "oncamera") {
            InitCamera(remotePeerIdRef.current);
        } else if(json.cmd === "snapshot_data") {
            props.OnSnapData(json.data);
        } else if(json.cmd === "alert") {
            window.swal("Alerta", json.data.message, json.data.type);
            props.OnSnapData();
        } else if(json.cmd === "snapshot_uploaded") {
            props.OnSnapUpload(json.data);
        } else if(json.cmd === "disconnect") {
            setTimeout(() => {
                try {
                    console.log("Timeout close");
                    callRef.current.close();
                } catch(e) {
                    callRef.current = undefined;
                }
    
                setTimeout(()=> {
                    //navigate("/Redirect/Atendimento");
                    props.OnDisconnect();
                }, 500);
            }, 100);
    
            setState({connected: false});
            StopCamera();
        }
    }


    const InitCamera = async(camId) => {
        console.log("InitCamera");
        let CameraId = camId;
        

        webCamPromise.current = navigator.mediaDevices.getUserMedia({audio: false, video: true}).then(_stream => {
            window.stream = _stream;
            callRef.current = peerRef.current.call(CameraId, _stream);

            callRef.current.on("stream", (stream) => {
                //console.log(stream)
                document.getElementById("remote-video").srcObject = stream;
                document.getElementById("remote-video").play();
            });
    
            callRef.current.on("error", (err) => {
                console.log(err);
            });
    
            callRef.current.on('close', () => {
                console.log('close');
                //EndCall();
            });
          

            return new Promise((resolve, reject) => {
                document.getElementById("remote-video").onloadedmetadata = () => {
                    console.log("onloadedmetadata");
                    let _w = window.$("#remote-video").width();
                    let _h = window.$("#remote-video").height();
        
                    window.$("#output_canvas").width(_w);
                    window.$("#output_canvas").height(_h);
                    setState({connected: true});
                    Timer();
                    props.OnConected(remotePeerIdRef.current);
                    resolve();
                 };
               });
        });


        
    }


    const EndCall = () => {
        console.log("EndCall");
        try {
            startLandMarksVar = false;
            viewLandMarksVar = false;
            setStartLandMarks(false);
            setViewLandMarks(false);

            connRef.current.send({cmd: 'endcall', data: 200});
        } catch(e) { }

        setTimeout(() => {
          
            try {
                callRef.current.close();
            } catch(e) {
                callRef.current = undefined;
            }

            try {
                connRef.current.close();
                peerRef.current.disconnect();
                peerRef.current.destroy();   
            } catch(e) {
                callRef.current = undefined;
            }
            
            callRef.current = null;
            peerRef.current = null;
            connRef.current = null;

            peerRef.current = new window.Peer();

            /*setTimeout(()=> {
                navigate("/Redirect/Atendimento");
            }, 500);*/
        }, 1000);

        setState({connected: false});
        StopCamera();
        webCamPromise.current = null;
    }


    const Snapshot = (id) => {
        console.log(id);
        if(id !== undefined) {
            connRef.current.send({cmd: 'snapshot_upload', data: id});
        } else {
            connRef.current.send({cmd: 'snapshot', data: 200});
        }
    }


    const Clear = () => {
        connRef.current.send({cmd: 'clear_photos', data: 200});
    }


    const SelectMe = (id) => {
        console.log({cmd: 'gallery', data: id});
        connRef.current.send({cmd: 'gallery', data: id});
    }


    const Recognize = (checked) => {
        let _state = {...state};

        isVideo = true;

        faceMesh.setOptions({
            selfieMode: true,
            maxNumFaces: 1,
            refineLandmarks: true,
            minDetectionConfidence: 0.5,
            minTrackingConfidence: 0.5
        });

        videoElement = videoRef.current;
        canvasElement = canvasRef.current;
        canvasCtx = canvasElement.getContext('2d');
        //console.log(_state.connected, checked);

        if(_state.connected) {
            //console.log({cmd: 'face_recognize', data: (checked ? 1 : 0)});
            //connRef.current.send({cmd: 'face_recognize', data: (checked ? 1 : 0)});

            let _w = window.$("#remote-video").width();
            let _h = window.$("#remote-video").height();

            window.$("#output_canvas").width(_w);
            window.$("#output_canvas").height(_h);
            startLandMarksVar = checked;
            viewLandMarksVar = checked;
            setStartLandMarks(checked);
            setViewLandMarks(checked);
            return checked;
        } else {
            startLandMarksVar = false;
            viewLandMarksVar = false;
            setStartLandMarks(false);
            setViewLandMarks(false);
            return false;
        }
    }


    const AddPhoto = (image) => {
        canvasCtx.save();
        canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);

        faceMesh.setOptions({
            selfieMode: false,
            maxNumFaces: 1,
            refineLandmarks: true,
            minDetectionConfidence: 0.5,
            minTrackingConfidence: 0.5
        });

        isVideo = false;

        startLandMarksVar = true;
        viewLandMarksVar = true;
        setStartLandMarks(true);
        setViewLandMarks(true);
     

        var img = new Image();
        img.onload = async function() {
            let ratio =  canvasElement.width / img.width;

            canvasElement.height = img.height * ratio;
            canvasCtx.drawImage(img, 0, 0, canvasElement.width, canvasElement.height);  
            //console.log('AddPhoto', img);

            for(let i=0; i<10;i++) {
                await faceMesh.send({ image: img });
            }

            startLandMarksVar = false;
            viewLandMarksVar = false;
            setStartLandMarks(false);
            setViewLandMarks(false);
        }
        img.src = image;   
    }
    //#endregion Init


  

    //#region Handlers
    const StopCamera = () => {
        try {
            startLandMarksVar = false;
            viewLandMarksVar = false;
            setStartLandMarks(false);
            setViewLandMarks(false);

            let tracks = window.stream.getTracks();
            tracks.forEach(track => {
                track.stop();
            }); 
        } catch(e) { }

        try {
            let tracks = window.stream.getVideoTracks();
            tracks.forEach(track => {
                track.stop();
            }); 
        } catch(e) { }  
    }
    //#endregion Handlers


   

    //#region Facemesh
    const onResults = async (results) => { 
        //if(isVideo) {
            canvasCtx.save();
            canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
        //}
        if(!isVideo) canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height); 

        //console.log('onResults', viewLandMarksVar);

        let  op_srt = "FF"

        if (results.multiFaceLandmarks) {
            for (const l of results.multiFaceLandmarks) {
                if(viewLandMarksVar) {
                    window.drawConnectors(canvasCtx, l, window.FACEMESH_TESSELATION, { color: '#ffffff'+ op_srt, lineWidth: 0.2 });
                    /*window.drawConnectors(canvasCtx, l, window.FACEMESH_RIGHT_EYE, { color: '#FF3030' + op_srt, lineWidth: 0.2  });
                    window.drawConnectors(canvasCtx, l, window.FACEMESH_RIGHT_EYEBROW, { color: '#FF3030' + op_srt, lineWidth: 0.2  });
                    window.drawConnectors(canvasCtx, l, window.FACEMESH_RIGHT_IRIS, { color: '#0000FF' + op_srt, lineWidth: 0.2  });
                    window.drawConnectors(canvasCtx, l, window.FACEMESH_LEFT_EYE, { color: '#30FF30' + op_srt, lineWidth: 0.2  });
                    window.drawConnectors(canvasCtx, l, window.FACEMESH_LEFT_EYEBROW, { color: '#30FF30' + op_srt, lineWidth: 0.2  });
                    window.drawConnectors(canvasCtx, l, window.FACEMESH_LEFT_IRIS, { color: '#0000FF' + op_srt, lineWidth: 0.2  });
                    window.drawConnectors(canvasCtx, l, window.FACEMESH_FACE_OVAL, { color: '#E0E0E0'+ op_srt, lineWidth: 1  });
                    window.drawConnectors(canvasCtx, l, window.FACEMESH_LIPS, { color: '#E0E0E0' + op_srt, lineWidth: 0.2  });*/

                    let ang1 = angle({x: l[127].x, y: l[127].z}, {x: l[356].x, y: l[356].z});
                    let ang2 = angle({x: l[127].x, y: l[127].y}, {x: l[356].x, y: l[356].y});
                    let ang3 = angle({x: l[10].x, y: l[10].z}, {x: l[152].x, y: l[152].z});

      
                    //console.log(ang1, ang2, ang3, (ang1 > -6 && ang1 < 6 && ang2 <= 7 && ang3 <= 117 && ang3 >= 63) );    
                    if(ang1 > -6 && ang1 < 6 && ang2 <= 7 && ang3 <= 117 && ang3 >= 63) {
                        let input = [];
                        let r = dist2d(l[10], l[152]);

                        let p = [176, 400, 150, 379, 172, 397, 132, 361, 234, 454, 162, 389, 54, 284, 13, 14];
                        for(let i=0; i<p.length-1; i++) {
                            input.push(dist2d(l[p[i+1]], l[p[i]]) / r);
                        }
                        
                        let result = facesModelRef.current.predict(window.tf.tensor2d(input, [1,15])).dataSync();

                        //console.log(result);

                        let index = result.reduce((accumulator, current, index) => {
                            return current > result[accumulator] ? index : accumulator;
                        }, 0);

                       // console.log(index);

                        props.OnFaceShape(index, isVideo);
                    } else {
                        props.OnFaceShape(-1, isVideo);
                    } 
                }

        
            }
        }
    }


    const faceMesh = new window.FaceMesh({
        locateFile: (file) => {
            console.log(`https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`);
            return `js/${file}`;
        }
    });


    faceMesh.setOptions({
        selfieMode: true,
        maxNumFaces: 1,
        refineLandmarks: true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5
    });


    faceMesh.onResults(onResults);


    const Timer = async() => {
        //console.log('Timer', startLandMarksVar);
        try {
        
        } catch(e) { }

        if(startLandMarksVar){
            //console.log(window.$("#camera").width(), window.$("#camera").height());
            await faceMesh.send({ image: videoElement ? videoElement : videoRef.current });
            if(window.$("#remote-video").width()) timeout_face_reconition = setTimeout(() => Timer(), 30);
        } else {
            timeout_face_reconition = setTimeout(() => {
                try {
                    canvasCtx.save();
                    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
                } catch(e) { }
                Timer();
            }, 100);
        }
    }
    //#endregion Facemesh


    //#region Functions  ---------------------------------------------------------------------------------------------------------------------------------
        const drawt = (x, y, ctx, color, txt) => {
            ctx.font = '10px serif';
            ctx.fillStyle = color;
            ctx.fillText(txt, x, y);
        }

        const drawp = (x, y, ctx, color, line) => {
            ctx.beginPath();
            ctx.arc(x, y, line, 0, 2 * Math.PI, false);
            ctx.fillStyle = color;
            ctx.fill();
        }

        const drawPath = (points, context) => {
            context.beginPath();
            context.lineWidth = 2;
            points.map((item, i) => {
                if (i == 0) {
                    context.moveTo(item.x, item.y);
                } else {
                    context.lineTo(item.x, item.y);
                }
            });
            context.strokeStyle = 'red';
            context.stroke();
        }

        const sleep = async (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        const p = (arr, index) => {
            return arr.find(v => v == index) > -1
        }

        const dist2d = (p1, p2) => {
            return Math.sqrt(Math.pow((p1.x - p2.x), 2) + Math.pow((p1.y - p2.y), 2));
        }


        const dist3d = (p1, p2) => {
            return Math.sqrt(Math.pow((p1.x - p2.x), 2) + Math.pow((p1.y - p2.y), 2) + Math.pow((p1.z - p2.z), 2));
        }


        const angle = (p1, p2) => {
            //console.log(p1, p2);
            return Math.abs(Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180 / Math.PI)
        }

        const distMatrix = (arr) => {
            let sum = 0;
            for (let i = 0; i < arr.length; i++) {
                sum += Math.pow(1 + arr[i], 2) * (i > 0 ? arr[i-1]: 1);
            }
            return sum;
        }


        const Output = (len, i) => {
            let arr = [];
            for (let j = 0; j < len; j++) {
                if (j == i) {
                    arr.push(1);
                } else {
                    arr.push(0);
                }
            }
            return arr.toString();
        }


        const Area = (vertices, ref = 1) => {
            var total = 0;

            for (var i = 0, l = vertices.length; i < l; i++) {
                var addX = vertices[i].x / ref;
                var addY = vertices[i == vertices.length - 1 ? 0 : i + 1].y / ref;
                var subX = vertices[i == vertices.length - 1 ? 0 : i + 1].x / ref;
                var subY = vertices[i].y / ref;

                total += (addX * addY * 0.5);
                total -= (subX * subY * 0.5);
            }

            return Math.abs(total);
        }


        const getScaledDim = (img, maxWidth, maxHeight) => {
            let scaled = {
                ratio: img.width / img.height,
                width: img.width,
                height: img.height
            }
            if (scaled.width > maxWidth) {
                scaled.width = maxWidth;
                scaled.height = scaled.width / scaled.ratio;
            }
            if (scaled.height > maxHeight) {
                scaled.height = maxHeight;
                scaled.width = scaled.height / scaled.ratio;
            }
            return scaled;
        }
        //#endregion Functions  ---------------------------------------------------------------------------------------------------------------------------------
    




    return (
        <div className="EMCamera">
            <video id="remote-video" className={!state.connected?'waiting':'active'} ref={videoRef} autoPlay></video>
            <canvas id="output_canvas" className="output_canvas" ref={canvasRef} />
        </div>
      )
  });
import { useState, useEffect, useRef, useContext } from 'react';
import { Request } from '../../scripts/Request';
import { GetServer, AppServer } from '../../scripts/Loadbalancer';
import { HubContext } from '../../Context/HubContext';
import { WaitObject } from '../../scripts/Wait';
import { resolvePath, useNavigate } from 'react-router-dom';
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";


let connectionHub;
let connectionHubConnected = null;
let connectionHubTimeout;

export default function useAuth() {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(false);
    const { Hub } = useContext(HubContext);
    const [user, setUser] = useState({ user_id: "", nome: "", token: "", acesso: "", nivel: "", code: "", validade: "" });
    const authRef = useRef(false);
    const tokenRef = useRef("");
    const navigate = useNavigate();



    useEffect(() => {
        //GetServer();
        //console.log("useEffect");
        connectionHubConnected = null;
        const _user = localStorage.getItem('user');

        if (_user) {
            let _u = JSON.parse(_user);
            setUser(_u);
            setAuthenticated(true);
            authRef.current = true;
            tokenRef.current = _u.token;
            try {
                connectionHub.stop();
                connectionHub.off("Refused");
                connectionHub.off("Accepted");
                connectionHub.off("OnList");
                connectionHub.off("Ping");
                connectionHub.off("OnHeartbeat");
                connectionHub.off("Disconnect");
            } catch (e) { }
            setTimeout(async() => {await Promise.resolve(TryConnect(_u.guid));}, 100);
            
        }
        setLoading(false);

        return () => {
            clearTimeout(connectionHubTimeout);
        }
    }, []);


    async function UserValidate() {
        //console.log("UserValidate");
        const _user = localStorage.getItem('user');

        if (_user) {
            let _u = JSON.parse(_user);
            setUser(_u);
            setAuthenticated(true);
            authRef.current = true;;
            tokenRef.current = _u.token;
        }
        setLoading(false);

        return JSON.parse(_user);
    }


    async function Refresh () {
        //console.log("Refresh");
        const user = await Promise.resolve(new Request().Run("api/Auth/Refresh", "Post", {token: tokenRef.current}, tokenRef.current, 0))
            .then((data) => {
                return data;
            });
        

        if(user.user_id) {
            localStorage.setItem('user', JSON.stringify(user));
            setAuthenticated(true);
            authRef.current = true;
            setUser(user);
            tokenRef.current = user.token;
        }
        return true;
    }


    async function handleLogin(form) {
        //console.log("handleLogin");
        setLoading(true);
        const user = await Promise.resolve(new Request().Run("api/Auth/Login", "Post", form, "", 0))
            .then((data) => {
                return data;
            });

        setLoading(false);


        if(user.ativo === 9) {
            return { tfa: user.token }
        } else if (user.user_id === 0 || user.token === "") {
            window.swal("Acesso não autorizado", "Senha Incorreta", "warning");
        } else if(user.user_id) {

            let result = localStorage.getItem('user_guid');
            //if(user?.guid !== result) {
                Hub.Delete("current_atend", true);
            //}

            if(user?.guid) localStorage.setItem('user_guid', user?.guid);


            await Promise.resolve(TryConnect(user?.guid));

            WaitObject(() => { return (connectionHub!==undefined && connectionHubConnected!==null)}).then(() => {
                //console.log("connectionHubConnected", connectionHubConnected, (connectionHub!==undefined && connectionHubConnected!==null));
                if(connectionHubConnected) {
                    localStorage.setItem('user', JSON.stringify(user));
                    setAuthenticated(true);
                    authRef.current = true;
                    setUser(user);
                    tokenRef.current = user.token;
                    if (user.nivel === 7) {
                        try {
                            //localStorage.removeItem('userpwd');
                        } catch(e) { }
                        navigate('/Espelho');
                    } else {
                        navigate('/Home');
                    }
                } else {
                    window.swal("Acesso não autorizado", "Esse usuário já se encontra logado", "warning");
                }
            });

        }
    }


    async function handleLogout(reload) {
        //console.log("handleLogout");
        try {
            connectionHub.invoke("Disconnect", "").catch(function (err) {
                return console.error(err.toString());
            });

            connectionHub.stop();
            connectionHub.off("Refused");
            connectionHub.off("Accepted");
            connectionHub.off("OnList");
            connectionHub.off("Ping");
            connectionHub.off("OnHeartbeat");
            connectionHub.off("Disconnect");
        } catch(e) { }


        clearTimeout(connectionHubTimeout);

        setAuthenticated(false);
        authRef.current = false;
        setUser({ user_id: "", nome: "", token: "", acesso: "", nivel: "", code: "", validade: "" });
        localStorage.removeItem('user');
        if(!reload) navigate('/');
    }

 
    async function Update(data) {
        //console.log("Update", data);
        if(authRef.current) {
            if(data.user_id) {
                localStorage.setItem('user', JSON.stringify(data));
                setAuthenticated(true);
                authRef.current = true;
                setUser(data);
                tokenRef.current = data.token;
            }
        } else {
            navigate('/');
        }
    }


    //#region Connection SignalR
    const TryConnect = async(uuid) => {
        let host = AppServer("easylens_sessions");
        //console.log(host);

        if(!connectionHub) {
            connectionHub = new HubConnectionBuilder().withUrl(host).withAutomaticReconnect().build();
        }

        try {
            await connectionHub.stop();
            connectionHub.off("Refused");
            connectionHub.off("Accepted");
            connectionHub.off("OnList");
            connectionHub.off("Ping");
            connectionHub.off("OnHeartbeat");
            connectionHub.off("Disconnect");
        } catch(e) { }

        return connectionHub.start().then(() => {
            connectionHub.on("Refused", (message, stack) => {
                connectionHubConnected = false;
                console.log("Refused", message, stack, connectionHubConnected);
            });

            connectionHub.on("Accepted", (message, stack) => {
                connectionHubConnected = true;
                console.log("Accepted", message, stack, connectionHubConnected);
            });

            connectionHub.on("OnList", (message, stack) => {
                console.log("OnList", message, stack, connectionHubConnected);
            });

            connectionHub.on("Ping", (message, stack) => {
                console.log("Ping", message, stack, connectionHubConnected);
            });

            connectionHub.on("OnHeartbeat", (message, stack) => {
                console.log("OnHeartbeat", message, stack, connectionHubConnected);
            });

            connectionHub.on("Disconnect", (message, stack) => {
                console.log("Disconnect", message, stack, connectionHubConnected);
            });

            
            //console.log("Connect", uuid);
            connectionHub.invoke("Connect", uuid).catch(function (err) {
                return console.error(err.toString());
            });

            
            KeepAlive();
            return;
        }).catch((error) => console.log(error));
    }


    const KeepAlive = () => {
        clearTimeout(connectionHubTimeout);
        connectionHubTimeout = setTimeout(() => {
            connectionHub.invoke("Ping").catch(function (err) {
                return console.error(err.toString());
            });

            KeepAlive();
        }, 5000);
    }
    //#nedregion Connection SignalR

    return { authenticated, loading, handleLogin, handleLogout, user, UserValidate, Refresh, Update };
}
import React, { useEffect,  useState, useRef, Suspense, lazy, useContext } from 'react';
import { Routes, Route,  useNavigate, useLocation, useParams } from 'react-router-dom';
import { GetServer } from './scripts/Loadbalancer';
import { Context } from './Context/AuthContext';
import Login  from './components/Login/Login';
import Layout from './Layout.js';
import Home from './components/Admin/Home';
import Unidades from './components/Admin/Unidades';
import { Cadastro as UnidadeCadastro } from './components/Admin/Unidades/Cadastro';
import Usuarios from './components/Admin/Usuarios';
import { Cadastro as UsuarioCadastro } from './components/Admin/Usuarios/Cadastro';
import Fornecedores from './components/Admin/Fornecedores';
import { Cadastro as FornecedorCadastro } from './components/Admin/Fornecedores/Cadastro';
import TiposTratamentos from './components/Admin/TiposTratamentos';
import { Cadastro as TiposTratamentoCadastro } from './components/Admin/TiposTratamentos/Cadastro';
import Tratamentos from './components/Admin/Tratamentos';
import { Cadastro as TratamentoCadastro } from './components/Admin/Tratamentos/Cadastro';
import Lentes from './components/Admin/Lentes';
import { Cadastro as LenteCadastro } from './components/Admin/Lentes/Cadastro';
import Armacoes from './components/Admin/Armacoes';
import { Cadastro as ArmacaoCadastro } from './components/Admin/Armacoes/Cadastro';
import Acessorios from './components/Admin/Acessorios';
import { Cadastro as AcessorioCadastro } from './components/Admin/Acessorios/Cadastro';
import { Perfil } from './components/Admin/Perfil';
import { Parametros } from './components/Admin/Parametros';
import Espelho from './components/Admin/Espelho';
import Atendimento from './components/Admin/Atendimento';
import { Redirect } from './components/Redirect';
import Tolerancias from './components/Admin/Tolerancias';
import { Cadastro as ToleranciaCadastro } from './components/Admin/Tolerancias/Cadastro';
import Clientes from './components/Admin/Clientes';
import { Cadastro as ClienteCadastro } from './components/Admin/Clientes/Cadastro';
import Ordens from './components/Admin/Ordens';
import { OsView } from './components/Admin/Ordens/View';
import Orcamentos from './components/Admin/Orcamentos';
import { OrcamentoView } from './components/Admin/Orcamentos/View';
import MapaCarga from './components/Admin/MapaCarga';
import Medicos from './components/Admin/Medicos';
import { Cadastro as MedicoCadastro } from './components/Admin/Medicos/Cadastro';
import Coloracoes from './components/Admin/Coloracoes';
import { Cadastro as ColoracaoCadastro } from './components/Admin/Coloracoes/Cadastro';
import Carnes from './components/Admin/Carnes';

export default function RouteManager() {
    const location = useLocation();
    const isLogin = (location.pathname.toLocaleLowerCase().indexOf("login") > -1 || location.pathname === "/");
    const navigate = useNavigate();
    const loadPrestineRef = useRef(false);


    useEffect(() => {
      let wait = setTimeout(() => {
        if(!loadPrestineRef.current) {
            loadPrestineRef.current = true;
            GetServer();
        }
      }, 200);

    }, [])

    const ProtectedRoute = ({ children }) => {
        const { loading, authenticated } = useContext(Context);
        if (!authenticated && !loading && !isLogin) {
           navigate("/login");
           //return children;
        } else {
            return children;
        }

      };


    return (
    
      <Suspense fallback={<div>Carregando...</div>}>
        <Layout>
            <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/Home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
                <Route exact path="/Unidades" element={<ProtectedRoute><Unidades /> </ProtectedRoute>} />
                <Route exact path="/Unidade/:Id" element={<ProtectedRoute><UnidadeCadastro /> </ProtectedRoute>} />
                <Route exact path="/Unidade" element={<ProtectedRoute><UnidadeCadastro /> </ProtectedRoute>} />
                <Route exact path="/Usuarios" element={<ProtectedRoute><Usuarios /> </ProtectedRoute>} />
                <Route exact path="/Usuario/:Id" element={<ProtectedRoute><UsuarioCadastro  /> </ProtectedRoute>} />
                <Route exact path="/Usuario" element={<ProtectedRoute><UsuarioCadastro  /> </ProtectedRoute>} />
                <Route exact path="/Fornecedores" element={<ProtectedRoute><Fornecedores /> </ProtectedRoute>} />
                <Route exact path="/Fornecedor/:Id" element={<ProtectedRoute><FornecedorCadastro  /> </ProtectedRoute>} />
                <Route exact path="/Fornecedor" element={<ProtectedRoute><FornecedorCadastro  /> </ProtectedRoute>} />
                <Route exact path="/TiposTratamentos" element={<ProtectedRoute><TiposTratamentos /> </ProtectedRoute>} />
                <Route exact path="/TiposTratamento/:Id" element={<ProtectedRoute><TiposTratamentoCadastro  /> </ProtectedRoute>} />
                <Route exact path="/TiposTratamento" element={<ProtectedRoute><TiposTratamentoCadastro  /> </ProtectedRoute>} />
                <Route exact path="/Tratamentos" element={<ProtectedRoute><Tratamentos /> </ProtectedRoute>} />
                <Route exact path="/Tratamento/:Id" element={<ProtectedRoute><TratamentoCadastro  /> </ProtectedRoute>} />
                <Route exact path="/Tratamento" element={<ProtectedRoute><TratamentoCadastro  /> </ProtectedRoute>} />
                <Route exact path="/Lentes" element={<ProtectedRoute><Lentes /> </ProtectedRoute>} />
                <Route exact path="/Lente/:Id" element={<ProtectedRoute><LenteCadastro  /> </ProtectedRoute>} />
                <Route exact path="/Lente" element={<ProtectedRoute><LenteCadastro  /> </ProtectedRoute>} />
                <Route exact path="/Armacoes" element={<ProtectedRoute><Armacoes /> </ProtectedRoute>} />
                <Route exact path="/Armacao" element={<ProtectedRoute><ArmacaoCadastro /> </ProtectedRoute>} />
                <Route exact path="/Armacao/:Id" element={<ProtectedRoute><ArmacaoCadastro  /> </ProtectedRoute>} />
                <Route exact path="/Acessorios" element={<ProtectedRoute><Acessorios /> </ProtectedRoute>} />
                <Route exact path="/Acessorio" element={<ProtectedRoute><AcessorioCadastro /> </ProtectedRoute>} />
                <Route exact path="/Acessorio/:Id" element={<ProtectedRoute><AcessorioCadastro  /> </ProtectedRoute>} />
                <Route exact path="/Perfil" element={<ProtectedRoute><Perfil /> </ProtectedRoute>} />
                <Route exact path="/Parametros" element={<ProtectedRoute><Parametros /> </ProtectedRoute>} />
                <Route exact path="/Espelho" element={<ProtectedRoute><Espelho /></ProtectedRoute>} />
                <Route exact path="/Atendimento" element={<ProtectedRoute><Atendimento /></ProtectedRoute>} />
                <Route exact path="/Atendimento/:TabId" element={<ProtectedRoute><Atendimento /></ProtectedRoute>} />
                <Route exact path="/Tolerancias" element={<ProtectedRoute><Tolerancias /> </ProtectedRoute>} />
                <Route exact path="/Tolerancia/:Id" element={<ProtectedRoute><ToleranciaCadastro /> </ProtectedRoute>} />
                <Route exact path="/Tolerancia" element={<ProtectedRoute><ToleranciaCadastro /> </ProtectedRoute>} />
                <Route exact path="/Clientes" element={<ProtectedRoute><Clientes /> </ProtectedRoute>} />
                <Route exact path="/Cliente/:Id" element={<ProtectedRoute><ClienteCadastro  /> </ProtectedRoute>} />
                <Route exact path="/Cliente" element={<ProtectedRoute><ClienteCadastro  /> </ProtectedRoute>} />
                <Route exact path="/Ordens" element={<ProtectedRoute><Ordens/> </ProtectedRoute>} />
                <Route exact path="/Ordem/:Id" element={<ProtectedRoute><OsView /> </ProtectedRoute>} />
                <Route exact path="/Orcamentos" element={<ProtectedRoute><Orcamentos/> </ProtectedRoute>} />
                <Route exact path="/Orcamento/:Id" element={<ProtectedRoute><OrcamentoView /> </ProtectedRoute>} />
                <Route exact path="/MapaCarga" element={<ProtectedRoute><MapaCarga /> </ProtectedRoute>} />
                <Route exact path="/Medicos" element={<ProtectedRoute><Medicos /> </ProtectedRoute>} />
                <Route exact path="/Medico/:Id" element={<ProtectedRoute><MedicoCadastro  /> </ProtectedRoute>} />
                <Route exact path="/Medico" element={<ProtectedRoute><MedicoCadastro  /> </ProtectedRoute>} />
                <Route exact path="/Coloracoes" element={<ProtectedRoute><Coloracoes /> </ProtectedRoute>} />
                <Route exact path="/Coloracao/:Id" element={<ProtectedRoute><ColoracaoCadastro  /> </ProtectedRoute>} />
                <Route exact path="/Coloracao" element={<ProtectedRoute><ColoracaoCadastro  /> </ProtectedRoute>} />
                <Route exact path="/Carnes" element={<ProtectedRoute><Carnes  /> </ProtectedRoute>} />

                <Route exact path="/Redirect/:Url" element={<ProtectedRoute><Redirect /></ProtectedRoute>} />
            </Routes>
        </Layout>
      </Suspense>
   
  );
}
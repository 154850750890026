/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Context } from '../../../../Context/AuthContext';
import { HubContext } from '../../../../Context/HubContext.js';
import { Request, Req } from '../../../../scripts/Request';
import { WaitObject } from '../../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import { Currency, UnCurrency, Number } from '../../../../scripts/StringUtils';
import { useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import moment from 'moment/moment';
import './index.css';
import { Camera } from './camera';
import { Thumb } from './Thumb';

import { MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';



let current_element;
let current_espelho_id;
let fotos_names = [];
let mirror_connected = false;

let faceShapes = [
    { id: 3, name: "Triangular", count: 0},
    { id: 5, name: "Retangular", count: 0},
    { id: 2, name: "Oval", count: 0},
    { id: 1, name: "Redondo", count: 0},
    { id: 4, name: "Quadrado", count: 0}
 ];

 let face_photo_count = 0;

 

export const Espelho = forwardRef((props, ref) => {
    const component_name = "admin/espelho";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [connected, setConnected] = useState(false);
    const [list, setList] = useState([]);
    const [photos, setPhotos] = useState([]);
    const [face, setFace] = useState({formato:"", nasal:""});
    const [recognize, setRecognize] = useState(false);
    const espelhoIdRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const cameraRef = useRef();
    const hiddenFileInput = useRef(null);
    const [image, setImage] = useState("");

    const navigate = useNavigate();

    const [formatos, setFormatos] = useState([
        {id: "", name: "Selecione um formato do rosto"},
        {id: 1, name: "Redondo"},
        {id: 2, name: "Oval"},
        {id: 3, name: "Triangular"},
        {id: 4, name: "Quadrado"},
        {id: 5, name: "Retangular"},
        {id: 6, name: "Diamante"}
    ]);


    const [nasal, setNasal] = useState([
        {id: "", name: "Selecione um formato do nasal"},
        {id: 1, name: "Superior"},
        {id: 2, name: "Intermediário"},
        {id: 3, name: "Médio"},
        {id: 4, name: "Inferior"}
    ]);


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    StopCamera();
                    Init();
                    RegisterHub();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(current_element? current_element :".main-content")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects


    //#region Ref
    useImperativeHandle(ref, (url, args, token) => ({
        async Init() { return Init() },
        async Clear() { return ClearComponent() }
    }));
    //#endregion Ref


    //#region Init
    const Init = async() => {
        mirror_connected = false;
        LoadEspelhos();
        let current_atend = await Hub.Get("current_atend", true);
        if(!current_atend) return;
        Hub.Update("current_atend", "date", moment().format("DD/MM/YYYY"), true);
        //if(current_atend) setFace({formato:current_atend?.formato, nasal:current_atend?.nasal});
    }


    const ClearComponent = async() => {
        setFace({formato:"", nasal:""});
    }
    //#endregion Init


    //#region Hub
    const RegisterHub = () => {
        Hub.Set("CAMERA_CLOSE", () => { cameraRef.current.EndCall(); });
    }
    //#endregion Hub


    //#region Loaders
    const LoadEspelhos = () => {
        current_element = ".TabletList";
        setPageLoading(true);
        Promise.resolve(new Request().Run("api/Espelhos/List/0/0/0", "POST", "", user.token, 0*24*60*60*1000))
        .then(async(data) => {
            current_element = "";
            setPageLoading(false);
            
            if(data.toString() !== "error") {
                //console.log(data.list);
                data.list.map((item, i) => {
                    item = Object.assign(item, {message: "Aguardando conexão"});
                });

                setList(data.list);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATESP001)", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATESP002)", "error");
        });
        
    }


    const Connect = (id) => {
        let _list = [...list];
        _list.find(c => c.espelho_id === id).message = "Conectando...";
        Promise.resolve(new Request().Run("api/Espelhos/GetUuid", "POST", {espelho_id: id}, user.token, 0))
        .then(async(data) => {
            if(data.toString() === "busy") {
                mirror_connected = false;
                setConnected(false);
                _list.find(c => c.espelho_id === id).message = "Não disponível";
                _list.find(c => c.espelho_id === id).status = 1;
                setList(_list);
            } else if(data.toString() !== "error" && data.toString() !== "") {
                current_espelho_id = id;
                cameraRef.current.Connect(data);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATESP003)", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATESP004)", "error");
        });
    }


    const Disconnect = (id) => {
        Promise.resolve(new Request().Run("api/Espelhos/Status", "POST", {espelho_id: id, status: 0}, user.token, 0))
        .then(async(data) => {
           if(data.toString() !== "error" && data.toString() !== "") {
               
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }
    //#endregion Loaders


    //#region Handlers
    const _HandleChange = (event) => {
        setFace(prev => prev = {...prev, [event.target.id]:event.target.value});

        Hub.Update("current_atend", event.target.id, event.target.value, true);
        //Hub.Debug("current_atend");
    }


    const _HandleEspelhoClick = (item) => {
        //cameraRef.current.Connect(item.uuid);
        if(mirror_connected) {
            window.swal("Alerta", "Já existe um espelho conectado", "error");
            return;
        }

        let _list = [...list];
        if(_list.find(c => c.espelho_id === item.espelho_id).status !== 0) {
            window.swal("Alerta", "Espelho não disponível", "error");
            return;
        }

        current_element = ".TabletList";
        setPageLoading(true);
        espelhoIdRef.current = item.espelho_id;
        Connect(item.espelho_id);
        setTimeout(() => {
            if(!mirror_connected) {
                current_element = "";
                setPageLoading(false);
                
                try {
                    _list.find(c => c.espelho_id === current_espelho_id).message = "Não disponivel";
                    _list.find(c => c.espelho_id === current_espelho_id).status = 1;
                    setList(_list);
                } catch(e) { }
            }
        }, 30000);
    }


    const _HandleSnapshot = () => {
        current_element = ".Actions";
        setPageLoading(true);
        cameraRef.current.Snapshot();
    }


    const _HandleClose = () => {
        cameraRef.current.EndCall();
        Disconnect(espelhoIdRef.current);
        setPhotos([]);
        mirror_connected = false;
        setConnected(false);
        LoadEspelhos();
    }


    const _HandleClear = () => {
        setPhotos([]);
        cameraRef.current.Clear();
        current_element = "";
        setPageLoading(false);
    }


    const HandleSnapData = (data) => {
        if(data) {
            setPhotos(prev => prev = [...prev, {data: data, selected: false}]);
        }
        current_element = "";
        setPageLoading(false);
    }


    const HandleConected = (id) => {
        mirror_connected = true;
        setConnected(true);
        current_element = "";
        setPageLoading(false);

        let _list = [...list];
        try {
            _list.find(c => c.espelho_id === current_espelho_id).message = "Conectado";
            _list.find(c => c.espelho_id === current_espelho_id).status = 0;
            setList(_list);
        } catch(e) { }
    }


    const HandlerUpload = (id) => {
        current_element = ".ActionsDiv";
        setPageLoading(true);
        cameraRef.current.Snapshot(id);
    }


    const HandleThumbClick = (id) => {
        cameraRef.current.SelectMe(id);
    }


    const HandleSnapUpload = (data) => {
        current_element = "";
        setPageLoading(false);

        fotos_names.push(data);

        Hub.Update("current_atend", "fotos", fotos_names);
        Hub.Debug("current_atend");
    }



    const StopCamera = () => {
        try {
            let tracks = window.stream.getTracks();
            tracks.forEach(track => {
                track.stop();
            }); 
        } catch(e) { }

        try {
            let tracks = window.stream.getVideoTracks();
            tracks.forEach(track => {
                track.stop();
            }); 
        } catch(e) { }  
    }


    const _HandleNextStep = () => {
        props.OnNext()
    }


    const _HandleNasofacial = async(e) => {
        faceShapes = [
            { id: 3, name: "Triangular", count: 0},
            { id: 5, name: "Retangular", count: 0},
            { id: 2, name: "Oval", count: 0},
            { id: 1, name: "Redondo", count: 0},
            { id: 4, name: "Quadrado", count: 0}
         ];

        let _r = await cameraRef.current.Recognize(e.target.checked);
        setRecognize(_r);
    }


    const _HandleFaceShape = (index, isVideo) => {
        let _face = {...face};
        face_photo_count++;


        if(index === -1) {
            if(!isVideo && face_photo_count >= 10) {
                window.alertify.message("Formato de rosto não encontrado, posicione o roso frontalmente!");
                _HandleNasofacial({target: {checked: false}});
                _face.formato = "";
                setFace(_face);
                setTimeout(() => { face_photo_count = 0 }, 3000);
            }
        } else {
           

            faceShapes[index].count++;
            

            console.log(isVideo, face_photo_count) ;

            let xMax = Math.max(...Array.from(faceShapes, o => o.count));
            let maxFS  = faceShapes.find(o => o.count === xMax);
            _face.formato = maxFS.id;

            if(isVideo && faceShapes[index].count >= 15) {
                faceShapes = [
                    { id: 3, name: "Triangular", count: 0},
                    { id: 5, name: "Retangular", count: 0},
                    { id: 2, name: "Oval", count: 0},
                    { id: 1, name: "Redondo", count: 0},
                    { id: 4, name: "Quadrado", count: 0}
                ];

                window.alertify.message("Face reconhecida: " + faceShapes[index].name);
                _HandleNasofacial({target: {checked: false}});
            } else if(!isVideo && face_photo_count >= 10) {
                window.alertify.message("Face reconhecida: " + faceShapes[_face.formato].name);
                _HandleNasofacial({target: {checked: false}});
                setTimeout(() => { face_photo_count = 0 }, 3000);
            }

        
            setFace(_face);

        }
    }
    //#endregion Handlers




    //#region Handlers Foto
    const _HandleEnviarFoto = () => {
        hiddenFileInput.current.click();
    }


    const _handleFileInputChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
            cameraRef.current.AddPhoto(reader.result);
        };
        reader.readAsDataURL(files[0]);
    }
    //#endregion Handle Foto


    return (
        <div className="row ETCamera">
            <div className="col-sm-4">
                <div className="TabletList">
                    <div>
                        <br />
                        <h5 className="font-size-17 mb-3 pt-2 left">Escolha um tablet disponível</h5>
                        <span className="bg-danger badge right" onClick={LoadEspelhos}>Atualizar lista</span>
                        <div className="clear"></div>
                        {
                            Array.isArray(list) && list.length > 0 && list.map((item, i) => (
                                <div key={'espelho_'+i} id={'espelho_'+item.espelho_id} className="card border shadow-none mb-2 btn_actions " onClick={() => _HandleEspelhoClick(item)}>
                                    <a className="text-body">
                                        <div className="p-2">
                                            <div className="d-flex align-items-center">
                                                <div className="avatar align-self-center me-3">
                                                    <div className={'avatar-title rounded ' + (item.status===0?'bg-soft-success text-success':'bg-soft-danger text-danger ') +'  font-size-24'}>
                                                        <i className="mdi mdi-image"></i>
                                                    </div>
                                                </div>
        
                                                <div className="overflow-hidden me-auto">
                                                    <h5 className="font-size-15 text-truncate mb-1">{item.name}</h5>
                                                    <p className="text-muted text-truncate mb-0">{item?.message}</p>
                                                </div>
        
                                                <div className="ms-2">
                                                    <p className="text-muted"></p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))
                        }

                       
                    </div>
                </div>
                
                <div className="mt-4 pt-3">
                    <div className="alert alert-success alert-dismissible fade show px-3 mb-0" role="alert">
                        <button type="button" className="btn-close hide" data-bs-dismiss="alert" aria-label="Close"></button>
                        <div className="mb-3">
                            {/*<i className="mdi mdi-account-supervisor-circle-outline h1 text-success"></i>*/}
                            <div className="check-label">
                                <div>
                                    <input type="checkbox" checked={recognize} onClick={_HandleNasofacial}/> 
                                </div>
                                
                                <div>
                                    <h5 className="_text-success">Iniciar reconhecimento nasofacial</h5>
                                </div>
                            </div>
                        </div>

                        <div>
                            {/*<h5 className="text-success">Formato do rosto e nasal</h5>*/}
                            <p>Você pode configurar manualmente características de um rosto</p>
                          

                            <div className="card border shadow-none mb-2 btn_actions">
                                <a className="text-body">
                                    <div className="p-2">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar align-self-center me-3">
                                                <div className="avatar-title rounded bg-soft-success text-success font-size-24">
                                                    <i className="uil uil-image-resize-square"></i>
                                                </div>
                                            </div>

                                            <div className="overflow-hidden me-auto">
                                                <select id="formato" className="form-control" value={face.formato} onChange={_HandleChange}>
                                                    {
                                                        formatos.map((item, i) => (<option key={'formato_'+i} value={item.id}>{item.name}</option>))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="card border shadow-none mb-2 btn_actions">
                                <a className="text-body">
                                    <div className="p-2">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar align-self-center me-3">
                                                <div className="avatar-title rounded bg-soft-success text-success font-size-24">
                                                    <i className="uil uil-location-arrow-alt"></i>
                                                </div>
                                            </div>

                                            <div className="overflow-hidden me-auto">
                                                <select id="nasal" className="form-control" value={face.nasal} onChange={_HandleChange}>
                                                    {
                                                        nasal.map((item, i) => (<option key={'fnasal_'+i}  value={item.id}>{item.name}</option>))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>                       
                        </div>
                    </div>
                    
                </div>


                <br />       
                <div className={'card border shadow-none mb-2 btn_actions ' + (face.formato===''||face.formato===undefined||face.formato===null||face.nasal===''||face.nasal===undefined||face.nasal===null?'_hide':'')} onClick={_HandleNextStep}>
                    <a className="text-body">
                        <div className="p-2">
                            <div className="d-flex align-items-center">
                                <div className="avatar align-self-center me-3">
                                    <div className="avatar-title rounded bg-soft-primary text-primary font-size-24">
                                        <i className="mdi mdi-account-plus"></i>
                                    </div>
                                </div>

                                <div className="overflow-hidden me-auto">
                                    <h5 className="font-size-15 text-truncate mb-1">Avançar para o cadastro de cliente</h5>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>  
            </div>

            <div className="col-sm-4 cameraDiv">
                <br />
                <Camera ref={cameraRef} OnSnapData={HandleSnapData} OnSnapUpload={HandleSnapUpload} OnConected={HandleConected} OnDisconnect={_HandleClose} OnFaceShape={_HandleFaceShape}/>
            </div>


            <div className={'col-sm-4 ActionsDiv ' + (!connected?'':'hide')}>
                <br />
                <div className="Actions">

                    <div className="card border shadow-none mb-2 btn_actions" onClick={_HandleEnviarFoto}>
                        <a className="text-body">
                            <div className="p-2">
                                <div className="d-flex align-items-center">
                                    <div className="avatar align-self-center me-3">
                                        <div className="avatar-title rounded bg-soft-primary  text-primary  font-size-24">
                                            <i className="mdi mdi-cloud-upload"></i>
                                        </div>
                                    </div>

                                    <div className="overflow-hidden me-auto">
                                        <input type="file" ref={hiddenFileInput} onChange={_handleFileInputChange} style={{display: 'none'}} accept=".jpg,.png"/>
                                        <h5 className="font-size-15 text-truncate mb-1">Carregar uma Foto</h5>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>


            <div className={'col-sm-4 ActionsDiv ' + (connected?'':'hide')}>
                <br />
         
                <div className="Actions">
                    <div className="card border shadow-none mb-2 btn_actions" onClick={_HandleSnapshot}>
                        <a className="text-body">
                            <div className="p-2">
                                <div className="d-flex align-items-center">
                                    <div className="avatar align-self-center me-3">
                                        <div className="avatar-title rounded bg-soft-success text-success font-size-24">
                                            <i className="mdi mdi-image"></i>
                                        </div>
                                    </div>

                                    <div className="overflow-hidden me-auto">
                                        <h5 className="font-size-15 text-truncate mb-1">Capturar Imagem</h5>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>


                    <div className="card border shadow-none mb-2 btn_actions" onClick={_HandleClear}>
                        <a className="text-body">
                            <div className="p-2">
                                <div className="d-flex align-items-center">
                                    <div className="avatar align-self-center me-3">
                                        <div className="avatar-title rounded bg-soft-warning text-warning font-size-24">
                                            <i className="mdi mdi-eraser"></i>
                                        </div>
                                    </div>

                                    <div className="overflow-hidden me-auto">
                                        <h5 className="font-size-15 text-truncate mb-1">Limpar fotos</h5>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>


                    <div className="card border shadow-none mb-2 btn_actions" onClick={_HandleClose}>
                        <a className="text-body">
                            <div className="p-2">
                                <div className="d-flex align-items-center">
                                    <div className="avatar align-self-center me-3">
                                        <div className="avatar-title rounded bg-soft-danger text-danger font-size-24">
                                            <i className="mdi mdi-motion-pause-outline"></i>
                                        </div>
                                    </div>

                                    <div className="overflow-hidden me-auto">
                                        <h5 className="font-size-15 text-truncate mb-1">Parar transmissão</h5>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                       

                </div>
                <div style={{clear:'both', height:'40px'}}>

                </div>

                <div>
                {
                        Array.isArray(photos) && photos.length > 0 && photos.map((item, i) => (
                            <div className={'thumb ' + (item.selected?'':'thumbBtn')} key={'thumb_'+i} >
                                <Thumb Id={i} Stream={item.data} OnUpload={HandlerUpload} OnGallery={HandleThumbClick}/>
                            </div>
                        ))
                    }
                </div>

                
               
               
            </div>


            
        </div>
    )
})
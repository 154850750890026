import React, { useContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import $ from 'jquery';
import { Context } from '../../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Request } from '../../scripts/Request';
import { WaitObject } from '../../scripts/Wait';
import { GetServer } from '../../scripts/Loadbalancer';
import './Login.css';
import ReactGA from 'react-ga';


const Login = () => {
    const { loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [state, setState] = useState({
        login: "",
        password: "",
        code2fa: "",
        remember: false
    });
    const [pageLoading, setPageLoading] = useState(false);
    const [pwdState, setPwdState] = useState({ message: "", color: "#FFFFFF" });
    const [step, setStep] = useState(1);
    const [recoverStep, setRecoverStep] = useState(1);
    const [qr2fa, setQr2fa] = useState("");


    const [recover, setRecover] = useState({
        email: "",
        code: "",
        senha: "",
        resenha: "",
        token: ""
    });

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const loadedRef = useRef(false);
    const navigate = useNavigate();




    //#region Effects
    useEffect(() => {
        GetServer();

        if(!loadedRef.current) {
            loadedRef.current = true;
            ReactGA.pageview(window.location.pathname + window.location.search);
            handleLogout(true);
            $("body").attr("data-layout", "");
            $("body").attr("data-topbar", "");
            $("body").addClass("bg-white");
            Init();
            window.passAddOn();
        }
    }, []);


    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".login-panel")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);

    //#endregion Effects


     //#region Core
     const Init = () => {
        const _userpwd = localStorage.getItem('userpwd');
        
        if (_userpwd) {
            let _u = JSON.parse(_userpwd);
            //console.log(_u);
            setState(_u);
        } else {
            localStorage.removeItem('userpwd');
        }
    };
    //#endregion Core


    //#region Loaders

    //#endregion Loaders


    //#region Handlers
    const _HandleChange = (e) => {
        const { id, value, type, checked } = e.target;
        //console.log(id, value);

        if(type === "checkbox") {
            setState(prevState => ({ ...prevState, [id]: checked }));
        } else {
            setState(prevState => ({ ...prevState, [id]: value }));
        }
    }


    const handleRecoverChange = (e) => {
        const { name, value } = e.target;

        setRecover(prev => ({
            ...prev,
            [name]: value
        }))
    }


    const _handleLogin = async(event) => {
        event.preventDefault();
        let _state = Object.assign({}, state);
        let _userpwd = localStorage.getItem('userpwd');

        setState({ login: _state.login, password: _state.password, remember: _state.remember  });


        if(_state.remember) {
            localStorage.setItem('userpwd', JSON.stringify({ login: _state.login, password: _state.password, remember: _state.remember }));
        } else {
            localStorage.removeItem('userpwd');
        }

        _userpwd = localStorage.getItem('userpwd');
        //console.log(_userpwd);

        let qr = await handleLogin(_state);
        if(qr && qr.tfa) setQr2fa(qr.tfa);
    };


    const _handleClick = (event, target, id) => {
        if(id < 3) 
        {
            setStep(2)
        } 
        else if(id === 3) {
            setStep(3);
        } 
        else if(id === 4) {
            setStep(4);
        }

    }

    const _handleClear = () => {
        setStep(1);
    }


    const _handleFormChange = (event) => {
        if(event.target.name === "resenha" || event.target.name === "senha") {
            StrengthChecker(event.target.value);
        }
    }


    const _handleRecoverClick = (event) => {
        if(recoverStep === 1) {
            setRecoverStep(2);
            return;
        }

        setRecover(prev =>({...prev, [event.target.name]: event.target.value}));
    }


    const _handleSendCode = () => {
        let _recover = Object.assign({}, recover );

        Promise.resolve(new Request().Run("api/Auth/SendCode", "POST", _recover, "", 0*1000))
        .then((data) => {
            if(data === "loaded") {
                window.swal("Sucesso", "Verifique o código enviado ao seu e-mail", "success");
                setRecoverStep(3);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
        
        });
    }



    const _handleCheckCode = () => {
        let _recover = Object.assign({}, recover );

        Promise.resolve(new Request().Run("api/Auth/CheckCode", "POST", _recover, "", 0*1000))
        .then((data) => {
            if(data !== "error") {
                setRecover(prev =>({...prev, token: data}));
                window.swal("Sucesso", "Código confirmado, insira uma nova senha.", "success");
                setRecoverStep(4);
            } else {
                window.swal("Alerta", "Código inválido, insira o código enviado no seu e-mail", "error");
            }
        }).catch(() => {
        
        });
    }


    const _handleSaveNewPwd = () => {
        let _recover = Object.assign({}, recover );

        if(_recover.senha === _recover.resenha && _recover.senha.length > 5) {
            Promise.resolve(new Request().Run("api/Auth/SaveNewPwd", "POST", _recover, "", 0*1000))
            .then((data) => {
                if(data === "loaded") {
                    setState({
                        email: "",
                        code: "",
                        senha: "",
                        resenha: "",
                        token: ""
                    });
                    setRecoverStep(1);
                    window.swal("Sucesso", "Senha atualizada com sucesso!", "success");
                    
                }
            }).catch(() => {
            
            });
        } else {
            window.swal("Alerta", "Escolha uma senha com 6 ou mais caracteres!", "error");
        }
    }


    const handleBackStep = (step) => {
        setRecoverStep(step);
    }



    const _FullScreen = () => {
        let elem =  document.documentElement;

        if (!$(elem).is(":fullscreen")) {
            
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
        }
    }
    //#endregion Handlers



    //#region Functions
    const StrengthChecker = (pass) => {
        var score = scorePassword(pass);

        if (score >= 75) {
            setPwdState({ message: "Senha Forte", color: "#008800" });
        } else if (score > 50) {
            setPwdState({ message: "Senha Aceitável", color: "#0000FF" });
        } else if (score >= 25) {
            setPwdState({ message: "Senha Fraca", color: "#FF0000" });
        } else if (score <= 5) {
            setPwdState({ message: "", color: "#FFFFFF" });
        }
    }


    const scorePassword = (pass) => {
        var score = 0;
        if (!pass)
            return score;

        // award every unique letter until 5 repetitions
        var letters = new Object();
        for (var i = 0; i < pass.length; i++) {
            letters[pass[i]] = (letters[pass[i]] || 0) + 1;
            score += 5.0 / letters[pass[i]];
        }

        // bonus points for mixing it up
        var variations = {
            digits: /\d/.test(pass),
            lower: /[a-z]/.test(pass),
            upper: /[A-Z]/.test(pass),
            nonWords: /\W/.test(pass),
        }

        var variationCount = 0;
        for (var check in variations) {
            variationCount += (variations[check] == true) ? 1 : 0;
        }
        score += (variationCount - 1) * 10;

        return parseInt(score);
    }
    //#endregion Functions



    return (
        <div className="auth-page d-flex align-items-center min-vh-100 Login">
            <div className="container-fluid p-0">
                <div className="row g-0">
                    <div className="col-xxl-3 col-lg-4 col-md-5">
                            <div className="d-flex flex-column h-100 py-5 px-4">
                                <div className="text-center text-muted mb-2">
                                    <div className="pb-3">
                                        <a>
                                            <span className="logo-lg">
                                                <img src="assets/images/logo.jpg" alt="" /> 
                                            </span>
                                        </a>
                                        <p className="text-muted font-size-15 w-75 mx-auto mt-3 mb-0">Uma nova experiência em gestão e venda para lentes e armações em óticas</p>
                                    </div>
                                </div>
        
                                <div className="my-auto">
                                    <div className="p-3 text-center pointer">
                                        <img src="assets/images/auth-img.png" alt="" className="img-fluid" onClick={_FullScreen}/>
                                    </div>
                                </div>
        
                                <div className="mt-4 mt-md-5 text-center">
                                        <p className="mb-0">© {new Date().getFullYear()} easy.lens</p>
                                </div>
                            </div>
                        
                      
                    </div>
   
    
                    <div className="col-xxl-9 col-lg-8 col-md-7">
                        <div className="auth-bg bg-light py-md-5 p-4 d-flex">
                            <div className="bg-overlay-gradient"></div>
                      
                            <div className="row justify-content-center g-0 align-items-center w-100">
                                <div className="col-xl-4 col-lg-8 login-panel">
                                    <div className="card">
                                        <div className={'card-body ' + (recoverStep===1?'':'hide')}>
                                            <div className="px-3 py-3">
                                                <div className="text-center">
                                                    <h5 className="mb-0">Seja bem vindo!</h5>
                                                    <p className="text-muted mt-2">Faça seu login para começar.</p>
                                                </div>
                                                <div className="mt-4 pt-2">
                                                    <div className="form-floating form-floating-custom mb-3">
                                                        <input type="text" className="form-control" id="login" placeholder="Digite seu E-mail" onChange={_HandleChange} value={state.login} />
                                                        <label for="input-username">Login / E-mail</label>
                                                        <div className="form-floating-icon">
                                                            <i className="uil uil-users-alt"></i>
                                                        </div>
                                                    </div>
                                                    <div className="form-floating form-floating-custom mb-3 auth-pass-inputgroup">
                                                        <input type="password" className="form-control" id="password" placeholder="Enter Password" onChange={_HandleChange} value={state.password}/>
                                                        <button type="button" className="btn btn-link position-absolute h-100 end-0 top-0" id="password-addon">
                                                            <i className="mdi mdi-eye-outline font-size-18 text-muted"></i>
                                                        </button>
                                                        <label for="password">Senha</label>
                                                        <div className="form-floating-icon">
                                                            <i className="uil uil-padlock"></i>
                                                        </div>
                                                    </div>
                                                    <div className={'form-floating form-floating-custom mb-3 ' + (qr2fa === '2fa' ? '' : 'hide')}>
                                                        <input type="text" className="form-control" id="code2fa" placeholder="Digite seu código de autenticação" onChange={_HandleChange}/>
                                                        <label for="input-username">Código Autenticação</label>
                                                        <div className="form-floating-icon">
                                                            <i className="uil uil-users-alt"></i>
                                                        </div>
                                                       
                                                    </div>


                                                    <div className="form-check form-check-primary font-size-16 py-1">
                                                        <input className="form-check-input" type="checkbox" id="remember" checked={state.remember} onClick={_HandleChange}/>
                                                        <div className="float-end">
                                                            <a className="text-muted text-decoration-underline font-size-14 pointer" onClick={() => handleBackStep(2)}>Esqueci minha senha</a>
                                                        </div>
                                                        <label className="form-check-label font-size-14" for="remember-check">
                                                            Lembrar senha
                                                        </label>
                                                    </div>
                                                    
                
                                                    <div className="mt-3">
                                                        <button className="btn btn-primary w-100" type="button" onClick={_handleLogin}>Entrar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className={'card-body ' + (recoverStep===2?'':'hide')}>
                                            <div className="px-3 py-3">
                                                <div className="text-center">
                                                    <h5 className="mb-0 pointer" onClick={() => handleBackStep(1)}>  <i className="uil-arrow-circle-left"></i> Recuperar acesso</h5>
                                                </div>
                                                <div className="mt-4 pt-2">
                                                    <div className="form-floating form-floating-custom mb-3">
                                                        <input className="form-control" name="email" type="email" placeholder="" value={recover.email} onChange={handleRecoverChange}/>
                                                        <label for="input-username">Login / E-mail</label>
                                                        <div className="form-floating-icon">
                                                            <i className="uil uil-users-alt"></i>
                                                        </div>
                                                    </div>

                                                    <div className="mt-3">
                                                        <button className="btn btn-primary w-100" type="button" onClick={_handleSendCode}>Enviar código de recuperação</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className={'card-body ' + (recoverStep===3?'':'hide')}>
                                            <div className="px-3 py-3">
                                                <div className="text-center">
                                                    <h5 className="mb-0 pointer" onClick={() => handleBackStep(2)}>  <i className="uil-arrow-circle-left"></i> Recuperar acesso</h5>
                                                </div>
                                                <div className="mt-4 pt-2">
                                                    <div className="form-floating form-floating-custom mb-3">
                                                        <input type="text" name="code" className="form-control" placeholder="" value={recover.code} onChange={handleRecoverChange}/>
                                                        <label for="input-username">Digite o código de recuperação</label>
                                                        <div className="form-floating-icon">
                                                            <i className="uil-lock-access"></i>
                                                        </div>
                                                    </div>

                                                    <div className="mt-3">
                                                        <button className="btn btn-primary w-100" type="button" onClick={_handleCheckCode}>Validar código de recuperação</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className={'card-body ' + (recoverStep===4?'':'hide')}>
                                            <div className="px-3 py-3">
                                                <div className="text-center">
                                                    <h5 className="mb-0 pointer" onClick={() => handleBackStep(3)}>  <i className="uil-arrow-circle-left"></i> Criar nova senha</h5>
                                                </div>
                                                <div className="mt-4 pt-2">
                                                    <div className="form-floating form-floating-custom mb-3">
                                                        <input type="password" name="senha" className="form-control" placeholder="Digite uma nova senha" value={recover.senha} onChange={handleRecoverChange}/>
                                                        <label for="input-username">Nova senha</label>
                                                        <div className="form-floating-icon">
                                                            <i className="uil uil-lock-alt"></i>
                                                        </div>
                                                    </div>

                                                    <div className="form-floating form-floating-custom mb-3">
                                                        <input type="password" name="resenha" className="form-control" placeholder="Redigite a nova senha" value={recover.resenha} onChange={handleRecoverChange}/>
                                                        <label for="input-username">Redigitar nova senha</label>
                                                        <div className="form-floating-icon">
                                                            <i className="uil uil-lock-alt"></i>
                                                        </div>
                                                    </div>

                                                    <div className="mt-3">
                                                        <button className="btn btn-primary w-100" type="button" onClick={_handleSaveNewPwd}>Salvar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Login;
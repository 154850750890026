/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Request } from '../../../scripts/Request';
import { UploadService } from '../../../scripts/UploadService';
import { WaitObject } from '../../../scripts/Wait';
import { useNavigate } from 'react-router-dom';
import { ServerURL } from '../../../scripts/Loadbalancer';
import { Uuid } from '../../../scripts/StringUtils';
import ReactGA from 'react-ga';
import $ from 'jquery';
import { Camera } from '../../Camera';
import Preview from './Preview';
import Gallery from './Gallery';
import { Thumb } from './Thumb';
import './index.css';


let fotos_count = 0;
let fotos = [];
//let wpeer = new window.Peer();

export default function Espelho() {
    const component_name = "admin/espelho_preview";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [step, setStep] = useState(0);
    const [img, setImg] = useState(null);
    const [photos, setPhotos] = useState([]);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const cameraRef = useRef();
    const callRef = useRef();
    const peerRef = useRef();
    const connRef = useRef();
    const remoteConnRef = useRef();
    const thumbRef = useRef([]);

    const navigate = useNavigate();
    
    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    peerRef.current = new window.Peer();
                    Init();
                });
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        fotos = photos;
    }, [photos]);
    //#endregion Effects



    //#region Init
    const Init = () => {
        //_FullScreen();
        StopCamera();
        InitPeer();
    }
    //#endregion Init


    //#region Peer
    const InitPeer = () => {
        console.log("InitPeer");
        peerRef.current.on('connection', function(conn) {
            remoteConnRef.current = conn;

            conn.on('data', function(data){
                console.log(data);
                Command(data);
            });
        });

        peerRef.current.on("open", function (id) {
            console.log(id);
            Promise.resolve(new Request().Run("api/Espelhos/Uuid", "POST", {uuid: id}, user.token, 0*24*60*1000))
            .then(async(data) => {
                if(data.toString() !== "error") {
                } else {
                    window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
                }
            }).catch(() => {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            });
        });
    }


    const InitCall = (stream) => {
        peerRef.current.on("call", (call) => {
            //console.log(stream);
            callRef.current = call;
            call.answer(stream);
        });

        peerRef.current.on("data", (data) => {
            //console.log(data);
        });
    }


    const Command = (json) => {
        if(json.cmd === "connected") {
            let hasConn = connRef.current || false;
            connRef.current = peerRef.current.connect(remoteConnRef.current.peer);

            
            console.log("Conn Open")
            connRef.current.on('open', function() {
                if(step===0) {
                    console.log(2, json.data);
                    connRef.current.send({cmd: 'connection_status', data: 200});
                } else {
                    connRef.current.send({cmd: 'connection_status', data: 401});
                    connRef.current.close();
                }
            });
            
            
        } else if(json.cmd === "start") {
            _HandleStart();
        } else if(json.cmd === "endcall") {
            _GoPreview();
        } else if(json.cmd === "snapshot") {
            TakeSnapshot();
        } else if(json.cmd === "clear_photos") {
            _ClearPreviews();
        } else if(json.cmd === "snapshot_upload") {
            UploadSnapShot(json.data);
        } else if(json.cmd === "face_recognize") {
            if(json.data === 1) {
                cameraRef.current.Play(true);
            } else {
                cameraRef.current.Stop();
            }
        } else if(json.cmd === "gallery") {
            thumbRef.current[json.data].SelectMe(json.data);
        }
    }
    //#endregion Peer


    //#region Snapshot
    const TakeSnapshot = () => {
        //console.log(fotos_count);

        if(fotos_count < 5) {
            cameraRef.current.Snapshot();
            fotos_count++;
        } else {
            connRef.current.send({cmd: 'alert', data: {type:"error", message:"O número máximo de fotos é 5"}});
        }
    }


    const HandleSnapshot = (data) => {
        setPhotos(photos => [...photos, {data: data, selected: false}]);
        ResizeDataURL(data, window.$("#camera").width()/6, window.$("#camera").height()/6);
        //connRef.current.send({cmd: "snapshot_data", data: data});
    }


    const HandleCamera = () => {
        console.log("HandleCamera");
        connRef.current.send({cmd: 'oncamera', data: 200});
    }


    const UploadSnapShot = (index) => {
        window.alertify.message("Salvando foto " + (index+1));
   
        let data = fotos[index].data;

        let img = new Image();
        img.onload = async function() {
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            canvas.width = window.$("#camera").width()/2;
            canvas.height = window.$("#camera").height()/2;
            ctx.drawImage(this, 0, 0, canvas.width, canvas.height);

            let foto = Uuid() + '.jpg';
            let blob = canvas.toBlob(async function (blob) {
                let file = new File([blob], foto, { type: 'image/jpeg' });
                let upload = new UploadService();
                upload.Data = {foto: foto, cliente_id: 0}
                upload.OnEnd = (image) => {
                    connRef.current.send({cmd: "snapshot_uploaded", data: image.img}); 
                }
                await upload.Upload(file, ServerURL("api/Espelhos/Upload"), user.token);
            }, 'image/jpeg');
           
            
        }

        img.src = data;
    }
    //#endregion Snaphot


    //#region Functions
    const _FullScreen = () => {
        let elem =  document.documentElement;

        if (!$(elem).is(":fullscreen")) {
            
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
        }
    }


    const ResizeDataURL = (data, w, h) => {
        let img = new Image();
        img.onload = function() {
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            canvas.width = w;
            canvas.height = h;
            ctx.drawImage(this, 0, 0, w, h);
            connRef.current.send({cmd: "snapshot_data", data: canvas.toDataURL('image/jpeg', 0.8)});
        }

        img.src = data;
    }


    const _HandleStart = () => {
        setStep(1);
    }


    const StopCamera = () => {
        try {
            let tracks = window.stream.getVideoTracks();
            tracks.forEach(track => {
                track.stop();
            }); 
        } catch(e) { }
    }


    const OnHandleClick = async (stream) => {
        setStep(2);
        try {
            await cameraRef.current.Stop();
        }catch(e) {}
        
        setImg(stream);

        photos.map(item => {
            if(item.data === stream) {
                item.selected = true;
            } else {
                item.selected = false;
            }
        });
    }


    const _HandleBack = async() => {
        setStep(1);
        try {
            await cameraRef.current.Play(false);
        } catch(e) {}
    }


    const _GoPreview = () => {
        //setStep(0);
        setPhotos([]);
        StopCamera();
        navigate("/Redirect/Espelho");
    }


    const _ClearPreviews = () => {
        fotos_count = 0;
        setPhotos([]);

        try {
            //callRef.current.close();
        } catch(e) {
           // callRef.current = undefined;
        }

        //peerRef.current.disconnect();
        //peerRef.current.destroy();
        //peerRef.current = undefined;
        //connRef.current = undefined;

        
          
        _HandleBack();
    }


    const _HandleDisconnect = () => {
        try {
            connRef.current.send({cmd: 'disconnect', data: 200});
            setTimeout(() => {
                _GoPreview();
            }, 1000);
        } catch(e) { }
    }
    //#endregion Functions


    return (
        <div className="Espelho">
            <div className="camera">
                { step === 0 && (<Preview OnStart={_HandleStart} />)}
                { step === 1 && (<Camera ref={cameraRef} OnStream={InitCall} OnSnapshot={HandleSnapshot} OnCamera={HandleCamera}/>)}
                { step === 2 && (<Gallery Stream={img}/>)}

                { step > 0 && (<div className="pad">

                    <button className={'backBtn ' + (step === 1?'':'hide')} onClick={_HandleDisconnect}> Desconectar </button>
                    <button className={'backBtn ' + (step === 2?'':'hide')} onClick={_HandleBack}>Voltar</button>
                    {
                        Array.isArray(photos) && photos.length > 0 && photos.map((item, i) => (
                            <div key={'thumb_'+i} className={'thumbnail ' + (item.selected?'':'thumbBtn')} >
                                <Thumb Id={i} ref={elem => thumbRef.current[i] = elem} Stream={item.data} OnClick={OnHandleClick}/>
                            </div>
                        ))
                    }

                    
                </div>) }
            </div>
            
            <div className="EspelhoNome">
                {user.nome}
            </div>
        </div>  

    )
}
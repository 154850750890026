/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request } from '../../../scripts/Request';
import { HubObjects } from '../../../scripts/HubObjects';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import { Currency, UnCurrency, Number } from '../../../scripts/StringUtils';
import ReactGA from 'react-ga';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import './index.css';


const statuses = [
    {id: 0, name:"Pendente"},
    {id: 1, name:"Convertido"}
];


export default function Lista() {
    const component_name = "admin/orcamentos";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const [search, setSearch] = useState({ word: "", status: "" });
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: "" });

    const navigate = useNavigate();

    



    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
     const Init = () => {
        _HandlerSearchChange();
     }
    //#endregion Init


    //#region Handlers
    const _HandleChange = (event) => {
        setSearch(prev => prev = {[event.target.id]: event.target.value});
        searchRef.current = {[event.target.id]: event.target.value};
    }


    const _HandleClear = (event) => {
        setSearch(prev => prev = {word: ""});
        searchRef.current = {word: ""};
        _HandlerSearchChange(event);
    }



    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setPageLoading(true);
        setHasMore(true);

        Promise.resolve(new Request().Run("api/Orcamentos/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];
                listRef.current = listRef.current.concat(_list);
                setList(listRef.current);
                setPageLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            }).catch(() => { setPageLoading(false); });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setPageLoading(true);

            Promise.resolve(new Request().Run("api/Orcamentos/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    listRef.current = listRef.current.concat(_list);
                    setList(listRef.current);
                    setPageLoading(false);
                }).catch(() => { setPageLoading(false); });
        } catch (e) {}
    }


    const _HandleEdit = (e, id) => {
        navigate("/Orcamento/"+id);
    }



    //#endregion Handlers


    return (
        <div className="main-content Orcamentos">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Lista de Orçamentos</h4>
            
                                    <div className="row"> 
                                        <div className="col-xl-12"> 
             
                                            <div className="align-right mr-10">
                                                <button type="button" className="btn btn-outline-primary" onClick={_HandlerSearchChange}>
                                                    <i className="mdi mdi-share-outline"></i> &nbsp;Buscar
                                                </button>
                                            </div>


                                            <div className={'search-box align-right mr-10 '}>
                                                <div className="position-relative">
                                                    <select id="selected" className="form-select" value={search.status} onChange={_HandleChange}>
                                                        <option value="">Todos status</option>
                                                        <option value="0">Pendentes</option>
                                                        <option value="1">Convertidos</option>
                                                    </select>
                                                </div>
                                            </div> 


                                            <div className="search-box align-right mr-10">
                                                <div className="position-relative">
                                                    <input id="word" typeName="text" value={search.word} className="form-control rounded bg-light border-0" placeholder="" onChange={_HandleChange}/>
                                                    <i className="bx bx-search search-icon"></i>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>



                                </div>

                                <div className="card-body pb-2">
                                    <div className="table-responsive">
                                        <InfiniteScroll
                                            dataLength={list.length}
                                            next={_fetchMoreData}
                                            hasMore={hasMore}
                                            loader={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Carregando...</b>
                                                </p>
                                            }
                                            style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                            endMessage={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Não há mais dados</b>
                                                </p>
                                            }>    


                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th style={{width:'100px'}}>Ref Nº</th>
                                                        <th>Cliente</th>
                                                        <th>Celular</th>
                                                        <th style={{width:'150px'}}>Valor Total</th>
                                                        <th style={{width:'100px'}}>Status</th>
                                                        <th style={{width:'100px'}}>Data</th>
                                                        <th style={{width:'70px'}}></th>
                                                    </tr>
                                                </thead>


                                                <tbody>
                                                {list.map((item, i) => (
                                                    <tr key={'tr' + i} className="pointer">
                                                        <td>{item.numero}</td>
                                                        <td>{item.nome}</td>
                                                        <td>{item.celular}</td>
                                                        <td>{Currency(item.valor)}</td>
                                                        <td>{statuses[statuses.findIndex(s => s.id === item.status)]?.name}</td>
                                                        <td>{item.date_created}</td>
                                                        <td>
                                                            <i className="fa fa-edit" onClick={(e) => _HandleEdit(e, item.orcamento_id)}></i>
                                                        </td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </table>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>
        </div>
    )
}
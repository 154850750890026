/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Context } from '../../../../Context/AuthContext';
import { HubContext } from '../../../../Context/HubContext.js';
import './Thumb.css';


export const Thumb = (props) => {
    const component_name = "admin/espelho_thumb";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [image, setImage] = useState([]);
    const thumbRef = useRef();
    const timerIdleRef = useRef(new Date());
    const prestineRef = useRef(false);

    const { Id, Stream } = props;


   
    //#region Effects
    useEffect(()=> {
        if(!prestineRef.current) {
             prestineRef.current = true;
             Init();
        }
  
     
         return () => {
             if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                 try {
                   
                 } catch(e) {}
             }
         }
    }, []);
    //#endregion Effects


    //#region Init
    const Init = () => {
        
    }
    //#endregion Init


    //#region Handlers
    const _HandleClick = () => {
        props.OnGallery(Id);
    }


    const _HandleUpload = () => {
        props.OnUpload(Id);
    }
    //#endregion Handlers


    return (
        <div className="Vthumb pointer" >
            <img src={Stream} alt="" onClick={_HandleClick}/>
            <button type="button" className="btn btn-outline-success" onClick={_HandleUpload}><i className="mdi mdi-upload"></i></button>
        </div>
    )


};
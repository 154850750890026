

/*
const application_endpoints_list = [
    {endpoint:"default", servers: ["https://localhost:7108/", "https://localhost:7108/", "https://localhost:7108/"]},
    {endpoint:"api/Auth/Login", servers: ["https://localhost:7108/", "https://localhost:7108/", "https://localhost:7108/"]},
    {endpoint:"easylens_sessions", servers: ["https://sessions.easylens.com.br/hub", "https://sessions.easylens.com.br/hub", "https://sessions.easylens.com.br/hub"]}
];
*/



const application_endpoints_list = [
    {endpoint:"default", servers: ["https://painel.easylens.com.br/", "https://painel.easylens.com.br/", "https://painel.easylens.com.br/"]},
    {endpoint:"api/Auth/Login", servers: ["https://painel.easylens.com.br/", "https://painel.easylens.com.br/", "https://painel.easylens.com.br/"]},
    {endpoint:"easylens_sessions", servers: ["https://sessions.easylens.com.br/hub", "https://sessions.easylens.com.br/hub", "https://sessions.easylens.com.br/hub"]}
];




export const GetServer = async(server_index) => {
   /* const db_version = "1.0.003";
    const db = new SQL();

    //console.log("GetServer");
    let db_name = "easylens_db_" + (window.location.href.indexOf("painel.easylens.com.br") > -1?"painel":"localhost");


    await db.Open(db_name, 16).then(()=> {
        //console.log("Db opened");
    }).catch((e)=>{
        //console.log("Db not opened " + db_name + " " + e);
    });


    //await db.Clear("cache");
    
    
    await db.Create("cache", "id INTEGER PRIMARY KEY, endpoint VARCHAR UNIQUE, content TEXT").then(()=>{
        //console.log("Tb created");
    }).catch(()=>{
        //console.log("Tb not created");
    });

    //await db.Clear("config");
    await db.Create("config", "id INTEGER PRIMARY KEY, name VARCHAR UNIQUE, value TEXT").then(()=>{ }).catch(()=>{ });


    
    await db.Query("SELECT value FROM config WHERE name=?", ['db_version']).then(async(result)=> {
        let v = result[0].value;
        if(v !== db_version) {
            console.log("Cleared all tables");
            await db.Clear("cache");
            await db.Clear("config");
            await db.Clear("invalidators");
            //console.log("Insert config");
            await db.Query("INSERT OR REPLACE INTO config (name, value) VALUES (?, ?)", ['db_version', db_version]);
        }
    }).catch(async(e) => {
        //console.log("Insert config");
        await db.Query("INSERT OR REPLACE INTO config (name, value) VALUES (?, ?)", ['db_version', db_version]);
    });
   

    
    let id_server = rIndex(0, application_servers_list.length-1);
    //console.log("id_server", id_server);
    let _server = ((server_index === undefined || server_index === null || server_index === "") ? application_servers_list[id_server] : application_servers_list[server_index]);

    let ourl = window.location.href;
    if (ourl.indexOf("localhost") > -1) {
        window.localStorage.setItem('app_server',_server);
    } else {
        window.localStorage.setItem('app_server', _server);

    }*/
}


export const ServerURL = (url) => {
    //if(window.location.href.indexOf("localhost") > -1) return url;
    if(url.toLowerCase().indexOf("http") === -1 && url.substr(0,1) !== "/" && url.substr(0,2) !== "./" ) url = AppServer(url) + url;
    return url;
}


export const AppServer = (endpoint = "") => {
    let _endpoint = application_endpoints_list.find(e => e.endpoint === endpoint);
    if(!_endpoint) {
        _endpoint = application_endpoints_list.find(e => e.endpoint === "default");
    }

    if(_endpoint) {
        //console.log(_endpoint);
        let id_server = rIndex(0, _endpoint.servers.length-1);
        //console.log("app_server", _endpoint,endpoint, _endpoint.servers[id_server]);
        return _endpoint.servers[id_server];
    } else {
        return "";
    }
}





const rIndex = (min, max) => {
    min = Math.floor(min);
    max = Math.ceil(max) + 0.9;
    //return Math.floor(Math.random() * (max - min)) + min;
    return  Math.floor((Math.random() * 1000000)%(max - min)) + min;
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useContext, useState, useRef } from 'react';
import { Context } from '../../Context/AuthContext';
import { Request } from '../../scripts/Request';
import { HubObjects } from '../../scripts/HubObjects';
import { WaitObject } from '../../scripts/Wait';
import { LinkContainer } from 'react-router-bootstrap';

import './index.css';


export default function Menu() {
    const component_name = "menu";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);
    const [selectedItem, setSeleceditem] = useState(0);

    const loadedRef = useRef(false);

    //#region Effects
    useEffect(() => {
        if(!loadedRef.current) {
            loadedRef.current = true;

            WaitObject(user.nome).then(() => {
                Init();
            });
        }
    }, []);
    //#endregion Effects


    //#region Init
    const Init = () => {
        LoadMenu();
    }
    //#endregion Init

    
    //#region Loaders
    const LoadMenu = async() => {
        await Promise.resolve(new Request().Run("/assets/json/menu_" + user.nivel + ".json", "GET", "", "", 0*1000))
            .then((data) => {
                setList(data)
            }).catch(() => {
            
            }).finally(() => {
              
            })
    }
    //#endregion Loaders


    //#region Handlers
    const _HandleClick = (index) => {
        try {
            //e.target.nextElementSibling.classList.toggle("show");
            //if(window.innerHeight > window.innerWidth) 
            window.$("#dmenu" + index).show();
            
        } catch (ex) { }
    }

    const _HandleCloseClick = (index) => {
        try {
            if(window.innerHeight > window.innerWidth) window.$("#dmenu" + index).hide();
           
        } catch (ex) { }
    }
    //#endregion Handlers
    


    
    return (
        <nav className="navbar navbar-light navbar-expand-lg topnav-menu">

            <div className="collapse navbar-collapse" id="topnav-menu-content">
                <ul className="navbar-nav active">
                    {
                        Array.isArray(list) && list.length > 0 && list.map((item, i) => (
                            <li key={'ime'+i} className={'nav-item dropdown ' + (i===selectedItem?'active':'')} >   
                                {
                                    item.link !== "" ? ( 
                                        <LinkContainer to={item.link} exact="true" key={"menuitem" + i}>
                                         <a className={'nav-link dropdown-toggle arrow-none pointer ' + (i===selectedItem?'active':'')} id="topnav-pages" role="button">
                                            <i className={item.icon}></i>
                                            <span data-key={'t-'+(item.name.toLowerCase().replace(' ','-'))}>{item.name}</span> <div className={'arrow-down ' + ((item.nodes && item.nodes.length > 0)?' ':'hide') }  onClick={() => _HandleClick(i)}></div>
                                        </a>
                                        </LinkContainer>
                                    ) : (
                                        <a className={'nav-link dropdown-toggle arrow-none pointer ' + (i===selectedItem?'active':'')} id="topnav-pages" role="button" onClick={() => _HandleClick(i)}>
                                            <i className={item.icon}></i>
                                            <span data-key={'t-'+(item.name.toLowerCase().replace(' ','-'))}>{item.name}</span> <div className="arrow-down"></div>
                                        </a>
                                    )
                                }
                               
                                { 
                                    Array.isArray(item.nodes) && item.nodes && item.nodes.length > 0 && (
                                        <div id={'dmenu'+i} className={'dropdown-menu'} aria-labelledby="topnav-pages">
                                            {
                                                item.nodes.map((node, j) => (
                                                    <LinkContainer to={node.link} exact="true" key={"submenuitem" + j}>
                                                          <a className="dropdown-item pointer" data-key={'t-'+(node.name.toLowerCase().replace(' ','-'))} onClick={() => _HandleCloseClick(i)}>{node.name}</a>
                                                    </LinkContainer>
                                                ))
                                            }

                                        </div>

                                )}    

                               
                            </li>
                        ))
                    }
                </ul>


            </div>
        </nav>  
    )
}
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useImperativeHandle, useEffect, useRef, forwardRef} from 'react';
import { Context } from '../../../../Context/AuthContext';
import { HubContext } from '../../../../Context/HubContext.js';
import { Request, Req } from '../../../../scripts/Request';
import { WaitObject } from '../../../../scripts/Wait';
import { Currency, Number, UnCurrency } from '../../../../scripts/StringUtils';
import { FormGenerator } from '../../../Form/FormGenerator';
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Button } from "react-bootstrap";
import ReactGA from 'react-ga';
import './index.css';
import { Lista } from './Lista';


import { MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';



let current_element;
let timer_lentes;

let distancia_focal_data = [
    {id: 0, name: "Escolha uma opção"},
    {id: 1, name: "Longe (Monofocal)"},
    {id: 2, name: "Perto (Monofocal)"},
    {id: 3, name: "Longe / Perto"},
];


let focal_data = [
    {id: 1, name: "Multifocal"},
    {id: 2, name: "Bifocal"},
    {id: 3, name: "Ocupacional"},
];


const armacao_tipo = [
    {id: 1, name: "Fechada"},
    {id: 2, name: "Nylon"},
    {id: 3, name: "Parafusada"},
    {id: 4, name: "Solar"}
]


const armacao_material = [
    {id: 1, name: "Metal"},
    {id: 2, name: "Acetato"},
]


const armacao_formato = [
    {id: 1, name: "Aviador"},
    {id: 2, name: "Browline"},
    {id: 3, name: "Olho de gato"},
    {id: 4, name: "Oval"},
    {id: 5, name: "Quadrado"},
    {id: 6, name: "Redondo"},
    {id: 7, name: "Retangular"},
    {id: 8, name:"Wayfarer"}
]


export const Lentes = forwardRef((props, ref) => {
    const component_name = "admin/lentes_pesquisa";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [lentes, setLentes] = useState({ first_multifocal:false, campo_visual: [], materiais: {acrilico:0, resina:0, poly: 0, highlight:0,  mineral: 0, trivex: 0}, olhos:"", direito:{a:"",b:"", c:"", d:"", fabricante_id:"", promo:"", venda_ini:"", venda_fim:""}, esquerdo:{a:"",b:"", c:"", d:"", fabricante_id:"", promo:"", venda_ini:"", venda_fim:""}});
    const [fabricantes, setFabricantes] = useState([]);
    //const [lentesDireito, setLentesDireito] = useState([]);
    const [os, setOs] = useState({od:"", oe:""});
    const [isMultifocal, setIsMultifocal] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState({title: "", img: ""});

    const [tratamentos, setTratamentos] = useState([]);
    const [atends, setAtends] = useState();
    const [receita, setReceita] = useState();

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const direitoRef = useRef();
    const esquerdoRef = useRef();
    const orRef = useRef();
    const osRef = useRef();



    
    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    window.$(".AtendimentoLentes .nav-tabs a[data-bs-toggle='tab']").on('shown.bs.tab', function (e) {
                        try {
                            let sid = parseInt(window.$(e.target).attr('lid').replace("lid", ""));
                            setTabIndex(sid);
                            if(sid===2) {
                                orRef.current.Init();
                            } else if(sid===3) {
                                osRef.current.Init();
                            }
                        } catch(e) {}
                    });

                    Init();
                });
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(current_element? current_element :".main-content")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects


    //#region Ref
    useImperativeHandle(ref, (url, args, token) => ({
        async Init() { return Init() },
        async Clear() { return ClearComponent() }
    }));
    //#endregion Ref


    //#region Init
    const Init = async() => {
        let current_atend = await Hub.Get("current_atend", true);
        //console.log(current_atend);

        let tratamentos  = await Hub.Get("tratamentos", true);
        setTratamentos(tratamentos);

        if(current_atend?.lentes) {
            setLentes(current_atend.lentes);
            LoadFabricantes(current_atend.lentes);
        }

        if(current_atend?.receita) {
            setIsMultifocal(current_atend.receita.distancia_focal === 3 && current_atend.receita.focal === 1);
        }



        if(current_atend?.os) {
            setOs(current_atend?.os);

            if(current_atend?.lentes) {
                let _lentes = {...current_atend?.lentes};

                if(current_atend.receita && current_atend.armacao?.form) {
                    if(current_atend.receita.distancia_focal === 3 && current_atend.receita.focal === 1) {
                        _lentes = Object.assign(_lentes, {altura_od: current_atend.armacao?.altura_od, altura_oe: current_atend.armacao?.altura_oe});
                    }
                    _lentes = Object.assign(_lentes, {receita: current_atend.receita, diagonal: current_atend.armacao.form.diag});
                } else {
                    return;
                }


                if(current_atend?.os.od !== "") {
                    //console.log("Lentes Direito");
                    direitoRef.current.Init(_lentes, {side: "OD"});
                }

                if(current_atend?.os.oe !== "") {
                    //console.log("Lentes Esquerdo");
                    esquerdoRef.current.Init(_lentes, {side: "OE"});
                }

                if(current_atend.os.od) {
                    document.querySelector("a[href='#olho_direito']").click();
                } else {
                    document.querySelector("a[href='#olho_esquerdo]").click();
                }
            }
        }


        let _receita = {
            focal: distancia_focal_data.find(f => f.id === current_atend?.receita?.distancia_focal)?.name,
            od_esf: (
                current_atend?.receita?.distancia_focal===1 ? parseFloat(current_atend?.receita?.longe_od_esf.toString().replace(",", ".")) : (current_atend?.receita?.distancia_focal===2 ? parseFloat(current_atend?.receita?.perto_od_esf.toString().replace(",", ".")) : Math.min(parseFloat(current_atend?.receita?.longe_od_esf.toString().replace(",", ".")), parseFloat(current_atend?.receita?.perto_od_esf.toString().replace(",", "."))))
            ),
            od_cil: (
                current_atend?.receita?.distancia_focal===1 ? parseFloat(current_atend?.receita?.longe_od_cil.toString().replace(",", ".")) : (current_atend?.receita?.distancia_focal===2 ? parseFloat(current_atend?.receita?.perto_od_cil.toString().replace(",", ".")) : Math.min(parseFloat(current_atend?.receita?.longe_od_cil.toString().replace(",", ".")), parseFloat(current_atend?.receita?.perto_od_cil.toString().replace(",", "."))))
            ),
            od_eixo: (
                current_atend?.receita?.distancia_focal===1 ? parseFloat(current_atend?.receita?.longe_od_eixo.toString().replace(",", ".")) : (current_atend?.receita?.distancia_focal===2 ? parseFloat(current_atend?.receita?.perto_od_eixo.toString().replace(",", ".")) : Math.min(parseFloat(current_atend?.receita?.longe_od_eixo.toString().replace(",", ".")), parseFloat(current_atend?.receita?.perto_od_eixo.toString().replace(",", "."))))
            ),
            od_dnp:  (
                current_atend?.receita?.distancia_focal===1 ? parseFloat(current_atend?.receita?.longe_od_dnp.toString().replace(",", ".")) : (current_atend?.receita?.distancia_focal===2 ? parseFloat(current_atend?.receita?.perto_od_dnp.toString().replace(",", ".")) : Math.min(parseFloat(current_atend?.receita?.longe_od_dnp.toString().replace(",", ".")), parseFloat(current_atend?.receita?.perto_od_dnp.toString().replace(",", "."))))
            ),
            oe_esf: (
                current_atend?.receita?.distancia_focal===1 ? parseFloat(current_atend?.receita?.longe_oe_esf.toString().replace(",", ".")) : (current_atend?.receita?.distancia_focal===2 ? parseFloat(current_atend?.receita?.perto_oe_esf.toString().replace(",", ".")) : Math.min(parseFloat(current_atend?.receita?.longe_oe_esf.toString().replace(",", ".")), parseFloat(current_atend?.receita?.perto_oe_esf.toString().replace(",", "."))))
            ),
            oe_cil: (
                current_atend?.receita?.distancia_focal===1 ? parseFloat(current_atend?.receita?.longe_oe_cil.toString().replace(",", ".")) : (current_atend?.receita?.distancia_focal===2 ? parseFloat(current_atend?.receita?.perto_oe_cil.toString().replace(",", ".")) : Math.min(parseFloat(current_atend?.receita?.longe_oe_cil.toString().replace(",", ".")), parseFloat(current_atend?.receita?.perto_oe_cil.toString().replace(",", "."))))
            ),
            oe_eixo:  (
                current_atend?.receita?.distancia_focal===1 ? parseFloat(current_atend?.receita?.longe_oe_eixo.toString().replace(",", ".")) : (current_atend?.receita?.distancia_focal===2 ? parseFloat(current_atend?.receita?.perto_oe_eixo.toString().replace(",", ".")) : Math.min(parseFloat(current_atend?.receita?.longe_oe_eixo.toString().replace(",", ".")), parseFloat(current_atend?.receita?.perto_oe_eixo.toString().replace(",", "."))))  
            ),
            oe_dnp:  (
                //current_atend?.receita?.distancia_focal===1 ? parseFloat(current_atend?.receita?.longe_oe_dnp.toString().replace(",", ".")) : (current_atend?.receita?.distancia_focal===2 ? parseFloat(current_atend?.receita?.perto_oe_dnp.toString().replace(",", ".")) : Math.min(parseFloat(current_atend?.receita?.longe_oe_dnp.toString().replace(",", ".")), parseFloat(current_atend?.receita?.perto_oe_dnp.toString().replace(",", "."))))
                current_atend?.receita?.distancia_focal===1 ? parseFloat(current_atend?.receita?.longe_oe_dnp.toString().replace(",", ".")) : (current_atend?.receita?.distancia_focal===2 ? parseFloat(current_atend?.receita?.perto_oe_dnp.toString().replace(",", ".")) : parseFloat(current_atend?.receita?.longe_oe_dnp.toString().replace(",", ".")))
            ),
            diametro_calc: 0
        }


        //console.log(current_atend?.receita?.longe_od_esf.toString().replace(",", "."), current_atend?.receita?.longe_oe_esf.toString().replace(",", "."), parseFloat(current_atend?.receita?.longe_od_esf.toString().replace(",", ".")), parseFloat(current_atend?.receita?.longe_oe_esf.toString().replace(",", ".")));


        let _tolerancia = await Req("api/AtendimentoLentes/Tolerancia", "GET", "", user.token);

        //console.log(_receita);
        //console.log(parseFloat(current_atend?.armacao?.form?.ponte)+parseFloat(current_atend?.armacao?.form?.aro)+parseFloat(current_atend?.armacao?.form?.diag));
        //console.log((2 * parseFloat((_receita.oe_dnp!==""?_receita.oe_dnp:_receita.od_dnp))));
        _receita.diametro_calc = (parseFloat(current_atend?.armacao?.form?.ponte)+parseFloat(current_atend?.armacao?.form?.aro)+parseFloat(current_atend?.armacao?.form?.diag)) - (2 * parseFloat((_receita.oe_dnp!==""?_receita.oe_dnp:_receita.od_dnp))) + _tolerancia;

        //console.log(current_atend);

        setAtends(current_atend);
        setReceita(_receita);
    }


    const ClearComponent = () => {
        setLentes({ first_multifocal:false, campo_visual: [], materiais: {acrilico:0, resina:0, poly: 0, highlight:0,  mineral: 0, trivex: 0}, olhos:"", direito:{a:"",b:"", c:"", d:"", fabricante_id:"", promo:"", venda_ini:"", venda_fim:""}, esquerdo:{a:"",b:"", c:"", d:"", fabricante_id:"", promo:"", venda_ini:"", venda_fim:""}});
        setFabricantes([]);
        setIsMultifocal(false);
        setOs({od:"", oe:""});
        try {
            direitoRef.current.Clear();
        } catch(e) {}

        try {
            esquerdoRef.current.Clear();
        } catch(e) {}
    }
    //#endregion Init


    //#region Loaders
    const LoadFabricantes = async(flentes) => {
        current_element = ".fabricante_td";
        setPageLoading(true);

        let _lentes = {...(flentes?flentes:lentes)};
        let current_atend = await Hub.Get("current_atend", true);

        if(current_atend.receita && current_atend.armacao?.form) {
            if(current_atend.receita.distancia_focal === 3 && current_atend.receita.focal === 1) {
                _lentes = Object.assign(_lentes, {altura_od: current_atend.armacao?.altura_od, altura_oe: current_atend.armacao?.altura_oe});
            }
            _lentes = Object.assign(_lentes, {receita: current_atend.receita, diagonal: current_atend.armacao.form.diag});
        } else {
            setPageLoading(false);
            return;
        }

        let data = await Req("api/AtendimentoLentes/Fabricantes", "POST", _lentes, user.token);
        current_element = "";
        setPageLoading(false);
        if(data.toString() !== "error") {
            setFabricantes(data);
        } else {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde ", "error");
        }
    }


    const LoadLentes = async(event, side) => {
        current_element = side === "direito" ? "lista_direito": (side === "esquerdo"?"lista_esquerdo":"") ;
        setPageLoading(true);

        let _lentes = {...lentes};
        let current_atend = await Hub.Get("current_atend", true);

        if(current_atend.receita && current_atend.armacao?.form) {
            if(current_atend.receita.distancia_focal === 3 && current_atend.receita.focal === 1) {
                _lentes = Object.assign(_lentes, {altura_od: current_atend.armacao?.altura_od, altura_oe: current_atend.armacao?.altura_oe});
            }

            _lentes = Object.assign(_lentes, {
                receita: current_atend.receita,
                tratamentos: current_atend?.anamnese?.tratamentos, 
                diagonal: current_atend.armacao.form.diag,
                ponte: current_atend.armacao.form.ponte,
                aro: current_atend.armacao.form.aro,
                tipo_armacao: current_atend.armacao.form.tipo,
                side: (side === "direito" ? 0 : 1)
            });
        } else {
            setPageLoading(false);
            return;
        }


        console.log(lentes)


        if(side === "direito") {
            direitoRef.current.Init(_lentes, {side: "OD"});
        } else {
            esquerdoRef.current.Init(_lentes, {side: "OE"});
        }
    }
    //#endregion Loaders


    //#region Handlers
    const _HandleSelect = async(event) => {
        let _lentes =  {...lentes };
        _lentes.materiais = {..._lentes.materiais, [event.target.id]: event.target.checked ? 1 : 0};
        setLentes(_lentes);

        //console.log(_lentes);

        let current_atend = await Hub.Get("current_atend", true);

        //console.log(current_atend.lentes);

        if(current_atend.lentes) {
            current_atend.lentes = {..._lentes};
        } else {
            current_atend = {...current_atend, lentes:_lentes}
        }

        await Hub.Update("current_atend", "lentes", _lentes, true);
        if(_lentes.olhos !== "") TimerLentes(_lentes);

        props.OnChange();
    }


    const _HandleOlho = async(event, index) => {
        let _lentes =  {...lentes};
        _lentes.olhos = index;
        setLentes(_lentes);

        let current_atend = await Hub.Get("current_atend", true);

        if(current_atend.lentes) {
            current_atend.lentes = {..._lentes};
        } else {
            current_atend = {...current_atend, lentes:_lentes}
        }

        if(index === 1 || index === 2) {
            window.$('.nav-tabs a[href="#olho_direito"]').tab('show');
            //window.$('.tab-pane a[href="#olho_esquerdo"]').tab('hide');
            //direitoRef.current.Init(_lentes, {side: "OD"});
        } else if(index === 3) {
            window.$('.nav-tabs a[href="#olho_esquerdo"]').tab('show');
            //window.$('.tab-pane a[href="#olho_direito"]').tab('hide');
            //direitoRef.current.Init(_lentes, {side: "OE"});
        }


        await Hub.Update("current_atend", "lentes", current_atend.lentes, true);
        TimerLentes();
        props.OnChange();
    }


    const TimerLentes = (flentes) => {
        //console.log(flentes)
        clearTimeout(timer_lentes);

        timer_lentes = setTimeout(async() => {
            LoadFabricantes(flentes);
        }, 700);
    }


    const _HandleLinha = async(event, side) => {
        let _lentes = {...lentes};
        _lentes[side][event.target.id] = event.target.checked ? 1 : 0;
        setLentes(_lentes);

        let current_atend = await Hub.Get("current_atend", true);

        if(current_atend.lentes) {
            current_atend.lentes = {..._lentes};
        } else {
            current_atend = {...current_atend, lentes:_lentes}
        }

        await Hub.Update("current_atend", "lentes", current_atend.lentes, true);
        props.OnChange();
    }


    const _HandleFabricante = async(event, side) => {
        let _lentes = {...lentes};
        _lentes[side]['fabricante_id'] = event.target.value;
        setLentes(_lentes);

        let current_atend = await Hub.Get("current_atend", true);

        if(current_atend.lentes) {
            current_atend.lentes = {..._lentes};
        } else {
            current_atend = {...current_atend, lentes:_lentes}
        }

        Hub.Update("current_atend", "lentes", current_atend.lentes, true);
    }


    const _HandlePromo = async(event, side) => {
        let _lentes = {...lentes};
        _lentes[side]['promo'] = event.target.checked ? 1 : 0;
        setLentes(_lentes);

        let current_atend = await Hub.Get("current_atend", true);

        if(current_atend.lentes) {
            current_atend.lentes = {..._lentes};
        } else {
            current_atend = {...current_atend, lentes:_lentes}
        }

        Hub.Update("current_atend", "lentes", current_atend.lentes, true);
    }


    const _HandlePreco = async(event, side, compl) => {
        let _lentes = {...lentes};
        //console.log(UnCurrency(event.target.value));
        _lentes[side]['venda_'+compl] = UnCurrency(event.target.value);
        setLentes(_lentes);

        let current_atend = await Hub.Get("current_atend", true);

        if(current_atend.lentes) {
            current_atend.lentes = {..._lentes};
        } else {
            current_atend = {...current_atend, lentes:_lentes}
        }

        Hub.Update("current_atend", "lentes", current_atend.lentes, true);
    }


    const _HandleReset = async(event, side, compl) => {
        let _lentes = {...lentes};
        _lentes[side]['venda_'+compl] = 0;
        setLentes(_lentes);

        let current_atend = await Hub.Get("current_atend", true);

        if(current_atend.lentes) {
            current_atend.lentes = {..._lentes};
        } else {
            current_atend = {...current_atend, lentes:_lentes}
        }

        Hub.Update("current_atend", "lentes", current_atend.lentes, true);
    }


    const _HandleSearch = (event, side) => {
        LoadLentes(event, side);
        props.OnChange();
    }


    const _HandleImageClick = async(index) => {
        let _lentes = {...lentes};
        let _campo_visual = _lentes.campo_visual || [];

        let _index = _campo_visual.findIndex(f => f === index) ;
        if(_index > -1) {
            _campo_visual.splice(_index, 1);
            _lentes.direito[index] = 0;
            _lentes.esquerdo[index] = 0;
        } else {
            _lentes.direito[index] = 1;
            _lentes.esquerdo[index] = 1;
            _campo_visual.push(index);
        }
        //console.log(_campo_visual)

        _lentes = {..._lentes, campo_visual: _campo_visual};
        setLentes(_lentes);

        let current_atend = await Hub.Get("current_atend", true);
        await Hub.Update("current_atend", "lentes", _lentes, true);
        props.OnChange();
    }


    const _HandleFirstSelect = async(event) => {
        let _lentes =  {...lentes, [event.target.id]: (event.target.checked?1:0) };
        setLentes(_lentes);

        let current_atend = await Hub.Get("current_atend", true);
        Hub.Update("current_atend", "lentes", _lentes, true);
    }


    const OnSaveLente = async(info, side) => {
        //console.log(info, side);

        let current_atend = await Hub.Get("current_atend", true);
        if(!current_atend?.os) {
            current_atend = {...current_atend, os: {od: "", oe:""}};
        }
    

        if(side === "OD") {
            current_atend.os.od = info;
        } else if(side === "OE"){
            current_atend.os.oe = info;
        }

        setOs(current_atend.os);
        await Hub.Update("current_atend", "os", current_atend.os, true);

        let _lentes = {...lentes};
        //console.log(side, current_atend.os.oe, current_atend.os.od, _lentes.olhos);

        if(side === "OD" && current_atend.os.oe === "" && (_lentes.olhos === 1 || _lentes.olhos === 3)) {
            document.querySelector("a[href='#olho_esquerdo']").click();
        } else if(side === "OE" && current_atend.os.od === "" && (_lentes.olhos === 1 || _lentes.olhos === 2)){
            document.querySelector("a[href='#olho_direito]").click();
        } else if((current_atend.os.oe && (_lentes.olhos === 1 || _lentes.olhos === 3))  || (current_atend.os.od && (_lentes.olhos === 1 || _lentes.olhos === 2))) {
            //document.querySelector("a[href='#acessorios']").click();
            props.OnNext();
        }
    }



     //#region AddCombo Handlers
     const _handleOpenModal = (title, img) => {
        setModalTitle({title: title, img: img});
        setShowModal(true);
    }


    const _handleCloseModal = () => {
        setShowModal(false);
    }
    //#endregion AddCombo Handlers
    //#endregion Handlers


    return (
        <div className="AtendimentoLentes">
            <br /> <br />

            <div className={'materiais'}>
                <center><h5>Selecione abaixo as indicações de materiais de lentes para sequência do atendimento</h5></center>
                <br />
                <div className="grid_selecao">
                    <div className="alert-white">
                        <div><input id="acrilico" type="checkbox" value="acrilico" checked={lentes?.materiais?.acrilico===1} onChange={(event) => _HandleSelect(event)} /></div>
                        <div><div>Acrílico</div></div>
                    </div>
                    <div className="alert-white">
                        <div><input id="resina" type="checkbox" value="resina" checked={lentes?.materiais?.resina===1} onChange={(event) => _HandleSelect(event)} /></div>
                        <div><div>Resina</div></div>
                    </div>
                    <div className="alert-white">
                        <div><input id="poly" type="checkbox" value="poly" checked={lentes?.materiais?.poly===1} onClick={(event) => _HandleSelect(event)} /></div>
                        <div><div>Policarbonato</div></div>
                    </div>
                    <div className="alert-white">
                        <div><input id="highlight" type="checkbox" value="highlight" checked={lentes?.materiais?.highlight===1} onChange={(event) => _HandleSelect(event)} /></div>
                        <div><div>Highlight</div></div>
                    </div>
                    <div className="alert-white">
                        <div><input id="mineral" type="checkbox" value="mineral" checked={lentes?.materiais?.mineral===1} onChange={(event) => _HandleSelect(event)} /></div>
                        <div><div>Mineral</div></div>
                    </div>
                    <div className="alert-white">
                        <div><input id="trivex" type="checkbox" value="trivex" checked={lentes?.materiais?.trivex===1} onChange={(event) => _HandleSelect(event)} /></div>
                        <div><div>Trivex</div></div>
                    </div>
                    <div className="alert-white-btn">
                        <ReactTooltip key={"tooltip0"} anchorId={"material_pop"} variant="info" place="top" content="Materiais das lentes"/>
                        <i id="material_pop" class="l_pop mdi mdi-shield-account-variant mdi-24px pointer" onClick={() => _handleOpenModal("Materiais das lentes", "material")}></i>&nbsp;&nbsp;

                        <ReactTooltip key={"tooltip1"} anchorId={"espessura_pop"} variant="info" place="top" content="Espessura das lentes"/>
                        <i id="espessura_pop" class="l_pop mdi mdi-wifi-strength-3 mdi-24px pointer" onClick={() => _handleOpenModal("Espessura das lentes", "espessura")}></i>
                    </div>
                </div>
                <br />
            </div>


            <div className={'campovisual ' + (isMultifocal?'':'hide')}>
                <hr />
                <br />
                    <center>
                        <h5>Selecione abaixo as indicações de campos de visão de lentes multifocais</h5>
                        <div className="check-label" style={{width:'300px',fontSize:'16px'}}>
                           <div><input id="first_multifocal" type="checkbox" value="1" checked={lentes.first_multifocal===1}  onChange={(event) => _HandleFirstSelect(event)} style={{width:'25px',height:'25px'}}/></div>
                           <div>Primeira vez que utiliza multifocal?</div> 
                        </div>
                    </center>
                 
                <div className={'multifocal'}>
                    {
                        ["d","c","b","a"].map(item => (
                            <div>
                                <img className={(Array.isArray(lentes.campo_visual) && lentes.campo_visual.findIndex(f => f === item) > -1? 'img-selected':'')} src={"/images/lentes/"+item+".jpg"} alt="" onClick={() => _HandleImageClick(item)}/>
                            </div>
                        ))
                    }
                </div>
                <br />
            </div>



            <div className={'olhos'}>
                <hr />
                <br />
                <center><h5>Selecione o tipo de pesquisa e os olhos envolvidos</h5></center>
                <br />
                <div className="grid_filtros">
                    <div className={'alert alert-white px-3 mb-0 '} role="alert" style={{backgroundImage:'url(/images/olhos/olho_ambos.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: 'right'}}>
                        <div>
                            <input id="olhos_1" type="checkbox" value="olhos_1" checked={lentes.olhos===1} onChange={(event) => _HandleOlho(event, 1)}/>
                        </div>

                        <div>
                            <div className="desc">
                                <span className="text-primary"><b>Ambos os olhos</b></span><br />
                                
                            </div>
                        </div>
                    </div>

                    <div className={'alert alert-white px-3 mb-0 '} role="alert" style={{backgroundImage:'url(/images/olhos/olho_direito.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: 'right'}}>
                        <div>
                            <input id="escolha_2" type="checkbox" value="olhos_2" checked={lentes.olhos===2} onChange={(event) => _HandleOlho(event, 2)}/>
                        </div>

                        <div>
                            <div className="desc">
                                <span className="text-primary"><b>Olho direito</b></span><br />
                            </div>
                        </div>
                    </div>



                    <div className={'alert alert-white px-3 mb-0 '} role="alert" style={{backgroundImage:'url(/images/olhos/olho_esquerdo.jpg)', backgroundRepeat: 'no-repeat', backgroundPosition: 'right'}}>
                        <div>
                            <input id="escolha_3" type="checkbox" value="olhos_3" checked={lentes.olhos===3} onChange={(event) => _HandleOlho(event, 3)}/>
                        </div>

                        <div>
                            <div className="desc">
                                <span className="text-primary"><b>Olho esquerdo</b></span><br />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>




            <div className={'olhos'}>
                <br />
                <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <li className={'nav-item olho_red ' + (lentes.olhos === 1 || lentes.olhos === 2?'':'hide')}>
                        <a lid="lid0" className={'nav-link active '} data-bs-toggle="tab" href="#olho_direito" role="tab">
                            <span className="d-none d-sm-block"> <i className="mdi mdi-arrow-right"></i> &nbsp; Olho direito  &nbsp;  </span> 
                        </a>
                    </li>
                    <li className={'nav-item olho_red ' + (lentes.olhos === 1 || lentes.olhos === 3?'':'hide')}>
                        <a lid="lid1" className="nav-link" data-bs-toggle="tab" href="#olho_esquerdo" role="tab">
                            <span className="d-none d-sm-block"> <i className="mdi mdi-arrow-left"></i> &nbsp; Olho esquerdo &nbsp;  </span> 
                        </a>
                    </li>
                </ul>

                

                <div className="tab-content _p-3 text-muted">
                    <div className="tab-pane active" id="olho_direito" role="tabpanel">
                        <div className={'lista_direito ' + (lentes.olhos === 1 || lentes.olhos === 2?'':'hide')}>

                            <div className="current_data font-size-15 mb-1 text-truncate">
                                {atends?.anamnese?.tratamentos?.length > 0 && (<span><b>Tratamentos:</b> </span>)}
                                {
                                    atends?.anamnese?.tratamentos?.length > 0 && atends?.anamnese?.tratamentos?.map((item, i) => (
                                        <>{tratamentos.find(t => t.tipo_tratamento_id===item)?.abrev} </>
                                    ))
                                }
                                {atends?.anamnese?.tratamentos?.length > 0 && (<span> &nbsp; <b> / </b> &nbsp; </span>)}

                                <span><b>Receita: </b> </span>  
                                <span>{receita?.focal} &nbsp; </span>
                                <span><b>ESF: </b> </span>
                                <span> {receita?.od_esf.toFixed(2).toString().replace(".", ",")} &nbsp; </span>
                                <span><b>CIL: </b> </span>
                                <span> {receita?.od_cil.toFixed(2).toString().replace(".", ",")} &nbsp; </span> 
                                <span><b>EIXO: </b> </span>
                                <span> {receita?.od_eixo} &nbsp; </span> 
                                <span><b>DNP: </b> </span>
                                <span> {receita?.od_dnp.toFixed(1).toString().replace(".", ",")} &nbsp; </span> 

                                <br />

                                <span><b>Armação: </b> </span>
                                <i>Ponte:</i> <span>{atends?.armacao?.form?.ponte}</span> &nbsp;-&nbsp; <i>Aro:</i> <span>{atends?.armacao?.form?.aro}</span> &nbsp;-&nbsp; <i>Diagonal:</i> <span>{atends?.armacao?.form?.diag}</span> &nbsp;-&nbsp; <i>Vertical:</i> <span>{atends?.armacao?.form?.altura}</span> &nbsp;-&nbsp;
                                <i>Cor:</i> <span>{atends?.armacao?.form?.cor}</span> &nbsp;-&nbsp; <i>Tipo:</i> <span>{armacao_tipo.find(a => a.id === parseInt(atends?.armacao?.form?.tipo))?.name}</span> &nbsp;-&nbsp;
                                <i>Material:</i> <span>{armacao_material.find(a => a.id === parseInt(atends?.armacao?.form?.material))?.name}</span> &nbsp;-&nbsp; <i>Formato:</i> <span>{armacao_formato.find(a => a.id === parseInt(atends?.armacao?.form?.formato))?.name}</span>
                            
                                <br />
                                <span><b>Diâmetro da lente: </b> </span>
                                <span> {receita?.diametro_calc} &nbsp; </span> 
                            </div>

                            <table className="table _table-bordered mb-0">
                                <thead>
                                    <tr>
                                        {/*<th style={{width:'150px'}}></th>*/}
                                        <th style={{width:'250px'}} className={(isMultifocal?'hide':'')}>Linha</th>
                                        <th>Fabricante</th>
                                        <th>Promoção</th>
                                        <th>Faixa de Preço</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        {/*<td style={{color:'#FFFFFF',backgroundColor:'#FF0000'}}>
                                            OLHO DIREITO
                                        </td>*/}

                                        <td className={(isMultifocal?'hide':'')}>
                                            <div className="grid_linha">
                                                <div className="alert-white">
                                                    <div><input id="a" type="checkbox" value="a" checked={lentes.direito.a===1} onChange={(event) =>_HandleLinha(event, 'direito')} disabled={lentes.campo_visual.findIndex(f => f === 'a') > -1}/></div>
                                                    <div>A</div>
                                                </div>

                                                <div className="alert-white">
                                                    <div><input id="b" type="checkbox" value="b" checked={lentes.direito.b===1} onChange={(event) => _HandleLinha(event, 'direito')} disabled={lentes.campo_visual.findIndex(f => f === 'b') > -1}/></div>
                                                    <div>B</div>
                                                </div>

                                                <div className="alert-white">
                                                    <div><input id="c" type="checkbox" value="c" checked={lentes.direito.c===1} onChange={(event) => _HandleLinha(event, 'direito')} disabled={lentes.campo_visual.findIndex(f => f === 'c') > -1}/></div>
                                                    <div>C</div>
                                                </div>

                                                <div className="alert-white">
                                                    <div><input id="d" type="checkbox" value="d" checked={lentes.direito.d===1} onChange={(event) => _HandleLinha(event, 'direito')} disabled={lentes.campo_visual.findIndex(f => f === 'd') > -1}/></div>
                                                    <div>D</div>
                                                </div>
                                            </div>
                                        </td>

                                        <td className="fabricante_td">
                                            <select id="direito_fabricante_id" className="fabricante form-control" value={lentes.direito.fabricante_id} onChange={(event) =>_HandleFabricante(event, 'direito')}>
                                                <option value="">Todos</option>
                                                {
                                                    Array.isArray(fabricantes) && fabricantes?.map((item, i) => (
                                                        <option key={'d_fabricante_'+i} value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </td>


                                        <td className="promo_td">
                                            <div className="alert-white">
                                                <div><input id="direito_c" type="checkbox" value="c" checked={lentes.direito.promo===1} onChange={(event) => _HandlePromo(event, 'direito')} /></div>
                                                <div>Ativo</div>
                                            </div>
                                        </td>

                                        <td className="preco_td">
                                            <div className="grid-preco">
                                                <div><center><span>De</span></center></div>
                                                <div><input id="direito_venda_ini" type="text" className="form-control"  value={Currency(lentes.direito.venda_ini)} onChange={(event) => _HandlePreco(event, 'direito', 'ini')} onDoubleClick={(event) => _HandleReset(event, 'direito', 'ini')}/></div>
                                                <div><center><span>Até</span></center></div>
                                                <div><input id="direito_venda_fim" type="text" className="form-control" value={Currency(lentes.direito.venda_fim)} onChange={(event) => _HandlePreco(event, 'direito', 'fim')} onDoubleClick={(event) => _HandleReset(event, 'direito', 'fim')}/></div>
                                            </div>
                                        </td>

                                        <td style={{padding:'8px 0 0 0',margin:0, width:'33px'}}>
                                            <button type="button" class="btn btn-sm btn-info " onClick={(event) => _HandleSearch(event, 'direito')}><i class="fa fa-search"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <br />

                            <div className="result_direito">
                                 <Lista ref={direitoRef} OnSave={OnSaveLente}/>
                            </div>
                        </div>      
                    </div>


                    <div className="tab-pane" id="olho_esquerdo" role="tabpanel">
                        <div className={'lista_esquerdo ' + (lentes.olhos === 1 || lentes.olhos === 3?'':'hide')}>
                        
                        <div className="current_data font-size-15 mb-1 text-truncate">
                                {atends?.anamnese?.tratamentos?.length > 0 && (<span><b>Tratamentos:</b> </span>)}
                                {
                                    atends?.anamnese?.tratamentos?.length > 0 && atends?.anamnese?.tratamentos?.map((item, i) => (
                                        <>{tratamentos.find(t => t.tipo_tratamento_id===item)?.abrev} </>
                                    ))
                                }
                                {atends?.anamnese?.tratamentos?.length > 0 && (<span> &nbsp; <b> / </b> &nbsp; </span>)}

                                <span><b>Receita: </b> </span>  
                                <span>{receita?.focal} &nbsp; </span>
                                <span><b>ESF: </b> </span>
                                <span> {receita?.oe_esf.toFixed(2).toString().replace(".", ",")} &nbsp; </span>
                                <span><b>CIL: </b> </span>
                                <span> {receita?.oe_cil.toFixed(2).toString().replace(".", ",")} &nbsp; </span> 
                                <span><b>EIXO: </b> </span>
                                <span> {receita?.oe_eixo} &nbsp; </span> 
                                <span><b>DNP: </b> </span>
                                <span> {receita?.oe_dnp.toFixed(1).toString().replace(".", ",")} &nbsp; </span> 

                                <br />

                                <span><b>Armação: </b> </span>
                                <i>Ponte:</i> <span>{atends?.armacao?.form?.ponte}</span> &nbsp;-&nbsp; <i>Aro:</i> <span>{atends?.armacao?.form?.aro}</span> &nbsp;-&nbsp; <i>Diagonal:</i> <span>{atends?.armacao?.form?.diag}</span> &nbsp;-&nbsp; <i>Vertical:</i> <span>{atends?.armacao?.form?.altura}</span> &nbsp;-&nbsp;
                                <i>Cor:</i> <span>{atends?.armacao?.form?.cor}</span> &nbsp;-&nbsp; <i>Tipo:</i> <span>{armacao_tipo.find(a => a.id === parseInt(atends?.armacao?.form?.tipo))?.name}</span> &nbsp;-&nbsp;
                                <i>Material:</i> <span>{armacao_material.find(a => a.id === parseInt(atends?.armacao?.form?.material))?.name}</span> &nbsp;-&nbsp; <i>Formato:</i> <span>{armacao_formato.find(a => a.id === parseInt(atends?.armacao?.form?.formato))?.name}</span>
                            
                                <br />
                                <span><b>Diâmetro da lente: </b> </span>
                                <span> {receita?.diametro_calc} &nbsp; </span> 
                            </div>
                          
                            <table className="table _table-bordered mb-0">
                                <thead>
                                    <tr>
                                        {/*<th style={{width:'150px'}}></th>*/}
                                        <th style={{width:'250px'}} className={(isMultifocal?'hide':'')}>Linha</th>
                                        <th>Fabricante</th>
                                        <th>Promoção</th>
                                        <th>Faixa de Preço</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        {/*<td style={{color:'#FFFFFF',backgroundColor:'#FF0000'}}>
                                            OLHO ESQUERDO
                                        </td>*/}

                                        <td className={(isMultifocal?'hide':'')}>
                                            <div className="grid_linha">
                                                <div className="alert-white">
                                                    <div><input id="a" type="checkbox" value="a" checked={lentes.esquerdo.a===1} onChange={(event) =>_HandleLinha(event, 'esquerdo')} disabled={lentes.campo_visual.findIndex(f => f === 'a') > -1}/></div>
                                                    <div>A</div>
                                                </div>

                                                <div className="alert-white">
                                                    <div><input id="b" type="checkbox" value="b" checked={lentes.esquerdo.b===1} onChange={(event) => _HandleLinha(event, 'esquerdo')} disabled={lentes.campo_visual.findIndex(f => f === 'b') > -1}/></div>
                                                    <div>B</div>
                                                </div>

                                                <div className="alert-white">
                                                    <div><input id="c" type="checkbox" value="c" checked={lentes.esquerdo.c===1} onChange={(event) => _HandleLinha(event, 'esquerdo')} disabled={lentes.campo_visual.findIndex(f => f === 'c') > -1}/></div>
                                                    <div>C</div>
                                                </div>

                                                <div className="alert-white">
                                                    <div><input id="d" type="checkbox" value="d" checked={lentes.esquerdo.d===1} onChange={(event) => _HandleLinha(event, 'esquerdo')} disabled={lentes.campo_visual.findIndex(f => f === 'd') > -1}/></div>
                                                    <div>D</div>
                                                </div>
                                            </div>
                                        </td>

                                        <td className="fabricante_td">
                                            <select id="esquerdo_fabricante_id" className="fabricante form-control" value={lentes.esquerdo.fabricante_id} onChange={(event) =>_HandleFabricante(event, 'esquerdo')}>
                                                <option value="">Todos</option>
                                                {
                                                    Array.isArray(fabricantes) && fabricantes?.map((item, i) => (
                                                        <option key={'e_fabricante_'+i} value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </td>


                                        <td className="promo_td">
                                            <div className="alert-white">
                                                <div><input id="esquerdo_c" type="checkbox" value="c" checked={lentes.esquerdo.promo===1} onChange={(event) => _HandlePromo(event, 'esquerdo')} /></div>
                                                <div>Ativo</div>
                                            </div>
                                        </td>

                                        <td className="preco_td">
                                            <div className="grid-preco">
                                                <div><center><span>De</span></center></div>
                                                <div><input id="esquerdo_venda_ini" type="text" className="form-control"  value={Currency(lentes.esquerdo.venda_ini)} onChange={(event) => _HandlePreco(event, 'esquerdo', 'ini')} onDoubleClick={(event) => _HandleReset(event, 'direito', 'ini')}/></div>
                                                <div><center><span>Até</span></center></div>
                                                <div><input id="esquerdo_venda_fim" type="text" className="form-control" value={Currency(lentes.esquerdo.venda_fim)} onChange={(event) => _HandlePreco(event, 'esquerdo', 'fim')} onDoubleClick={(event) => _HandleReset(event, 'direito', 'fim')}/></div>
                                            </div>
                                        </td>

                                        <td style={{padding:'8px 0 0 0',margin:0, width:'33px'}}>
                                        <button type="button" className="btn btn-sm btn-info " onClick={(event) => _HandleSearch(event, 'esquerdo')}><i className="fa fa-search"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <br />

                            <div className="result_esquerdo">
                                <Lista ref={esquerdoRef} OnSave={OnSaveLente}/>               
                            </div>
                        </div>
                    </div>


                </div>
            </div>


            
            <MDBModal show={showModal} setShow={setShowModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle><h4>{modalTitle.title}</h4></MDBModalTitle>
                        <Button className='btn btn-primary' onClick={_handleCloseModal} style={{width:'25px',height:'25px', padding:0}}>x</Button>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <img src={'/images/lentes/' + modalTitle.img +'.jpg'} alt="" />
                    </MDBModalBody>

                    <MDBModalFooter>
                            <Button variant="secondary" onClick={_handleCloseModal}>
                            Fechar
                            </Button>
                    </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>


            
        </div>
        )
});
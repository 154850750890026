import React, {useEffect} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const Redirect = () => {
    const navigate = useNavigate();
    let { Url } = useParams();


    useEffect(()=> {
        setTimeout(() => {
            navigate("/"+Url);
        }, 100)
    }, []);




    return (<>Loading</>)
}
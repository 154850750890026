/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Request, Req } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import ReactGA from 'react-ga';
import { Currency, UnCurrency, DateBrMoment } from '../../../scripts/StringUtils';
import { useNavigate, useParams } from 'react-router-dom';
import Barcode from 'react-barcode';
import moment from 'moment/moment';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import './View.css';


const statuses = [
    {id: 0, name:"LIBERADO PARA PEDIDO"},
    {id: 1, name:"AGUARDANDO LENTE E/OU ARO"},
    {id: 2, name:"MONTAGEM"},
    {id: 3, name:"PRONTA"},
    {id: 4, name:"ENTREGUE"},
    {id: 9, name:"CANCELADA"},
];


const forma_pagamento = [
    {id: 1, name: "Dinheiro"},
    {id: 2, name: "Pix"},
    {id: 3, name: "Cartão de Crédito"},
    {id: 4, name: "Cartão de Débito"}
]


const armacao_tipo = [
    {id: 1, name: "Fechada"},
    {id: 2, name: "Nylon"},
    {id: 3, name: "Parafusada"},
    {id: 4, name: "Solar"}
]


const armacao_material = [
    {id: 1, name: "Metal"},
    {id: 2, name: "Acetato"},
]


const armacao_formato = [
    {id: 1, name: "Aviador"},
    {id: 2, name: "Browline"},
    {id: 3, name: "Olho de gato"},
    {id: 4, name: "Oval"},
    {id: 5, name: "Quadrado"},
    {id: 6, name: "Redondo"},
    {id: 7, name: "Retangular"},
    {id: 8, name:"Wayfarer"}
]


export const OsView = () => {
    const component_name = "admina/os";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [atend, setAtend] = useState({});
    const [unidade, setUnidade]= useState({});
    const [osNumber, setOsNumber] = useState("000000");
    const [prazo, setPrazo] = useState("");
    const [osObs, setOsObs] = useState("");
    const [hora, setHora] = useState("");
    const [updated, setUpdated] = useState("");
    const [percent, setPercent] = useState(0);
    const [pagamento, setPagamento] = useState(1);
    const [status, setStatus] = useState(0);
    const [saved, setSaved] = useState(false);
    const [valores, setValores] = useState({compra:0, venda:0, total:0, tratamentos:0, desconto: 0});
    const [nome, setNome] = useState("");
    
    const JSBarcode = useRef();
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const atendRef = useRef({});


    let { Id } = useParams();


    //#region Effects
    useEffect(() => {
        if(!loadPrestineRef.current) {
            loadPrestineRef.current = true;
            WaitObject(user.nome).then(() => {
                Init();
                LoadUnidadeInfo();
            });
        }

        return () => {
            
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);
    //#region Effects


    //#region Init
    const Init = () => {
        LoadOs();
    }
    //#endregion Init



    //#region Loaders
    const LoadOs = async() => {
        let data = await Req("api/Os/Load?id=" + Id, "GET", "", user.token, 0*24*60*60*1000);

        if(data.toString() !== "error") {
            setOsNumber(data.numero);
            let atend = JSON.parse(data.stream);
            console.log(atend);

            setAtend(atend);
            setStatus(data.status);
            setNome(data.nome);
            setUpdated(data.date_updated);
            setPrazo(moment(data.date_prazo).format("DD/MM/YYYY"));
            setHora(moment(data.date_prazo).format("HH:mm"));
            setPagamento(atend?.forma_pagamento);

            let dt_ini = DateBrMoment(atend?.date);
            let dt_fim = moment(data.date_prazo);
            let diff_all = Math.abs(dt_ini.diff(dt_fim, 'days'));
            let diff_dt = Math.abs(dt_ini.diff(moment(), 'days'));
            let perc = Math.ceil(diff_dt * 100)/diff_all;
            if(perc > 100 ) perc = 100;
            setPercent(perc);
           
        } else {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde ", "error");
        }
    }


    const LoadUnidadeInfo = async() => {
        let data = await Req("api/AtendimentoLentes/Unidade?q=" + user.unidade_id, "GET", "", user.token, 0*24*60*60*1000);

        if(data.toString() !== "error") {
            setUnidade(data);
        } else {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde ", "error");
        }
    }

    //#endregion Loaders


    //#region Handlers
    const _HandlePrint = () => {
        window.$(".os_body").printThis({
            debug: false,                   // show the iframe for debugging
            importCSS: true,                // import parent page css
            importStyle: true,             // import style tags
            printContainer: true,           // grab outer container as well as the contents of the selector
            //loadCSS: "/assets/css/os_print.css",      // path to additional css file - use an array [] for multiple
            pageTitle: "ORDEM DE SERVIÇO",                  // add title to print page
            removeInline: false,            // remove all inline styles from print elements
            //removeInlineSelector: "body *", // custom selectors to filter inline styles. removeInline must be true
            printDelay: 333,                // variable print delay
            header: null,                   // prefix to html
            footer: null,                   // postfix to html
            base: false,                    // preserve the BASE tag, or accept a string for the URL
            formValues: true,               // preserve input/form values
            canvas: false,                  // copy canvas elements
            doctypeString: '...',           // enter a different doctype for older markup
            removeScripts: false,           // remove script tags from print content
            copyTagClasses: false,          // copy classes from the html & body tag
            beforePrintEvent: null,         // callback function for printEvent in iframe
            beforePrint: null,              // function called before iframe is filled
            afterPrint: null                // function called before iframe is removed
            });
    }


    const _HandlePrintCarne = () => {
        window.$(".os_content").hide();
        window.$(".carne_painel").show();

        window.$(".os_body").printThis({
            debug: false,                   // show the iframe for debugging
            importCSS: true,                // import parent page css
            importStyle: true,             // import style tags
            printContainer: true,           // grab outer container as well as the contents of the selector
            //loadCSS: "/assets/css/os_print.css",      // path to additional css file - use an array [] for multiple
            pageTitle: "CARNÊ - PAGAMENTOS",                  // add title to print page
            removeInline: false,            // remove all inline styles from print elements
            //removeInlineSelector: "body *", // custom selectors to filter inline styles. removeInline must be true
            printDelay: 333,                // variable print delay
            header: null,                   // prefix to html
            footer: null,                   // postfix to html
            base: false,                    // preserve the BASE tag, or accept a string for the URL
            formValues: true,               // preserve input/form values
            canvas: false,                  // copy canvas elements
            //doctypeString: '...',           // enter a different doctype for older markup
            removeScripts: false,           // remove script tags from print content
            copyTagClasses: false,          // copy classes from the html & body tag
            beforePrintEvent: null,         // callback function for printEvent in iframe
            beforePrint: null,              // function called before iframe is filled
            afterPrint: null                // function called before iframe is removed
        });


        setTimeout(() => {
            window.$(".os_content").show();
            window.$(".carne_painel").hide();
        }, 500);
        
    }


    const _HandleStatus = async(status) => {
        let data = await Req("api/Os/Status", "POST", {os_id: Id, status: status}, user.token, 0*24*60*60*1000);

        if(data.toString() !== "error") {
            setStatus(status);
        } else {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde ", "error");
        }
    }


    const _HandleLogoError = (e) => {
        window.jQuery("#elogo").attr("src","/assets/images/os_logo_0.jpg");
    }
    //#endregion Handlers


    //#region Whatsapp
    const _HandleWhatsapp = async() => {
        if(atend?.cliente?.celular) {
            let cel = atend?.cliente?.celular.replace('-','').replace('(','').replace(')','').replace('.','').replace(' ','');

            if(cel.substr(0,2) !== "55") cel = "55" + cel;

            if(cel.length < 13) {
                window.swal("Alerta", "Número de Whatsapp inválido", "error");
                return;
            }


 
            const quality = 1;
            html2canvas(document.querySelector('#os_body'),
                { scale: 1 }
            ).then(async canvas => {
                const pdf = new jsPDF();
                pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, canvas.width*0.2, canvas.height*0.2);
                //pdf.save();
    
                
                let blobPDF = new Blob([pdf.output('blob')], { type: 'application/pdf'});
                let blobUrl = URL.createObjectURL(blobPDF);
    

                let base64 = await convertBase64(blobPDF);
                let files_list = [];
                files_list.push({ "name": osNumber + ".pdf", "size": blobPDF.size, "type": blobPDF.type, "base64":  base64 });
    
                let _contacts = [];
                _contacts.push( {number: cel, name: atend?.cliente?.nome, message: "Olá " + atend?.cliente?.nome + ", ordem de serviço Nº " + osNumber +  " emitida em: " + unidade?.nome + ", " + unidade?.endereco + " " + unidade?.numero + " - " + unidade?.bairro + " " + unidade?.cidade + "/" + unidade?.uf})
                
            
                //console.log(files_list[0]);

                let event = new CustomEvent('Event', { 'detail': {
                    message:"Olá @name, seu pedido está pronto para retirada ", 
                    attachments:[], 
                    files: files_list, 
                    list: _contacts
                }});
                console.log(event);
                event.initEvent('whatsnap_api_broadcast');
                document.dispatchEvent(event);

            });
        }


    }
    //#endregion Whatsapp


    //#region Functions
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            try {
                fileReader.readAsDataURL(file);
            } catch(e) {
                console.log("try", e);
                reject(e);
            }
    
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
    
            fileReader.onerror = (error) => {
                console.log(error);
                reject(error);
            };
        });
    };
    //#endregion Functions


    return (
        
        <div className="main-content Atendimento">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title left">Ordem de Serviço </h4>
                                </div>

                                <div className="card-body pb-2">
                                <div className="AtendimentoFinalizar">
                                 
                                    <div className="row">
                                        <div className="col col-sm-4">
                                            <div className="card pricing-box">
                                                <div className="card-body p-4">
                                                    <div className="text-center">
                                                        <h5 className="mb-1 font-size-20">Valor Total</h5>
                                                        <div className="pt-4 pb-2">
                                                            <h2 className="month" style={{color:'red'}}>{Currency(
                                                                    (atend?.os?.oe?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                                                    (atend?.os?.od?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                                                    (atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0)+
                                                                    (atend?.os?.od?.lente?.venda/2||0)+(atend?.os?.oe?.lente?.venda/2||0)+
                                                                    (atend?.os?.od?.lente?.color?.valor||0)+(atend?.os?.oe?.lente?.color?.valor||0)+
                                                                    (UnCurrency(atend?.armacao?.form?.venda)||0)-UnCurrency(atend?.desconto||0)
                                                                )}</h2>

                                                        <span className={'' + (!atend?.parcelas||atend?.parcelas <= 1 ? ' _hide': '')}>
                                                            <b>Parcelas: {atend?.parcelas}x {Currency(
                                                                ((atend?.os?.oe?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                                                (atend?.os?.od?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                                                (atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0)+
                                                                (atend?.os?.od?.lente?.venda/2||0)+(atend?.os?.oe?.lente?.venda/2||0)+
                                                                (atend?.os?.od?.lente?.color?.valor||0)+(atend?.os?.oe?.lente?.color?.valor||0)+
                                                                (UnCurrency(atend?.armacao?.form?.venda)||0)-(atend?.desconto||0)-(atend?.entrada||0)) / (atend?.parcelas)
                                                            )}</b>
                                                        </span> 
                                                        </div>
                                                    
                                                        <p className="text-muted">Confira as opções de pagamento e parcelamento</p>
                                                    </div>

                                                        <hr className="my-4 text-muted" />
                                                        
                                                        <ul className="list-unstyled pricing-features mb-0 ps-4">
                                                            <li><i className="uil uil-check-circle text-success font-size-18 align-middle me-2"></i> <b>{Currency(atend?.armacao?.form?.venda||0)}</b>  - Armação </li>
                                                            <li><i className="uil uil-check-circle text-success font-size-18 align-middle me-2"></i> <b>{Currency((atend?.os?.od?.lente?.venda/2||0)+(atend?.os?.oe?.lente?.venda/2||0))}</b>  - Lentes</li>
                                                            <li><i className="uil uil-check-circle text-success font-size-18 align-middle me-2"></i> <b>{Currency((atend?.os?.od?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+(atend?.os?.oe?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0))}</b>  - Tratamentos </li>
                                                            <li><i className="uil uil-check-circle text-success font-size-18 align-middle me-2"></i> <b>{Currency((atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0))}</b>  - Acessórios </li>
                                                            <li><i className="uil uil-check-circle text-success font-size-18 align-middle me-2"></i> <b>{Currency((atend?.os?.od?.lente?.color?.valor||0)+(atend?.os?.oe?.lente?.color?.valor||0))}</b>  - Colorações </li>    

                                                        </ul>

                                                        <hr className="my-4 text-muted" />  


                                                        <div className="position-relative">
                                                            Entrada Valor
                                                            <input id="entrada" type="text" value={Currency(atend?.entrada)} className="form-control rounded bg-light border-0" disabled/>
                                                        </div>

                                                        <br />                  
                                                        <div className="position-relative">
                                                            Desconto
                                                            <input id="desconto" type="text" value={Currency(atend?.desconto)} className="form-control rounded bg-light border-0" disabled />
                                                        </div>

                                                        <br />
                                                        <div className="position-relative">
                                                            Parcelas
                                                            <input id="parcelas" type="number" value={atend?.parcelas} className="form-control rounded bg-light border-0" min={1} max={10} step={1} disabled />
                                                        </div>

                                                        <br />
                                                        <div className="position-relative">
                                                            Forma de pagamento
                                                            <select className="form-control rounded bg-light border-0" value={pagamento} disabled>
                                                                {forma_pagamento && forma_pagamento.map((item, i) => (
                                                                    <option value={item.id}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>

                                                        <br />
                                                       
                                                        <div className="card border">
                                                            <div className="card-body">
                                                                <div className="">
                                                                    <div className="dropdown float-end">
                                                                        <a className="text-muted dropdown-toggle font-size-16" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                                            <i className="bx bx-dots-vertical-rounded font-size-20"></i>
                                                                        </a>
                                                                        {(user?.nivel <= 3) && (
                                                                        <div className="dropdown-menu dropdown-menu-end">
                                                                            <a className="dropdown-item pointer" onClick={() => {_HandleStatus(0)}}>Liberado para Pedido</a>
                                                                            <a className="dropdown-item pointer" onClick={() => {_HandleStatus(1)}}>Aguardando Lente e/ou Aro</a>
                                                                            <a className="dropdown-item pointer" onClick={() => {_HandleStatus(2)}}>Montagem</a>
                                                                            <a className="dropdown-item pointer" onClick={() => {_HandleStatus(3)}}>Pronta</a>
                                                                            <a className="dropdown-item pointer" onClick={() => {_HandleStatus(4)}}>Entregue</a>
                                                                            <a className="dropdown-item pointer" onClick={() => {_HandleStatus(9)}}>Cancelado</a>
                                                                        </div>
                                                                        )} 
                                                                    </div>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="avatar align-self-center me-3">
                                                                            <div className={'avatar-title rounded bg-soft-'+(percent<30?'info':(percent<75?'warning':(status===0?'danger':'success')))+' text-'+(percent<30?'info':(percent<75?'warning':(status===0?'danger':'success')))+' font-size-24'}>
                                                                                <i className={(percent<30?'mdi mdi-calendar-clock':(percent<75?'mdi mdi-calendar-refresh':(status===0?'mdi mdi-calendar-cursor':'mdi mdi-calendar-check-outline')))}></i>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex-1">
                                                                            <h5 className="font-size-15 mb-1">{statuses[statuses.findIndex(s => s.id === status)]?.name}</h5>
                                                                            <a className="font-size-13 text-muted"><u>Status</u></a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-3 pt-1">
                                                                        <div className="d-flex justify-content-between">
                                                                            <p className="text-muted font-size-13 mb-1">{atend?.date}</p>
                                                                            <p className="text-muted font-size-13 mb-1">{prazo}</p>
                                                                        </div>
                                                                        <div className="progress animated-progess custom-progress">
                                                                            <div className={'progress-bar bg-gradient bg-'+(percent<30?'info':(percent<75?'warning':(status===0?'danger':'success')))} role="progressbar" style={{width: percent + '%'}} aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100">
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="mt-3">
                                                                        <p className="mb-0 text-muted"><i className="mdi mdi-clock me-1"></i> Última modificação: {updated}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                            
                                                        
                                                        
                                                      
                                                    
                                                    </div>
                                                </div>
                                            </div>

                                        <div className="col col-sm-8 devgrid pricing-box">
                                            <div className={'OS'}>
                                                <div id="os_body" className={'os_body'}>
                                                    <div className={'os_painel'}>
                                                        <div className={'os_unidade'}>
                                                            <div>
                                                                <img id="elogo" src={"/assets/images/os_logo_"+ (user?.rede_id?user?.rede_id:0) + ".jpg"} alt="" onError={_HandleLogoError}/>
                                                            </div>
                                                            <div className="os_address">
                                                                {unidade?.endereco}, {unidade?.numero} - {unidade?.bairro}<br />
                                                                {unidade?.cidade} / {unidade?.uf} - Fone: {unidade?.telefone}<br />
                                                                {unidade?.nome} - {unidade?.complemento}<br />
                                                            </div>
                                                            <div className="os_number">
                                                                OS: <b><span>{osNumber}</span></b>
                                                            </div>
                                                        </div>

                                                        <div className="os_datas">
                                                            Data: <span>{atend?.date}</span> - Data de Retirada: <span>{prazo}</span> - Horário: <span>{hora}</span> <br />
                                                            Vendedor(a): <span>{nome!==''?nome:user?.nome}</span>
                                                        </div>

                                                        <div className={'os_note'}>
                                                            <div className={'os_note_obs'}>
                                                                <textarea id="obs" value={atend?.os?.obs} disabled></textarea>
                                                            </div>

                                                            <div className={'os_note_barcode'}>
                                                                <Barcode value={osNumber}  height={50} displayValue={false} width={2} margin={0}/>
                                                            </div>
                                                        </div>
                                                    </div>




                                                    <hr className='hr_separator' />




                                                    <div className={'os_painel os_content'}>
                                                        <div className={'os_head'}>
                                                            <div className={'os_head_title'}>
                                                                <div><span>ORDEM DE SERVIÇO</span></div>
                                                                <div>{atend?.date}</div>
                                                                <div>OS: <span>{osNumber}</span></div>
                                                            </div>
                                                            <div className="os_stakeholders os_datas">
                                                                Cliente(a): <span>{atend?.cliente?.nome}</span> &nbsp;-&nbsp; CPF: <span>{atend?.cliente?.cpf}</span><br />
                                                                Telefone: <span>{atend?.cliente?.telefone}</span> &nbsp;-&nbsp; Celular: <span>{atend?.cliente?.celular}</span><br />
                                                                Vendedor(a): <span>{user.nome}</span> &nbsp;-&nbsp; Loja: <span>{unidade?.nome}</span>
                                                            </div>
                                                            <div className="os_datas os_retorno bt">
                                                                Retorno: <span>{DateBrMoment(prazo).add(-1, 'days').format('DD/MM/YYYY')}</span> &nbsp; Hora: <span>{atend?.os?.hora}</span> &nbsp;-&nbsp; Entrega: <span>{prazo}</span> &nbsp; Hora: <span>{hora}</span>
                                                            </div>
                                                        </div>

                                                        <div className={'os_receita bt'}>
                                                            <div><span>RECEITA</span></div>
                                                            <div>
                                                                <table className="table ">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className={'td_bottom'} colSpan={2}></th>
                                                                            <th className={'th_null'} >esf.</th>
                                                                            <th className={'th_null'} >cil.</th>
                                                                            <th className={'th_null'} >eixo</th>
                                                                            <th className={'th_null'} >dnp</th>
                                                                            <th className={'th_null'} >adi.</th>
                                                                            <th className={'th_null'} >alt.</th>
                                                                        </tr>
                                                                    </thead>
                                                                    
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className={'td_dist td'} rowSpan={2}>LONGE</td>
                                                                            <td className={'td'}>OD</td>
                                                                            <td className={'td'}>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_od_esf}</td>
                                                                            <td className={'td'}>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_od_cil}</td>
                                                                            <td className={'td'}>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_od_eixo}</td>
                                                                            <td className={'td'}>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_od_dnp}</td>
                                                                            <td className={'td'}>{atend?.receita?.distancia_focal!==3?'':atend?.receita?.longe_od_adc}</td>
                                                                            <td className={'td'}>{atend?.armacao?.altura_od===0?'':atend?.armacao?.altura_od}</td>
                                                                        </tr>
                                                                        <tr>
                                                                        
                                                                            <td className={'td'}>OE</td>
                                                                            <td className={'td'}>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_oe_esf}</td>
                                                                            <td className={'td'}>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_oe_cil}</td>
                                                                            <td className={'td'}>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_oe_eixo}</td>
                                                                            <td className={'td'}>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_oe_dnp}</td>
                                                                            <td className={'td'}>{atend?.receita?.distancia_focal===2?'':atend?.receita?.distancia_focal!==3?'':atend?.receita?.longe_oe_adc}</td>
                                                                            <td className={'td'}>{atend?.armacao?.altura_oe===0?'':atend?.armacao?.altura_oe}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td className={'td_dist td'} rowSpan={2}>PERTO</td>
                                                                            <td className={'td'}>OD</td>
                                                                            <td className={'td'}>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_od_esf}</td>
                                                                            <td className={'td'}>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_od_cil}</td>
                                                                            <td className={'td'}>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_od_eixo}</td>
                                                                            <td className={'td'}>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_od_dnp}</td>
                                                                            <td className={'td'}>{atend?.receita?.perto_od_adc}</td>
                                                                            <td className={'td'}></td>
                                                                        </tr>
                                                                        <tr>   
                                                                            <td className={'td'}>OE</td>
                                                                            <td className={'td'}>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_oe_esf}</td>
                                                                            <td className={'td'}>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_oe_cil}</td>
                                                                            <td className={'td'}>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_oe_eixo}</td>
                                                                            <td className={'td'}>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_oe_dnp}</td>
                                                                            <td className={'td'}>{atend?.receita?.perto_oe_adc}</td>
                                                                            <td className={'td'}></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                        <div className={'os_datas os_medico bt'}>
                                                            MÉDICO: <span>{atend?.medico?.nome}</span> &nbsp;-&nbsp; TEL: <span>{(atend?.medico?.telefone!==''?atend?.medico?.telefone:atend?.medico?.celular)}</span>
                                                        </div>

                                                        <div className={'os_armacao bt'}>
                                                            <div><span>ARMAÇÃO</span></div>
                                                            <div>{atend?.armacao?.form?.marca} - {atend?.armacao?.form?.modelo} <span className="right">{Currency(atend?.armacao?.form?.venda)}</span></div>
                                                            <div>
                                                                Ponte: <span>{atend?.armacao?.form?.ponte}</span> &nbsp;-&nbsp; Aro: <span>{atend?.armacao?.form?.aro}</span> &nbsp;-&nbsp; Diagonal: <span>{atend?.armacao?.form?.diag}</span> &nbsp;-&nbsp; Vertical: <span>{atend?.armacao?.form?.altura}</span>
                                                            </div>
                                                            <div>
                                                                Cor: <span>{atend?.armacao?.form?.cor}</span> &nbsp;-&nbsp; Tipo: <span>{armacao_tipo.find(a => a.id === parseInt(atend?.armacao?.form?.tipo))?.name}</span> <br /> Material: <span>{armacao_material.find(a => a.id === parseInt(atend?.armacao?.form?.material))?.name}</span> &nbsp;-&nbsp; Formato: <span>{armacao_formato.find(a => a.id === parseInt(atend?.armacao?.form?.formato))?.name}</span>
                                                            </div>
                                                        </div>

                                                        <div className={'os_lentes bt'}>
                                                            <div><span>LENTES</span></div>
                                                            <div>OD: <span>{atend?.os?.od?.lente?.nome}</span> <span className="right">{Currency(atend?.os?.od?.lente?.venda/2)}</span></div>
                                                            <div>OE: <span>{atend?.os?.oe?.lente?.nome}</span> <span className="right">{Currency(atend?.os?.oe?.lente?.venda/2)}</span></div>
                                                        </div>


                                                        <div className={'os_tratamentos bt '+ ((Array.isArray(atend?.os?.od?.tratamentos) && atend?.os?.od?.tratamentos.length > 0) || (Array.isArray(atend?.os?.oe?.tratamentos) && atend?.os?.oe?.tratamentos.length > 0) ?'':'hide')}>
                                                            <div><span>TRATAMENTOS</span></div>
                                                            {
                                                                Array.isArray(atend?.os?.od?.tratamentos) && atend?.os?.od?.tratamentos.length > 0 && atend?.os?.od?.tratamentos.map((item, i) => (
                                                                    <div><b>OD:</b> {item.titulo} <small className={(item.tipo_id!==2?'hide':'hide')}>({Currency(item.valor)})</small> <span className={'right ' + (item.tipo_id===2?'hide':'')}>{Currency(item.valor/2)}</span></div>
                                                                ))
                                                            }

                                                            {
                                                                Array.isArray(atend?.os?.oe?.tratamentos) && atend?.os?.oe?.tratamentos.length > 0 && atend?.os?.oe?.tratamentos.map((item, i) => (
                                                                    <div><b>OE:</b> {item.titulo} <small className={(item.tipo_id!==2?'hide':'hide')}>({Currency(item.valor)})</small> <span className={'right ' + (item.tipo_id===2?'hide':'')}>{Currency(item.valor/2)}</span></div>
                                                                ))
                                                            }
                                                        </div>


                                                        <div className={'os_acessorios bt ' + (Array.isArray(atend?.acessorios) && atend?.acessorios.length > 0?'':'hide')}>
                                                            <div><span>ACESSORIOS</span></div>
                                                            {
                                                                Array.isArray(atend?.acessorios) && atend?.acessorios.length > 0 && atend?.acessorios.map((item, i) => (
                                                                    <div>{item.modelo} <span className="right">{Currency(item.venda)}</span></div>
                                                                ))
                                                            }
                                                        </div>


                                                        <div className={'os_coloracaoes bt ' + (atend?.os?.od?.lente?.color || atend?.os?.oe?.lente?.color?'':'hide')}>
                                                            <div><span>COLORAÇÕES</span></div>
                                                            {
                                                                atend?.os?.od?.lente?.color && (<div>OD: <span>{atend?.os?.od?.lente?.color?.name}</span> <span className="right">{Currency(atend?.os?.od?.lente?.color?.valor)}</span></div>)
                                                            }

                                                            {
                                                                atend?.os?.oe?.lente?.color && (<div>OE: <span>{atend?.os?.oe?.lente?.color?.name}</span> <span className="right">{Currency(atend?.os?.oe?.lente?.color?.valor)}</span></div>)
                                                            }
                                                        </div>


                                                        <div className={'os_total bt'}>
                                                        
                                                            <div>&nbsp;<span className="right"> TOTAL: {Currency(
                                                                (atend?.os?.oe?.tratamentos?.reduce((p, c) => p +  (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                                                (atend?.os?.od?.tratamentos?.reduce((p, c) => p +  (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                                                (atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0)+
                                                                (atend?.os?.od?.lente?.venda/2||0)+(atend?.os?.oe?.lente?.venda/2||0)+
                                                                (atend?.os?.od?.lente?.color?.valor||0)+ (atend?.os?.oe?.lente?.color?.valor||0)+
                                                                (UnCurrency(atend?.armacao?.form?.venda)||0)-(atend?.desconto||0)
                                                            )}</span></div>

                                                            <div className={'' + (!atend?.desconto||atend?.desconto === 0 ? ' hide': '')}>
                                                                &nbsp;<span className="right">Desconto: {Currency(atend?.desconto)}</span>
                                                            </div>


                                                            <div className={'' + (!atend?.parcelas||atend?.parcelas <= 1 ? ' hide': '')}>
                                                                &nbsp;<span className="right">Parcelas: {atend?.parcelas}x {Currency(
                                                                    ((atend?.os?.oe?.tratamentos?.reduce((p, c) => p +  (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                                                    (atend?.os?.od?.tratamentos?.reduce((p, c) => p +  (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                                                    (atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0)+
                                                                    (atend?.os?.od?.lente?.venda/2||0)+(atend?.os?.oe?.lente?.venda/2||0)+
                                                                    (atend?.os?.od?.lente?.color?.valor||0)+ (atend?.os?.oe?.lente?.color?.valor||0)+
                                                                    (UnCurrency(atend?.armacao?.form?.venda)||0)-(atend?.desconto||0)-(atend?.entrada||0)) / (atend?.parcelas)
                                                                )}</span>
                                                            </div>

                                                            <div>
                                                                &nbsp;<span className="right">Pagamento: {forma_pagamento.find(f => f.id === atend?.forma_pagamento)?.name}</span>
                                                            </div>
                                                        </div>


                                                        <div className={'os_alert bt'}>
                                                            <div>Autorizo a execução desta ordem de serviço e declaro ter conferido todos os ítens constantes dela. Também confirmo ter sido orientado quanto as advertências impressas nessa mesma ordem de serviço, alertando sobre possiveis dificuldades de adaptação do uso do óculos. </div>
                                                            <br /><br /><br /><br />
                                                            <center>
                                                                ________________________________________________________<br />
                                                                <span style={{fontSize:'15px'}}>{atend?.cliente?.nome}</span><br />
                                                                <div className={(atend?.armacao?.form?.aro>atend?.armacao?.tamanho_sugerido)?'':'hide'}>Tamanho da armação maior que o tamanho sugerido</div>
                                                            </center>
                                                        </div>
                                                        

                                                        <div className={'os_barcode bt'}>
                                                            <div>&nbsp;<span className="right"><Barcode value={osNumber}  height={50} displayValue={false} width={2} margin={0}/></span></div>
                                                            <div className="clear"></div>
                                                        </div>
                                                    </div>


                                                    <div className={'carne_painel hide'}>
                                                        <div className={'carne_content'}>
                                                            <br />
                                                            <div className="carne_title"><span>CARNÊ - PARCELAS</span></div>
                                                            <br />
                                                            {
                                                                atend?.parcelas && Array(atend?.parcelas).fill(1).map((x, y) => x + y).map((item, i) => (
                                                                    <>
                                                                    <div className="carne_parcela clear">
                                                                        <div className="clear">
                                                                            <span className="carne_parcela_title">PARCELA <b>{item}/{atend?.parcelas} &nbsp; </b> </span>
                                                                            
                                                                            <span className="carne_parcela_valor right">Valor <b>{Currency(
                                                                                ((atend?.os?.oe?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                                                                (atend?.os?.od?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                                                                (atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0)+
                                                                                (atend?.os?.od?.lente?.venda/2||0)+(atend?.os?.oe?.lente?.venda/2||0)+
                                                                                (atend?.os?.od?.lente?.color?.valor||0)+ (atend?.os?.oe?.lente?.color?.valor||0)+
                                                                                (UnCurrency(atend?.armacao?.form?.venda)||0)-(atend?.desconto||0)-(atend?.entrada||0)) / (atend?.parcelas)
                                                                            )}</b></span> 
                                                                        </div> 

                                                                        <div className="carne_parcela_vencimento"> 
                                                                            <span className="right">Vencimento: <b>{moment(atend?.carne_date).add((item-1), 'month').format('DD/MM/YYYY')}</b></span>
                                                                        </div>
                                                                        <div className="carne_parcela_ass_line">
                                                                            <span>______________________________________________</span>
                                                                        </div>

                                                                        <div className="carne_parcela_ass">
                                                                            <span> Assinatura</span>
                                                                        </div>
                                                                        
                                                                        <hr />
                                                                        
                                                                    </div>
                                                                    </>
                                                                ))
                                                            }      
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="mt-4">
                                                    <center>
                                                        <div className={'btn-group btn-group-lg '} role="group" >
                                                            <a className={'pointer btn btn-outline-primary '} onClick={_HandlePrint}><i className="fa fa-print"></i> Imprimir</a>
                                                            {atend?.forma_pagamento === 5 && (<a className={'pointer btn btn-outline-primary '} onClick={_HandlePrintCarne}><i className="fa fa-print"></i> Carnê</a>)}
                                                            <button className={'btn btn-primary '} onClick={_HandleWhatsapp} style={{backgroundColor:'#128C7E',border:'0px'}}><i className="mdi mdi-whatsapp mdi-18px" style={{padding:'0 !important',margin:'0 !important'}}></i> </button>
                                                        </div>     
                                                    </center>
                                                </div>
                                            </div>
                            
                                        

                                            <br />
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>


        

 
    )
       
}
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Context } from '../../Context/AuthContext';
import { HubContext } from '../../Context/HubContext.js';
import { Request, Req } from '../../scripts/Request';
import { WaitObject } from '../../scripts/Wait';
import ReactGA from 'react-ga';
import { Currency, UnCurrency, DateBrMoment } from '../../scripts/StringUtils';
import Barcode from 'react-barcode';
import moment from 'moment/moment';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import './index.css';


const forma_pagamento = [
    {id: 1, name: "Dinheiro"},
    {id: 2, name: "Pix"},
    {id: 3, name: "Cartão de Crédito"},
    {id: 4, name: "Cartão de Débito"},
    {id: 5, name: "Carnê"},
]

const armacao_tipo = [
    {id: 1, name: "Fechada"},
    {id: 2, name: "Nylon"},
    {id: 3, name: "Parafusada"},
    {id: 4, name: "Solar"}
]


const armacao_material = [
    {id: 1, name: "Metal"},
    {id: 2, name: "Acetato"},
]


const armacao_formato = [
    {id: 1, name: "Aviador"},
    {id: 2, name: "Browline"},
    {id: 3, name: "Olho de gato"},
    {id: 4, name: "Oval"},
    {id: 5, name: "Quadrado"},
    {id: 6, name: "Redondo"},
    {id: 7, name: "Retangular"},
    {id: 8, name:"Wayfarer"}
]


export const OS = forwardRef((props, ref) => {
    const component_name = "admin/os";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [atend, setAtend] = useState({});
    const [unidade, setUnidade]= useState({});
    const [osNumber, setOsNumber] = useState("000000");
    const [prazo, setPrazo] = useState("");
    const [osObs, setOsObs] = useState("");
    const [hora, setHora] = useState("");
    const [saved, setSaved] = useState(false);
    const [valores, setValores] = useState({compra:0, venda:0, total:0, tratamentos:0, desconto: 0});
    const [blocked, setSBlocked] = useState(false);
    
    const JSBarcode = useRef();
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const atendRef = useRef({});



    //#region Effects
    useEffect(() => {
        if(!loadPrestineRef.current) {
            loadPrestineRef.current = true;
            WaitObject(user.nome).then(() => {
                Init();
                LoadUnidadeInfo();
            });
        }

        return () => {
            
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);
    //#region Effects


    //#region Init
    const Init = async() => {
        let current_atend = await Hub.Get("current_atend", true);
        if(!current_atend) return;
        
        setAtend(current_atend);

        if(current_atend?.os_id > 0) {
            setSaved(true);
        } else {
            setSaved(false);
        }

        if(current_atend?.os_numero) setOsNumber(current_atend?.os_numero);

        let _hora = moment().format("HH:mm");
        setHora(_hora);
        
        
        let dt_od = undefined;
        let dt_oe = undefined;

        if(current_atend?.os?.od?.prazo && current_atend?.os?.od?.prazo !== "") {
            dt_od = DateBrMoment(current_atend?.os?.od?.prazo);
        }

        if(current_atend?.os?.oe?.prazo && current_atend?.os?.oe?.prazo !== "") {
            dt_oe = DateBrMoment(current_atend?.os?.oe?.prazo);
        }

        //console.log(current_atend?.os?.od?.prazo, current_atend?.os?.oe?.prazo, dt_od.format('YYYY-MM-DD HH:mm:ss'), dt_oe.format('YYYY-MM-DD HH:mm:ss'));

        let add_color_prazo = 0;
        if(current_atend?.os?.od?.lente?.color?.prazo || current_atend?.os?.oe?.lente?.color?.prazo) {
            add_color_prazo = Math.max((current_atend?.os?.od?.lente?.color?.prazo||0), (current_atend?.os?.oe?.lente?.color?.prazo||0));
            console.log(add_color_prazo);
        }

        //console.log(dt_od, dt_oe, dt_od.add(add_color_prazo, 'days').format('YYYY-MM-DD HH:mm:ss'), dt_oe.add(add_color_prazo, 'days').format('YYYY-MM-DD HH:mm:ss'));

        let date_prazo;
        if(dt_od && !dt_oe) {
            date_prazo = dt_od.add(add_color_prazo, 'days').format('YYYY-MM-DD HH:mm:ss');
            //setPrazo(current_atend?.os?.od?.prazo);
        } else if(!dt_od && dt_oe) {
            date_prazo = dt_oe.add(add_color_prazo, 'days').format('YYYY-MM-DD HH:mm:ss');
            //setPrazo(current_atend?.os?.oe?.prazo);
        } else if(dt_od && dt_oe) {
            if(dt_od > dt_oe) {
                date_prazo = dt_od.add(add_color_prazo, 'days').format('YYYY-MM-DD HH:mm:ss');
                //setPrazo(current_atend?.os?.od?.prazo);
            } else {
                date_prazo = dt_oe.add(add_color_prazo, 'days').format('YYYY-MM-DD HH:mm:ss');
                //setPrazo(current_atend?.os?.oe?.prazo);
            }
        }

        setPrazo(date_prazo);

    
        if(current_atend?.os?.obs) {
            setOsObs(current_atend?.os?.obs);
        }

       

        let _os = {...current_atend?.os, hora: _hora, prazo: date_prazo};


        let _venda = (current_atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0)+(current_atend?.os?.od?.lente?.venda/2||0)+(current_atend?.os?.oe?.lente?.venda/2||0)+(UnCurrency(atend?.armacao?.form?.venda)||0);
        let _compra = (current_atend?.acessorios?.reduce((p, c) => p + c.compra, 0)||0)+(current_atend?.os?.od?.lente?.venda/2||0)+(current_atend?.os?.oe?.lente?.venda/2||0)+(current_atend?.armacao?.form?.compra||0);
        
        let _total = (current_atend?.os?.oe?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
        (current_atend?.os?.od?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+ 
        (current_atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0)+
        (current_atend?.os?.od?.lente?.venda/2||0)+(current_atend?.os?.oe?.lente?.venda/2||0)+
        (current_atend?.os?.od?.lente?.color?.valor||0)+(current_atend?.os?.oe?.lente?.color?.valor||0)+
        (UnCurrency(current_atend?.armacao?.form?.venda)||0)


        let _desconto = (current_atend?.desconto||0);
        let _tratamentos = (current_atend?.os?.oe?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor:0), 0)||0)+(current_atend?.os?.od?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor:0), 0)||0);

        
        setValores({compra:_compra, venda:_venda , total:_total, desconto: _desconto, tratamentos:_tratamentos});
        current_atend = {...current_atend, valores: {compra:_compra, venda:_venda , total:_total, desconto: _desconto, tratamentos:_tratamentos}, os:_os};
        console.log(current_atend);
        setAtend(current_atend);
        await Hub.Set("current_atend", current_atend, undefined, true);

    }


    const Update = async(patend) => {
        setTimeout(async()=> {
            let _atend = patend ? patend : atend;

            //console.log(_atend);

            if(_atend?.os_id && _atend?.os_id > 0 && _atend?.os?.prazo.toLowerCase().indexOf("invalid") === -1) {
                let data = await Req("api/AtendimentoFinalizar/UpdateOs", "POST", _atend, user.token, 0);
            }
        }, 300);

    }
    //#endregion Init



    //#region Ref
    useImperativeHandle(ref, (url, args, token) => ({
        async Init() { return Init() },
        async Update(atend_data) { return Update(atend_data) }
    }));
    //#endregion Ref



    //#region Loaders 
    const LoadUnidadeInfo = async() => {
        let data = await Req("api/AtendimentoLentes/Unidade?q=" + user.unidade_id, "GET", "", user.token, 0*24*60*60*1000);

        if(data.toString() !== "error") {
            setUnidade(data);
        } else {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde ", "error");
        }
    }
    //#endregion Loaders 


    //#region Handlers
    const _HandleChange = async(event) => {
        setOsObs(event.target.value);
        let current_atend = await Hub.Get("current_atend", true);

        current_atend.os =  {...current_atend?.os, obs: event.target.value};
        await Hub.Update("current_atend", "os", {...current_atend?.os, obs: event.target.value}, true);
        setAtend(current_atend);

        if(saved) {
            Update(current_atend);
        }
    }

    const _HandleSave = async() => {
        //console.log(atend);
        let data = await Req("api/AtendimentoFinalizar/SaveOs", "POST", atend, user.token, 0);


        if(data.toString() === "acessorios_less") {
            window.swal("Alerta", "Acessórios faltando em estoque", "error");
        } else if(data && data?.numero && data.toString() !== "error") {
            setOsNumber(data?.numero);
            setSaved(true);

            window.swal("Sucesso", "Ordem de serviço salva com sucesso", "success");
            await Hub.Update("current_atend", "os_numero", data?.numero, true);
            await Hub.Update("current_atend", "os_id", data?.os_id, true);
        } else {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde ", "error");
        }
    }


    const _HandlePrint = () => {
        window.$(".os_body").printThis({
            debug: false,                   // show the iframe for debugging
            importCSS: true,                // import parent page css
            importStyle: true,             // import style tags
            printContainer: true,           // grab outer container as well as the contents of the selector
            //loadCSS: "/assets/css/os_print.css",      // path to additional css file - use an array [] for multiple
            pageTitle: "ORDEM DE SERVIÇO",                  // add title to print page
            removeInline: false,            // remove all inline styles from print elements
            //removeInlineSelector: "body *", // custom selectors to filter inline styles. removeInline must be true
            printDelay: 333,                // variable print delay
            header: null,                   // prefix to html
            footer: null,                   // postfix to html
            base: false,                    // preserve the BASE tag, or accept a string for the URL
            formValues: true,               // preserve input/form values
            canvas: false,                  // copy canvas elements
            //doctypeString: '...',           // enter a different doctype for older markup
            removeScripts: false,           // remove script tags from print content
            copyTagClasses: false,          // copy classes from the html & body tag
            beforePrintEvent: null,         // callback function for printEvent in iframe
            beforePrint: null,              // function called before iframe is filled
            afterPrint: null                // function called before iframe is removed
        });

        props.OnSave();
    }


    const _HandlePrintCarne = () => {
        window.$(".os_content").hide();
        window.$(".carne_painel").show();

        window.$(".os_body").printThis({
            debug: false,                   // show the iframe for debugging
            importCSS: true,                // import parent page css
            importStyle: true,             // import style tags
            printContainer: true,           // grab outer container as well as the contents of the selector
            //loadCSS: "/assets/css/os_print.css",      // path to additional css file - use an array [] for multiple
            pageTitle: "CARNÊ - PAGAMENTOS",                  // add title to print page
            removeInline: false,            // remove all inline styles from print elements
            //removeInlineSelector: "body *", // custom selectors to filter inline styles. removeInline must be true
            printDelay: 333,                // variable print delay
            header: null,                   // prefix to html
            footer: null,                   // postfix to html
            base: false,                    // preserve the BASE tag, or accept a string for the URL
            formValues: true,               // preserve input/form values
            canvas: false,                  // copy canvas elements
            //doctypeString: '...',           // enter a different doctype for older markup
            removeScripts: false,           // remove script tags from print content
            copyTagClasses: false,          // copy classes from the html & body tag
            beforePrintEvent: null,         // callback function for printEvent in iframe
            beforePrint: null,              // function called before iframe is filled
            afterPrint: null                // function called before iframe is removed
        });

        props.OnSave();

        setTimeout(() => {
            window.$(".os_content").show();
            window.$(".carne_painel").hide();
        }, 500);
        
    }


    const _HandleFinalizar = () => {
        Hub.Exec("CLEAR_SESSION", component_name);
    }


    const _HandlePrazoChange = async(event) => {
        setPrazo(event.target.value);

        let current_atend = await Hub.Get("current_atend", true);


        let _prazo = moment(event.target.value).format("YYYY-MM-DD")
        let _os = {...current_atend?.os, hora: hora, prazo: moment(_prazo + " " + hora + ":00").format('YYYY-MM-DD HH:mm:ss')};
        current_atend.os = {...current_atend?.os, hora: hora, prazo: moment(_prazo + " " + hora + ":00").format('YYYY-MM-DD HH:mm:ss')};
        await Hub.Update("current_atend", "os", current_atend.os, true);
        setAtend(current_atend);
        Update(current_atend);
    }


    const _HandleHoraChange = async(event) => {
        setHora(event.target.value);

        let current_atend = await Hub.Get("current_atend", true);
        
        
        let _os = {...current_atend?.os, hora: event.target.value, prazo: moment(prazo.substr(0,10) + " " + event.target.value + ":00").format('YYYY-MM-DD HH:mm:ss')};
        current_atend.os = {...current_atend?.os, hora: event.target.value, prazo: moment(prazo.substr(0,10) + " " + event.target.value + ":00").format('YYYY-MM-DD HH:mm:ss')};
        //console.log(current_atend);
        await Hub.Update("current_atend", "os", _os, true);
        setAtend(current_atend);
        Update(current_atend);
    }


    const _HandleLogoError = (e) => {
        window.jQuery("#elogo").attr("src","/assets/images/os_logo_0.jpg");
    }
    //#endregion Handlers



    //#region Whatsapp
    const _HandleWhatsapp = async() => {
        let current_atend = await Hub.Get("current_atend", true);

        if(current_atend?.cliente?.celular) {
            let cel = current_atend?.cliente?.celular.replace('-','').replace('(','').replace(')','').replace('.','').replace(' ','');

            if(cel.substr(0,2) !== "55") cel = "55" + cel;

            if(cel.length < 13) {
                window.swal("Alerta", "Número de Whatsapp inválido", "error");
                return;
            }


            window.jQuery(".OS .os_datas_whatsapp").show();
            window.jQuery(".OS .os_datas_input").hide();

            
            const quality = 1;
            html2canvas(document.querySelector('#os_body'),
                { 	
                    allowTaint: true,
                    scale: 1 
                }
            ).then(async canvas => {
                const pdf = new jsPDF();
                pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, canvas.width*0.2, canvas.height*0.2);
                //pdf.save();
    
                
                let blobPDF = new Blob([pdf.output('blob')], { type: 'application/pdf'});
                let blobUrl = URL.createObjectURL(blobPDF);
    

                let base64 = await convertBase64(blobPDF);
                let files_list = [];
                files_list.push({ "name": osNumber + ".pdf", "size": blobPDF.size, "type": blobPDF.type, "base64":  base64 });
    
                let _contacts = [];
                //osNumber = "556661221043";
                console.log( osNumber );
                _contacts.push( {number: cel, name: current_atend?.cliente?.nome, message: "Olá " + current_atend?.cliente?.nome + ", ordem de serviço Nº " + osNumber +  " emitida em: " + unidade?.nome + ", " + unidade?.endereco + " " + unidade?.numero + " - " + unidade?.bairro + " " + unidade?.cidade + "/" + unidade?.uf})
                
            
                //console.log(files_list[0]);

                let event = new CustomEvent('Event', { 'detail': {
                    message:"Olá @name, seu pedido está pronto para retirada ", 
                    attachments:[], 
                    files: files_list, 
                    list: _contacts
                }});
                event.initEvent('whatsnap_api_broadcast');
                document.dispatchEvent(event);

                window.jQuery(".OS .os_datas_whatsapp").hide();
                window.jQuery(".OS .os_datas_input").show();

                props.OnSave();
            }).catch(() => {
                window.jQuery(".OS .os_datas_whatsapp").hide();
                window.jQuery(".OS .os_datas_input").show();
            });
        }


    }
    //#endregion Whatsapp


    //#region Functions
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            try {
                fileReader.readAsDataURL(file);
            } catch(e) {
                console.log("try", e);
                reject(e);
            }
    
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
    
            fileReader.onerror = (error) => {
                console.log(error);
                reject(error);
            };
        });
    };
    //#endregion Functions


    return (
        <div className={'OS'}>
            <div id="os_body" className={'os_body'}>
                <div className={'os_painel'}>
                    <div className={'os_unidade'}>
                        <div>
                            <img id="elogo" src={"/assets/images/os_logo_"+ (user?.rede_id?user?.rede_id:0) + ".jpg"} alt="" onError={_HandleLogoError}/>
                        </div>
                        <div className="os_address">
                            {unidade?.endereco}, {unidade?.numero} - {unidade?.bairro}<br />
                            {unidade?.cidade} / {unidade?.uf} - Fone: {unidade?.telefone}<br />
                            {unidade?.nome} - {unidade?.complemento}<br />
                        </div>
                        <div className="os_number">
                            OS: <b><span>{osNumber}</span></b>
                        </div>
                    </div>

                    <div className="os_datas os_datas_input">
                        Data: <span>{atend?.date}</span> - Data de Retirada: <span><input type="date" className="date_input" value={moment(DateBrMoment(prazo)).format('YYYY-MM-DD')} disabled={blocked} style={{width: '105px'}} onChange={_HandlePrazoChange}/></span> - Horário: <span><input type="time" className="date_input" value={hora} disabled={blocked} style={{width: '70px'}} onChange={_HandleHoraChange}/></span> <br />
                        Vendedor(a): <span>{user.nome}</span>
                    </div>

                    <div className="os_datas os_datas_whatsapp">
                        Data: <span>{atend?.date}</span> - Data de Retirada: <span>{moment(DateBrMoment(prazo)).format('DD/MM/YYYY')}</span> - Horário: <span>{hora}</span> <br />
                        Vendedor(a): <span>{user.nome}</span>
                    </div>
                    

                    <div className={'os_note'}>
                        <div>
                            <textarea id="obs" value={osObs} onChange={_HandleChange} disabled={blocked}></textarea>
                        </div>

                        <div>
                            <Barcode value={osNumber}  height={50} displayValue={false} width={2} margin={0}/>
                        </div>
                    </div>
                </div>




                <hr className='hr_separator' />




                <div className={'os_painel os_content'}>
                    <div className={'os_head'}>
                        <div className={'os_head_title'}>
                            <div><span>ORDEM DE SERVIÇO</span></div>
                            <div>{atend?.date}</div>
                            <div>OS: <span>{osNumber}</span></div>
                        </div>
                        <div className="os_stakeholders os_datas">
                            Cliente(a): <span>{atend?.cliente?.nome}</span> &nbsp;-&nbsp; CPF: <span>{atend?.cliente?.cpf}</span><br />
                            Telefone: <span>{atend?.cliente?.telefone}</span> &nbsp;-&nbsp; Celular: <span>{atend?.cliente?.celular}</span><br />
                            Vendedor(a): <span>{user.nome}</span> &nbsp;-&nbsp; Loja: <span>{unidade?.nome}</span>
                        </div>
                        <div className="os_datas os_retorno bt">
                            Retorno: <span>{DateBrMoment(prazo).add(-1, 'days').format('DD/MM/YYYY')}</span> &nbsp; Hora: <span>{hora}</span> &nbsp;-&nbsp; Entrega: <span>{moment(DateBrMoment(prazo)).format('DD/MM/YYYY')} </span> &nbsp; Hora: <span>{hora}</span>
                        </div>
                    </div>

                    <div className={'os_receita bt'}>
                        <div><span>RECEITA</span></div>
                        <div>
                            <table className="table ">
                                <thead>
                                    <tr>
                                        <th className={'td_bottom'} colSpan={2}></th>
                                        <th className={'th_null'} >esf.</th>
                                        <th className={'th_null'} >cil.</th>
                                        <th className={'th_null'} >eixo</th>
                                        <th className={'th_null'} >dnp</th>
                                        <th className={'th_null'} >adi.</th>
                                        <th className={'th_null'} >alt.</th>
                                    </tr>
                                </thead>
                                
                                <tbody>
                                    <tr>
                                        <td className={'td_dist td'} rowSpan={2}>LONGE</td>
                                        <td className={'td'}>OD</td>
                                        <td className={'td'}>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_od_esf}</td>
                                        <td className={'td'}>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_od_cil}</td>
                                        <td className={'td'}>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_od_eixo}</td>
                                        <td className={'td'}>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_od_dnp}</td>
                                        <td className={'td'}>{atend?.receita?.distancia_focal!==3?'':atend?.receita?.longe_od_adc}</td>
                                        <td className={'td'}>{atend?.armacao?.altura_od===0?'':atend?.armacao?.altura_od}</td>
                                    </tr>
                                    <tr>
                                       
                                        <td className={'td'}>OE</td>
                                        <td className={'td'}>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_oe_esf}</td>
                                        <td className={'td'}>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_oe_cil}</td>
                                        <td className={'td'}>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_oe_eixo}</td>
                                        <td className={'td'}>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_oe_dnp}</td>
                                        <td className={'td'}>{atend?.receita?.distancia_focal!==3?'':atend?.receita?.longe_oe_adc}</td>
                                        <td className={'td'}>{atend?.armacao?.altura_oe===0?'':atend?.armacao?.altura_oe}</td>
                                    </tr>

                                    <tr>
                                        <td className={'td_dist td'} rowSpan={2}>PERTO</td>
                                        <td className={'td'}>OD</td>
                                        <td className={'td'}>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_od_esf}</td>
                                        <td className={'td'}>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_od_cil}</td>
                                        <td className={'td'}>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_od_eixo}</td>
                                        <td className={'td'}>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_od_dnp}</td>
                                        <td className={'td'}>{atend?.receita?.perto_od_adc}</td>
                                        <td className={'td'}></td>
                                    </tr>
                                    <tr>   
                                        <td className={'td'}>OE</td>
                                        <td className={'td'}>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_oe_esf}</td>
                                        <td className={'td'}>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_oe_cil}</td>
                                        <td className={'td'}>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_oe_eixo}</td>
                                        <td className={'td'}>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_oe_dnp}</td>
                                        <td className={'td'}>{atend?.receita?.perto_oe_adc}</td>
                                        <td className={'td'}></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className={'os_datas os_medico bt'}>
                        MÉDICO: <span>{atend?.medico?.nome}</span> &nbsp;-&nbsp; TEL: <span>{(atend?.medico?.telefone!==''?atend?.medico?.telefone:atend?.medico?.celular)}</span>
                    </div>

                    <div className={'os_armacao bt'}>
                        <div><span>ARMAÇÃO</span></div>
                        <div>{atend?.armacao?.form?.marca} - {atend?.armacao?.form?.modelo} <span className="right">{Currency(atend?.armacao?.form?.venda)}</span></div>
                        <div>
                            Ponte: <span>{atend?.armacao?.form?.ponte}</span> &nbsp;-&nbsp; Aro: <span>{atend?.armacao?.form?.aro}</span> &nbsp;-&nbsp; Diagonal: <span>{atend?.armacao?.form?.diag}</span> &nbsp;-&nbsp; Vertical: <span>{atend?.armacao?.form?.altura}</span>
                        </div>
                        <div>
                            Cor: <span>{atend?.armacao?.form?.cor}</span> &nbsp;-&nbsp; Tipo: <span>{armacao_tipo.find(a => a.id === parseInt(atend?.armacao?.form?.tipo))?.name}</span> <br /> Material: <span>{armacao_material.find(a => a.id === parseInt(atend?.armacao?.form?.material))?.name}</span> &nbsp;-&nbsp; Formato: <span>{armacao_formato.find(a => a.id === parseInt(atend?.armacao?.form?.formato))?.name}</span>
                        </div>
                    </div>

                    <div className={'os_lentes bt'}>
                        <div><span>LENTES</span></div>
                        <div>OD: <span>{atend?.os?.od?.lente?.nome}</span> <span className="right">{Currency(atend?.os?.od?.lente?.venda/2)}</span></div>
                        <div>OE: <span>{atend?.os?.oe?.lente?.nome}</span> <span className="right">{Currency(atend?.os?.oe?.lente?.venda/2)}</span></div>
                    </div>


                    <div className={'os_tratamentos bt '+ ((Array.isArray(atend?.os?.od?.tratamentos) && atend?.os?.od?.tratamentos.length > 0) || (Array.isArray(atend?.os?.oe?.tratamentos) && atend?.os?.oe?.tratamentos.length > 0)?'':'hide')}>
                        <div><span>TRATAMENTOS</span></div>
                        {
                            Array.isArray(atend?.os?.od?.tratamentos) && atend?.os?.od?.tratamentos.length > 0 && atend?.os?.od?.tratamentos.map((item, i) => (
                                <div><b>OD:</b> {item.titulo} <small className={(item.tipo_id!==2?'hide':'hide')}>({Currency(item.valor)})</small> <span className={'right ' + (item.tipo_id===2?'hide':'')}>{Currency(item.valor/2)}</span></div>
                            ))
                        }

                        {
                            Array.isArray(atend?.os?.oe?.tratamentos) && atend?.os?.oe?.tratamentos.length > 0 && atend?.os?.oe?.tratamentos.map((item, i) => (
                                <div><b>OE:</b> {item.titulo} <small className={(item.tipo_id!==2?'hide':'hide')}>({Currency(item.valor)})</small> <span className={'right ' + (item.tipo_id===2?'hide':'')}>{Currency(item.valor/2)}</span></div>
                            ))
                        }
                    </div>


                    <div className={'os_acessorios bt ' + (Array.isArray(atend?.acessorios) && atend?.acessorios.length > 0?'':'hide')}>
                        <div><span>ACESSORIOS</span></div>
                        {
                            Array.isArray(atend?.acessorios) && atend?.acessorios.length > 0 && atend?.acessorios.map((item, i) => (
                                <div>{item.modelo} <span className="right">{Currency(item.venda)}</span></div>
                            ))
                        }
                    </div>


                    <div className={'os_coloracaoes bt ' + (atend?.os?.od?.lente?.color || atend?.os?.oe?.lente?.color?'':'hide')}>
                        <div><span>COLORAÇÕES</span></div>
                        {
                            atend?.os?.od?.lente?.color && (<div>OD: <span>{atend?.os?.od?.lente?.color?.name}</span> <span className="right">{Currency(atend?.os?.od?.lente?.color?.valor)}</span></div>)
                        }

                        {
                            atend?.os?.oe?.lente?.color && (<div>OE: <span>{atend?.os?.oe?.lente?.color?.name}</span> <span className="right">{Currency(atend?.os?.oe?.lente?.color?.valor)}</span></div>)
                        }
                    </div>


                    <div className={'os_total bt'}>
                    
                        <div>&nbsp;<span className="right"> TOTAL: {Currency(
                            (atend?.os?.oe?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                            (atend?.os?.od?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                            (atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0)+
                            (atend?.os?.od?.lente?.venda/2||0)+(atend?.os?.oe?.lente?.venda/2||0)+
                            (atend?.os?.od?.lente?.color?.valor||0)+ (atend?.os?.oe?.lente?.color?.valor||0)+
                            (UnCurrency(atend?.armacao?.form?.venda)||0)-(atend?.desconto||0)
                        )}</span></div>


                        <div className={'' + (!atend?.desconto||atend?.desconto === 0 ? ' hide': '')}>
                            &nbsp;<span className="right">Desconto {Currency(atend?.desconto)}</span>
                        </div>

                        <div className={'' + (!atend?.parcelas||atend?.parcelas <= 1 ? ' hide': '')}>
                            &nbsp;<span className="right">Parcelas: {atend?.parcelas}x {Currency(
                                ((atend?.os?.oe?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                (atend?.os?.od?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                (atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0)+
                                (atend?.os?.od?.lente?.venda/2||0)+(atend?.os?.oe?.lente?.venda/2||0)+
                                (atend?.os?.od?.lente?.color?.valor||0)+ (atend?.os?.oe?.lente?.color?.valor||0)+
                                (UnCurrency(atend?.armacao?.form?.venda)||0)-(atend?.desconto||0)-(atend?.entrada||0)) / (atend?.parcelas)
                            )}</span>
                        </div>

                        <div className={'' + (!atend?.entrada ? ' hide': '')}>
                            &nbsp;<span className="right">Entrada: {Currency(atend?.entrada)}</span>
                        </div>

                        <div>
                            &nbsp;<span className="right">Pagamento: {forma_pagamento.find(f => f.id === atend?.forma_pagamento)?.name}</span>
                        </div>
                    </div>


                    <div className={'os_alert bt'}>
                        <div>Autorizo a execução desta ordem de serviço e declaro ter conferido todos os ítens constantes dela. Também confirmo ter sido orientado quanto as advertências impressas nessa mesma ordem de serviço, alertando sobre possiveis dificuldades de adaptação do uso do óculos. </div>
                        <br /><br /><br /><br />
                        <center>
                            ________________________________________________________<br />
                            <span style={{fontSize:'15px'}}>{atend?.cliente?.nome}</span><br />
                            <div className={(atend?.armacao?.form?.aro>atend?.armacao?.tamanho_sugerido)?'':'hide'}>Tamanho da armação maior que o tamanho sugerido</div>
                        </center>
                    </div>
                    

                    <div className={'os_barcode bt'}>
                        <div>&nbsp;<span className="right"><Barcode value={osNumber}  height={50} displayValue={false} width={2} margin={0}/></span></div>
                        <div className="clear"></div>
                    </div>
                </div>



                <div className={'carne_painel hide'}>
                    <div className={'carne_content'}>
                        <br />
                        <div className="carne_title"><span>CARNÊ - PARCELAS</span></div>
                        <br />
                        {
                            atend?.parcelas && Array(atend?.parcelas).fill(1).map((x, y) => x + y).map((item, i) => (
                                <>
                                <div className="carne_parcela clear">
                                    <div className="clear">
                                        <span className="carne_parcela_title">PARCELA <b>{item}/{atend?.parcelas} &nbsp; </b> </span>
                                        
                                        <span className="carne_parcela_valor right">Valor <b>{Currency(
                                            ((atend?.os?.oe?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                            (atend?.os?.od?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                            (atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0)+
                                            (atend?.os?.od?.lente?.venda/2||0)+(atend?.os?.oe?.lente?.venda/2||0)+
                                            (atend?.os?.od?.lente?.color?.valor||0)+ (atend?.os?.oe?.lente?.color?.valor||0)+
                                            (UnCurrency(atend?.armacao?.form?.venda)||0)-(atend?.desconto||0)-(atend?.entrada||0)) / (atend?.parcelas)
                                        )}</b></span> 
                                    </div> 

                                    <div className="carne_parcela_vencimento"> 
                                        <span className="right">Vencimento: <b>{moment(atend?.carne_date).add((item-1), 'month').format('DD/MM/YYYY')}</b></span>
                                    </div>
                                    <div className="carne_parcela_ass_line">
                                        <span>______________________________________________</span>
                                    </div>

                                    <div className="carne_parcela_ass">
                                        <span> Assinatura</span>
                                    </div>
                                    
                                    <hr />
                                    
                                </div>
                                </>
                            ))
                        }      
                    </div>
                </div>
            </div>


            <div className="mt-4">
                <center>
                    <a className={'pointer btn btn-primary ' + (saved?'hide':'')} onClick={_HandleSave}>Salvar Ordem de serviço</a>

                    <div className={'btn-group btn-group-lg ' + (saved?'':'hide')} role="group" aria-label="orcamento">
                        <a className={'pointer btn btn-primary '} onClick={_HandleFinalizar}>Novo Atendimento</a>
                        <a className={'pointer btn btn-outline-primary '} onClick={_HandlePrint}><i className="fa fa-print"></i> Imprimir</a>
                        {atend?.forma_pagamento === 5 && (<a className={'pointer btn btn-outline-primary '} onClick={_HandlePrintCarne}><i className="fa fa-print"></i> Carnê</a>)}
                        <button className={'btn btn-primary '} onClick={_HandleWhatsapp} style={{backgroundColor:'#128C7E',border:'0px'}}><i className="mdi mdi-whatsapp mdi-18px" style={{padding:'0 !important',margin:'0 !important'}}></i> </button>
                    </div>
                </center>
            </div>
        </div>
    )
});
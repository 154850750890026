/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Req, Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import { Currency } from '../../../scripts/StringUtils';
import moment from 'moment/moment';

import './Unidade.css';

export default function Unidade() {
    const component_name = "admin/home_unidade";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [timeData, setTimeData] = useState({});
    const [osData, setOsData] = useState({});
    const [orData, setOrData] = useState({});
    const [osTotal, setOsTotal] = useState({});
    const [vendedores, setVendedores] = useState([]);

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);



    //#region Effects
    useEffect(() => {
        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        LoadAtendimentos();
        LoadTimeAvg();
        LoadOsInfo();
        LoadOrInfo();
        LoadOsTotal();
        LoadVendedores();
    }
    //endregion Init


    //#region Loaders
    const LoadAtendimentos = async(user_id = "", force = false) => {
        let atend_data = await Req("api/Home/GraphAtendimentos?id="+user_id, "POST", {user_id: user_id, force: force}, user.token, 0*60*60*1000);

        let _data = [];
        for(let i=0;i<=8;i++) {
            if(atend_data[i]) {
                _data[i] = atend_data[i].counter;
            } else {
                _data[i] = 0;
            }
        }

        setTimeout(() => {
            window.draw_chart_radar_steps("polygon_radar","Últimos 30 dias", _data, ["Espelho","Cliente","Anamnese","Médico","Receita","Armações","Lentes","Acessórios","Finalizar"], "Atendimentos");
        },100);
    }


    const LoadTimeAvg = async(user_id = "", force = false) => {
        let time_data = await Req("api/Home/GetTimeAVG?id="+user_id, "POST", {user_id: user_id, force: force}, user.token, 0*60*60*1000);
        
        setTimeData(time_data);
    }


    const LoadOsInfo = async(user_id = "", force = false) => {
        let os_data = await Req("api/Home/GetOsInfo?id="+user_id, "POST", {user_id: user_id, force: force}, user.token, 0*60*60*1000);

        setOsData(os_data);
    }


    const LoadOrInfo = async(user_id = "", force = false) => {
        let or_data = await Req("api/Home/GetOrcamentos?id="+user_id, "POST", {user_id: user_id, force: force}, user.token, 0*60*60*1000);

        setOrData(or_data);
    }


    const LoadOsTotal = async(user_id = "", force = false) => {
        let os_total = await Req("api/Home/GetOsTotal?id="+user_id, "POST", {user_id: user_id, force: force}, user.token, 0*60*60*1000);

        setOsTotal(os_total);
    }


    const LoadVendedores = async(force = false) => {
        let vendedores = await Req("api/Home/GetVendedores", "POST", {force: force}, user.token, 0*60*60*1000);

        setVendedores(vendedores);
    }
    //#endregion Loaders


    return (
        <div className="HomeUnidade">
            <div className="row">
                <div className="col-lg-3">
                    <div className="card bg-light">
                        <h6 className="card-header bg-light">Tempo de atendimento <small>(Últimos 30 dias)</small></h6>
                        <div className="card-body">
                            <p className="card-text top-info">
                                <span className="title">Tempo médio:</span> &emsp;<span className="info_content"><b>{timeData?.avg && moment.utc(timeData?.avg*1000).format('HH:mm:ss')}</b></span> <br />
                                <span className="title">Desvio padrão:</span> &emsp;<span className="info_content"><b>{timeData?.avg && moment.utc(timeData?.stddev*1000).format('HH:mm:ss')}</b></span> <br />
                                <span className="title">Variância:</span> &emsp;<span className="info_content"><b>{timeData?.avg && moment.utc(timeData?.variance*1000).format('HH:mm:ss')}</b></span> <br />
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3">
                    <div className="card bg-light">
                        <h6 className="card-header bg-light">Orçamentos <small>(Últimos 30 dias)</small></h6>
                        <div className="card-body">
                            <p className="card-text">
                            <span className="title">Não Convertidos: </span> <span><b>{orData?.or_no}</b></span><br />
                                <span className="title">Convertidos em Os: </span> <span><b>{orData?.or_convertidos}</b></span><br />
                                <br />
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3">
                    <div className="card bg-light">
                        <h6 className="card-header bg-light">Ordens de serviço <small>(Últimos 30 dias)</small></h6>
                        <div className="card-body">
                            <p className="card-text top-info">
                                <span className="title">Finalizadas: </span> <span><b>{osData?.os_finalizados}</b></span><br />
                                <span className="title">Canceladas: </span> <span><b>{osData?.os_cancelados}</b></span><br />
                                <br />
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3">
                    <div className="card bg-light">
                        <h6 className="card-header bg-light">Receita de Vendas <small>(Últimos 30 dias)</small></h6>
                        <div className="card-body">
                            <p className="card-text">
                                <span className="title">Total: </span> <span><b>{Currency(osTotal?.total)}</b></span><br />
                                <br />
                                <br />
                            </p>
                        </div>
                    </div>
                </div>

            </div>

            <div className="row">
             
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-header justify-content-between d-flex align-items-center">
                            <h4 className="card-title">Etapas de Atendimento</h4>
                        </div>
                        <div className="card-body">                                        
                            <div id="polygon_radar" data-colors='["--bs-info"]' className="apex-charts" dir="ltr"></div>
                        </div>
                    </div>
                </div>


                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-header justify-content-between d-flex align-items-center">
                            <h4 className="card-title">Ranking de Vendedores</h4>
                        </div>
                        <div id="scroll_vendedores" className="card-body sellers_rank">                                          
                            <div className="table-responsive">
                                <table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Vendas</th>
                                        </tr>
                                    </thead>


                                    <tbody>
                                        {Array.isArray(vendedores) && vendedores.length > 0 && vendedores.map((item, i) => (
                                            <tr key={'tr' + i} className="pointer">
                                                <td>{item.nome}</td>
                                                <td>{item.oss}</td>
                                            </tr>
                                        ))}
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>


             
            </div>

          
        </div>
    )
}


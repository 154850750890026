/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Request } from '../../../scripts/Request';
import { HubObjects } from '../../../scripts/HubObjects';
import { WaitObject } from '../../../scripts/Wait';
import ReactGA from 'react-ga';
import './index.css';
import Dev from './Dev';
import Rede from './Rede';
import Unidade from './Unidade';
import Vendedor from './Vendedor';


export default function Home() {
    const component_name = "admin/home";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                    RegisterHub();
                });
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        /*setTimeout(() => {
            window.draw_column_chart();
            window.draw_chart_donut();
            window.draw_chart_area();
            window.draw_slider();
            window.draw_sales_countries();
            window.draw_sparklines(1);
            window.draw_sparklines(2);
            window.draw_sparklines(3);
            window.draw_sparklines(4);
            window.draw_sparklines(5);
            window.draw_mini_1();
        }, 10);*/
    }
    //#endregion Init


    //#region HubObjects
    const RegisterHub = () => {
        Hub.Set("HOME_INIT_DRAW", () => { 
            Init(); 
        }, component_name);

        
    }
    //#endregion HubObjects


    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    {/*<div className="row">
                        <div className="col-xl-8">
                            <div className="card">
                                <div className="card-body pb-2">
                                    <div className="d-flex align-items-start mb-4 mb-xl-0">
                                        <div className="flex-grow-1">
                                            <h5 className="card-title">Invoice Overview</h5>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <div className="dropdown">
                                                <a className="dropdown-toggle text-reset" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span className="fw-semibold">Sort By:</span> <span className="text-muted">Yearly<i className="mdi mdi-chevron-down ms-1"></i></span>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                    <a className="dropdown-item" href="#">Yearly</a>
                                                    <a className="dropdown-item" href="#">Monthly</a>
                                                    <a className="dropdown-item" href="#">Weekly</a>
                                                    <a className="dropdown-item" href="#">Today</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row align-items-center">
                                        <div className="col-xl-4">
                                            <div className="card bg-light mb-0">
                                                <div className="card-body">
                                                    <div className="py-2">
                                                        <h5>Total Revenue:</h5>
                                                    <h2 className="mt-4 pt-1 mb-1">$9,542,00</h2>
                                                    <p className="text-muted font-size-15 text-truncate">From Jan 20,2022 to July,2022</p>

                                                    <div className="d-flex mt-4 align-items-center">
                                                        <div id="mini-1" data-colors='["--bs-success"]' className="apex-charts"></div>
                                                        <div className="ms-3">
                                                            <span className="badge bg-danger"><i className="mdi mdi-arrow-down me-1"></i>16.3%</span>
                                                        </div>
                                                    </div>

                                                    <div className="row mt-4">
                                                        <div className="col">
                                                            <div className="d-flex mt-2">
                                                                <i className="mdi mdi-square-rounded font-size-10 text-success mt-1"></i>
                                                                <div className="flex-grow-1 ms-2 ps-1">
                                                                    <h5 className="mb-1">3,526,56</h5>
                                                                    <p className="text-muted text-truncate mb-0">Net Profit</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="d-flex mt-2">
                                                                <i className="mdi mdi-square-rounded font-size-10 text-primary mt-1"></i>
                                                                <div className="flex-grow-1 ms-2 ps-1">
                                                                    <h5 className="mb-1">5,324,85</h5>
                                                                    <p className="text-muted text-truncate mb-0">Net Revenue</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div className="col-xl-8">
                                        <div>
                                            <div id="column_chart" data-colors='["--bs-primary", "--bs-primary-rgb, 0.2"]' className="apex-charts" dir="ltr"></div>  
                                        </div>
                                    </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-start">
                                        <div className="flex-grow-1">
                                            <h5 className="card-title mb-2">Order Stats</h5>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <div className="dropdown">
                                                <a className="dropdown-toggle text-muted" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Monthly<i className="mdi mdi-chevron-down ms-1"></i>
                                                </a>

                                                <div className="dropdown-menu dropdown-menu-end">
                                                    <a className="dropdown-item" href="#">Yearly</a>
                                                    <a className="dropdown-item" href="#">Monthly</a>
                                                    <a className="dropdown-item" href="#">Weekly</a>
                                                    <a className="dropdown-item" href="#">Today</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="chart-donut" data-colors='["--bs-primary", "--bs-success","--bs-danger"]' className="apex-charts" dir="ltr"></div>

                                    <div className="mt-1 px-2">
                                        <div className="order-wid-list d-flex justify-content-between border-bottom">
                                            <p className="mb-0"><i className="mdi mdi-square-rounded font-size-10 text-primary me-2"></i>Order Completed</p>
                                            <div>
                                                <span className="pe-5">56,236</span>
                                                <span className="badge bg-primary"> + 0.2% </span>
                                            </div>
                                        </div>
                                        <div className="order-wid-list d-flex justify-content-between border-bottom">
                                            <p className="mb-0"><i className="mdi mdi-square-rounded font-size-10 text-success me-2"></i>Order Processing</p>
                                            <div>
                                                <span className="pe-5">12,596</span>
                                                <span className="badge bg-success"> - 0.7% </span>
                                            </div>
                                        </div>
                                        <div className="order-wid-list d-flex justify-content-between">
                                            <p className="mb-0"><i className="mdi mdi-square-rounded font-size-10 text-danger me-2"></i>Order Cancel</p>
                                            <div>
                                                <span className="pe-5">1,568</span>
                                                <span className="badge bg-danger"> + 0.4% </span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-7">
                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="card">
                                        <div className="card-body pb-3">
                                            <div className="d-flex align-items-start">
                                                <div className="flex-grow-1">
                                                    <h5 className="card-title mb-2">Sales By Social Source</h5>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <div className="dropdown">
                                                        <a className="dropdown-toggle text-muted" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            Monthly<i className="mdi mdi-chevron-down ms-1"></i>
                                                        </a>
            
                                                        <div className="dropdown-menu dropdown-menu-end">
                                                            <a className="dropdown-item" href="#">Yearly</a>
                                                            <a className="dropdown-item" href="#">Monthly</a>
                                                            <a className="dropdown-item" href="#">Weekly</a>
                                                            <a className="dropdown-item" href="#">Today</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div className="mt-3 pt-1">
                                                <div className="social-box row align-items-center border-bottom pt-0 g-0">
                                                    <div className="col-12 col-sm-5">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 me-3">
                                                                <div className="avatar">
                                                                    <div className="avatar-title rounded bg-primary">
                                                                        <i className="mdi mdi-facebook font-size-20"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 overflow-hidden">
                                                                <h5 className="font-size-15 mb-1 text-truncate">Facebook Ads</h5>
                                                                <p className="text-muted mb-0">Shoes</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col col-sm-3">
                                                        <div className="mt-3 mt-md-0 text-md-end">
                                                            <h5 className="font-size-15 mb-1 text-truncate">4,562 Sale</h5>
                                                            <p className="text-muted mb-0 text-truncate">4.2k Like</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto col-sm-4">
                                                        <div className="mt-3 mt-md-0 text-end">
                                                            <h5 className="font-size-15 mb-1 text-truncate">$47,526.00</h5>
                                                            <p className="text-muted mb-0">
                                                                <span className="badge bg-success"><i className="mdi mdi-arrow-top-right me-1"></i>50%</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
        
                                                <div className="social-box row align-items-center border-bottom g-0">
                                                    <div className="col-12 col-sm-5">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 me-3">
                                                                <div className="avatar">
                                                                    <div className="avatar-title rounded bg-info">
                                                                        <i className="mdi mdi-twitter font-size-20"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 overflow-hidden">
                                                                <h5 className="font-size-15 mb-1 text-truncate">Twitter Ads</h5>
                                                                <p className="text-muted mb-0">T-shirt</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col col-sm-3">
                                                        <div className="mt-3 mt-md-0 text-md-end">
                                                            <h5 className="font-size-15 mb-1 text-truncate">1,652 Sale</h5>
                                                            <p className="text-muted mb-0 text-truncate">3.7k Like</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto col-sm-4">
                                                        <div className="mt-3 mt-md-0 text-end">
                                                            <h5 className="font-size-15 mb-1 text-truncate">$52,785.00</h5>
                                                            <p className="text-muted mb-0">
                                                                <span className="badge bg-success"><i className="mdi mdi-arrow-top-right me-1"></i>45%</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
        
                                                <div className="social-box row align-items-center border-bottom g-0">
                                                    <div className="col-12 col-sm-5">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 me-3">
                                                                <div className="avatar">
                                                                    <div className="avatar-title rounded bg-danger">
                                                                        <i className="mdi mdi-linkedin font-size-20"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 overflow-hidden">
                                                                <h5 className="font-size-15 mb-1 text-truncate">linkedin Ads</h5>
                                                                <p className="text-muted mb-0">Watch</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col col-sm-3">
                                                        <div className="mt-3 mt-md-0 text-md-end">
                                                            <h5 className="font-size-15 mb-1 text-truncate">5,256 Sale</h5>
                                                            <p className="text-muted mb-0 text-truncate">3.3k Like</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto col-sm-4">
                                                        <div className="mt-3 mt-md-0 text-end">
                                                            <h5 className="font-size-15 mb-1 text-truncate">$52,785.00</h5>
                                                            <p className="text-muted mb-0">
                                                                <span className="badge bg-danger"><i className="mdi mdi-arrow-bottom-right me-1"></i>30%</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
        
                                                <div className="social-box row align-items-center border-bottom g-0">
                                                    <div className="col-12 col-sm-5">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 me-3">
                                                                <div className="avatar">
                                                                    <div className="avatar-title rounded bg-danger">
                                                                        <i className="mdi mdi-youtube font-size-20"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 overflow-hidden">
                                                                <h5 className="font-size-15 mb-1 text-truncate">Youtube Ads</h5>
                                                                <p className="text-muted mb-0">Chairs</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col col-sm-3">
                                                        <div className="mt-3 mt-md-0 text-md-end">
                                                            <h5 className="font-size-15 mb-1 text-truncate">6,965 Sale</h5>
                                                            <p className="text-muted mb-0 text-truncate">3.7k Like</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto col-sm-4">
                                                        <div className="mt-3 mt-md-0 text-end">
                                                            <h5 className="font-size-15 mb-1 text-truncate">$86,456.00</h5>
                                                            <p className="text-muted mb-0">
        
                                                                <span className="badge bg-success"><i className="mdi mdi-arrow-top-right me-1"></i>35%</span>
        
                                                                </p>
                                                        </div>
                                                    </div>
                                                </div>
        
                                                <div className="social-box row align-items-center border-bottom g-0">
                                                    <div className="col-12 col-sm-5">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 me-3">
                                                                <div className="avatar">
                                                                    <div className="avatar-title rounded bg-primary">
                                                                        <i className="mdi mdi-instagram font-size-20"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 overflow-hidden">
                                                                <h5 className="font-size-15 mb-1 text-truncate">Instagram Ads</h5>
                                                                <p className="text-muted mb-0">Chairs</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col col-sm-3">
                                                        <div className="mt-3 mt-md-0 text-md-end">
                                                            <h5 className="font-size-15 mb-1 text-truncate">8,532 Sale</h5>
                                                            <p className="text-muted mb-0 text-truncate">4.2k Like</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto col-sm-4">
                                                        <div className="mt-3 mt-md-0 text-end">
                                                            <h5 className="font-size-15 mb-1 text-truncate">$92,452.00</h5>
                                                            <p className="text-muted mb-0">
        
                                                                <span className="badge bg-success"><i className="mdi mdi-arrow-top-right me-1"></i>35%</span>
        
                                                                </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-6">
                                    <div className="card">
                                        <div className="card-body pb-1">
                                          <div className="d-flex align-items-start">
                                              <div className="flex-grow-1">
                                                  <h5 className="card-title mb-2">Product Traking</h5>
                                              </div>
                                              <div className="flex-shrink-0">
                                                  <div className="dropdown">
                                                      <a className=" dropdown-toggle" href="#" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                          <span className="text-muted">View All<i className="mdi mdi-chevron-down ms-1"></i></span>
                                                      </a>
                              
                                                      <div className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton2">
                                                          <a className="dropdown-item" href="#">Members</a>
                                                          <a className="dropdown-item" href="#">New Members</a>
                                                          <a className="dropdown-item" href="#">Old Members</a>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
          
                                        <div className="mx-n4 px-4" data-simplebar style={{height: '258px'}}>
                                                <div className="mt-3">
                                                    <ol className="activity-checkout mb-0 mt-2 ps-3">
                                                            <li className="checkout-item crypto-activity">
                                                                <div className="avatar checkout-icon">
                                                                    <div className="avatar-title rounded-circle bg-primary">
                                                                        <i className="mdi mdi-cart text-white font-size-17"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="feed-item-list">
                                                                    <div className="d-flex">
                                                                        <div className="flex-grow-1 overflow-hidden me-4">
                                                                            <h5 className="font-size-15 mb-1 text-truncate">Have 5 pending order.</h5>
                                                                            <p className="text-truncate text-muted mb-2">Delivered</p>
                                                                        </div>
                                                                        <div className="flex-shrink-0 text-end">
                                                                            <h5 className="mb-1 font-size-15">Nov 02</h5>
                                                                            <p className="text-muted mb-0">6 hour ago</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                    
                                                            <li className="checkout-item crypto-activity">
                                                                <div className="avatar checkout-icon">
                                                                    <div className="avatar-title rounded-circle bg-primary">
                                                                        <i className="mdi mdi-gift text-white font-size-17"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="feed-item-list">
                                                                    <div className="d-flex">
                                                                        <div className="flex-grow-1 overflow-hidden me-4">
                                                                            <h5 className="font-size-15 mb-1 text-truncate">New Order Received</h5>
                                                                            <p className="text-truncate text-muted mb-2">Pick Up</p>
                                                                        </div>
                                                                        <div className="flex-shrink-0 text-end">
                                                                            <h5 className="mb-1 font-size-15">Nov 03</h5>
                                                                            <p className="text-muted mb-0">1 day ago</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                    
                                                            <li className="checkout-item crypto-activity">
                                                                <div className="avatar checkout-icon">
                                                                    <div className="avatar-title rounded-circle bg-primary">
                                                                        <i className="mdi mdi-account text-white font-size-17"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="feed-item-list">
                                                                    <div className="d-flex">
                                                                        <div className="flex-grow-1 overflow-hidden me-4">
                                                                            <h5 className="font-size-15 mb-1 text-truncate">Manager Posted</h5>
                                                                            <p className="text-truncate text-muted mb-2">In Transit</p>
                                                                        </div>
                                                                        <div className="flex-shrink-0 text-end">
                                                                            <h5 className="mb-1 font-size-15">Nov 03</h5>
                                                                            <p className="text-muted mb-0">Yesterday</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                    
                                                            <li className="checkout-item crypto-activity">
                                                                <div className="avatar checkout-icon">
                                                                    <div className="avatar-title rounded-circle bg-primary">
                                                                        <i className="mdi mdi-wallet text-white font-size-20"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="feed-item-list">
                                                                    <div className="d-flex">
                                                                        <div className="flex-grow-1 overflow-hidden me-4">
                                                                            <h5 className="font-size-15 mb-1 text-truncate">Have 1 pending order.</h5>
                                                                            <p className="text-truncate text-muted mb-2">2 hour ago</p>
                                                                        </div>
                                                                        <div className="flex-shrink-0 text-end">
                                                                            <h5 className="mb-1 font-size-15">Nov 04</h5>
                                                                            <p className="text-muted mb-0">6 hour ago</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                    
                                                            <li className="checkout-item crypto-activity">
                                                                <div className="avatar checkout-icon">
                                                                    <div className="avatar-title rounded-circle bg-primary">
                                                                        <i className="mdi mdi-weight text-white font-size-20"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="feed-item-list">
                                                                    <div className="d-flex">
                                                                        <div className="flex-grow-1 overflow-hidden me-4">
                                                                            <h5 className="font-size-15 mb-1 text-truncate">Order Received</h5>
                                                                            <p className="text-truncate text-muted mb-2">Received</p>
                                                                        </div>
                                                                        <div className="flex-shrink-0 text-end">
                                                                            <h5 className="mb-1 font-size-15">Nov 04</h5>
                                                                            <p className="text-muted mb-0">Today</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            
                                                    </ol>
                                                </div>
                                           </div>
          
                                          <div id="chart-area" data-colors='["--bs-primary"]' className="apex-charts"></div>
          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-5">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-start">
                                        <div className="flex-grow-1">
                                            <h5 className="card-title">Best Selling Product</h5>
                                        </div>
                                    </div>

                                    <div className="slider mt-4">
                                        <div className="swiper-button-next"><i className="mdi mdi-arrow-right"></i></div>
                                        <div className="swiper-button-prev"><i className="mdi mdi-arrow-left"></i></div>
                                        
                                        <div className="swiper-container">
                                            <div className="swiper-wrapper">
                                                <div className="swiper-slide">
                                                    <div className="card dash-product-box shadow-none border mb-0">
                                                        <div className="card-body">
                                                            <div className="pricing-badge">
                                                                <span className="badge bg-success">Sale</span>
                                                            </div>
                                                           <div className="dash-product-img">
                                                             <img src="assets/images/product/img-1.png" className="img-fluid" alt=""/>
                                                           </div>
    
                                                            <h5 className="font-size-17 mt-1">
                                                                <a href="ecommerce-product-detail.html" className="text-dark lh-base">Stylish Cricket & Walking Light Weight Shoes</a>
                                                            </h5>
    
                                                            <h5 className="font-size-20 text-primary mt-3 mb-0"><del className="font-size-17 text-muted fw-normal me-1">$280</del> $140.00</h5>
    
                                                            <div className="font-size-16">
                                                                <i className="mdi mdi-star text-warning"></i>
                                                                <i className="mdi mdi-star text-warning"></i>
                                                                <i className="mdi mdi-star text-warning"></i>
                                                                <i className="mdi mdi-star-half-full text-warning"></i>
                                                            </div>
    
                                                            <div className="mt-4">
                                                                <a href="ecommerce-product-detail.html" className="btn btn-primary btn-sm w-lg"><i className="mdi mdi-cart me-1 align-middle"></i> Buy
                                                                    Now</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
            
                                                <div className="swiper-slide">
                                                    <div className="card dash-product-box shadow-none border mb-0">
                                                        <div className="card-body">
                                                            <div className="dash-product-img">
                                                                <img src="assets/images/product/img-2.png" className="img-fluid" alt=""/>
                                                              </div>
       
                                                               <h5 className="font-size-17 mt-1">
                                                                   <a href="ecommerce-product-detail.html" className="text-dark lh-base">Combo Pack of 2 Sports Shoes Running Shoes</a>
                                                               </h5>
       
                                                               <h5 className="font-size-20 text-primary mt-3 mb-0"><del className="font-size-17 text-muted fw-normal me-1">$320</del> $280.00</h5>
       
                                                               <div className="font-size-16">
                                                                   <i className="mdi mdi-star text-warning"></i>
                                                                   <i className="mdi mdi-star text-warning"></i>
                                                                   <i className="mdi mdi-star text-warning"></i>
                                                                   <i className="mdi mdi-star text-warning"></i>
                                                               </div>
       
                                                               <div className="mt-4">
                                                                   <a href="ecommerce-product-detail.html" className="btn btn-primary btn-sm w-lg"><i className="mdi mdi-cart me-1 align-middle"></i> Buy
                                                                       Now</a>
                                                               </div>
                                                        </div>
                                                    </div>
                                                </div>
            
                                                <div className="swiper-slide">
                                                    <div className="card dash-product-box shadow-none border mb-0">
                                                          <div className="card-body">
                                                            <div className="dash-product-img">
                                                                <img src="assets/images/product/img-3.png" className="img-fluid" alt=""/>
                                                              </div>
       
                                                               <h5 className="font-size-17 mt-1">
                                                                   <a href="ecommerce-product-detail.html" className="text-dark lh-base">Trendy Men Sports Running Running Shoes</a>
                                                               </h5>
      
                                                               <h5 className="font-size-20 text-primary mt-3 mb-0"><del className="font-size-17 text-muted fw-normal me-1">$740</del> $520.00</h5>
      
                                                               <div className="font-size-16">
                                                                   <i className="mdi mdi-star text-warning"></i>
                                                                   <i className="mdi mdi-star text-warning"></i>
                                                                   <i className="mdi mdi-star text-warning"></i>
                                                                   <i className="mdi mdi-star-half-full text-warning"></i>
                                                               </div>
       
                                                               <div className="mt-4">
                                                                   <a href="ecommerce-product-detail.html" className="btn btn-primary btn-sm w-lg"><i className="mdi mdi-cart me-1 align-middle"></i> Buy
                                                                       Now</a>
                                                               </div>
                                                          </div>
                                                     </div>
                                                </div>

                                                <div className="swiper-slide">
                                                    <div className="card dash-product-box shadow-none border mb-0">
                                                          <div className="card-body">
                                                            <div className="dash-product-img">
                                                                <img src="assets/images/product/img-6.png" className="img-fluid" alt=""/>
                                                              </div>
       
                                                               <h5 className="font-size-17 mt-1">
                                                                   <a href="ecommerce-product-detail.html" className="text-dark lh-base">Sneakers For Women Sports Running Shoes (Blue)</a>
                                                               </h5>
      
                                                               <h5 className="font-size-20 text-primary mt-3 mb-0"><del className="font-size-17 text-muted fw-normal me-1">$530</del> $420.00</h5>
      
                                                               <div className="font-size-16">
                                                                   <i className="mdi mdi-star text-warning"></i>
                                                                   <i className="mdi mdi-star text-warning"></i>
                                                                   <i className="mdi mdi-star text-warning"></i>
                                                                   <i className="mdi mdi-star-half-full text-warning"></i>
                                                               </div>
       
                                                               <div className="mt-4">
                                                                   <a href="ecommerce-product-detail.html" className="btn btn-primary btn-sm w-lg"><i className="mdi mdi-cart me-1 align-middle"></i> Buy
                                                                       Now</a>
                                                               </div>
                                                          </div>
                                                     </div>
                                                </div>
                                            </div>
                                         
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-4">
                           <div className="card">
                               <div className="card-body pb-0">
                                    <div className="d-flex align-items-start">
                                        <div className="flex-grow-1">
                                            <h5 className="card-title">Sales Revenue</h5>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <div className="dropdown">
                                                <a className="dropdown-toggle text-reset" href="#" data-bs-toggle="dropdown"
                                                    aria-haspopup="true" aria-expanded="false">
                                                    <span className="fw-semibold">Year:</span> <span
                                                        className="text-muted">2022<i
                                                            className="mdi mdi-chevron-down ms-1"></i></span>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                    <a className="dropdown-item" href="#">2019</a>
                                                    <a className="dropdown-item" href="#">2020</a>
                                                    <a className="dropdown-item" href="#">2021</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-3">
                                        <div id="world-map-markers" style={{height: '230px'}}></div>
                                    </div>

                                    <div>
                                        <div id="sales-countries" className="apex-charts" dir="ltr"></div>
                                    </div>
                               </div>
                           </div>
                        </div>

                        <div className="col-xl-8">
                            <div className="card">
                                <div className="card-body pb-3">
                                    <div className="d-flex align-items-start">
                                        <div className="flex-grow-1">
                                            <h5 className="card-title mb-2">Recent Orders</h5>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <div className="dropdown">
                                                <a className="dropdown-toggle text-muted" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Monthly<i className="mdi mdi-chevron-down ms-1"></i>
                                                </a>
    
                                                <div className="dropdown-menu dropdown-menu-end">
                                                    <a className="dropdown-item" href="#">Yearly</a>
                                                    <a className="dropdown-item" href="#">Monthly</a>
                                                    <a className="dropdown-item" href="#">Weekly</a>
                                                    <a className="dropdown-item" href="#">Today</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="table-responsive">
                                            <table className="table project-list-table table-nowrap align-middle table-borderless mb-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{width: '210px'}}>Customer</th>
                                                        <th scope="col" style={{width: '140px'}}>Product Name</th>
                                                        <th scope="col">Order ID</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Stock</th>
                                                        <th scope="col">Trend</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    
                                                    <tr>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-shrink-0 me-3">
                                                                    <img src="assets/images/users/avatar-1.jpg" alt="" className="avatar rounded-circle" />
                                                                </div>
                                                                <div className="flex-grow-1">Neal Matthews</div>
                                                            </div>
                                                        </td>
                                                        <td>Office Chair</td>
                                                        <td>
                                                          <span>#526552</span>
                                                        </td>
                                                        <td>
                                                            $210.00
                                                        </td>
                                                        <td>
                                                            <p className="mb-0"><i className="mdi mdi-square-rounded font-size-10 text-success me-2"></i>Available</p>
                                                        </td>
                                                        <td>
                                                            <div id="chart-sparkline1" data-colors='["--bs-primary"]' className="apex-charts"></div>
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <a className="text-muted dropdown-toggle font-size-18" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                                    <i className="mdi mdi-dots-horizontal"></i>
                                                                </a>
                                                                <div className="dropdown-menu dropdown-menu-end">
                                                                    <a className="dropdown-item" href="#">Edit</a>
                                                                    <a className="dropdown-item" href="#">Print</a>
                                                                    <a className="dropdown-item" href="#">Delete</a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-shrink-0 me-3">
                                                                    <img src="assets/images/users/avatar-2.jpg" alt="" className="avatar rounded-circle" />
                                                                </div>
                                                                <div className="flex-grow-1">Connie Franco</div>
                                                            </div>
                                                        </td>
                                                        <td>Black T-shirt</td>
                                                        <td>
                                                            <span>#746648</span>
                                                        </td>
                                                        <td>
                                                            $150.00
                                                        </td>
                                                        <td>
                                                            <p className="mb-0"><i className="mdi mdi-square-rounded font-size-10 text-success me-2"></i>Available</p>
                                                        </td>
                                                        <td>
                                                            <div id="chart-sparkline2" data-colors='["--bs-primary"]' className="apex-charts"></div>
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <a className="text-muted dropdown-toggle font-size-18" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                                    <i className="mdi mdi-dots-horizontal"></i>
                                                                </a>
                                                                <div className="dropdown-menu dropdown-menu-end">
                                                                    <a className="dropdown-item" href="#">Edit</a>
                                                                    <a className="dropdown-item" href="#">Print</a>
                                                                    <a className="dropdown-item" href="#">Delete</a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-shrink-0 me-3">
                                                                    <img src="assets/images/users/avatar-3.jpg" alt="" className="avatar rounded-circle" />
                                                                </div>
                                                                <div className="flex-grow-1">Paul Reynolds</div>
                                                            </div>
                                                        </td>
                                                        <td>Smart Watch</td>
                                                        <td>
                                                            <span>#125635</span>
                                                        </td>
                                                        <td>
                                                          $260.00
                                                        </td>
                                                        <td>
                                                            <p className="mb-0"><i className="mdi mdi-square-rounded font-size-10 text-danger me-2"></i>Out Of Stock</p>
                                                        </td>
                                                        <td>
                                                            <div id="chart-sparkline3" data-colors='["--bs-primary"]' className="apex-charts"></div>
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <a className="text-muted dropdown-toggle font-size-18" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                                    <i className="mdi mdi-dots-horizontal"></i>
                                                                </a>
                                                                <div className="dropdown-menu dropdown-menu-end">
                                                                    <a className="dropdown-item" href="#">Edit</a>
                                                                    <a className="dropdown-item" href="#">Print</a>
                                                                    <a className="dropdown-item" href="#">Delete</a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-shrink-0 me-3">
                                                                    <img src="assets/images/users/avatar-4.jpg" alt="" className="avatar rounded-circle" />
                                                                </div>
                                                                <div className="flex-grow-1">Ronald Patton</div>
                                                            </div>
                                                        </td>
                                                        <td>Apple Phone</td>
                                                        <td>
                                                            <span>#236521</span>
                                                        </td>
                                                        <td>
                                                            $170.00
                                                        </td>
                                                        <td>
                                                            <p className="mb-0"><i className="mdi mdi-square-rounded font-size-10 text-success me-2"></i>Available</p>
                                                        </td>
                                                        <td>
                                                            <div id="chart-sparkline4" data-colors='["--bs-primary"]' className="apex-charts"></div>
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <a className="text-muted dropdown-toggle font-size-18" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                                    <i className="mdi mdi-dots-horizontal"></i>
                                                                </a>
                                                                <div className="dropdown-menu dropdown-menu-end">
                                                                    <a className="dropdown-item" href="#">Edit</a>
                                                                    <a className="dropdown-item" href="#">Print</a>
                                                                    <a className="dropdown-item" href="#">Delete</a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-shrink-0 me-3">
                                                                    <img src="assets/images/users/avatar-5.jpg" alt="" className="avatar rounded-circle" />
                                                                </div>
                                                                <div className="flex-grow-1"> Adella Perez</div>
                                                            </div>
                                                        </td>
                                                        <td>New Shoes</td>
                                                        <td>
                                                            <span>#236521</span>
                                                            
                                                        </td>
                                                        <td>
                                                            $240.00
                                                        </td>
                                                        <td>
                                                            <p className="mb-0"><i className="mdi mdi-square-rounded font-size-10 text-danger me-2"></i>Out Of Stock</p>
                                                        </td>
                                                        <td>
                                                            <div id="chart-sparkline5" data-colors='["--bs-primary"]' className="apex-charts"></div>
                                                        </td>
                                                        <td>
                                                            <div className="dropdown">
                                                                <a className="text-muted dropdown-toggle font-size-18" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                                    <i className="mdi mdi-dots-horizontal"></i>
                                                                </a>
                                                                <div className="dropdown-menu dropdown-menu-end">
                                                                    <a className="dropdown-item" href="#">Edit</a>
                                                                    <a className="dropdown-item" href="#">Print</a>
                                                                    <a className="dropdown-item" href="#">Delete</a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
    
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>*/}

                    {user?.nivel === 0 && (<Dev />)}
                    {user?.nivel === 1 && (<Rede />)}
                    {user?.nivel === 2 && (<Unidade />)}
                    {user?.nivel === 3 && (<Unidade />)}
                    {user?.nivel === 4 && (<Vendedor />)}
                    {user?.nivel === 5 && (<Vendedor />)}
                    {user?.nivel === 6 && (<Vendedor />)}
                </div>
            </div>
        </div>
    );
}
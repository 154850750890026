import React, { useState, useEffect, useRef,  forwardRef, useContext, useImperativeHandle } from 'react';
import { Context } from '../../Context/AuthContext';
import { HubContext } from '../../Context/HubContext.js';
import './index.css';


let startLandMarksVar = false;
let viewLandMarksVar = false;
let timeout_face_reconition;

export const Camera = forwardRef((props, ref) => {
    const component_name = "camera";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const timerIdleRef = useRef(new Date());
    const prestineRef = useRef(false);
    const [cameras, setCameras] = useState([]);
    const [selectedCamera, setSelectedCamera] = useState();
    const [viewLandMarks, setViewLandMarks] = useState(false);
    const [startLandMarks, setStartLandMarks] = useState(false);
    const videoRef = useRef();
    const canvasRef = useRef();
    const captureRef = useRef();
    const camerasRef = useRef();
    const webCamPromise = useRef();

    let videoElement = null;
    let canvasElement = null;
    let captureElement = null;
    let canvasCtx = null;
    let captureCtx = null;

     //#region Effects
     useEffect(()=> {
        if(!prestineRef.current) {
             prestineRef.current = true;
             Init();
        }
  
     
         return () => {
             if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                 try {
                    clearTimeout(timeout_face_reconition);
                    console.log("Unmount camera")
                 } catch(e) {}
             }
         }
    }, []);


    /*useEffect(() => {
        if(startLandMarks) Timer();
    }, [startLandMarks]);*/
    //#endregion Effects
 
 
    //#region Ref
     useImperativeHandle(ref, (args) => ({
        async Snapshot() { return Snapshot() },
        async Play(lands = true) { Play(lands) },
        async Stop() { Stop() },
        async InitCamera() { InitCamera()}
    }));
    //#endregion Ref

 
    //#region Init
    const Init = async() => {
        videoElement = videoRef.current;
        canvasElement = canvasRef.current;
        canvasCtx = canvasElement.getContext('2d');
        captureElement = captureRef.current;
        captureCtx = captureElement.getContext('2d');
        ListCameras();
    }


    const Stop = () => {
        try {
            startLandMarksVar = false;
            viewLandMarksVar = false;
            setStartLandMarks(false);
            setViewLandMarks(false);
            //let isVideoPlaying = ( videoRef.current.currentTime > 0 && ! videoRef.current.paused && ! videoRef.current.ended &&  videoRef.current.readyState > 2);
           // if(isVideoPlaying) videoRef.current.stop();
           
        } catch(e) {  }
    }


    const Play = (lands) => {
        try {
            console.log("play");
            videoElement = videoRef.current;
            canvasElement = canvasRef.current;
            canvasCtx = canvasElement.getContext('2d');
            captureElement = captureRef.current;
            captureCtx = captureElement.getContext('2d');
            startLandMarksVar = lands;
            viewLandMarksVar = lands;
            setStartLandMarks(lands);
            setViewLandMarks(lands);
            //let isVideoPlaying = ( videoRef.current.currentTime > 0 && ! videoRef.current.paused && ! videoRef.current.ended &&  videoRef.current.readyState > 2);
            //if(!isVideoPlaying) videoRef.current.play();
        } catch(e) {  }
    }


    const InitCamera = async(camId) => {
        try {
            //window.screen.orientation.lock("portrait");
        } catch(e) {}


        try {
            //videoRef.current.stop();
        } catch(e) {}

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            const width = window.innerWidth
            const height = window.innerHeight
            const aspectRatio = width / height
            const reverseAspectRatio = height / width

            const mobileAspectRatio = reverseAspectRatio > 1.5 ? reverseAspectRatio + (reverseAspectRatio * 12 / 100) : reverseAspectRatio

            const tabletScreen = true;
            //console.log( (camId ? camId : camerasRef.current.deviceId));
          

            webCamPromise.current = navigator.mediaDevices.getUserMedia({audio: false, video: { deviceId: {exact: (camId ? camId : camerasRef.current.deviceId)}, facingMode: 'user', aspectRatio: tabletScreen ? mobileAspectRatio : aspectRatio}}).then(stream => {
                window.stream = stream;
                videoRef.current.srcObject = stream;
                videoRef.current.play();
                props.OnStream(stream);
              

                return new Promise((resolve, reject) => {
                    videoRef.current.onloadedmetadata = () => {
                        console.log("onloadedmetadata");
                        Timer();
                        props.OnCamera();
                        resolve();
                     };
                   });
            });
             
           
        }
    }
    //#endregion Init


  



    //#region Handlers
    const _HandleCameraChange = (event) => {
        //let _cameras = [...cameras];
        let deviceId = event.target.value;
        //let selCamera = _cameras.find(c => c.deviceId === deviceId);
        //setSelectedCamera(selCamera);
        //InitCamera(deviceId);
    }
    //#endregion Handlers


    //#region Facemesh
    const onResults = async (results) => { 
        canvasCtx.save();
        canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
        //canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height); 

        //console.log('onResults', viewLandMarksVar);

        let  op_srt = "FF"

        if (results.multiFaceLandmarks) {
            for (const l of results.multiFaceLandmarks) {
                if(viewLandMarksVar) {
                    window.drawConnectors(canvasCtx, l, window.FACEMESH_TESSELATION, { color: '#ffffff'+ op_srt, lineWidth: 0.2 });
                    window.drawConnectors(canvasCtx, l, window.FACEMESH_RIGHT_EYE, { color: '#FF3030' + op_srt, lineWidth: 0.2  });
                    window.drawConnectors(canvasCtx, l, window.FACEMESH_RIGHT_EYEBROW, { color: '#FF3030' + op_srt, lineWidth: 0.2  });
                    window.drawConnectors(canvasCtx, l, window.FACEMESH_RIGHT_IRIS, { color: '#0000FF' + op_srt, lineWidth: 0.2  });
                    window.drawConnectors(canvasCtx, l, window.FACEMESH_LEFT_EYE, { color: '#30FF30' + op_srt, lineWidth: 0.2  });
                    window.drawConnectors(canvasCtx, l, window.FACEMESH_LEFT_EYEBROW, { color: '#30FF30' + op_srt, lineWidth: 0.2  });
                    window.drawConnectors(canvasCtx, l, window.FACEMESH_LEFT_IRIS, { color: '#0000FF' + op_srt, lineWidth: 0.2  });
                    window.drawConnectors(canvasCtx, l, window.FACEMESH_FACE_OVAL, { color: '#E0E0E0'+ op_srt, lineWidth: 1  });
                    window.drawConnectors(canvasCtx, l, window.FACEMESH_LIPS, { color: '#E0E0E0' + op_srt, lineWidth: 0.2  });
                }
            }
        }
    }

    const faceMesh = new window.FaceMesh({
        locateFile: (file) => {
            console.log(`https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`);
            return `js/${file}`;
        }
    });

    faceMesh.setOptions({
        selfieMode: true,
        maxNumFaces: 1,
        refineLandmarks: true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5
    });

    faceMesh.onResults(onResults);


    const Timer = async() => {
        //console.log('Timer', startLandMarksVar);
        try {
          
        } catch(e) { }

        if(startLandMarksVar){
            //console.log(window.$("#camera").width(), window.$("#camera").height());
            await faceMesh.send({ image: videoElement ? videoElement : videoRef.current });
            if(window.$("#camera").width()) timeout_face_reconition = setTimeout(() => Timer(), 30);
        } else {
            timeout_face_reconition = setTimeout(() => {
                try {
                    canvasCtx.save();
                    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
                } catch(e) { }
                Timer();
            }, 100);
        }
    }
    //#endregion Facemesh
    


    //#region Functions
    const ListCameras = () => {
        let _cameras = [];
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.enumerateDevices().then(function (devices) {
                for(var i = 0; i < devices.length; i ++){
                    var device = devices[i];
                    //console.log(device);
                    if (device.kind === 'videoinput') {
                       /* var option = document.createElement('option');
                        option.value = device.deviceId;
                        option.text = device.label || 'camera ' + (i + 1);
                        document.querySelector('select#videoSource').appendChild(option);
                        */
                        _cameras.push({deviceId: device.deviceId, label: (device.label || 'camera ' + (i + 1))});
                    }
                };

                camerasRef.current = _cameras;
                setCameras(_cameras);
                InitCamera();
            });
        }
    }


    const StopCamera = () => {
        try {
            let stream = videoRef.current.srcObject;
            let tracks = stream.getVideoTracks();
            tracks.forEach(track => {
                track.stop();
            }); 
        } catch(e) { }
        //videoRef.current = null;
    }


    const Snapshot = () => {
        captureElement = captureRef.current;
        captureCtx = captureElement.getContext('2d');
        captureCtx.save();
        captureCtx.clearRect(0, 0, window.$("#camera").width(), window.$("#camera").height());


        const width =  window.$("#camera").width()
        const height =  window.$("#camera").height()
        const aspectRatio = width / height
        const reverseAspectRatio = height / width

        const mobileAspectRatio = reverseAspectRatio > 1.5 ? reverseAspectRatio + (reverseAspectRatio * 12 / 100) : reverseAspectRatio;


        let video = document.getElementById("camera");
        captureCtx.canvas.width = width;
        captureCtx.canvas.height = height;
        //console.log(width, height, window.$("#camera").width(), window.$("#camera").height());
        captureCtx.drawImage(video, 0, 0,  captureCtx.canvas.width, captureCtx.canvas.height); 
        props.OnSnapshot(captureElement.toDataURL());
    }

   
    //#endregion Functions


    return (
        <div className="Camera">
            <video id="camera" className="input_video" autoPlay muted ref={videoRef} />
            <canvas className="output_canvas" ref={canvasRef} />
            <canvas className="capture_canvas" ref={captureRef} />
            
        </div>
      )
  });
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Req, Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import { Currency, UnCurrency, Number, Uuid } from '../../../scripts/StringUtils';
import { useNavigate, useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Tooltip as ReactTooltip } from "react-tooltip";
import moment from 'moment/moment';
import "react-tooltip/dist/react-tooltip.css";
import './index.css';

import { Espelho } from './Espelho';
import { Cliente } from './Cliente';
import { Medico } from './Medico';
import { Receita } from './Receita';
import { Armacoes } from './Armacoes';
import { Anamnese } from './Anamnese';
import { Lentes } from './Lentes';
import { Acessorios } from './Acessorios';
import { Finalizar } from './Finalizar';


let current_tab_loaded = 0;
let clearing_session = false;


export default function Atendimento() {
    const component_name = "admin/atendimento";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [step, setStep] = useState();
    const [tabIndex, setTabIndex] = useState();
    const [medico, setMedico]= useState("");
    const [cliente, setCliente]= useState("");
    
    const espelhoRef = useRef();
    const clienteRef = useRef();
    const anamneseRef = useRef();
    const medicoRef = useRef();
    const receitaRef = useRef();
    const armacoesRef = useRef();
    const lentesRef = useRef();
    const acessoriosRef = useRef();
    const finalizarRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const navigate = useNavigate();

    let { TabId } = useParams();

    
    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        RegisterHub();

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(async() => {
                    Init();
                    
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);


    //#endregion Effects


    //#region Init
    const Init = async() => {
        /*window.$(".Atendimento .nav-tabs a[data-bs-toggle='tab']").on("click", function(e) {
            console.log(1);
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            window.$('.nav-link a[href="#home2"]').click();
            return false;
        });*/

        window.$(".Atendimento .nav-tabs a[data-bs-toggle='tab']").on('shown.bs.tab', function (e) {
            try {
                let sid = parseInt(window.$(e.target).attr('id').replace("id", ""));
                setTabIndex(sid);
                if(sid === 8) {
                    finalizarRef.current.Init();
                    setTabIndex(sid);
                    setStep(sid);
                }
                Tracker(sid);
            } catch(e) {}
        });

        if(TabId) {
            Tracker(TabId);
            setStep(parseInt(TabId));
            setTabIndex(parseInt(TabId));
        }

        let current_atend = await Hub.Get("current_atend", true);

        if(current_atend && current_atend.step) {
            Tracker(current_atend.step);
            setStep(current_atend.step);
            setTabIndex(current_atend.step);
        } else {
            console.log("step 0");
            await Hub.Update("current_atend", "step", 0, true);
            await Hub.Update("current_atend", "guid", Uuid(), true);
            await Hub.Update("current_atend", "date", moment().format("DD/MM/YYYY"), true);
            await Tracker(0);
            setStep(0);
            setTabIndex(0);
        }
    }
    
    //#endregion Init


    //#region Hub
    const RegisterHub = async() => {
        console.log("current_atend " + await Hub.Exists("current_atend", true)); 
        if(!await Hub.Exists("current_atend", true)) 
        {
            let current_atend = await Hub.Get("current_atend", true);
            console.log("current_atend", current_atend);
            await Hub.Update("current_atend", "step", 0, true);
            await Hub.Update("current_atend", "guid", Uuid(), true);
            await Hub.Update("current_atend", "date", moment().format("DD/MM/YYYY"), true);
            await Tracker(0);
        }
        if(!await Hub.Exists("CLEAR_SESSION")) Hub.Set("CLEAR_SESSION", (message) => {
            ClearSession(message);
        });
    }


    const Tracker = async value => {
        let current_atend = await Hub.Get("current_atend", true);
        if(!current_atend?.guid || current_atend?.guid === "") await Hub.Update("current_atend", "guid", Uuid(), true);
        await Req("api/AtendimentoFinalizar/Tracker", "POST", {guid: current_atend.guid, step: value}, user.token, 0);
    }

    const SetClienteNome = (value) => {
        setCliente(value);
    }


    const SetMedicoNome = (value) => {
        setMedico(value);
    }


    const ClearSession = async(message) => {
        if(message) console.log(message);
        clearing_session = false;

        if(!clearing_session) {
            clearing_session = true
            console.log("CLEAR_SESSION");
            window.alertify.message("Limpando sessão");
            await Hub.Delete("current_atend", true);
            Hub.Exec("CAMERA_CLOSE");
            setCliente("");
            setMedico("");

            setTimeout(async() => {
                console.log("step 0");
                await Hub.Update("current_atend", "step", 0, true);
                await Hub.Update("current_atend", "guid", Uuid(), true);
                await Hub.Update("current_atend", "date", moment().format("DD/MM/YYYY"), true);
                await Tracker(0);
                espelhoRef.current.Clear();
                clienteRef.current.Clear();
                anamneseRef.current.Clear();
                medicoRef.current.Clear();
                receitaRef.current.Clear();
                armacoesRef.current.Clear();
                lentesRef.current.Clear();
                acessoriosRef.current.Clear();
                finalizarRef.current.Clear();
                current_tab_loaded = 0;
                setStep(0);
                setTabIndex(0);
                espelhoRef.current.Init();
                //navigate("/Redirect/Atendimento");
                setTimeout(() => { clearing_session = false }, 300);
            }, 300);
        }
    }
    //#endregion Hub


    //#region Handlers
    const _HandleNext = async(tabId) => {
        //Hub.Debug("current_atend");
        console.log("step " + (tabId+1));
        await Hub.Update("current_atend", "step", (tabId+1), true);
        //Hub.Debug("current_atend");
        Hub.Exec("CAMERA_CLOSE");
        
        //console.log(tabId+1);
        let current_atend = await Hub.Get("current_atend", true);
        await Tracker((tabId+1));
        //console.log(current_atend);

        window.scrollTo({top: 0, behavior: 'smooth'});
       

        switch(tabId+1) {
            case 0:
                espelhoRef.current.Init();
                break;
            case 1:
                //clienteRef.current.Init();
                break;
            case 2:
                if(current_atend && current_atend.cliente) setCliente(current_atend.cliente?.nome);
                //anamneseRef.current.Init();
                break;
            case 3:
                //medicoRef.current.Init();
                break;
            case 4:
                if(current_atend && current_atend.medico) setMedico(current_atend.medico?.nome);
                receitaRef.current.Init();
                break;
            case 5:
                await Hub.DeleteKey("current_atend", "armacao", true);
                //console.log("Hub.DeleteKey");
                armacoesRef.current.Init();
                break;
            case 6:
                
                //console.log(current_atend);

                await Hub.DeleteKey("current_atend", "lentes", true);
                await Hub.DeleteKey("current_atend", "os", true);
                lentesRef.current.Clear();
                lentesRef.current.Init();
                break;
            case 7:
                acessoriosRef.current.Init();
                break;
            case 8:
                finalizarRef.current.Init();
                break;
        }

        //console.log(step, tabId+1);

        setTabIndex((tabId+1));
        setStep((tabId+1));
    }


    const _HandleTabUpdate = (tabId) => {
        setStep(tabId);  
        ClearFutureSteps(tabId);
    }
    //#endregion Handlers


    //#region Functions
    const ClearFutureSteps = (index) => {
        if(index === 0) { //Espelho
            clienteRef.current.Clear();
            anamneseRef.current.Clear();
            medicoRef.current.Clear();
            receitaRef.current.Clear();
            armacoesRef.current.Clear();
            lentesRef.current.Clear();
            finalizarRef.current.Clear();
        } else if(index === 1) { //Cliente
            anamneseRef.current.Clear();
            medicoRef.current.Clear();
            receitaRef.current.Clear();
            armacoesRef.current.Clear();
            lentesRef.current.Clear();
            finalizarRef.current.Clear();
        } else if(index === 2) { //Anamnese
            lentesRef.current.Clear();
            finalizarRef.current.Clear();
        } else if(index === 3) { //Médico
            receitaRef.current.Clear();
            armacoesRef.current.Clear();
            lentesRef.current.Clear();
            finalizarRef.current.Clear();
        } else if(index === 4) { //Receita
            armacoesRef.current.Clear();
            lentesRef.current.Clear();
            finalizarRef.current.Clear();
        } else if(index === 5) { //Armações
            lentesRef.current.Clear();
            finalizarRef.current.Clear();
        } else if(index === 6) { //Lentes
            finalizarRef.current.Clear();
        } else if(index === 7) { //Acessórios
            finalizarRef.current.Clear();
        }
        
    }
    //#endregion Functions



    return (
        <div className="main-content Atendimento">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title left">Atendimento &nbsp;&nbsp; <span className={cliente===''?'hide':''} style={{color:'#8dc572'}}> &nbsp;&nbsp; <b>Cliente:</b> </span> <span style={{color:'#777777'}}>{cliente}</span> <span className={medico===''?'hide':''} style={{color:'#8dc572'}}> &nbsp;&nbsp; <b>Médico:</b> </span> <span style={{color:'#777777'}}>{medico}</span></h4> 
                                    <span className="bg-danger badge right" onClick={ClearSession}>Novo Atendimento </span>
                                </div>

                                <div className="card-body pb-2">

                                    <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                        <li className={'nav-item ' + (step<0&&'disabled ')}>
                                            <a id="id0" className={'nav-link ' + (tabIndex===0?'active':'')} data-bs-toggle="tab" href="#home1" role="tab">
                                                <span className="d-none d-sm-block"> <i className="mdi mdi-account-box-outline md-24"></i> &nbsp; Espelho Virtual &nbsp;  </span> 
                                            </a>
                                        </li>

                                        <li className={'nav-item ' + (step<0&&'disabled ')}>
                                            <a id="id1" className={'nav-link ' + (tabIndex===1?'active':'')} data-bs-toggle="tab" href="#home2" role="tab">
                                                <span className="d-none d-sm-block"> <i className="mdi mdi-account md-24"></i> &nbsp; Cliente &nbsp;  </span> 
                                            </a>
                                        </li>

                                        <li className={'nav-item ' + (step<2&&'disabled ')}>
                                            <a id="id2" className={'nav-link ' + (tabIndex===2?'active':'')} data-bs-toggle="tab" href="#home3" role="tab">
                                                <span className="d-none d-sm-block"> <i className="mdi mdi-text-box-check-outline md-24"></i> &nbsp; Anamnese &nbsp;  </span> 
                                            </a>
                                        </li>

                                        <li className={'nav-item ' + (step<3&&'disabled ')}>
                                            <a id="id3" className={'nav-link ' + (tabIndex===3?'active':' ')} data-bs-toggle="tab" href="#home4" role="tab">
                                                <span className="d-none d-sm-block"> <i className="mdi mdi-account-child-circle md-24"></i> &nbsp; Médico &nbsp;  </span> 
                                            </a>
                                        </li>

                                        <li className={'nav-item ' + (step<4&&'disabled ')}>
                                            <a id="id4" className={'nav-link ' + (tabIndex===4?'active':'')} data-bs-toggle="tab" href="#home5" role="tab">
                                                <span className="d-none d-sm-block"> <i className="mdi mdi-pill md-24" ></i> &nbsp; Receita &nbsp;  </span> 
                                            </a>
                                        </li>


                                        <li className={'nav-item ' + (step<5&&'disabled ')}>
                                            <a id="id5" className={'nav-link ' + (tabIndex===5?'active':'')} data-bs-toggle="tab" href="#home6" role="tab">
                                                <span className="d-none d-sm-block"> <i className="mdi mdi-sunglasses md-24"></i> &nbsp; Armações &nbsp;  </span> 
                                            </a>
                                        </li>

                                        <li className={'nav-item ' + (step<6&&'disabled ')}>
                                            <a id="id6" className={'nav-link ' + (tabIndex===6?'active':'')} data-bs-toggle="tab" href="#home7" role="tab">
                                                <span className="d-none d-sm-block"> <i className="mdi mdi-eye-check-outline md-24"></i> &nbsp; Lentes &nbsp;  </span> 
                                            </a>
                                        </li>

                                        <li className={'nav-item ' + (step<7&&'disabled ')}>
                                            <a id="id7" className={'nav-link ' + (tabIndex===7?'active':'')} data-bs-toggle="tab" href="#home8" role="tab">
                                                <span className="d-none d-sm-block"> <i className="mdi mdi-shopping-outline md-24"></i> &nbsp; Acessórios &nbsp;  </span> 
                                            </a>
                                        </li>

                                        <li className={'nav-item ' + (step<7&&'disabled ')}>
                                            <a id="id8" className={'nav-link ' + (tabIndex===8?'active':'')} data-bs-toggle="tab" href="#home9" role="tab">
                                                <span className="d-none d-sm-block"> <i className="mdi mdi-sticker-check-outline md-24"></i> &nbsp; Finalizar &nbsp;  </span> 
                                            </a>
                                        </li>
                                    </ul>

                            
                                    <div className="tab-content text-muted">
                                        <div className={'tab-pane ' + (tabIndex===0?'active':'hide')} id="home1" role="tabpanel">
                                            <Espelho ref={espelhoRef} OnNext={() => _HandleNext(0)} OnChange={() => _HandleTabUpdate(0)}/>
                                        </div>

                                        <div className={'tab-pane ' + (tabIndex===1?'active':'hide')} id="home2" role="tabpanel">
                                            <Cliente ref={clienteRef} OnNext={() => _HandleNext(1)} OnChange={() => _HandleTabUpdate(1)}/>
                                        </div>

                                        <div className={'tab-pane ' + (tabIndex===2?'active':'hide')} id="home3" role="tabpanel">
                                            <Anamnese ref={anamneseRef} OnNext={() => _HandleNext(2)} OnChange={() => _HandleTabUpdate(2)}/>
                                        </div>

                                        <div className={'tab-pane ' + (tabIndex===3?'active':'hide')} id="home4" role="tabpanel">
                                            <Medico ref={medicoRef} OnNext={() => _HandleNext(3)} OnChange={() => _HandleTabUpdate(3)}/>
                                        </div>

                                        <div className={'tab-pane ' + (tabIndex===4?'active':'hide')} id="home5" role="tabpanel">
                                            <Receita ref={receitaRef} OnNext={() => _HandleNext(4)} OnChange={() => _HandleTabUpdate(4)}/>
                                        </div>

                                        <div className={'tab-pane ' + (tabIndex===5?'active':'hide')} id="home6" role="tabpanel">
                                            <Armacoes ref={armacoesRef} OnNext={() => _HandleNext(5)} OnChange={() => _HandleTabUpdate(5)}/>
                                        </div>

                                        <div className={'tab-pane ' + (tabIndex===6?'active':'hide') }id="home7" role="tabpanel">
                                            <Lentes ref={lentesRef} OnNext={() => _HandleNext(6)} OnChange={() => _HandleTabUpdate(6)}/>
                                        </div>

                                        <div className={'tab-pane ' + (tabIndex===7?'active':'hide') }id="home8" role="tabpanel">
                                            <Acessorios ref={acessoriosRef} OnNext={() => _HandleNext(7)} OnChange={() => _HandleTabUpdate(7)} />
                                        </div>

                                        <div className={'tab-pane ' + (tabIndex===8?'active':'hide') }id="home9" role="tabpanel">
                                            <Finalizar ref={finalizarRef} />
                                        </div>
                                    </div>

                                    <br />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useImperativeHandle, useEffect, useRef, forwardRef} from 'react';
import { Context } from '../../../../Context/AuthContext';
import { HubContext } from '../../../../Context/HubContext.js';
import { Request } from '../../../../scripts/Request';
import { WaitObject } from '../../../../scripts/Wait';
import { FormGenerator } from '../../../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";
import ReactGA from 'react-ga';
import './index.css';


let current_element;
let search_medico;
let search_medico_element;
let medico_auto_fill = false;

export const Medico = forwardRef((props, ref) => {
    const component_name = "admin/medico_cadastro";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [medicoId, setMedicoId] = useState('');
    const [formChanged, setFormChanged] = useState(false);

    const _formUserRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);


    

    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(current_element? current_element :".main-content")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects


    //#region Ref
    useImperativeHandle(ref, (url, args, token) => ({
        async Init() { return Init() },
        async Clear() { return ClearComponent() }
    }));
    //#endregion Ref


    //#region Init
    const Init = async() => {
        let current_atend = await Hub.Get("current_atend", true);

        WaitObject(() => _formUserRef.current.IsLoaded()).then(async() => {
            if(current_atend && current_atend.medico) {
                medico_auto_fill = true;
                _formUserRef.current.Load(current_atend.medico);
                if(current_atend.medico_id) setMedicoId(current_atend.medico_id);
            }
        }); 
    }


    const ClearComponent = () => {
        ClearMedico();
        setMedicoId('');
        setFormChanged(false);
    }
    //#endregion Init


    //#region Loaders
    const LoadUfs = (uf = 26) => {
        Promise.resolve(new Request().Run("/assets/json/estados.json", "GET", "", "", 0*24*60*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            await _formUserRef.current.Select("uf_codigo", data);
            await _formUserRef.current.Value("uf_codigo", uf);
            LoadCidades(uf)

            if(data.toString() !== "error") {
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATMED001)", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATMED002)", "error");
        });
    }


    const LoadCidades = (uf_codigo, city = "") => {
        if(!uf_codigo || uf_codigo==="") return;
        
        Promise.resolve(new Request().Run("api/Clientes/Cidades?uf_codigo=" + uf_codigo, "GET", "", user.token, 0*24*60*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            data.unshift({id:"", name: "Selecione uma cidade"});
            await _formUserRef.current.Select("cidade_codigo", data);

            if(city !== "") {
                city = city./*normalize('NFD').replace(/[\u0300-\u036f]/g, "").*/toLocaleUpperCase();
                let _id = data.find(c => c.name === city).id;
                await _formUserRef.current.Value("cidade_codigo", _id);

                let current_atend = await Hub.Get("current_atend", true);
                if(current_atend.medico?.cidade_codigo !== _id) Hub.Update("current_atend", "medico",_formUserRef.current.GetData(), true);
            }

            if(data.toString() !== "error") {
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATMED003)", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATMED004)", "error");
        });
    }


    const LoadMedicos = (word) => {
        current_element = ".Medico .choices__inner";
        setPageLoading(true);

        Promise.resolve(new Request().Run("api/Medicos/Search", "POST", {word: word}, user.token, 0*24*60*1000))
        .then(async(data) => {
            current_element = "";
            setPageLoading(false);
            if(data.toString() !== "error") {
                let r = [];
                data.map((item) => {
                    r.push({id: item.id, value:item.id, label:item.name})
                });
                try {
                    search_medico_element.clearChoices().setChoices(r);
                } catch(e) { }
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATMED005)", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATMED006)", "error");
        });
    }
    //#endregion Loaders



    //#region Form Handlers
    const cep = async value => {
        if(!medico_auto_fill) {
            let _rua = await _formUserRef.current.GetValue("rua");
            if(value.length === 9 /*&& _rua === ""*/) {
                /*Promise.resolve(new Request().Run("https://brasilapi.com.br/api/cep/v1/" + value.replace('-',''), "GET", "", "", 0*24*60*1000)).then(async(data) => {
                    if(data.toString() !== "error") {
                        await _formUserRef.current.Value("rua", data.street);
                        await _formUserRef.current.Value("bairro", data.neighborhood);
                        let _uf_options = await _formUserRef.current.GetOptions("uf_codigo");
                        let _uf = _uf_options.find(u => u.sigla === data.state).id;
                        await _formUserRef.current.Value("uf_codigo", _uf);
                        LoadCidades(_uf, data.city);

                    }
                }).catch(() => {
                
                });*/

                Promise.resolve(new Request().Run("https://viacep.com.br/ws/" + value.replace('-', '') + "/json", "GET", "", "", 0*24*60*1000)).then(async(data) => {
                    if(data.toString() !== "error") {
                        await _formUserRef.current.Value("rua", data.logradouro);
                        await _formUserRef.current.Value("bairro", data.bairro);
                        let _uf_options = await _formUserRef.current.GetOptions("uf_codigo");
                        let _uf = _uf_options.find(u => u.sigla === data.uf).id;
                        await _formUserRef.current.Value("uf_codigo", _uf);
                        LoadCidades(_uf, data.localidade);
                    }
                }).catch(() => {
                
                });
            }
        }
    }


    const uf_codigo = value => {
        if(!medico_auto_fill) {
            LoadCidades(value);
        }
    }


    const onFormGenerator = () => {
        if(!medico_auto_fill) {
            setFormChanged(true);
            Hub.Update("current_atend", "medico",_formUserRef.current.GetData(), true);
        }
    }


    const OnFormLoaded = async(fields) => {
        setPageLoading(true);
 
        WaitObject(_formUserRef.current).then(async() => {
            window.$(".medicoDiv .separatorbar_content").html('<select class="form-control" data-trigger="" name="search-medico" id="search-medico"></select>');
            window.$(".medicoDiv .separatorbar_actions").html ('<span class="bg-primary badge" onclick="window.ClearMedico()">Limpar Médico</span>');
            setFormChanged(false);

            setTimeout(()=> {
                LoadUfs();
                
                 let c = 0;
                 let wait_choices = setInterval(()=> {
                    let wid = window.jQuery("#search-medico").width();

                    console.log(wid, (typeof search_medico_element).toString(), c)

                    if((wid >= 228) && (typeof search_medico_element).toString()=== "object") {
                        clearInterval(wait_choices);
                    } else {
                        c++;
                        console.log("search_medico_element");
                        search_medico = document.getElementById("search-medico");
                        search_medico_element = new window.Choices(search_medico, {
                            placeholderValue:"",
                            searchPlaceholderValue:"Digite o nome, crm ou celular",
                            noChoicesText: "",
                            noResultsText: "Nenhum Resultado encontrado",
                            itemSelectText: "Clique para selecionar"
                        });
        
                        search_medico.addEventListener('change',_HandleSearchChoose, false );
                        search_medico.addEventListener('search',_HandleSearchChange, false );
                        medico_auto_fill = false;
                    }
                }, 100);
            }, 100);

            setPageLoading(false);
        });
        
    }

    
    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const ClearMedico = async() => {
        _formUserRef.current.Clear();
        await Hub.DeleteKey("current_atend", "medico_id", true);
        await Hub.DeleteKey("current_atend", "medico", true);
        setFormChanged(false);
        setMedicoId('');
        try {
            search_medico_element.clearInput();
            search_medico_element.clearStore();
        } catch(e) {}
        //search_element.clearChoices();
    }
    window.ClearMedico = ClearMedico;


    let wait_typing;
    const _HandleSearchChange = (event) => {
        if(event.detail.value.length > 2) {
            clearTimeout(wait_typing);

            wait_typing = setTimeout(() => {
                _HandleTimerSearchChange(event.detail.value);
            }, 700);
        }
    }


    const _HandleTimerSearchChange = (word) => {
        LoadMedicos(word);
    }


    const _HandleSearchChoose = async(event) => {
        let id = parseInt(event.detail.value);
        if(!isNaN(id) && id > 0) {
            Hub.Update("current_atend", "medico_id", id, true);
            let medico_data = await new Request().Run("api/Medicos/Load?id="+id, "GET", "", user.token);
            if(medico_data !== undefined && medico_data.medico_id !== null) {
                setMedicoId(medico_data.medico_id);
                await _formUserRef.current.Load(medico_data);
                Hub.Update("current_atend", "medico", medico_data, true);
            }
        }
    }


    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        let validade = _formUserRef.current.RequiredValidation();

        if(validade) {
            setPageLoading(true);

            let current_atend = await Hub.Get("current_atend", true);
            let _medico_id = current_atend.medico_id;
            if(isNaN(_medico_id )) _medico_id = "";

            let result = await _formUserRef.current.Post("api/Medicos/Save", {medico_id: _medico_id}, user.token);
            setPageLoading(false);

            if(result === "error") {
                window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
                setMedicoId('');
                return;
            } else if(result === "exists") {
                window.swal("Alerta", "CPF já registrado por outro médico", "error");
                setMedicoId('');
                return;
            } else {
                setMedicoId(result);
                Hub.Update("current_atend", "medico_id", result, true);
                window.swal("Sucesso", "Médico salvo com sucesso!", "success");
                setFormChanged(false);
                props.OnChange();
            }

       } else {
           window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
       }
    }


    const _HandleNext = () => {
        props.OnNext();
    }
    //#endregion Handlers


    return(
        <div className="Medico">

             <form name="fmedico" onSubmit={(event) => _HandleSubmit('medico', event)} autoComplete="off" id="form-contact" className="" noValidate>
                <div className="row">
                    <div className="col-md-12 pt-1 pb-1">
                        <FormGenerator ref={_formUserRef} name={'medico'} url={'/assets/forms/medico.json'} execRef={Exec}></FormGenerator>
                        <div style={{clear:'both'}}></div>
                        <center>
                            <hr />
                            <button id="btn_sv" className={'btn btn-primary btn-lg btn-rounded mb-2 ' + (formChanged?'': 'hide') } type="submit">Salvar informações</button>
                            <button id="btn_next" className={'btn btn-info btn-lg btn-rounded mb-2 '+ (medicoId!==''&&!formChanged?'': 'hide')} type="button" onClick={_HandleNext}>Avançar para cadastro da receita</button>
                        </center>
                        <br />
                    </div> 
                </div>
            </form>
        </div>
        
    )
});
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import { Currency, UnCurrency, Number } from '../../../scripts/StringUtils';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';


export default function Lista() {
    const component_name = "admin/tolerancias_lista";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);
    const [search, setSearch] = useState({ word: "" });
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [tipos, setTipos] = useState([{id:1, name: "Monofocal"}, {id:3, name: "Multifocal"}]);
    const [selectedItems, setSelectedItems] =  useState([]);
    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: "" });

    const navigate = useNavigate();


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = async() => {
        _HandlerSearchChange();
    }
    //#endregion Init



    //#region Handlers
    const _HandleChange = (event) => {
        setSearch(prev => prev = {[event.target.id]: event.target.value});
        searchRef.current = {[event.target.id]: event.target.value};
    }


    const _HandleVChange = (event, item, field) => {
        let _list = [...list];

        let _index = parseInt(event.target.id.split('_')[1]);
        let _old = Object.assign({},  _list[_index]);

        if(field === "esf_ini" || field === "esf_fim" || field === "cil_ini" || field === "cil_fim") {
            _list[_index][field] = Number(event.target.value, undefined, 2);
        } else {
            _list[_index][field] = event.target.value;
        }

        setList(_list);

        if(_index > -1) {
            let _selectedItems = [...selectedItems];
            let _arrindex = _selectedItems?.findIndex(s => s.index === _index);
            if(_arrindex === -1) {
                _selectedItems.push({index: _index, data: _list[_index], old: _old });
            } else {
                //_selectedItems[_arrindex] = {index: _index, data: _list[_index], old: _old };
            }
            setSelectedItems(_selectedItems);
        }
    }


    const _HandleClear = (event) => {
        setSearch(prev => prev = {word: ""});
        searchRef.current = {word: ""};
        _HandlerSearchChange(event);
    }


    const _HandleSaveItem = (e, i , item) => {
        Promise.resolve(new Request().Run("api/Tolerancias/Update", "POST", item, user.token))
        .then((data) => {
            let _selectedItems = [...selectedItems];
            let _index = _selectedItems?.findIndex(s => s.index === i);

            _selectedItems.splice(_index, 1);
            setSelectedItems(_selectedItems);
        });
    }


    const _HandleCancelItem = (e, i , item) => {
        let _list = [...list];
        let _selectedItems = [...selectedItems];

        let _index = _selectedItems?.findIndex(s => s.index === i);

        if(_index > -1) {
            _list[i] = Object.assign({},  _selectedItems[_index].old);
            _selectedItems.splice(_index, 1);
            setSelectedItems(_selectedItems);
            setList(_list);
        }
    }



    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setPageLoading(true);
        setHasMore(true);

        Promise.resolve(new Request().Run("api/Tolerancias/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", { word: searchRef.current.word }, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];
                listRef.current = listRef.current.concat(_list);
                setList(listRef.current);
                setPageLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setPageLoading(true);

            Promise.resolve(new Request().Run("api/Tolerancias/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", { word: searchRef.current.word }, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    listRef.current = listRef.current.concat(_list);
                    setList(listRef.current);
                    setPageLoading(false);
                });
        } catch (e) { }
    }


    const _HandleEdit = (e, id) => {
        navigate("/Tolerancia/"+id);
    }


    const _HandleAddTolerancias = () => {
        navigate("/Tolerancia/");
    }


    //#endregion Handlers


    return (
        <div className="main-content Tolerancias">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Lista de Tolerancias</h4>
                                    <div className="poll-right">
                                        <button type="button" className="btn btn-outline-primary" onClick={_HandleAddTolerancias}>
                                            <i className="mdi mdi-star-plus"></i> &nbsp;Adicionar
                                        </button>
                                    </div>
                                </div>

                                <div className="card-body pb-2">
                                    <div className="table-responsive">
                                        <InfiniteScroll
                                            dataLength={list.length}
                                            next={_fetchMoreData}
                                            hasMore={hasMore}
                                            loader={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Carregando...</b>
                                                </p>
                                            }
                                            style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                            endMessage={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Não há mais dados</b>
                                                </p>
                                            }>    


                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Tipo</th>
                                                        <th>Esf.de</th>
                                                        <th>Esf.Até</th>
                                                        <th>Esf.de</th>
                                                        <th>Esf.Até</th>
                                                        <th>Força</th>
                                                        <th>Tolerância 1</th>
                                                        <th>Tolerância 2</th>
                                                        <th>CO</th>
                                                        <th>NY</th>
                                                        <th>PA</th>
                                                        <th>SO</th>
                                                        <th style={{width:'40px'}} className={(user.nivel>0?'hide':'')}></th>
                                                        <th style={{width:'40px'}} className={(user.nivel>0||selectedItems.length>-1?'hide':'')}></th>
                                                    </tr>
                                                </thead>


                                                <tbody>
                                                {list.map((item, i) => (
                                                    <tr key={'tr' + i} className={'pointer ' + (selectedItems?.findIndex(s => s.index === i) > -1 ? 'trfocus':'')}>
                                                        <td className="inputtext" style={{width:'120px'}}>
                                                            <select id={"tipo_"+i} value={item.tipo} onChange={(event) => _HandleVChange(event, item, 'tipo')}>
                                                                {
                                                                    Array.isArray(tipos) && tipos.length > 0 && tipos.map((forn, j) =>(
                                                                        <option key={'forn'+i+'_'+j} value={forn.id}>{forn.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </td>
                                                        
                                                        <td className="inputtext">
                                                            <input id={'esf_ini_'+i} type="text" value={item.esf_ini.toString().replace('.', ',')} onChange={(event) => _HandleVChange(event, item, 'esf_ini')}/>
                                                        </td>

                                                        <td className="inputtext">
                                                            <input id={'esf_fim_'+i} type="text" value={item.esf_fim.toString().replace('.', ',')} onChange={(event) => _HandleVChange(event, item, 'esf_fim')}/>
                                                        </td>

                                                        <td className="inputtext">
                                                            <input id={'cil_ini_'+i} type="text" value={item.cil_ini.toString().replace('.', ',')} onChange={(event) => _HandleVChange(event, item, 'cil_ini')}/>
                                                        </td>

                                                        <td className="inputtext">
                                                            <input id={'cil_fim_'+i} type="text" value={item.cil_fim.toString().replace('.', ',')} onChange={(event) => _HandleVChange(event, item, 'cil_fim')}/>
                                                        </td>   

                                                        <td className="inputtext">
                                                            <input id={'forca_'+i} type="text" value={item.forca} onChange={(event) => _HandleVChange(event, item, 'forca')}/>
                                                        </td>  

                                                        <td className="inputtext">
                                                            <input id={'tolerancia1_'+i} type="text" value={item.tolerancia1} onChange={(event) => _HandleVChange(event, item, 'tolerancia1')}/>
                                                        </td>    

                                                        <td className="inputtext">
                                                            <input id={'tolerancia2_'+i} type="text" value={item.tolerancia2} onChange={(event) => _HandleVChange(event, item, 'tolerancia2')}/>
                                                        </td>  
                                                     
                                                       


                                                        <td className="inputcheckbox co">
                                                            <input id={"co_"+i} type="checkbox" checked={item.co===1} disabled={(user.nivel>0)} onChange={(event) => _HandleVChange(event, 'co')}/>
                                                        </td>
                                                        
                                                        <td className="inputcheckbox co">
                                                            <input id={"ny_"+i} type="checkbox" checked={item.ny===1} disabled={(user.nivel>0)} onChange={(event) => _HandleVChange(event, 'ny')}/>
                                                        </td>

                                                        <td className="inputcheckbox co ">
                                                            <input id={"pa_"+i} type="checkbox" checked={item.pa===1} disabled={(user.nivel>0)} onChange={(event) => _HandleVChange(event, 'pa')}/>
                                                        </td>
                                                        
                                                        <td className="inputcheckbox co ">
                                                            <input id={"so_"+i} type="checkbox" checked={item.so===1} disabled={(user.nivel>0)} onChange={(event) => _HandleVChange(event, 'so')}/>
                                                        </td>


                                                        <td style={{padding:'3px 0 0 3px'}} className={(user.nivel>0||(selectedItems?.findIndex(s => s.index === i) > -1)?'hide':'')}>
                                                            <button type="button" className={'btn btn-sm btn-info '} onClick={(e) => _HandleEdit( e, item.tolerancia_id)}><i className="fa fa-edit"></i></button>
                                                        </td>

                                                        <td style={{padding:'3px 0 0 3px'}} className={(user.nivel>0||(selectedItems?.findIndex(s => s.index === i) === -1)?'hide':'')}>
                                                            <button type="button" className={'btn btn-sm btn_ativo btn-danger '} onClick={(e) => _HandleSaveItem( e, i, item)}>Salvar</button>
                                                        </td>

                                                        <td style={{padding:'3px 2px 0 3px'}} className={(user.nivel>0||(selectedItems?.findIndex(s => s.index === i) === -1)?'hide':'')}>
                                                            <button type="button" className={'btn btn-sm btn-warning '} onClick={(e) => _HandleCancelItem( e, i, item)}><i className="fa fa-reply"></i></button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </table>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>
        </div>
    )
}
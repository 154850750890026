/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request, Req } from '../../../scripts/Request';
import { HubObjects } from '../../../scripts/HubObjects';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import { Currency, UnCurrency, Number } from '../../../scripts/StringUtils';
import ReactGA from 'react-ga';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import './index.css';


let whatsapp_response = undefined;

export default function MapaCarga() {
    const component_name = "admin/oss";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const [osNumber, setOsNumber] = useState("");
    const [osNumbers, setOsNumbers] = useState("");
    const [status, setStatus] = useState(1);
    const [unidade, setUnidade]= useState({});

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: "" });


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);



    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        LoadUnidadeInfo();

        document.addEventListener("whatsnap_api_broadcast_response", function (data) {
            //console.log(data)
            whatsapp_response = data;
        });
    }
    //#endregion Init


    //#region Loaders
    const LoadOss = async(idx) => {
        if(!idx) return;
        idx = idx.trim();
        idx = idx.replaceAll("\r\n", ",");
        idx = idx.replaceAll("\n\r", ",");
        idx = idx.replaceAll("\n", ",");
        idx = idx.replaceAll("\r", ",");
        idx = idx.replaceAll(";", ",");
        idx = idx.replaceAll("  ", " ");
        idx = idx.replaceAll(" ", ",");
        idx = idx.replaceAll(",,", ",");

        //console.log(idx);

        let data = await Req("api/MapaCarga/List", "POST", {ids: idx, status: status}, user.token, 0*24*60*60*1000);


        if(data.toString().indexOf("order_error") > -1) {
            window.swal("Alerta", "Mudança de status incorreta: " + data.toString().replace("order_error:",""), "error");
        } else if(data.toString() !== "error") {
            let _list = [...list];
            [].push.apply(_list, data);
            setList(_list);

            setOsNumber("");
            setOsNumbers("");
        } else {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde ", "error");
        }
    }


    const LoadUnidadeInfo = async() => {
        let data = await Req("api/AtendimentoLentes/Unidade?q=" + user.user_id, "GET", "", user.token, 1*24*60*60*1000);

        if(data.toString() !== "error") {
            setUnidade(data);
        } else {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde ", "error");
        }
    }
    //#endregion Loaders


    //#region Handlers
    const _HandleChange = (event) => {
        setOsNumber(prev => prev = event.target.value);
    }


    const _HandleMChange = (event) => {
        setOsNumbers(prev => prev = event.target.value);
    }


    const _HandleStatusChange = (event) => {
        setStatus(prev => prev = event.target.value);
        setList([]);
    }

    const _HandleInclude = (event) => {
        let osn = osNumber;
        LoadOss(osn);
    }

    const _HandleMInclude = (event) => {
        let osn = osNumbers;
        if(osNumbers !== "") {
            LoadOss(osn);
        } else {
            window.swal("Alerta", "A lista está vazia", "error");
        }
    }


    const _HandleIncludeTyping = (event) => {
        let key = event.which ||  event.keyCode;

        let osn = "";

        if (parseInt(key) === 13) { 
            osn = event.target.value;
            LoadOss(osn);
        }    
    }


    const _HandleClear = () => {
        setList([]);
        setOsNumber("");
        setOsNumbers("");
    }


    const _HandleWhatsapp = () => {
        let _contacts = [];
        let _list = [...list];
        
        if(_list.length > 0) {
            _list.map((item, i) => {
                item.celular = item.celular.replace('-','').replace('(','').replace(')','').replace('.','').replace(' ','');
                if(item.celular.substring(0,2) !== '55') item.celular = '55' + item.celular;
                _contacts.push( {number: item.celular, name: item.cliente, message: "Olá " + item.cliente + ", seu pedido Nº " + item.numero +  " está pronto para retirada loja: " + unidade?.nome + ", " + unidade?.endereco + " " + unidade?.numero + " - " + unidade?.bairro + " " + unidade?.cidade + "/" + unidade?.uf})
            });


            let event = new CustomEvent('Event', { 'detail': {
                message:"Olá @name, seu pedido está pronto para retirada ", 
                attachments:[], 
                files:[], 
                list: _contacts
            }});
            event.initEvent('whatsnap_api_broadcast');
            document.dispatchEvent(event);
        }


        let c = 0;
        let wait = setInterval(() => {
            if(whatsapp_response && whatsapp_response?.detail?.status === 200) {
                clearInterval(wait);
                whatsapp_response = undefined;
                window.swal("Sucesso", "Lista de contados carregados na extensão do Whatsapp", "success");
            } else {
                c++;
                //console.log(c);
                if(c===30) {
                    clearInterval(wait);
                    console.log("Whatsapp timeout");
                    whatsapp_response = undefined;
                    window.swal("Alerta", "Extensão do Whatsapp não encontrada", "error");
                }
            }
        }, 100);
    }

    //#endregion Handlers


    return (
        <div className="main-content MapaCarga">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title left">Mapa de Carga </h4>
                                    

                                    <div className="input-group" style={{width:'490px'}}>
                                        <button className={'btn btn-primary ' + (parseInt(status)===3 && list?.length>0?'':'hide')} onClick={_HandleWhatsapp} style={{backgroundColor:'#128C7E',border:'0px'}}><i className="mdi mdi-whatsapp mdi-18px" style={{padding:'0 !important',margin:'0 !important'}}></i> </button>
                                         &nbsp;&nbsp;
                                        <div className="input-group-text" style={{width:'80px'}}>Status</div>

                                        <select className="form-control" value={status} onChange={_HandleStatusChange}>
                                            <option value="0">Liberado para Pedido</option>
                                            <option value="1">Aguardando Lente e/ou Aro</option>
                                            <option value="2">Montagem</option>
                                            <option value="3">Pronta</option>
                                            <option value="4">Entregue</option>
                                            <option value="9">Cancelado</option>
                                        </select>
                                        &nbsp;&nbsp;
                                        <button className="btn btn-primary" onClick={_HandleClear} style={{width:'90px'}}>Limpar</button>
                                    </div>

                                    
                                </div>



                                <div className="card-body pb-2">
                                        <div className="row">
                                            <div className="col col-sm-4">
                                                <div className="pricing-box">
                                                    <br />
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" id="os_number" value={osNumber} placeholder="Número da Os" onChange={_HandleChange} onKeyDown={_HandleIncludeTyping}/>
                                                        <button className="btn btn-primary" onClick={_HandleInclude} style={{width:'90px'}}>Incluir</button>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    {/*<textarea className="form-control" value={osNumbers} onChange={_HandleMChange}></textarea>
                                                    <br />
                                                    <button className="btn btn-primary right" onClick={_HandleMInclude}>Incluir Lista</button>
                                                    <br />&nbsp;*/}


                                                    
                                                </div>
                                            </div>

                                            <div className="col col-sm-8">
                                                <div className="pricing-box">
                                                    <br />


                                                    <div className="os_list">
                                                        <table className={'table mb-0 ' + (Array.isArray(list) && list.length > 0?'':'hide')} >
                                                            <thead>
                                                                <tr>
                                                                    <th>Nº</th>
                                                                    <th>Cliente</th>
                                                                    <th>Celular</th>
                                                                    <th>Valor</th>
                                                                    <th>Criado em</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    Array.isArray(list) && list.length > 0 && list.map((item, i) => (
                                                                    <tr key={'os_'+i}>
                                                                        <td>{item.numero}</td>
                                                                        <td>{item.cliente}</td>
                                                                        <td>{item.celular}</td>
                                                                        <td>{Currency(item.valor_total)}</td>
                                                                        <td>{item.date_created}</td>
                                                                    </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                        

                                                        {
                                                            (!list || list?.length === 0) && (
                                                            
                                                                <div>
                                                                    <br /><br /><br />
                                                                    <center>
                                                                        <h4>Nenhuma ordem de serviço adicionada</h4>
                                                                    </center>        
                                                                </div>
                                                            )
                                                        }

                                                        <br />&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}
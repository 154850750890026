/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Req, Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';


export default function Unidade() {
    const component_name = "admin/home_unidade";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);



    return (
        <>
            <div className="row g-0">
                <div className="col-xxl-12 col-lg-12 col-md-12">
                    <div className="d-flex flex-column ">
                        <div className="text-center text-muted mb-2">
                        <h3>Uma nova experiência em gestão e venda para lentes e armações em óticas</h3>
                        </div>

                        <div className="my-auto">
                            <div className="p-3 text-center pointer">
                                <img src="assets/images/auth-img.png" alt="" className="img-fluid" style={{width:'480px', height:'auto'}}/>
                            </div>
                        </div>

                    
                    </div>
                    
                
                </div>
            </div>
        </>
    )
}


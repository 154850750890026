/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef} from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request } from '../../../scripts/Request';
import { HubContext } from '../../../Context/HubContext.js';
import { WaitObject } from '../../../scripts/Wait';
import { FormGenerator } from '../../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Currency, UnCurrency, Number } from '../../../scripts/StringUtils';
import ReactGA, { set } from 'react-ga';
import { Cache } from '../../../scripts/Cache';
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import './cadastro.css';


let cil_timer;


export function Cadastro() {
    const component_name = "admin/lentes_cadastro";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [block, setBlock] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [fornecedores, setFornecedores] = useState([]);
    const [tratamentos, setTratamentos] = useState([]);
    const [tipostratamentos, setTiposTratamentos] = useState([]);
    const [dioptria, setDioptria] = useState({ esf_ini: '0,00', esf_fim: '0,00', cil_ini: '0,00', cil_fim: '0,00', d_esf_negative:0, d_esf_positive:0 });
    const [dioptrias, setDioptrias] = useState([]);
    const [countObrig, setCountObrig]  = useState(0);
    const [lente, setLente] = useState({});
    const [isSurf, setIsSurf] = useState(false);
    const dioptriaRef = useRef(dioptria);
    const _formUserRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const navigate = useNavigate();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    let { Id } = useParams();

    const tipos = [
        {id: 0, name: "None"},
        {id: 1, name: "Multi"},
        {id: 2, name: "Bi"},
        {id: 3, name: "Mono"},
        {id: 4, name: "Ocup."},
        {id: 5, name: "Solar"}
    ];
    
    
    const materiais = [
        {id: 0, name: "None"},
        {id: 1, name: "Acrílico"},
        {id: 2, name: "Resina"},
        {id: 3, name: "Poly"},
        {id: 4, name: "H.L."},
        {id: 5, name: "Mineral"},
        {id: 6, name: "Trivex"}
    ];


    //#region Effects
     useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Cache.Set("current_url", component_name);
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);

    //#endregion Effects


    //#region Init
    const Init = () => {
        LoadTiposTratamentos();
    }
    //#endregion Init



     //#region Loaders
     const LoadTiposTratamentos = () => {
        Promise.resolve(new Request().Run("api/Lentes/TiposTratamentos", "GET", "", user.token, 0*24*60*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            
            if(data.toString() !== "error") {
                setTiposTratamentos(data);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }


     const LoadTratamentos = (fid = "", surf = "") => {
        Promise.resolve(new Request().Run("api/Lentes/Tratamentos", "POST", {lente_id: (Id?Id:''), fornecedor_id: fid, surf: (surf ? 1 : 0)}, user.token, 0*24*60*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            
            if(data.toString() !== "error") {
                setTratamentos(data);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }
    //#endregion Loaders



    //#region Form Handlers
    const fornecedor_id = async(value) => {
        if(value === 0) value = "";

        let _surf = await _formUserRef.current.GetValue('surf');
        LoadTratamentos(value, _surf);
    }


    const surf = async(value) => {
        setIsSurf(value);

        
        
        let opts = await _formUserRef.current.GetOptions('fornecedor_id');
        let _index = opts.findIndex(o => o.id === 0);

        if(value) {
            if(_index === -1) {
                opts.unshift({id: 0, name: "Todos"})
            }  
        }
        else 
        {
            if(_index > -1) {
                opts.splice(_index, 1);
            }    
        }
        
        let _fornecedor_id = await _formUserRef.current.GetValue('fornecedor_id')?.id;

        if(value === 0) value = "";
        if(_fornecedor_id === 0) _fornecedor_id = "";
       
        LoadTratamentos(_fornecedor_id, value);

        if(value) { // Surfassada não tem obrigatórios

        }
    }


    const indice = (value) => {
        if(value !== "") {
            let compra = _formUserRef.current.GetValue('compra');
            compra = UnCurrency(compra);
            value = parseFloat(value);
            let venda = compra + (compra * value) / 100 ;
            _formUserRef.current.Value('venda', Currency(venda));
        }
    }

    const compra = (value) => {
        let indice = _formUserRef.current.GetValue('indice');
        indice = parseFloat(indice);
    }


    const venda = (value) => {
        let compra = _formUserRef.current.GetValue('compra');
        compra = UnCurrency(compra);
        value = UnCurrency(value);

        let indice = ((value / compra) * 100) - 100;
        _formUserRef.current.Value('indice', indice);
    }


    const tipo = (value) => {
        if(parseInt(value) === 3) {
            _formUserRef.current.Value("adicao1", "");
            _formUserRef.current.Value("adicao2", "");
            _formUserRef.current.Value("alt", "");
            _formUserRef.current.DisableFields(["adicao1", "adicao2", "alt"]);
        } else {
            _formUserRef.current.Value("adicao1", "0,00");
            _formUserRef.current.Value("adicao2", "0,00");
            if(user.nivel < 1)_formUserRef.current.DisableFields(["adicao1", "adicao2", "alt"], true);
        }
    }


    const par = (value) => {
      if(value) {
        _formUserRef.current.Value("olho", 1);
      }
    }


    const OnFormLoaded = async(fields) => {
        setPageLoading(true);
 
        let lente_data;
        WaitObject(_formUserRef.current).then(async() => {
            await _formUserRef.current.Select("fornecedor_id", [], "api/TiposTratamentos/Fornecedores", "GET", "", user.token);

            if(Id) {
                lente_data = await new Request().Run("api/Lentes/Load?id="+Id, "GET", "", user.token);
                //console.log(lente_data);
                if(lente_data !== undefined && lente_data.lente_id !== null) {
                    await _formUserRef.current.Load(lente_data);
                    setLente(lente_data);
                    setIsSurf(lente_data.surf === 1);
                    //LoadTratamentos(lente_data.fornecedor_id);
                    dioptriaRef.current = { 
                        esf_ini: Number(lente_data.esf_ini.toFixed(2)), 
                        esf_fim: Number(lente_data.esf_fim.toFixed(2)), 
                        cil_ini: Number(lente_data.cil_ini.toFixed(2)), 
                        cil_fim: Number(lente_data.cil_fim.toFixed(2)),  
                        d_esf_negative: lente_data.d_esf_negative, 
                        d_esf_positive: lente_data.d_esf_positive
                    }
                    setDioptria(dioptriaRef.current);
                    DrawDioptrias(lente_data.dioptrias);
                    
                    LoadTratamentos(lente_data.fornecedor_id, (lente_data.surf === 1));

                    if(user.nivel > 0) {
                        _formUserRef.current.DisableFields();
                        if(user.nivel === 1) _formUserRef.current.DisableFields(["cod_loja", "linha", "premio", "promo", "compra", "venda", "indice", "prazo", "qtd", "obs"], true);
                        setBlock(true);
                    } else {
                        setBlock(false);
                    }
                } else {
                    LoadTratamentos(lente_data.fornecedor_id, (lente_data.surf === 1));
                }
            } else {
                setBlock(false);

                let wait_fornecedor = setInterval(async() => {
                    let _fornecedores = await _formUserRef.current.GetOptions("fornecedor_id");
                    let _surf = await _formUserRef.current.GetValue("surf");
                    if(_fornecedores.length > 0) {
                        clearInterval(wait_fornecedor);
                        LoadTratamentos(_fornecedores[0].id, _surf);
                    } else {
                        //console.log(1);
                    }
                }, 100);

                
            }

            setPageLoading(false);

            
        });
        
    }

    
    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        let validade = _formUserRef.current.RequiredValidation();

       
        if(validade) {
           

            let _tratamentos = [...tratamentos];
            let _temp_tratamentos = [];

            _tratamentos.map((item, i) => {
                if(item.obrigatorio === 1 || item.disponivel === 1) {
                    //item.disponivel = 1;  
                    _temp_tratamentos.push({tratamento_id: item.tratamento_id, disponivel: item.disponivel, obrigatorio: item.obrigatorio});
                }
            });


            /*if(_temp_tratamentos.length===0) {
                window.swal("Alerta", "Nenhum tratamento foi aplicado", "error");
                return;
            }*/

            let _dioptrias = [...dioptrias];

            
            if(_dioptrias.length===0) {
                window.swal("Alerta", "Dioptrias não configuradas", "error");
                return;
            }

            setPageLoading(true);
            let result = await _formUserRef.current.Post("api/Lentes/Save", {lente_id: Id, tratamentos: _temp_tratamentos, esf_ini: dioptriaRef.current.esf_ini, esf_fim: dioptriaRef.current.esf_fim, cil_ini: dioptriaRef.current.cil_ini, cil_fim: dioptriaRef.current.cil_fim, d_esf_negative: dioptriaRef.current.d_esf_negative, d_esf_positive: dioptriaRef.current.d_esf_positive, dioptrias:_dioptrias }, user.token);
            setPageLoading(false);

            if(result === "error") {
                window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
                return;
            } 

            window.scrollTo({top: 0, behavior: 'smooth'});
            navigate("/Lentes");
        } else {
            window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
        }
        
    }
    //#endregion Handlers
    


    //#region Tratamentos
    const _HandleDisponibleChange = (event, item) => {
        let _tratamentos = [...tratamentos];
        let _index = _tratamentos.findIndex(t => t.tratamento_id === item.tratamento_id);

        if(_index > -1) {
            _tratamentos[_index].disponivel = (event.target.checked ? 1 : 0) ;
            setTratamentos(_tratamentos);
        }

        
        if(Id) {
            Promise.resolve(new Request().Run("api/Lentes/TratamentoDisponivel", "POST", { lente_id: Id, tratamento_id: item.tratamento_id, ativo: (event.target.checked ? 1 : 0) }, user.token))
            .then((data) => {
            
            });
        }
    }


    const _HandleObrigatorioChange = (event, item) => {
        let _tratamentos = [...tratamentos];
        let _index = _tratamentos.findIndex(t => t.tratamento_id === item.tratamento_id);

        if(_index > -1) {
            _tratamentos[_index].obrigatorio = (event.target.checked ? 1 : 0) ;
            //_tratamentos[_index].disponivel = (event.target.checked ? 1 : 0) ;
            setTratamentos(_tratamentos);
        }

        console.log(item, event.target.checked);

        let _count_obrig = _tratamentos.filter(t => { return t.tipo_id !== 1 && t.obrigatorio === 1});
        setCountObrig(_count_obrig?.length || 0);

        if(Id) {
            Promise.resolve(new Request().Run("api/Lentes/TratamentoObrigatorio", "POST", { lente_id: Id, tratamento_id: item.tratamento_id, ativo: (event.target.checked ? 1 : 0) }, user.token))
            .then((data) => {
            
            });
        }
    }
    //#endregion Tratamentos



    //#region Dioptrias
    const _HandleDioptriaChange = (event) => {
        dioptriaRef.current = { ...dioptria, [event.target.id] : (event.target.type==='number'?event.target.value : Number(event.target.value, 6))};
        setDioptria(dioptriaRef.current);

        try {
            let esf_ini = parseFloat(dioptriaRef.current.esf_ini.replace(",","."));
            let esf_fim = parseFloat(dioptriaRef.current.esf_fim.replace(",","."));
            let cil_ini = parseFloat(dioptriaRef.current.cil_ini.replace(",","."));
            let cil_fim = parseFloat(dioptriaRef.current.cil_fim.replace(",","."));

            //console.log(Math.abs(esf_ini%0.25), Math.abs(esf_fim%0.25),Math.abs(cil_ini%0.25), Math.abs(cil_fim%0.255));

            if(cil_ini>=0) {
                //window.swal("Alerta", "É necessário utilizar um valor menor que zero!", "error");
                //dioptriaRef.current.cil_ini = "";
                return;
            }

            if(cil_ini>=cil_fim) {
                //window.swal("Alerta", "Cil final deve ser maior que Cil inicial!", "error");
                //dioptriaRef.current.cil_fim = "";
                return;
            }

            if(cil_fim > 0) {
                //window.swal("Alerta", "Cil final deve igual ou menor que zero", "error");
                //dioptriaRef.current.cil_fim = "";
                return;
            }

            if(esf_ini<esf_fim 
                && cil_ini<cil_fim
                && Math.abs(esf_ini%0.25)===0
                && Math.abs(esf_fim%0.25)===0
                && Math.abs(cil_ini%0.25)===0
                && Math.abs(cil_fim%0.25)===0
                ) {
                DrawDioptrias();
            } 
        } catch(e) { }
    }


    const _HandleBlur = (event) => {
        dioptriaRef.current = { ...dioptria};
        clearInterval(cil_timer);

        cil_timer = setTimeout(() => {
            let esf_ini = parseFloat(dioptriaRef.current.esf_ini.replace(",","."));
            let esf_fim = parseFloat(dioptriaRef.current.esf_fim.replace(",","."));
            let cil_ini = parseFloat(dioptriaRef.current.cil_ini.replace(",","."));
            let cil_fim = parseFloat(dioptriaRef.current.cil_fim.replace(",","."));

            console.log(cil_ini, cil_fim );

            if(cil_ini>=0) {
                dioptriaRef.current.cil_ini = "";
                window.swal("Alerta", "É necessário utilizar um valor menor que zero!", "error");
                
                //return;
            }

            if(cil_ini>=cil_fim) {
                dioptriaRef.current.cil_fim = "";
                window.swal("Alerta", "Cil final deve ser maior que Cil inicial!", "error");
            
                //return;
            }

            if(cil_fim > 0) {
                dioptriaRef.current.cil_fim = "";
                window.swal("Alerta", "Cil final deve igual ou menor que zero", "error");
                
                //return;
            }

            setDioptria(dioptriaRef.current);
        }, 400);
    }


    const _HandleReset = (event) => {
       
        dioptriaRef.current = { ...dioptria, [event.target.id] : (event.target.type==='number'?0 :'0,00')}
        setDioptria(dioptriaRef.current);

        if(dioptriaRef.current.esf_ini<dioptriaRef.current.esf_fim 
            && dioptriaRef.current.cil_ini<dioptriaRef.current.cil_fim
            && (dioptriaRef.current.esf_ini*100)%5===0
            && (dioptriaRef.current.esf_fim*100)%5===0
            && (dioptriaRef.current.cil_ini*100)%5===0
            && (dioptriaRef.current.cil_fim*100)%5===0
            ) {
            DrawDioptrias();
        } 
        
    }


    const DrawDioptrias = (arr) => {
        let _tmp = [];

        let esf_ini = parseFloat(dioptriaRef.current.esf_ini);
        let esf_fim = parseFloat(dioptriaRef.current.esf_fim);
        let cil_ini = parseFloat(dioptriaRef.current.cil_ini);
        let cil_fim = parseFloat(dioptriaRef.current.cil_fim);
        let d_esf_negative = parseInt(dioptriaRef.current.d_esf_negative);
        let d_esf_positive = parseInt(dioptriaRef.current.d_esf_positive);

        for(let i=esf_ini; i<=esf_fim; i=i+0.25) {
            let dio = {esf: i, cil: []};

            for(let j=cil_ini; j<=cil_fim;  j=j+0.25) {
                let { rchecked, rvalue } = arr ? DbDiametro(arr, i, j)  : CheckDiametro(i, j, 1, (i<=0?d_esf_negative:d_esf_positive));
                dio.cil.push({c: j, ativo: rchecked, value: rvalue})
            }

            _tmp.push(dio);
        }

        //console.log(_tmp);
        setDioptrias(_tmp)
    }


    const  DbDiametro = (arr, i, j) => {
        let r = arr.find(a => a.esf === i && a.cil === j);
        return  {rchecked: r.ativo, rvalue: r.value}
    }

    const CheckDiametro = (i, j, checked, value) => {
        let _dioptrias = [...dioptrias];
        let r = {rchecked: checked, rvalue: value}
        try {
            r.rchecked = checked; //_dioptrias[i].cil[j].checked;
        } catch(e) {
           
        }

        try {
            r.rvalue = value; //_dioptrias[i].cil[j].value;
        } catch(e) {
           
        }

        return r;
    }


    const _HandleCilChange = (event, i, j) => {
        let _dioptrias = [...dioptrias];
        if(event.target.type ==='checkbox') {
            _dioptrias[i].cil[j].ativo = event.target.checked ? 1: 0;
        } else {
            _dioptrias[i].cil[j].value = event.target.value;
        }

        setDioptrias(_dioptrias);
    }
    //#endregion Dioptrias



    return(
        <div className="main-content LentesCadastro">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Cadastro de Lente</h4>
                                </div>

                                <div className="card-body pb-2">
                                   
                                    <form name="fuser" onSubmit={(event) => _HandleSubmit('lente', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                        <div className="row">
                                            <div className="col-md-12 pt-1 pb-1">
                                                <FormGenerator ref={_formUserRef} name={'lente'} url={'/assets/forms/lente.json'} initial_trigger={false} execRef={Exec}></FormGenerator>
                                                <div style={{clear:'both'}}></div>
                                                <br />
                                         
                                                <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                                    <li className="nav-item">
                                                        <a className="nav-link active" data-bs-toggle="tab" href="#home2" role="tab">
                                                            <span className="d-block d-sm-none"><i className="fa fa-home"></i></span>
                                                            <span className="d-none d-sm-block"> <i className="mdi mdi-filter-plus"></i> &nbsp; Tratamentos disponíveis  &nbsp;  </span> 
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" data-bs-toggle="tab" href="#profile2" role="tab">
                                                            <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                                            <span className="d-none d-sm-block"> <i className="mdi mdi-clipboard-check-multiple-outline"></i> &nbsp; Grade de dioptrias &nbsp;  </span> 
                                                        </a>
                                                    </li>
                                                </ul>
        
                                      
                                                <div className="tab-content p-3 text-muted">
                                                    <div className="tab-pane active" id="home2" role="tabpanel">
                                                        <div className="table-responsive">

                                                            { Array.isArray(tipostratamentos) && tipostratamentos.length > 0 && tipostratamentos.map((item, i) => (
                                                            <ReactTooltip key={"tooltip"+i} anchorId={"td_i"+i} variant="info" place="top" content={item.titulo}/>))
                                                            }

                                                            <table className="table table-bordered mb-0 ">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Tratamento</th>
                                                                        <th>Valor</th>
                                                                        {
                                                                            Array.isArray(tipostratamentos) && tipostratamentos.length > 0 && tipostratamentos.map((item, i) => (
                                                                                <th key={"tipo_i"+i} id={"td_i"+i} style={{width:'35px', padding:'0.75rem 0'}} className="pointer th_abrev"><span>{item.titulo_abrev}</span></th>
                                                                            ))
                                                                        }
                                                                        <th style={{width: '70px'}} className="pointer th_abrev">DISPONÍVEL</th>
                                                                        <th style={{width: '70px'}} className="pointer th_abrev">OBRIGATÓRIO</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                  
                                                                    {
                                                                        Array.isArray(tratamentos) && tratamentos.length > 0 && tratamentos.map((item, i) => (
                                                                            <tr key={"atipo_i"+i}>
                                                                                <td>{item.titulo}</td>
                                                                                <td>{Currency(item.valor)}</td>
                                                                                {
                                                                                    Array.isArray(tipostratamentos) && tipostratamentos.length > 0 && tipostratamentos.map((tipo, j) => (
                                                                                        <th key={"ctipo_"+ i + "_" + j}><center><span>{(item.tipos.findIndex(t => t.tipo_tratamento_id === tipo.tipo_tratamento_id) > -1)?'X':''} </span></center></th>
                                                                                    ))
                                                                                }
                                                                                <td style={{ padding:'7px 0 0 3px'}}>
                                                                                    <center>
                                                                                        <input id={"dispo_i"+i} type="checkbox" style={{width:'22px', height: '22px', padding:'0 0 0 0', margin:'0'}} value={item.tratamento_id} checked={item.disponivel===1} onClick={(event) => _HandleDisponibleChange(event, item)} disabled={item.tipo_id===1 || (user.nivel===0||item.obrigatorio===1||(!isSurf && user.nivel > 0)||user.nivel>1)}/>
                                                                                    </center>
                                                                                </td>

                                                                                <td style={{ padding:'7px 0 0 3px'}}>
                                                                                    <center>
                                                                                        <input id={"obrig_i"+i} type="checkbox" style={{width:'22px', height: '22px', padding:'0 0 0 0', margin:'0'}} value={item.tratamento_id} checked={(item.obrigatorio===1 && !isSurf) || (item.obrigatorio===1 && item.tipo_id===1)} onClick={(event) => _HandleObrigatorioChange(event, item)} disabled={ (countObrig>0 && item.obrigatorio!==1 && item.tipo_id!==1) || (isSurf && item.tipo_id!==1) || user.nivel>0}/>
                                                                                    </center>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                    
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>




                                                    <div className="tab-pane" id="profile2" role="tabpanel">
                                                        <div className="row">
                                                            <div className="col-xl-3">
                                                                <div className={'form-floating  mb-3 '}>
                                                                    <input type="text" className="form-control" id="esf_ini" value={dioptria.esf_ini} onChange={_HandleDioptriaChange} maxLength="6" onDoubleClick={_HandleReset} disabled={(user.nivel>0)}/>
                                                                    <label for="input-username">Esférico inicial</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-3">
                                                                <div className={'form-floating  mb-3 '}>
                                                                    <input type="text" className="form-control" id="esf_fim" value={dioptria.esf_fim} onChange={_HandleDioptriaChange} maxLength="6" onDoubleClick={_HandleReset} disabled={(user.nivel>0)}/>
                                                                    <label for="input-username">Esférico final</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-3">
                                                                <div className={'form-floating  mb-3 '}>
                                                                    <input type="text" className="form-control" id="cil_ini" value={dioptria.cil_ini} onChange={_HandleDioptriaChange} maxLength="6" onDoubleClick={_HandleReset} disabled={(user.nivel>0)} onBlur={_HandleBlur}/>
                                                                    <label for="input-username">Cil inicial</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-3">
                                                                <div className={'form-floating  mb-3 '}>
                                                                    <input type="text" className="form-control" id="cil_fim" value={dioptria.cil_fim} onChange={_HandleDioptriaChange} maxLength="6" onDoubleClick={_HandleReset} disabled={(user.nivel>0)} onBlur={_HandleBlur}/>
                                                                    <label for="input-username">Cil final</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-xl-3">
                                                                
                                                            </div>

                                                            <div className="col-xl-3">
                                                                <div className={'form-floating  mb-3 '}>
                                                                    <input type="number" className="form-control" id="d_esf_negative" value={dioptria.d_esf_negative} onChange={_HandleDioptriaChange} step="1" disabled={(user.nivel>0)}/>
                                                                    <label for="input-username">Diâmetro esférico até 0</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-3">
                                                                <div className={'form-floating  mb-3 '}>
                                                                    <input type="number" className="form-control" id="d_esf_positive" value={dioptria.d_esf_positive} onChange={_HandleDioptriaChange} step="1" disabled={(user.nivel>0)}/>
                                                                    <label for="input-username">Diâmetro esférico acima de 0</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-3">
                                                                
                                                            </div>
                                                        </div>            


                                                        <div className="table-responsive">
                                                            <table className="table table-bordered mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{backgroundColor:'#feffff'}}>E/C</th>
                                                                        {
                                                                            Array.isArray(dioptrias) && dioptrias.length > 0 && dioptrias[0].cil.map((item, i) => (
                                                                                <th key={'thdio'+i} style={{color:'red',backgroundColor:'#ffffff'}}>{item.c}</th>
                                                                            ))
                                                                        }
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {
                                                                        Array.isArray(dioptrias) && dioptrias.length > 0 && dioptrias.map((item, i) => (
                                                                            <tr key={'trdio'+i}>
                                                                                <td style={{color:'#3050e0',backgroundColor:'#fffff5'}}><b>{item.esf}</b></td>
                                                                                {
                                                                                    dioptrias[i].cil.map((cil, j) => (
                                                                                        <td key={'tddio'+i+'_'+j}>
                                                                                             <input  type="checkbox" className={'inputcheckbox'} style={{width:'22px', height: '22px', padding:'0 0 0 0', margin:'0'}} value={cil.c} checked={cil.ativo===1} onClick={(event) => _HandleCilChange(event, i, j)} disabled={(user.nivel>0)}/>
                                                                                             <input type="number" className={'inputnumber'} value={cil.value} onChange={(event) => _HandleCilChange(event, i, j)} step="1" disabled={(user.nivel>0)}/>
                                                                                        </td>
                                                                                    ))
                                                                                }
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>              

                                                      

                                                                          
                                                            
                                                        

                                                        
                                                    </div>
                                                </div>


                                                
                                                

                                                <center>
                                                    <hr />
                                                    <button id="btn_sv" className={'btn btn-primary btn-lg btn-rounded mb-2 ' + (user.nivel > 1?'hide':'')} type="submit">Salvar informações</button>
                                                 </center>
                                                <br />
                                            </div> 
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>


            
        </div>
    )
}
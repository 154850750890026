import React from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';

export default function Preview(props) {


    const navigate = useNavigate();

    const _HandleStart = () => {
        //props.OnStart();
        navigate("/Redirect/Espelho")
    }

    const _FullScreen = () => {
        let elem =  document.documentElement;

        if (!$(elem).is(":fullscreen")) {
            
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
        }
    }

    return (
        <div className="auth-bg-basic d-flex align-items-center min-vh-100">
                    <div className="bg-overlay bg-light"></div>
                    <div className="container">
                        <div className="d-flex flex-column min-vh-100 py-5 px-3">
                            <div className="row justify-content-center my-auto">
                                <div className="col-xl-12">
                                    <div className="text-center">
                                        <a href="./"><img src="assets/images/logo.jpg" alt="" /> </a>

                                        <div className="row mt-5 pt-3 justify-content-center">
                                            <div className="col-md-6">
                                                <img src="assets/images/maintenance.png" className="img-fluid pointer" alt="" onClick={_FullScreen}/>
                                            </div>
                                        </div>

                                        <h3 className="mt-5 pt-4">Espelho Virtual</h3>
                                        <p>Aguarde liberação do atendente</p>

                                        <div className="row mt-4">
                                            <div className="col-md-4">
                                                <div className="card mt-4 maintenance-box">
                                                    <div className="card-body">
                                                        <i className="bx bx-images mb-4 h2 text-primary"></i>
                                                        <h5 className="font-size-15 text-uppercase">O que é um Espelho Virtual?</h5>
                                                        <p className="text-muted mb-0">É uma funcionalidade para auxiliar na escolha de armações e reconhecimento do formato do rosto por inteligência artificial</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="card mt-4 maintenance-box">
                                                    <div className="card-body">
                                                        <i className="bx bx-time-five mb-4 h2 text-primary"></i>
                                                        <h5 className="font-size-15 text-uppercase">
                                                            Agilidade no atendimento</h5>
                                                        <p className="text-muted mb-0">O Espelho Virtual agiliza o processo de atendimento alimentando filtros de armações e lentes personalizados</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="card mt-4 maintenance-box">
                                                    <div className="card-body pointer" onClick={_HandleStart}>
                                                        <i className="bx bx-mobile-alt mb-4 h2 text-primary"></i>
                                                        <h5 className="font-size-15 text-uppercase">
                                                            Iniciar o Espelho Virtual</h5>
                                                        <p className="text-muted mb-0">Clique aqui para iniciar a câmera do dispositivo e aguardar um atendente iniciar a conexão com o espelho selecionado</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
    )
}
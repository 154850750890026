/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request } from '../../../scripts/Request';
import { HubObjects } from '../../../scripts/HubObjects';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";



export default function Lista() {
    const component_name = "admin/tipos_tratamentos_lista";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const [filtros, setFiltros] = useState([]);
    const [search, setSearch] = useState({ word: "" });
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: "" });

    const navigate = useNavigate();


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
     const Init = () => {
        _HandlerSearchChange();
     }
    //#endregion Init


    //#region Loaders
    const LoadFiltros = () => {
        Promise.resolve(new Request().Run("/assets/json/tratamentos_filtros.json", "GET", "", "", 1*24*60*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            
            if(data.toString() !== "error") {
                setFiltros(data);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }


    const UpdateFiltro = (item, filtro, selected) => Promise.resolve(new Request().Run("api/TiposTratamentos/Filtro", "POST", { tipo_tratamento_id: item.tipo_tratamento_id, filtro_id: filtro.filtro_id, selected: selected }, user.token)).then((data) => {});
    //#endregion Loaders


    //#region Handlers
    const _HandleChange = (event) => {
        setSearch(prev => prev = {[event.target.id]: event.target.value});
        searchRef.current = {[event.target.id]: event.target.value};
    }




    const _HandleClear = (event) => {
        setSearch(prev => prev = {word: ""});
        searchRef.current = {word: ""};
        _HandlerSearchChange(event);
    }



    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setPageLoading(true);
        setHasMore(true);

        Promise.resolve(new Request().Run("api/TiposTratamentos/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];
                listRef.current = listRef.current.concat(_list);
                setList(listRef.current);
                setPageLoading(false);
                LoadFiltros();
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setPageLoading(true);

            Promise.resolve(new Request().Run("api/TiposTratamentos/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    listRef.current = listRef.current.concat(_list);
                    setList(listRef.current);
                    setPageLoading(false);
                    LoadFiltros();
                });
        } catch (e) { }
    }


    const _HandleEdit = (e, id) => {
        navigate("/TiposTratamento/"+id);
    }


    const _HandleAddFornecedor = () => {
        navigate("/TiposTratamento/");
    }


    const _HandleAtivo = (item) => {
        Promise.resolve(new Request().Run("api/TiposTratamentos/Ativo", "POST", { tipo_tratamento_id: item.tipo_tratamento_id, ativo: item.ativo}, user.token))
        .then((data) => {
            let _list = [...list];
            _list.find(u => u.tipo_tratamento_id === item.tipo_tratamento_id).ativo = data;
            setList(_list);
        });
    }


    const  _HandleFiltroChange = (event, item, filtro) => {
        let _list = [...list];
        let _index = _list.findIndex(u => u.tratamento_id === item.tratamento_id);

        //console.log(_index, _list, item, filtro);
        if(_index > -1) {
            if(_list[_index].filtros !== null ) {
                let _findex =  _list[_index].filtros.findIndex(f => f.filtro_id === filtro.filtro_id);

                //console.log(_findex, filtro.filtro_id, _list[_index].filtros);

                if(_findex > -1) {
                    //_list[_index].filtros[_findex].selected = (event.target.checked ? 1 : 0);
                    if(!event.target.checked) {
                        _list[_index].filtros.splice(_findex, 1);
                    }
                } else {
                    if(event.target.checked) _list[_index].filtros.push({filtro_id: filtro.filtro_id, selected: (event.target.checked ? 1 : 0)});
                }
                UpdateFiltro(item, filtro, (event.target.checked ? 1 : 0));
            } else {
                _list[_index].filtros = [];
                if(event.target.checked) _list[_index].filtros.push({filtro_id: filtro.filtro_id, selected: (event.target.checked ? 1 : 0)});
                UpdateFiltro(item, filtro, (event.target.checked ? 1 : 0));
            }
        }

        //console.log(_list[_index], event.target.checked);
        setList(_list);
    }
    //#endregion Handlers



    return (
        <div className="main-content TiposTratamentos">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Lista de Tratamentos</h4>

                                    <div className="row"> 
                                        <div className="col-xl-12"> 
                                            <div className={'align-right ' + (user.nivel>0?'hide':'')}>
                                                <button type="button" className="btn btn-primary" onClick={_HandleAddFornecedor}>
                                                    <i className="mdi mdi-star-plus"></i> &nbsp;Adicionar
                                                </button>
                                            </div>

                                            <div className="align-right mr-10">
                                                <button type="button" className="btn btn-outline-primary" onClick={_HandlerSearchChange}>
                                                    <i className="mdi mdi-share-outline"></i> &nbsp;Buscar
                                                </button>
                                            </div>


                                            <div className="search-box align-right mr-10">
                                                <div className="position-relative">
                                                    <input id="word" typeName="text" value={search.word} className="form-control rounded bg-light border-0" placeholder="" onChange={_HandleChange}/>
                                                    <i className="bx bx-search search-icon"></i>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body pb-2">
                                    <div className="table-responsive">
                                        <InfiniteScroll
                                            dataLength={list.length}
                                            next={_fetchMoreData}
                                            hasMore={hasMore}
                                            loader={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Carregando...</b>
                                                </p>
                                            }
                                            style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                            endMessage={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Não há mais dados</b>
                                                </p>
                                            }>    


                                            { filtros.length > 0 && filtros.map((item, i) => (
                                            <ReactTooltip key={"tooltip"+i} anchorId={"td_i"+i} variant="info" place="top" content={item.name}/>))
                                            }

                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Título resumido</th>
                                                        <th>Abrev</th>
                                                        <th>Título</th>
                                                        {
                                                            filtros.length > 0 && filtros.map((item, i) => (
                                                              
                                                                <th key={"filtro_i"+i} id={"td_i"+i} style={{width:'20px', padding:'0.75rem 0'}} className="pointer"><center><span>{item.filtro_id}</span></center>
                                                                </th>
                                                             
                                                            ))
                                                        }
                                                        <th style={{width:'60px'}}><center>Status</center></th>
                                                        <th style={{width:'50px'}}></th>
                                                    </tr>
                                                </thead>


                                                <tbody>
                                                {list.map((item, i) => (
                                                    <tr key={'tr' + i} className="pointer">
                                                        <td style={{color:'#564ab1'}} onClick={(e) => _HandleEdit( e, item.tipo_tratamento_id)}> {item.titulo_resumido}</td>
                                                        <td onClick={(e) => _HandleEdit( e, item.tipo_tratamento_id)}>{item.titulo_abrev}</td>
                                                        <td onClick={(e) => _HandleEdit( e, item.tipo_tratamento_id)}>{item.titulo}</td>
                                                        {
                                                            filtros.length > 0 && filtros.map((filtro, j) => (
                                                                <td key={"filtro_i"+i+"_j"+j} style={{ padding:'11px 0 0 3px'}}>
                                                                    <input id={"filtro_i"+i+"_j"+j} type="checkbox" style={{width:'22px', height: '22px', padding:'0 0 0 0', margin:'0'}} value={filtro.filtro_id} checked={item.filtros?.findIndex(f => f.filtro_id === filtro.filtro_id) > -1} onClick={(event) => _HandleFiltroChange(event, item, filtro)} disabled={(user.nivel>0)}/>
                                                                </td>
                                                            ))
                                                        }
                                                        <td style={{padding:'9px 0 0 5px'}}><button type="button" className={'btn btn-sm ' + (item.ativo===1?'btn-primary':'btn-danger')} onClick={() => _HandleAtivo(item)} disabled={(user.nivel>0)}>{item.ativo===1?'Ativo':'Inativo'}</button></td>
                                                        <td>
                                                            <i className="fa fa-edit" onClick={(e) => _HandleEdit( e, item.tipo_tratamento_id)}></i>
                                                        </td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </table>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>
        </div>
    )
}
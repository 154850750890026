/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext';
import './Thumb.css';


export const Thumb = forwardRef((props, ref) => {
    const component_name = "admin/espelho_thumb";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [image, setImage] = useState([]);
    const thumbRef = useRef();
    const timerIdleRef = useRef(new Date());
    const prestineRef = useRef(false);

    const { Id, Stream } = props;



    //#region Effects
    useEffect(()=> {
        if(!prestineRef.current) {
             prestineRef.current = true;
             Init();
             console.log(Id);
        }
  
     
         return () => {
             if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                 try {
                   
                 } catch(e) {}
             }
         }
    }, []);
    //#endregion Effects


     //#region Ref
     useImperativeHandle(ref, (args) => ({
        async SelectMe(id) { 
            //console.log(id, Id);
            if(id === Id) _HandleClick();
        },

    }));
    //#endregion Ref


    //#region Init
    const Init = () => {
        /*let buffer = Uint8Array.from(atob(Stream.split(',')[1]), c => c.charCodeAt(0));
        let blob = new Blob([buffer], { type: "image/png" });
        let url = URL.createObjectURL(blob);*/
        //thumbRef.current.src = Stream;
    }
    //#endregion Init


    //#region Handlers
    const _HandleClick = () => {
        props.OnClick(Stream);
    }
    //#endregion Handlers


    return (
        <div className="Thumb" onClick={_HandleClick}>
            <img src={Stream} alt="" />
        </div>
    )
});
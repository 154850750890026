/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request } from '../../../scripts/Request';
import { HubObjects } from '../../../scripts/HubObjects';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import { Currency, UnCurrency, Number } from '../../../scripts/StringUtils';
import { useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Cache } from '../../../scripts/Cache';
import { IsEmptyObject } from '../../../scripts/ArrayUtils';
import "react-tooltip/dist/react-tooltip.css";
import './index.css';




export default function Lista() {
    const component_name = "admin/lentes_lista";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const [selectedItems, setSelectedItems] =  useState([]);
    const [fornecedores, setFornecedores] = useState([]);
    const [tratamentos, setTratamentos] = useState([]);
    const [search, setSearch] = useState({ word: "", fornecedor_id: "", tratamento_id:"", selected: "", focal: "" });
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const searchRef = useRef({ word: "", fornecedor_id: "", tratamento_id:"", selected: "", focal: "" });

    const navigate = useNavigate();



    const tipos = [
        {id: 0, name: "None"},
        {id: 1, name: "Multfocali"},
        {id: 2, name: "Bifocal"},
        {id: 3, name: "Monofocal"},
        {id: 4, name: "Ocup."},
        {id: 5, name: "Solar"}
    ];
    
    
    const materiais = [
        {id: 0, name: "None"},
        {id: 1, name: "Acrílico"},
        {id: 2, name: "Resina"},
        {id: 3, name: "Poly"},
        {id: 4, name: "H.L."},
        {id: 5, name: "Mineral"},
        {id: 6, name: "Trivex"}
    ]


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    let previous_component = Cache.Get("current_url");
                    if(previous_component !== "admin/lentes_cadastro") {
                        Cache.Remove("lentes_search");
                    }
                    Cache.Set("current_url", component_name);
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects



    //#region Init
     const Init = () => {
        LoadFornecedores();
        LoadTratamentos();
        _HandlerSearchChange();
     }
    //#endregion Init


    //#region Loaders
    const LoadFornecedores = () => {
        Promise.resolve(new Request().Run("api/TiposTratamentos/Fornecedores", "GET", "", user.token, 0*24*60*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            
            if(data.toString() !== "error") {
                setFornecedores(data);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            setPageLoading(false);
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }


    const LoadTratamentos = () => {
        Promise.resolve(new Request().Run("api/Lentes/TratamentosDropdown", "GET", "", user.token, 0*24*60*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            
            if(data.toString() !== "error") {
                setTratamentos(data);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            setPageLoading(false);
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }


    const CopyLentes = (ids) => {
        setPageLoading(true);

        Promise.resolve(new Request().Run("api/Lentes/Copy", "POST", {ids: ids.join()}, user.token))
        .then(async(data) => {
            setPageLoading(false);

            if(data.toString() !== "error") {
                window.swal("Sucesso", "Lentes copiadas com sucesso", "success");
                _HandlerSearchChange();
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            setPageLoading(false);
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }
    //#endregion Loaders


    //#region Handlers
    const _HandleChange = (event) => {
        setSearch(prev => prev = {...searchRef.current, [event.target.id]: event.target.value});
        searchRef.current = {...searchRef.current, [event.target.id]: event.target.value};
    }




    const _HandleClear = (event) => {
        setSearch(prev => prev = {word: ""});
        searchRef.current = {word: ""};
        _HandlerSearchChange(event);
    }


    const _HandleSearchClick = (event) => {
        Cache.Remove("lentes_search");
        _HandlerSearchChange(event);
    }


    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setPageLoading(true);
        setHasMore(true);

        let _cached = Cache.JGet("lentes_search");
        //console.log(IsEmptyObject(_cached), _cached);
        if(_cached && _cached !== "" && !IsEmptyObject(_cached)) {
            setSearch(_cached);
            searchRef.current = _cached;
        }

        Cache.JSet("lentes_search", searchRef.current);

        Promise.resolve(new Request().Run("api/Lentes/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;

                data.list.map((item, i) => {
                    item.adicao1 = Number(item.adicao1, undefined, 2);
                    item.adicao2 = Number(item.adicao2, undefined, 2);
                    item.refr = Number(item.refr, undefined, 3);
                })

                let _list = [...data.list];
                listRef.current = listRef.current.concat(_list);
                setList(listRef.current);
                setPageLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setPageLoading(true);

            Promise.resolve(new Request().Run("api/Lentes/List/" + pageRef.current.page + "/50/" + pageRef.current.offset, "POST", searchRef.current, user.token))
                .then((data) => {
                    data.list.map((item, i) => {
                        item.adicao1 = Number(item.adicao1, undefined, 2);
                        item.adicao2 = Number(item.adicao2, undefined, 2);
                        item.refr = Number(item.refr, undefined, 3);
                    })

                    let _list = [...data.list];
                    listRef.current = listRef.current.concat(_list);
                    setList(listRef.current);
                    setPageLoading(false);
                });
        } catch (e) { }
    }


    const _HandleEdit = (e, id) => {
        navigate("/Lente/"+id);
    }


    const _HandleAddLente = () => {
        let _arr = listRef.current.filter(item => { return item.selected === 1 });

       
        if(_arr.length > 0) {
            console.log(_arr);
            let _ids = [];
            _arr.map(item => { _ids.push(item.lente_id) });


            window.swal({
                title: "Confirmação de cópia?",
                text: "Deseja compiar as lentes selecionadas?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Sim',
                cancelButtonText: "Não",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(isConfirm){
            
               if (isConfirm){
                    CopyLentes(_ids);
                } else {
                    window.swal("Cancelado", "Ação cancelada", "error");
                }
            });
            
        } else {
            navigate("/Lente/");
        } 
    }


    const _HandleAtivo = (item) => {
        Promise.resolve(new Request().Run("api/Lentes/Ativo", "POST", { lente_id: item.lente_id, ativo: item.ativo}, user.token))
        .then((data) => {
            let _list = [...list];
            _list.find(u => u.lente_id === item.lente_id).ativo = data;
            setList(_list);
        });
    }


    const  _HandleSelect = (event, lente_id) => {
        let _list = [...list];
        _list.find(u => u.lente_id === lente_id).selected = (event.target.checked ? 1: 0);
        setList(_list);

        Promise.resolve(new Request().Run("api/Lentes/Include", "POST", { lente_id: lente_id, selected: (event.target.checked ? 1: 0)}, user.token))
        .then((data) => {
            
        });
    }


    const _HandleItemChange = (event, field) => {
        let _list = [...list];

        let _index = parseInt(event.target.id.split('_')[1]);
        let _old = Object.assign({},  _list[_index]);
        //console.log(_old);

        if(field === "compra") {
            _list[_index][field] = UnCurrency(event.target.value);
        } else if(field === "adicao1" || field === "adicao2") {
            _list[_index][field] = Number(event.target.value, undefined, 2);
        } else if(field === "refr") {
           _list[_index][field] = Number(event.target.value, undefined, 3);
        } else if(field === "venda") {
            _list[_index][field] = UnCurrency(event.target.value);
            let compra = list[_index]["compra"];
            compra = UnCurrency(compra);
            let value =_list[_index][field];

            
            let indice = ((value / compra) * 100) - 100;
            _list[_index]['indice'] = indice;
        } else {
            if(event.target.type==="checkbox") {
                _list[_index][field] = (event.target.checked ? 1 : 0);
            } else {
                _list[_index][field] = event.target.value;
            }
        }

        if(field === "tipo") {
            if(parseInt(event.target.value) === 3) {
                _list[_index]['adicao1'] = '';
                _list[_index]['adicao2'] = '';
                _list[_index]['alt'] = '';
            } else {
                _list[_index]['adicao1'] = '0,00';
                _list[_index]['adicao2'] = '0,00';
            }
        }

        setList(_list);

        if(_index > -1) {
            let _selectedItems = [...selectedItems];
            let _arrindex = _selectedItems?.findIndex(s => s.index === _index);
            if(_arrindex === -1) {
                _selectedItems.push({index: _index, data: _list[_index], old: _old });
            } else {
                //_selectedItems[_arrindex] = {index: _index, data: _list[_index], old: _old };
            }
            setSelectedItems(_selectedItems);
        }
    }


    const _HandleSaveItem = (e, i , item) => {
        Promise.resolve(new Request().Run("api/Lentes/Update", "POST", item, user.token))
        .then((data) => {
            let _selectedItems = [...selectedItems];
            let _index = _selectedItems?.findIndex(s => s.index === i);
            _selectedItems.splice(_index, 1);
            setSelectedItems(_selectedItems);
        });
    }


    const _HandleCancelItem = (e, i , item) => {
        let _list = [...list];
        let _selectedItems = [...selectedItems];

        let _index = _selectedItems?.findIndex(s => s.index === i);

        if(_index > -1) {
            _list[i] = Object.assign({},  _selectedItems[_index].old);
            _selectedItems.splice(_index, 1);
            setSelectedItems(_selectedItems);
            setList(_list);
        }
    }

    

    const _HandleReset = (event, field) => {
        let _list = [...list];
        let _index = parseInt(event.target.id.split('_')[1]);

        let _old = (_list[_index]) ? _list[_index] : Object.assign({},  _list[_index]);

        if(field === "adicao1" || field === "adicao2") {
            _list[_index][field] = '';
        } else if(field === "valor") {
            _list[_index][field] = Currency(0);
        } else {
            _list[_index][field] = "";
        }
        setList(_list);

        if(_index > -1) {
            let _selectedItems = [...selectedItems];
            _selectedItems.push({index: _index, data: _list[_index], old: _old });
            setSelectedItems(_selectedItems);
        }
    }



    //#endregion Handlers



    return (
        <div className="main-content Lentes">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Lista de Lentes</h4>

                                    <div className="row"> 
                                        <div className="col-xl-12"> 
                                            <div className={'align-right ' + (user.nivel>0?'hide':'')}>
                                                <button type="button" className="btn btn-primary" onClick={_HandleAddLente}>
                                                    <i className="mdi mdi-star-plus"></i> &nbsp;Adicionar
                                                </button>
                                            </div>

                                            <div className="align-right _mr-10">
                                                <button type="button" className="btn btn-outline-primary" onClick={_HandleSearchClick}>
                                                    <i className="mdi mdi-share-outline"></i> &nbsp;Buscar
                                                </button>
                                            </div>

                                            <div className="search-box align-right mr-4">
                                                <div className="position-relative">
                                                    <select id="tratamento_id" className="form-select" value={search.tratamento_id} onChange={_HandleChange}>
                                                        <option value="">Qualquer tratamento</option>
                                                        {
                                                            Array.isArray(tratamentos) && tratamentos.length > 0 && tratamentos.map((item, i) =>(
                                                                <option key={'trat'+i} value={item.id}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div> 

                                            <div className="search-box align-right mr-4">
                                                <div className="position-relative">
                                                    <select id="fornecedor_id" className="form-select" value={search.fornecedor_id} onChange={_HandleChange}>
                                                        <option value="">Todos fornecedores</option>
                                                        {
                                                            Array.isArray(fornecedores) && fornecedores.length > 0 && fornecedores.map((item, i) =>(
                                                                <option key={'forn'+i} value={item.id}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div> 

                                          

                                            <div className={'search-box align-right mr-4 ' + (user.nivel!==1?'hide':'')}>
                                                <div className="position-relative">
                                                    <select id="selected" className="form-select" value={search.selected} onChange={_HandleChange}>
                                                        <option value="">Todas lentes</option>
                                                        <option value="0">Selecionadas</option>
                                                        <option value="1">Não Selecionadas</option>
                                                    </select>
                                                </div>
                                            </div> 


                                            <div className={'search-box align-right mr-4 ' + (user.nivel!==1?'_hide':'')}>
                                                <div className="position-relative">
                                                    <select id="tipo" className="form-select" value={search.tipo} onChange={_HandleChange}>
                                                        <option value="">Qualquer tipo</option>
                                                        <option value="1">Surfaçada</option>
                                                        <option value="0">Pronta</option>
                                                    </select>
                                                </div>
                                            </div> 

                                            <div className={'search-box align-right mr-4 ' + (user.nivel!==1?'_hide':'')}>
                                                <div className="position-relative">
                                                    <select id="focal" className="form-select" value={search.focal} onChange={_HandleChange}>
                                                        <option value="">Qualquer focal</option>
                                                        <option value="1">Multi</option>
                                                        <option value="2">Bi</option>
                                                        <option value="3">Mono</option>
                                                        <option value="4">Ocup.</option>
                                                        <option value="5">Solar</option>
                                                    </select>
                                                </div>
                                            </div> 


                                            <div className="search-box align-right mr-4">
                                                <div className="position-relative">
                                                    <input id="word" typeName="text" value={search.word} className="form-control rounded bg-light border-0" placeholder="" onChange={_HandleChange}/>
                                                    <i className="bx bx-search search-icon"></i>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body pb-0">
                                    <div className="table-responsive">
                                        <InfiniteScroll
                                            dataLength={list.length}
                                            next={_fetchMoreData}
                                            hasMore={hasMore}
                                            loader={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Carregando...</b>
                                                </p>
                                            }
                                            style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                            endMessage={
                                                <p style={{ textAlign: "center", padding:'25px' }}>
                                                    <b>Não há mais dados</b>
                                                </p>
                                            }>    

                                            <table className="table _table-bordered mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className={"inputcheckbox selected " + (user.nivel!==1?"_hide":"")}></th>
                                                        <th className="x1232">C.Loja</th>
                                                        <th className="x1115">C.Fab</th>
                                                        <th>Fornecedor</th>
                                                        <th>Nome</th>
                                                        <th className="x1180">Ext</th>
                                                        <th>Surf</th>
                                                        <th>Tipo</th>
                                                        <th>Material</th>
                                                        <th className="">CO</th>
                                                        <th className="">NY</th>
                                                        <th className="">PA</th>
                                                        <th className="">SO</th>
                                                        <th className="x1055">Refr.</th>
                                                        <th colSpan={2} className="x990">Adição</th>
                                                        <th className="x1145">Alt.</th>
                                                        <th>Compra</th>
                                                        <th>Venda</th>
                                                        <th className="">Par</th>
                                                        <th>Qtd</th>
                                                        <th style={{width:'50px'}} className={(user.nivel>1?'hide':'')}></th>
                                                    </tr>
                                                </thead>


                                                <tbody>
                                                {list.map((item, i) => (
                                                    <tr key={'tr' + i} className={'pointer '+  (selectedItems?.findIndex(s => s.index === i) > -1 ? 'trfocus':'')}>
                                                        <td className={'inputcheckbox selected ' + (user.nivel!==1?'_hide ':'') }>
                                                            <input type="checkbox" checked={item.selected===1} onClick={(event) => _HandleSelect(event, item.lente_id)}/>
                                                        </td>

                                                        <td className="inputtext cloja x1232">
                                                            <input id={'codloja_'+i} type="text" value={item.cod_loja} disabled={(user.nivel>1)} onChange={(event) => _HandleItemChange(event, 'cod_loja')}/>
                                                        </td>

                                                        <td className="inputtext cfab x1115">
                                                            <input id={'codfab_'+i} type="text" value={item.cod_fabricacao} disabled={(user.nivel>0)} onChange={(event) => _HandleItemChange(event, 'cod_fabricacao')}/>
                                                        </td>

                                                        <td className="inputtext fornecedor">
                                                            <select id={"fornecedorid_"+i} value={item.fornecedor_id} disabled={(user.nivel>0)} onChange={(event) => _HandleItemChange(event, 'fornecedor_id')}>
                                                                {
                                                                    Array.isArray(fornecedores) && fornecedores.length > 0 && fornecedores.map((forn, j) =>(
                                                                        <option key={'forn'+i+'_'+j} value={forn.id===0?'':forn.id}>{forn.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </td>

                                                        <td className="inputtext nome">
                                                            <input id={"nome_"+i} type="text" value={item.nome} disabled={(user.nivel>0)} onChange={(event) => _HandleItemChange(event, 'nome')}/>
                                                        </td>

                                                        <td className="inputcheckbox ext x1180">
                                                            <input id={"ext_"+i} type="checkbox" checked={item.ext===1} disabled={(user.nivel>0)} onChange={(event) => _HandleItemChange(event, 'ext')}/>
                                                        </td>
                                                           
                                                        <td className="inputcheckbox ext">
                                                            <input id={"surf_"+i} type="checkbox" checked={item.surf===1} disabled={(user.nivel>0)} onChange={(event) => _HandleItemChange(event, 'surf')}/>
                                                        </td>
                                                        
                                                        
                                                        <td className="inputtext tipo">
                                                            <select id={"tipo_"+i} value={item.tipo} disabled={(user.nivel>0)} onChange={(event) => _HandleItemChange(event, 'tipo')}>
                                                                <option value="1">Multi</option>
                                                                <option value="2">Bi</option>
                                                                <option value="3">Mono</option>
                                                                <option value="4">Ocup.</option>
                                                                <option value="5">Solar</option>
                                                            </select>
                                                        </td>


                                                        <td className="inputtext material">
                                                            <select id={"material_"+i} value={item.material} disabled={(user.nivel>0)} onChange={(event) => _HandleItemChange(event, 'material')}>
                                                                <option value="1">Acrílico</option>
                                                                <option value="2">Resina</option>
                                                                <option value="3">Poly</option>
                                                                <option value="4">H.L</option>
                                                                <option value="5">Mineral</option>
                                                                <option value="6">Trivex</option>
                                                            </select>
                                                        </td>


                                                        <td className="inputcheckbox co">
                                                            <input id={"co_"+i} type="checkbox" checked={item.co===1} disabled={(user.nivel>0)} onChange={(event) => _HandleItemChange(event, 'co')}/>
                                                        </td>
                                                        
                                                        <td className="inputcheckbox co">
                                                            <input id={"ny_"+i} type="checkbox" checked={item.ny===1} disabled={(user.nivel>0)} onChange={(event) => _HandleItemChange(event, 'ny')}/>
                                                        </td>

                                                        <td className="inputcheckbox co ">
                                                            <input id={"pa_"+i} type="checkbox" checked={item.pa===1} disabled={(user.nivel>0)} onChange={(event) => _HandleItemChange(event, 'pa')}/>
                                                        </td>
                                                        
                                                        <td className="inputcheckbox co ">
                                                            <input id={"so_"+i} type="checkbox" checked={item.so===1} disabled={(user.nivel>0)} onChange={(event) => _HandleItemChange(event, 'so')}/>
                                                        </td>
                                                        
                                                        <td className="inputtext refr x1055">
                                                            <input id={"refr_"+i}  type="text" value={item.refr} disabled={(user.nivel>0)} onChange={(event) => _HandleItemChange(event, 'refr')}/>
                                                        </td>
                                                        
                                                        <td className="inputtext adicao1 x990">
                                                            <input id={"adicao1_"+i} type="text" value={item.adicao1} disabled={(user.nivel>0)} onChange={(event) => _HandleItemChange(event, 'adicao1')} onDoubleClick={(event) => _HandleReset(event, 'adicao1')}/>
                                                        </td>

                                                        <td className="inputtext adicao1 x990">
                                                            <input id={"adicao2_"+i}  type="text" value={item.adicao2} disabled={(user.nivel>0)} onChange={(event) => _HandleItemChange(event, 'adicao2')} onDoubleClick={(event) => _HandleReset(event, 'adicao2')}/>
                                                        </td>

                                                        <td className="inputtext alt x1145">
                                                            <input id={"alt_"+i} type="number" value={item.alt} disabled={(user.nivel>0)} step="1" onChange={(event) => _HandleItemChange(event, 'alt')}/>
                                                        </td>

                                                        <td className="inputtext compra ">
                                                            <input id={"compra_"+i}  type="text" value={Currency(item.compra)} onChange={(event) => _HandleItemChange(event, 'compra')} onDoubleClick={(event) => _HandleReset(event, 'compra')} disabled={(user.nivel>1)}/>
                                                        </td>
                                                        
                                                        <td className="inputtext venda">
                                                            <input id={"venda_"+i} type="text" value={Currency(item.venda)} onChange={(event) => _HandleItemChange(event, 'venda')} onDoubleClick={(event) => _HandleReset(event, 'venda')} disabled={(user.nivel>1)}/>
                                                        </td>

                                                        <td className="inputcheckbox co ">
                                                            <input id={"par_"+i} type="checkbox" checked={item.par===1} disabled={(user.nivel>0)} onChange={(event) => _HandleItemChange(event, 'par')}/>
                                                        </td>
                                                        
                                                        <td className="inputtext qtd">
                                                            <input id={"qtd_"+i}  type="number" value={item.qtd} step="1" onChange={(event) => _HandleItemChange(event, 'qtd')} disabled={(user.nivel>1)}/>
                                                        </td>

                                                        <td className={'edit ' + ((selectedItems?.findIndex(s => s.index === i) > -1))} > 
                                                            <button type="button" className={'btn btn-sm btn-info '} onClick={(e) => _HandleEdit( e, item.lente_id)}><i className="fa fa-edit"></i></button>
                                                        </td>

                                                        <td className={'edit ' +  ((selectedItems?.findIndex(s => s.index === i) === -1)||(user.nivel>1)?'hide':'')} > 
                                                            <button type="button" className={'btn btn-sm btn_ativo btn-danger '} onClick={(e) => _HandleSaveItem(e, i, item)}><i className="fa fa-check"></i></button>
                                                        </td>

                                                        <td className={'edit ' +  ((selectedItems?.findIndex(s => s.index === i) === -1)||(user.nivel>1)?'hide':'')} > 
                                                             <button type="button" className={'btn btn-sm btn-warning '} onClick={(e) => _HandleCancelItem(e, i, item)}><i className="fa fa-reply"></i></button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </table>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>
        </div>
    )
}
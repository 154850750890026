/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef} from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request } from '../../../scripts/Request';
import { HubContext } from '../../../Context/HubContext.js';
import { WaitObject } from '../../../scripts/Wait';
import { FormGenerator } from '../../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Currency, UnCurrency } from '../../../scripts/StringUtils';
import ReactGA from 'react-ga';
import './cadastro.css';


export function Cadastro() {
    const component_name = "admin/tipostratamentos_cadastro";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false);
    const [block, setBlock] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedtipos, setSelectedTipos] = useState([]);
    const [tipos, setTipos] = useState([]);
    const [fornecedores, setFornecedores] = useState([]);
    const [tratamento, setTratamento] = useState({});
    const _formUserRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const navigate = useNavigate();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    let { Id } = useParams();


    //#region Effects
     useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);

    //#endregion Effects


    //#region Init
    const Init = () => {
        LoadTipos();
        //LoadFornecedores();
    }
    //#endregion Init



    //#region Loaders
    const LoadTipos = () => {
        Promise.resolve(new Request().Run("api/Tratamentos/Tipos", "GET", "", user.token, 0*24*60*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            
            if(data.toString() !== "error") {
                setTipos(data);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }



    /*const LoadFornecedores = () => {
        Promise.resolve(new Request().Run("api/TiposTratamentos/Fornecedores", "GET", "", user.token, 0*24*60*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            
            if(data.toString() !== "error") {
                setFornecedores(data);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }*/



    const UpdateTipo = (item, tipo, selected) => Promise.resolve(new Request().Run("api/Tratamentos/Tipo", "POST", { tratamento_id: item.tratamento_id, tipo_tratamento_id: tipo.tipo_tratamento_id, selected: selected }, user.token)).then((data) => {});
    //#endregion Loaders



    //#region Form Handlers
    const OnFormLoaded = async(fields) => {
        setPageLoading(true);
 
        let tratamento_data;
        WaitObject(_formUserRef.current).then(async() => {
            await _formUserRef.current.Select("fornecedor_id", [], "api/TiposTratamentos/Fornecedores", "GET", "", user.token);

            if(Id) {
                tratamento_data = await new Request().Run("api/Tratamentos/Load?id="+Id, "GET", "", user.token);
                if(tratamento_data !== undefined && tratamento_data.tipo_tratamento_id !== null) {
                    await _formUserRef.current.Load(tratamento_data);
                    setTratamento(tratamento_data);

                    if(user.nivel > 0) {
                        _formUserRef.current.DisableFields();
                        setBlock(true);
                    } else {
                        setBlock(false);
                    }
                }
            } else {
                setBlock(false);
            }


            if(tratamento_data.tipos && tratamento_data.tipos.length > 0) {
                setSelectedTipos(tratamento_data.tipos);
            }

            setPageLoading(false);
        });
        
    }

    
    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleTipoChange = (event, item) => {
        let _selectedtipos = [...selectedtipos];
        let _index = _selectedtipos.findIndex(u => u.tipo_tratamento_id === item.tipo_tratamento_id);


        if(event.target.checked && _index === -1) {
            _selectedtipos.push({tipo_tratamento_id: item.tipo_tratamento_id});  
        } else if(!event.target.checked && _index > -1) {
            _selectedtipos.splice(_index, 1);
        }



        setSelectedTipos(_selectedtipos);
    }


    /*
    const _HandleRemove = (i) => {
        let _tipos = [...tipos];
        if(_tipos[i].tipo_id !== "") {
            Promise.resolve(new Request().Run("api/Tratamentos/DeleteTipo", "POST", { tipo_id: _tipos[i].tipo_id }, user.token)).then((data) => {});
        }
        _tipos.splice(i, 1);
        setTipos(_tipos);
    }*/


    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        let validade = _formUserRef.current.RequiredValidation();

        let _selectedtipos = [...selectedtipos];

       //validade = false;
       
        if(validade) {
             setPageLoading(true);
            //_formUserRef.current.Test("","");

            let result = await _formUserRef.current.Post("api/Tratamentos/Save", {tratamento_id: Id, tipos: _selectedtipos }, user.token);
            setPageLoading(false);

            if(result === "error") {
                window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
                return;
            } 

            window.scrollTo({top: 0, behavior: 'smooth'});
            navigate("/Tratamentos");
        } else {
            window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
        }
        
    }
    //#endregion Handlers
    


    return(
        <div className="main-content TratamentosCadastro">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Cadastro de Tratamento</h4>
                                </div>

                                <div className="card-body pb-2">
                                   
                                    <form name="fuser" onSubmit={(event) => _HandleSubmit('tratamento', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                        <div className="row">
                                            <div className="col-md-12 pt-1 pb-1">
                                                <FormGenerator ref={_formUserRef} name={'tratamento'} url={'/assets/forms/tratamento.json'} execRef={Exec}></FormGenerator>
                                                <div style={{clear:'both'}}></div>
                                                <br />
                                         
                                                <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                                    <li className="nav-item">
                                                        <a className="nav-link active" data-bs-toggle="tab" href="#home2" role="tab">
                                                            <span className="d-block d-sm-none"><i className="fa fa-home"></i></span>
                                                            <span className="d-none d-sm-block"> <i className="mdi mdi-filter-plus"></i> &nbsp; Tipos de Tratamento  &nbsp;  </span> 
                                                        </a>
                                                    </li>
                                                </ul>
        
                                      
                                                <div className="tab-content p-3 text-muted">
                                                    <div className="tab-pane active" id="home2" role="tabpanel">
                                                        <div className="table-responsive">
                                                            <table className="table mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{width:'40px'}}></th>
                                                                        <th style={{width:'40px'}}>Abrev</th>
                                                                        <th>Titulo</th>
                                                                        <th>Descrição</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                {
                                                                        tipos.length > 0 && tipos.map((item, i) => (
                                                                            <tr key={'titulo'+i}>
                                                                                <td style={{ padding:'11px 0 0 3px'}}>
                                                                                    <input id={"titulo_i"+i} type="checkbox" style={{width:'22px', height: '22px', padding:'0 0 0 0', margin:'0'}} value={item.item_tratamento_id} checked={selectedtipos.findIndex(t => t.tipo_tratamento_id === item.tipo_tratamento_id) > -1} onClick={(event) => _HandleTipoChange(event, item)} disabled={user.nivel>0}/>
                                                                                </td>
                                                                                <td>
                                                                                    {item.titulo_abrev}
                                                                                </td>
                                                                                <td>
                                                                                    {item.titulo}
                                                                                </td>
                                                                                <td>
                                                                                    {item.descricao}
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    
                                                   
                                                </div>


                                                
                                                

                                                <center>
                                                    <hr />
                                                    <button id="btn_sv" className={'btn btn-primary btn-lg btn-rounded mb-2 '} type="submit">Salvar informações</button>
                                                 </center>
                                                <br />
                                            </div> 
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>


                       
                    </div>
                </div>
            </div>


            
        </div>
    )
}
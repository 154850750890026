/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Request, Req } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import ReactGA from 'react-ga';
import { Currency, UnCurrency, Number } from '../../../scripts/StringUtils';
import { useNavigate, useParams } from 'react-router-dom';
import Barcode from 'react-barcode';
import moment from 'moment/moment';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import './View.css';


export const OrcamentoView = () => {
    const component_name = "admina/os";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [atend, setAtend] = useState({});
    const [unidade, setUnidade]= useState({});
    const [orNumber, setOrNumber] = useState("000000");
    const [prazo, setPrazo] = useState("");
    const [osObs, setOsObs] = useState("");
    const [hora, setHora] = useState("");
    const [saved, setSaved] = useState(false);
    const [status, setStatus] = useState(0);
    const [valores, setValores] = useState({compra:0, venda:0, total:0, tratamentos:0, desconto: 0});
    const [nome, setNome] = useState("");
    
    const JSBarcode = useRef();
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const atendRef = useRef({});

    const navigate = useNavigate();

    let { Id } = useParams();


    //#region Effects
    useEffect(() => {
        if(!loadPrestineRef.current) {
            loadPrestineRef.current = true;
            WaitObject(user.nome).then(() => {
                Init();
                LoadUnidadeInfo();
            });
        }

        return () => {
            
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);
    //#region Effects


    //#region Init
    const Init = () => {
        LoadOs();
    }
    //#endregion Init



    //#region Loaders
    const LoadOs = async() => {
        let data = await Req("api/Orcamentos/Load?id=" + Id, "GET", "", user.token, 0*24*60*60*1000);

        if(data.toString() !== "error") {
            setOrNumber(data.numero);
            setNome(data.nome);
            setAtend(JSON.parse(data.stream));
            setStatus(data.status);
            setPrazo(moment(data.date_prazo).format("DD/MM/YYYY"));
            setHora(moment(data.date_prazo).format("HH:mm"));
        } else {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde ", "error");
        }
    }


    const LoadUnidadeInfo = async() => {
        let data = await Req("api/AtendimentoLentes/Unidade?q=" + user.user_id, "GET", "", user.token, 1*24*60*60*1000);

        if(data.toString() !== "error") {
            setUnidade(data);
        } else {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde ", "error");
        }
    }
    //#endregion Loaders


    //#region Handlers
    const _HandleConvert = async(event) => {
        let data = await Req("api/Orcamentos/ConvertOs?id=" + Id, "GET", "", user.token, 0*24*60*60*1000);

        if(data.toString() !== "error") {
            navigate("/Ordem/"+data);
        } else {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde ", "error");
        }
    }


    const _HandlePrint = () => {
        window.$(".or_body").printThis({
            debug:false,                   // show the iframe for debugging
            importCSS: true,                // import parent page css
            importStyle: true,             // import style tags
            printContainer: true,           // grab outer container as well as the contents of the selector
            //loadCSS: "/assets/css/orcamento_print.css",      // path to additional css file - use an array [] for multiple
            pageTitle: "ORÇAMENTO",                  // add title to print page
            removeInline: false,            // remove all inline styles from print elements
            //removeInlineSelector: "body *", // custom selectors to filter inline styles. removeInline must be true
            printDelay: 333,                // variable print delay
            header: null,                   // prefix to html
            footer: null,                   // postfix to html
            base: false,                    // preserve the BASE tag, or accept a string for the URL
            formValues: true,               // preserve input/form values
            canvas: false,                  // copy canvas elements
            doctypeString: '...',           // enter a different doctype for older markup
            removeScripts: false,           // remove script tags from print content
            copyTagClasses: false,          // copy classes from the html & body tag
            beforePrintEvent: null,         // callback function for printEvent in iframe
            beforePrint: null,              // function called before iframe is filled
            afterPrint: null                // function called before iframe is removed
            });
    }
    //#endregion Handlers


    //#region Whatsapp
    const _HandleWhatsapp = async() => {
        let current_atend = await Hub.Get("current_atend", true);

        if(current_atend?.cliente?.celular) {
            let cel = current_atend?.cliente?.celular.replace('-','').replace('(','').replace(')','').replace('.','').replace(' ','');

            if(cel.substr(0,2) !== "55") cel = "55" + cel;

            if(cel.length < 13) {
                window.swal("Alerta", "Número de Whatsapp inválido", "error");
                return;
            }



            
            const quality = 1;
            html2canvas(document.querySelector('#or_body'),
                { scale: 1 }
            ).then(async canvas => {
                const pdf = new jsPDF();
                pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, canvas.width*0.2, canvas.height*0.2);
                //pdf.save();
    
                
                let blobPDF = new Blob([pdf.output('blob')], { type: 'application/pdf'});
                let blobUrl = URL.createObjectURL(blobPDF);
    

                let base64 = await convertBase64(blobPDF);
                let files_list = [];
                files_list.push({ "name": orNumber + ".pdf", "size": blobPDF.size, "type": blobPDF.type, "base64":  base64 });
    
                let _contacts = [];
                _contacts.push( {number: cel, name: current_atend?.cliente?.nome, message: "Olá " + current_atend?.cliente?.nome + ", orçamento Nº " + orNumber +  " emitido em: " + unidade?.nome + ", " + unidade?.endereco + " " + unidade?.numero + " - " + unidade?.bairro + " " + unidade?.cidade + "/" + unidade?.uf})
                
            
                //console.log(files_list[0]);

                let event = new CustomEvent('Event', { 'detail': {
                    message:"Olá @name, seu pedido está pronto para retirada ", 
                    attachments:[], 
                    files: files_list, 
                    list: _contacts
                }});
                event.initEvent('whatsnap_api_broadcast');
                document.dispatchEvent(event);

            });
        }


    }
    //#endregion Whatsapp


    //#region Functions
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            try {
                fileReader.readAsDataURL(file);
            } catch(e) {
                console.log("try", e);
                reject(e);
            }
    
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
    
            fileReader.onerror = (error) => {
                console.log(error);
                reject(error);
            };
        });
    };
    //#endregion Functions



    return (
        
        <div className="main-content Atendimento">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title left">Orçamento</h4>
                                </div>

                                <div className="card-body pb-2">
                                <div className="AtendimentoFinalizar">
                                 
                                    <div className="row">
                                        <div className="col col-sm-4">
                                            <div className="card pricing-box">
                                                <div className="card-body p-4">
                                                    <div className="text-center">
                                                        <h5 className="mb-1 font-size-20">Valor Total</h5>
                                                        <div className="pt-4 pb-2">
                                                            <h2 className="month" style={{color:'red'}}>{Currency(
                                                                    (atend?.os?.oe?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                                                    (atend?.os?.od?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                                                    (atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0)+
                                                                    (atend?.os?.od?.lente?.venda/2||0)+(atend?.os?.oe?.lente?.venda/2||0)+
                                                                    (atend?.os?.od?.lente?.color?.valor||0)+ (atend?.os?.oe?.lente?.color?.valor||0)+
                                                                    (atend?.armacao?.form?.venda||0)-UnCurrency(atend?.desconto||0)
                                                                )}</h2>

                                                        <span className={'' + (!atend?.parcelas||atend?.parcelas <= 1 ? ' _hide': '')}>
                                                            <b>Parcelas: {atend?.parcelas}x {Currency(
                                                                ((atend?.os?.oe?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                                                (atend?.os?.od?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                                                (atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0)+
                                                                (atend?.os?.od?.lente?.venda/2||0)+(atend?.os?.oe?.lente?.venda/2||0)+
                                                                (atend?.os?.od?.lente?.color?.valor||0)+ (atend?.os?.oe?.lente?.color?.valor||0)+
                                                                (atend?.armacao?.form?.venda||0)-(atend?.desconto||0)-(atend?.entrada||0)) / (atend?.parcelas)
                                                            )}</b>
                                                        </span> 
                                                        </div>
                                                    
                                                        <p className="text-muted">Confira as opções de pagamento e parcelamento</p>
                                                    </div>

                                                        <hr class="my-4 text-muted" />
                                                        
                                                        <ul className="list-unstyled pricing-features mb-0 ps-4">
                                                            <li><i className="uil uil-check-circle text-success font-size-18 align-middle me-2"></i> <b>{Currency(atend?.armacao?.form?.venda||0)}</b>  - Armação </li>
                                                            <li><i className="uil uil-check-circle text-success font-size-18 align-middle me-2"></i> <b>{Currency((atend?.os?.od?.lente?.venda/2||0)+(atend?.os?.oe?.lente?.venda/2||0))}</b>  - Lentes</li>
                                                            <li><i className="uil uil-check-circle text-success font-size-18 align-middle me-2"></i> <b>{Currency((atend?.os?.od?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+(atend?.os?.oe?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0))}</b>  - Tratamentos </li>
                                                            <li><i className="uil uil-check-circle text-success font-size-18 align-middle me-2"></i> <b>{Currency((atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0))}</b>  - Acessórios </li>
                                                            <li><i className="uil uil-check-circle text-success font-size-18 align-middle me-2"></i> <b>{Currency((atend?.os?.od?.lente?.color?.valor||0)+(atend?.os?.oe?.lente?.color?.valor||0))}</b>  - Colorações </li>
                                                        </ul>

                                                        <hr class="my-4 text-muted" />  

                                                        <div className="position-relative">
                                                            Entrada Valor
                                                            <input id="entrada" type="text" value={Currency(atend?.entrada)} className="form-control rounded bg-light border-0" disabled/>
                                                        </div>

                                                        <br />  
                                                        <div className="position-relative">
                                                            Desconto
                                                            <input id="desconto" type="text" value={Currency(atend?.desconto)} className="form-control rounded bg-light border-0" disabled />
                                                        </div>

                                                        <br />
                                                        <div className="position-relative">
                                                            Parcelas
                                                            <input id="parcelas" type="number" value={atend?.parcelas} className="form-control rounded bg-light border-0" min={1} max={10} step={1} disabled />
                                                        </div>

                                                        <br />
                                                        <center>
                                                        <button typeName="button" className={'btn btn-primary ' + (status===0?'':'hide')} onClick={_HandleConvert}>Converter em OS</button>           
                                                        </center>
                                                        <br />
                                                    
                                                    </div>
                                                </div>
                                            </div>

                                        <div className="col col-sm-8 devgrid pricing-box">
                                            <div className={'OR'}>
                                                <div id="or_body" className={'or_body'}>
                                                
                                                    <div className={'or_painel'}>
                                                        <div className={'or_head'}>
                                                            <div className={'or_head_title'}>
                                                                <div><span>ORÇAMENTO</span></div>
                                                                <div>{moment(new Date()).format('DD/MM/YYYY')}</div>
                                                                <div>Ref: <span>{orNumber}</span></div>
                                                            </div>
                                                            <div className="or_stakeholders os_datas">
                                                                Cliente(a): <span>{atend?.cliente?.nome}</span> &nbsp;-&nbsp; CPF: <span>{atend?.cliente?.cpf}</span><br />
                                                                Telefone: <span>{atend?.cliente?.telefone}</span> &nbsp;-&nbsp; Celular: <span>{atend?.cliente?.celular}</span><br />
                                                                Vendedor(a): <span>{nome!==''?nome:user?.nome}</span> &nbsp;-&nbsp; Loja: <span>{unidade?.nome}</span>
                                                            </div>
                                                        </div>

                                                        <div className={'or_receita bt'}>
                                                            <div><span>RECEITA</span></div>
                                                            <div>
                                                                <table className="table ">
                                                                    <thead>
                                                                        <tr>
                                                                            <th colSpan={2}></th>
                                                                            <th className={'th_null'} >esf.</th>
                                                                            <th className={'th_null'} >cil.</th>
                                                                            <th className={'th_null'} >eixo</th>
                                                                            <th className={'th_null'} >dnp</th>
                                                                            <th className={'th_null'} >adi.</th>
                                                                            <th className={'th_null'} >alt.</th>
                                                                        </tr>
                                                                    </thead>
                                                                    
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className={'td_dist'} rowSpan={2}>LONGE</td>
                                                                            <td>OD</td>
                                                                            <td>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_od_esf}</td>
                                                                            <td>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_od_cil}</td>
                                                                            <td>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_od_eixo}</td>
                                                                            <td>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_od_dnp}</td>
                                                                            <td>{atend?.receita?.distancia_focal!==3?'':atend?.receita?.longe_od_adc}</td>
                                                                            <td>{atend?.armacao?.altura_od===0?'':atend?.armacao?.altura_od}</td>
                                                                        </tr>
                                                                        <tr>
                                                                        
                                                                            <td>OE</td>
                                                                            <td>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_oe_esf}</td>
                                                                            <td>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_oe_cil}</td>
                                                                            <td>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_oe_eixo}</td>
                                                                            <td>{atend?.receita?.distancia_focal===2?'':atend?.receita?.longe_oe_dnp}</td>
                                                                            <td>{atend?.receita?.distancia_focal!==3?'':atend?.receita?.longe_oe_adc}</td>
                                                                            <td>{atend?.armacao?.altura_oe===0?'':atend?.armacao?.altura_oe}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td className={'td_dist'} rowSpan={2}>PERTO</td>
                                                                            <td>OD</td>
                                                                            <td>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_od_esf}</td>
                                                                            <td>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_od_cil}</td>
                                                                            <td>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_od_eixo}</td>
                                                                            <td>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_od_dnp}</td>
                                                                            <td>{atend?.receita?.perto_od_adc}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>   
                                                                            <td>OE</td>
                                                                            <td>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_oe_esf}</td>
                                                                            <td>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_oe_cil}</td>
                                                                            <td>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_oe_eixo}</td>
                                                                            <td>{atend?.receita?.distancia_focal===1?'':atend?.receita?.perto_oe_dnp}</td>
                                                                            <td>{atend?.receita?.perto_oe_adc}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                        <div className={'or_datas os_medico bt'}>
                                                            MÉDICO: <span>{atend?.medico?.nome}</span> &nbsp;-&nbsp; TEL: <span>{(atend?.medico?.telefone!==''?atend?.medico?.telefone:atend?.medico?.celular)}</span>
                                                        </div>

                                                        <div className={'or_armacao bt'}>
                                                            <div><span>ARMAÇÃO</span></div>
                                                            <div>{atend?.armacao?.form?.marca} - {atend?.armacao?.form?.modelo} <span className="right">{Currency(atend?.armacao?.form?.venda)}</span></div>
                                                            <div>
                                                            Ponte: <span>{atend?.armacao?.form?.ponte}</span> &nbsp;-&nbsp; Aro: <span>{atend?.armacao?.form?.aro}</span> &nbsp;-&nbsp; Diagonal: <span>{atend?.armacao?.form?.diag}</span> &nbsp;-&nbsp; Vertical: <span>{atend?.armacao?.form?.altura}</span>
                                                            </div>
                                                            
                                                        </div>

                                                        <div className={'or_lentes bt'}>
                                                            <div><span>LENTES</span></div>
                                                            <div>OD: <span>{atend?.os?.od?.lente?.nome}</span> <span className="right">{Currency(atend?.os?.od?.lente?.venda/2)}</span></div>
                                                            <div>OE: <span>{atend?.os?.oe?.lente?.nome}</span> <span className="right">{Currency(atend?.os?.oe?.lente?.venda/2)}</span></div>
                                                        </div>


                                                        <div className={'or_tratamentos bt'}>
                                                            <div><span>TRATAMENTOS</span></div>
                                                            {
                                                                Array.isArray(atend?.os?.od?.tratamentos) && atend?.os?.od?.tratamentos.length > 0 && atend?.os?.od?.tratamentos.map((item, i) => (
                                                                    <div><b>OD:</b> {item.titulo} <small className={(item.tipo_id!==2?'hide':'hide')}>({Currency(item.valor)})</small> <span className={'right ' + (item.tipo_id===2?'hide':'')}>{Currency(item.valor/2)}</span></div>
                                                                ))
                                                            }

                                                            {
                                                                Array.isArray(atend?.os?.oe?.tratamentos) && atend?.os?.oe?.tratamentos.length > 0 && atend?.os?.oe?.tratamentos.map((item, i) => (
                                                                    <div><b>OE:</b> {item.titulo} <small className={(item.tipo_id!==2?'hide':'hide')}>({Currency(item.valor)})</small> <span className={'right ' + (item.tipo_id===2?'hide':'')}>{Currency(item.valor/2)}</span></div>
                                                                ))
                                                            }
                                                        </div>


                                                        <div className={'or_acessorios bt'}>
                                                            <div><span>ACESSORIOS</span></div>
                                                            {
                                                                Array.isArray(atend?.acessorios) && atend?.acessorios.length > 0 && atend?.acessorios.map((item, i) => (
                                                                    <div>{item.modelo} <span className="right">{Currency(item.venda)}</span></div>
                                                                ))
                                                            }
                                                        </div>


                                                        <div className={'or_total bt'}>
                                                            <div className={'' + (!atend?.desconto||atend?.desconto === 0 ? ' hide': '')}>
                                                                &nbsp;<span className="right">DESCONTO: {Currency(atend?.desconto)}</span>
                                                            </div>

                                                            <div>&nbsp;<span className="right"> TOTAL: {Currency(
                                                                (atend?.os?.oe?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                                                (atend?.os?.od?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                                                (atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0)+
                                                                (atend?.os?.od?.lente?.venda/2||0)+(atend?.os?.oe?.lente?.venda/2||0)+
                                                                (atend?.os?.od?.lente?.color?.valor||0)+ (atend?.os?.oe?.lente?.color?.valor||0)+
                                                                (atend?.armacao?.form?.venda||0)-(atend?.desconto||0)
                                                            )}</span></div>


                                                            <div className={'' + (!atend?.parcelas||atend?.parcelas <= 1 ? ' hide': '')}>
                                                                &nbsp;<span className="right">Parcelas: {atend?.parcelas}x {Currency(
                                                                    ((atend?.os?.oe?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                                                    (atend?.os?.od?.tratamentos?.reduce((p, c) => p + (c.tipo_id!==2?c.valor/2:0), 0)||0)+
                                                                    (atend?.acessorios?.reduce((p, c) => p + c.venda, 0)||0)+
                                                                    (atend?.os?.od?.lente?.venda/2||0)+(atend?.os?.oe?.lente?.venda/2||0)+
                                                                    (atend?.os?.od?.lente?.color?.valor||0)+ (atend?.os?.oe?.lente?.color?.valor||0)+
                                                                    (atend?.armacao?.form?.venda||0)-(atend?.desconto||0)-(atend?.entrada||0)) / (atend?.parcelas)
                                                                )}</span>
                                                            </div>
                                                        </div>


                                                        <div className={'or_alert bt'}>
                                                        <br />
                                                            <div><center>Orçamento válido por 15 dias.</center></div>
                                                            <br /><br />
                                                        
                                                        </div>

                                                    </div>
                                                </div>


                                                <div className="mt-4">
                                                    <center>
                                                        <div className={'btn-group btn-group-lg ' + (saved?'':'_hide')} role="group" aria-label="orcamento">
                                                            <a className={'pointer btn btn-outline-primary '} onClick={_HandlePrint}><i className="fa fa-print"></i> Imprimir</a>
                                                            <button className={'btn btn-primary '} onClick={_HandleWhatsapp} style={{backgroundColor:'#128C7E',border:'0px'}}><i className="mdi mdi-whatsapp mdi-18px" style={{padding:'0 !important',margin:'0 !important'}}></i> </button>
                                                        </div>
                                                    </center>
                                                </div>
                                            </div>
                            
                                        

                                            <br />
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>


        

 
    )
       
}
import React, { useState, useContext, useCallback, useEffect, useRef} from 'react';
import { Context } from '../../../Context/AuthContext';
import { HubContext } from '../../../Context/HubContext.js';
import { Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import { FormGenerator } from '../../Form/FormGenerator';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";
import ReactGA from 'react-ga';
import './index.css';


export function Perfil() {
    const component_name = "admin/perfil";
    const { user, loading, authenticated, handleLogin, handleLogout, Refresh } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [unidades, setUnidades] = useState([]);
    const _formUserRef = useRef();
    const _formLoadedRef = useRef();
    const loadPrestineRef = useRef(false);
    const timerIdleRef = useRef(new Date());
    const [tfa, setTfa] = useState(false);
    const [qr2fa, setQr2fa] = useState("");
    const [code2fa, setCode2fa] = useState("");

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".main-content")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        LoadNiveis();
    }
    //#endregion Init


    //#region Loaders
    const LoadNiveis = () => {
        Promise.resolve(new Request().Run("api/Perfil/Niveis", "GET", "", user.token, 0*24*60*1000))
        .then(async(data) => {
            setPageLoading(false);
            await _formUserRef.current.Select("nivel", data);

            if(data.toString() !== "error") {
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }

    //#endregion Loaders


    //#region Form Handlers
    const OnFormLoaded = async(fields) => {
        setPageLoading(true);
 
        WaitObject(_formUserRef.current).then(async() => {
            let user_data = await new Request().Run("api/Perfil/Load", "GET", "", user.token);
            if(user_data !== undefined && user_data.user_id !== null) {
                await _formUserRef.current.Load(user_data);
                setTfa(user_data.tfa);
            }
            
        setPageLoading(false);
            window.scrollTo({top: 0, behavior: 'smooth'});
            setTimeout(async() => {
                await _formUserRef.current.Value("senha", "a");
                await _formUserRef.current.Value("resenha", "");
                setTimeout(async() => {
                    await _formUserRef.current.Value("senha", "");
                }, 100);

            let unidades;
            let wait_unidades = setInterval(() => {
                unidades = Hub.Exec("HEADER_UNIDADES");
                if(unidades && unidades.length > 0) {
                    clearInterval(wait_unidades);
                    if(user_data) {
                        let _myUnidades = user_data.unidades?.split(',');

                        unidades.map((item, i) => {
                            if(_myUnidades.find(v => parseInt(v) === item.id) > -1) {
                                item.selected = true;
                            } else {
                                item.selected = false;
                            }
                        });
                    }
                    setUnidades(unidades);
                } 
            }, 100);

            forceUpdate();
            }, 100);
        });
        
    }

    
    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers



    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        let validade = _formUserRef.current.RequiredValidation();

        if(validade) {
             setPageLoading(true);
            let result = await _formUserRef.current.Post("api/Perfil/Save", {}, user.token);
            setPageLoading(false);

            await _formUserRef.current.Value("senha", "");
            await _formUserRef.current.Value("resenha", "");

            if(result === "error") {
                window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
                return;
            } else if(result === "exists") {
                window.swal("Alerta", "Usuário já registrado", "error");
                return;
            }

            window.scrollTo({top: 0, behavior: 'smooth'});
            window.swal("Sucesso", "Informações atualizadas", "success");
            await Refresh();
        } else {
            window.swal("Alerta", "Preencha todos campos obrigatórios", "error");
        }

    }


    const _HandleTFAClick = (e) => {
        setTfa(e.target.checked);
        Promise.resolve(new Request().Run("api/Perfil/TFA", "POST", {tfa: e.target.checked}, user.token)).then((data) => { }).catch(() => { });
    }


    const _HandleTFAQRClick = (e) => {
        setTfa(e.target.checked);
        Promise.resolve(new Request().Run("api/Perfil/QRTFA", "POST", {tfa: e.target.checked}, user.token)).then((data) => { 
            setQr2fa(data)
        }).catch(() => { });
    }


    const _HandleChangeTFA = (e) => {
        setCode2fa(e.target.value);
        if(e.target.value.length === 6) {
            Promise.resolve(new Request().Run("api/Perfil/TFACheck", "POST", {tfa: e.target.value}, user.token)).then((data) => { 
                setCode2fa("");
                if(data.toString() === "loaded") {
                    setQr2fa({tfa: "", code:""});
                    setTfa(true);
                    window.swal("Sucesso", "Autenticação de 2 fatores ativada", "success");
                } else {
                    setTfa(false);
                    window.swal("Alerta", "Código para ativação incorreto", "error");
                }
                
            }).catch(() => { });
        }
    }
    //#endregion Handlers



    return(
        <div className="main-content Perfil">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-9">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Meu perfil</h4>
                                </div>

                             

                                <div className="card-body pb-2">
                                   
                                    <form name="fuser" onSubmit={(event) => _HandleSubmit('perfil', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                        <div className="row">
                                            <div className="col-md-12 pt-1 pb-1">
                                                <FormGenerator ref={_formUserRef} name={'perfil'} url={'/assets/forms/perfil.json'} execRef={Exec}></FormGenerator>
                                                <div style={{clear:'both'}}></div>

                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <table className="table mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{width:'50px'}}></th>
                                                                    <th>Unidade</th>
                                                                    <th>Rede</th>
                                                                </tr>
                                                            </thead>


                                                            <tbody>
                                                            {unidades.map((item, i) => (
                                                                <tr key={'unidade' + i} className="pointer">
                                                                    <td style={{ padding:'11px 0 0 11px'}}><input id={"unidade_"+i} type="checkbox" style={{width:'22px', height: '22px', padding:'0 0 0 0', margin:'0'}} value={item.unidade_id} checked={item.selected} disabled={true}/></td>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.rede}</td>
                                                                </tr>
                                                            ))}
                                                            
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                <center>
                                                    <hr />
                                                    <button id="btn_sv" className="btn btn-primary btn-lg btn-rounded mb-2" type="submit">Salvar informações</button>
                 
                                                </center>
                                                <br />
                                            </div> 
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>


                        <div className="col-xl-3">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Autenticação de 2 fatores</h4>
                                    <input type="checkbox" className="custom-control-input" id="tfa" checked={tfa} onClick={_HandleTFAQRClick} />
                                    
                                </div>

                                <div className="card-body pb-2">
                                    <div className="card-header border-0 pb-0 pt-0">
                                        <small><a style={{color:'#0000ff', float:'right'}} href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=pt_BR&gl=US&pli=1" target="_blank">Google Authenticator</a></small>
                                    </div>
                                    <br />

                                    <div className="card-body portfolios-card">
                                       
                                        {
                                            qr2fa.tfa && qr2fa.tfa !== "" && (<>
                                                <center>
                                                    <img src={qr2fa.tfa} alt="" style={{width:'100%', height:'auto'}}/>
                                                    <br />
                                                    <input id="code2fa" type="text" placeholder="" className="form-control"  value={code2fa} onChange={_HandleChangeTFA}/>

                                                    <br />
                                                </center>
                                                <small>
                                                <p>Escaneie o QR Code no app Google Authenticator e digite o código fornecido para ativação. Após ativado em cada novo login no sistema será pedido um código atualizado.</p>
                                                <p>Caso desinstale o app, você deve acessar a recuperação de senha para desativar a autenticação de 2 fatores</p>
                                                </small>
                                                </> )
                                        }
                                         
									</div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>

        </div>
    )
}
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useImperativeHandle, useEffect, useRef, forwardRef} from 'react';
import { Context } from '../../../../Context/AuthContext';
import { HubContext } from '../../../../Context/HubContext.js';
import { Request, Req } from '../../../../scripts/Request';
import { WaitObject } from '../../../../scripts/Wait';
import { Number } from '../../../../scripts/StringUtils';
import ReactGA from 'react-ga';
import './index.css';


let current_element;
let search_receita;
let search_receita_element;
let receita_block = false;
let adicao_dnp = 2;

let distancia_focal_data = [
    {id: 0, name: "Escolha uma opção"},
    {id: 1, name: "Longe (Monofocal)"},
    {id: 2, name: "Perto (Monofocal)"},
    {id: 3, name: "Longe / Perto"},
];


let focal_data = [
    {id: 1, name: "Multifocal"},
    {id: 2, name: "Bifocal"},
    {id: 3, name: "Ocupacional"},
];


export const Receita = forwardRef((props, ref) => {
    const component_name = "admin/receita_cadastro";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [receitas, setReceitas] = useState([]);
    const [receitaId, setReceitaId] = useState('');
    const [receita, setReceita] = useState({receita_id: "", cliente_id: "", medico_id: "", longe_od_esf:"", longe_od_cil:"", longe_od_eixo:"", longe_od_dnp:"", longe_od_adc:"", longe_oe_esf:"", longe_oe_cil:"", longe_oe_eixo: "", longe_oe_dnp:"", longe_oe_adc:"", perto_od_esf:"", perto_od_cil:"", perto_od_eixo:"", perto_od_dnp:"", perto_oe_esf:"", perto_oe_cil:"", perto_oe_eixo:"", perto_oe_dnp:"", distancia_focal:"", focal:"", obs:""});
    const [medico, setMedico]= useState("");
    const [formChanged, setFormChanged] = useState(false);

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);


   

    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(current_element? current_element :".main-content")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects


    //#region Ref
    useImperativeHandle(ref, (url, args, token) => ({
        async Init() { return Init() },
        async Clear() { return ClearComponent() }
    }));
    //#endregion Ref


    //#region Init
    const Init = async() => {
        let current_atend = await Hub.Get("current_atend", true);
        if(!current_atend) return;
        
        if(current_atend && current_atend.medico) {
            setMedico(current_atend.medico.nome);
        }

        //if(current_atend && current_atend.cliente) setCliente(current_atend.medico.cliente);

        //console.log(current_atend?.receita);

        if(current_atend && current_atend.receita) {
            setReceita(current_atend.receita);

            if(current_atend.receita?.receita_id) {
                setReceita(prev => prev = {...prev, receita_id: current_atend.receita?.receita_id});
                setReceitaId(parseInt(current_atend.receita?.receita_id));
            }
        }  
        LoadReceitas();
    }


    const ClearComponent = () => {
        setReceitas([]);
        setReceitaId('');
        setReceita({receita_id: "", cliente_id: "", medico_id: "", longe_od_esf:"", longe_od_cil:"", longe_od_eixo:"", longe_od_dnp:"", longe_od_adc:"", longe_oe_esf:"", longe_oe_cil:"", longe_oe_eixo: "", longe_oe_dnp:"", longe_oe_adc:"", perto_od_esf:"", perto_od_cil:"", perto_od_eixo:"", perto_od_dnp:"", perto_oe_esf:"", perto_oe_cil:"", perto_oe_eixo:"", perto_oe_dnp:"", distancia_focal:"", focal:"", obs:""})
        setMedico("");
    }
    //#endregion Init


    //#region Loaders
    const LoadReceitas = async() => {
        current_element = "#receita_id";
        setPageLoading(true);

        let current_atend = await Hub.Get("current_atend", true);

        if(current_atend && current_atend.cliente_id && current_atend.medico_id) {
            let _cliente_id = current_atend.cliente_id;
            let _medico_id = current_atend.medico_id;

            let data = await Req("api/Receitas/Search", "POST", { cliente_id: _cliente_id, medico_id: _medico_id }, user.token, 0*24*60*1000);
            current_element = "";
            setPageLoading(false);
            if(data.toString() !== "error") {
                setReceitas(data.receita);
                adicao_dnp = data.adicao_dnp;
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde ", "error");
            }
        } else {
            if(current_atend.step >= 4) Hub.Exec("CLEAR_SESSION", component_name);
        }
    }


    const LoadReceita = async(id) => {
        if(!id) return;
        current_element = "";
        setPageLoading(true);

        let current_atend = await Hub.Get("current_atend", true);
        if(current_atend && current_atend.medico) setMedico(current_atend.medico.nome);


        Promise.resolve(new Request().Run("api/Receitas/Load?id="+id, "GET", "", user.token, 0*24*60*1000)).then(async(data) => {
            current_element = "";
            setPageLoading(false);
            if(data.toString() !== "error") {
                let arr = ["longe_od_esf", "longe_od_cil", "longe_od_eixo", "longe_od_dnp", "longe_od_adc", "longe_oe_esf", "longe_oe_cil", "longe_oe_eixo", "longe_oe_dnp", "longe_oe_adc", "perto_od_esf", "perto_od_cil", "perto_od_eixo", "perto_od_dnp", "perto_oe_esf", "perto_oe_cil", "perto_oe_eixo", "perto_oe_dnp"];
                arr.map(item => {
                    data[item] = data[item] === null ? "" : Number(data[item], undefined, (item.indexOf("_eixo") > -1 ? 0 : (item.indexOf("_dnp") > -1 ? 1 : 2) ));
                });
                Hub.Update("current_atend", "receita", data, true);
                setReceita(data);
                setFormChanged(false);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde ", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }
    //#endregion Loaders


    
    //#region Handlers
    const ClearReceita = async() => {
        await Hub.DeleteKey("current_atend", "receita_id", true);
        await Hub.DeleteKey("current_atend", "receita", true);
        setReceitaId('');
        setReceita({receita_id: "", cliente_id: "", medico_id: "", longe_od_esf:"", longe_od_cil:"", longe_od_eixo:"", longe_od_dnp:"", longe_od_adc:"", longe_oe_esf:"", longe_oe_cil:"", longe_oe_eixo: "", longe_oe_dnp:"", longe_oe_adc:"", perto_od_esf:"", perto_od_cil:"", perto_od_eixo:"", perto_od_dnp:"", perto_oe_esf:"", perto_oe_cil:"", perto_oe_eixo:"", perto_oe_dnp:"", distancia_focal:"", focal:"", obs:""});
    }


    const _HandleChange = (event, maxlength, decimals) => {
        setFormChanged(true);

        if(event.target.id.indexOf("_cil") > -1) {
            if(event.target.value.indexOf("-") !== 0) {
                event.target.value = "-" + event.target.value;
            }
        }
        let val = Number(event.target.value, maxlength, decimals);

        let current_atend = Hub.Get("current_atend", true);

        if(event.target.id === "receita_id") {
            Hub.Update("current_atend", event.target.id, event.target.value, true);
            setReceita(prev => prev = {...prev, [event.target.id]: parseInt(event.target.value)});
            setReceitaId(parseInt(event.target.value));
            LoadReceita(parseInt(event.target.value))
        } else if(event.target.id === "distancia_focal") {
            if(event.target.id === "distancia_focal" && parseInt(event.target.value) !== 3) {
                current_atend.receita = {...receita, [event.target.id]: parseInt(event.target.value), focal:''};
            } else {
                current_atend.receita = {...receita, [event.target.id]: parseInt(event.target.value), focal:1};
            }
            Hub.Update("current_atend", "receita", current_atend.receita, true);
            setReceita(current_atend.receita);
            //console.log(current_atend.receita);
        } else if(event.target.id === "focal") {
            current_atend.receita = {...receita, [event.target.id]: parseInt(event.target.value)};
            Hub.Update("current_atend", "receita", current_atend.receita, true);
            setReceita(current_atend.receita);
            console.log(current_atend.receita);
        } else {
            if(event.target.id === "obs") {
                current_atend.receita = {...receita, [event.target.id]: event.target.value};
            } else {
                current_atend.receita = {...receita, [event.target.id]: val};
            }
            //console.log(event.target.id, val);
            Hub.Update("current_atend", "receita", current_atend.receita, true);
            setReceita(current_atend.receita);
        }
    }


    const _HandleReset = (event) => {
        setFormChanged(true);
        Hub.Update("current_atend", "receita", {...receita, [event.target.id]: ""}, true);
        setReceita(prev => prev = {...prev, [event.target.id]: ""});
    }


    const _HandleNext = async() => {
        //let current_atend = await Hub.Get("current_atend", true);
        //console.log(current_atend);
        //await Hub.DeleteKey("current_atend", "armacao", true);
        //await Hub.DeleteKey("current_atend", "lentes", true);


        if(receita.distancia_focal === "" ||  receita.distancia_focal === 0) {
            window.swal("Alerta", "Informe o tipo de lente para poder salvar a receita! ", "error");
            return;
        }

        if(receita.distancia_focal === 3 && (receita.longe_od_adc === "" || receita.longe_oe_adc === "" || receita.perto_od_esf === "" || receita.perto_od_cil === "")) {
            window.swal("Alerta", "Informe as informações de adição, longe e perto, corretamente!", "error");
            return;
        }

        props.OnNext();
    }


    const _HandleSave = async() => {
        current_element = "";
     
        if(!Validate()) {
            return;
        }

        if(receita.distancia_focal === "" ||  receita.distancia_focal === 0) {
            window.swal("Alerta", "Informe o tipo de lente para poder salvar a receita! ", "error");
            return;
        }

        if(receita.distancia_focal === 3 && (receita.longe_od_adc === "" && receita.longe_oe_adc === "" && receita.perto_od_esf === "" && receita.perto_od_cil === "")) {
            window.swal("Alerta", "Informe as adições, OD e OE corretamente!", "error");
            return;
        }

        if(receita.distancia_focal === 1 && IsLongeEmpty()) {
            window.swal("Alerta", "Informe os dados de ESF e CIL para Longe corretamente!", "error");
            return;
        }

        if(receita.distancia_focal === 2 && IsPertoEmpty()) {
            window.swal("Alerta", "Informe os dados de ESF e CIL para Perto corretamente!", "error");
            return;
        }

        setPageLoading(true);

        let current_atend = await Hub.Get("current_atend", true);
        let _cliente_id = current_atend.cliente_id;
        let _medico_id = current_atend.medico_id;

        
        Promise.resolve(new Request().Run("api/Receitas/Save", "POST", {...receita, cliente_id: _cliente_id, medico_id: _medico_id}, user.token, 0*24*60*1000)).then(async(data) => {
            current_element = "";
            setPageLoading(false);
            if(data.toString() !== "error") {
                await Hub.Update("current_atend", "receita", {...receita, receita_id: data}, true);
                await Hub.Update("current_atend", "receita_id", data, true);
                setReceita(prev => prev = {...prev, receita_id: data});
                setReceitaId(parseInt(data));
                LoadReceitas();
                setFormChanged(false);
                props.OnChange();
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde ", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde", "error");
        });
    }


    const _HandleBlur = async(event) => {
        let msg = "";
        let id = event?.target?.id;
        let str = event?.target?.value?.toString();
        let value = parseFloat(str?.replace(",","."));

        let current_atend = Hub.Get("current_atend", true);

        //console.log(id, event.target.value, typeof(event.target));
     
        receita_block = false;
        //console.log(event.target.addction);
        let _receita = {...receita};
        //console.log(id, _receita[id]);

        //if(isNaN(_receita[id]) ||  _receita[id] === "NaN" ||  _receita[id] === "") return;



        if(id.indexOf("_esf") > -1 && event.target.value !== "") {
            //console.log(value);
            //if(isNaN(_receita[id]) ||  _receita[id] === "NaN" ||  _receita[id] === "") return false;
            if(value < -22 || value > 22) msg += "Esférico fora do intervalo válido (-22 a 22) <br />";
            let decArr = event?.target?.value?.toString().split(',');
            if(decArr.length > 0) {
                let dec = decArr[1];
                //console.log(id,event.target.value, dec)
                if((dec!=="00")&&(dec!=="12")&&(dec!=="25")&&(dec!=="37")&&(dec!=="50")&&(dec!=="62")&&(dec!=="75")&&(dec!=="87")) {
                    msg += "Medida de Esférico inválida. Parte decimal diferente de 00, 12, 25, 37, 50, 62, 75, 87 <br />";
                }
            }
            if(msg !== "") {
                setTimeout(() => {
                    try {
                        event.target.focus();
                    } catch(e) {
                        window.$("#" + id).focus();
                    }
                }, 200);
                receita_block = true;
                window.swal({html: true, title:'Alerta', text: msg, type: "error"});

                current_atend.receita = {...receita, [event.target.id]: ""};
                Hub.Update("current_atend", "receita", current_atend.receita, true);
                setReceita(current_atend.receita);
                return false;
            } else {
                if(event.target.addction !== 'none') Adicao(id);
            } 
        } else if(id.indexOf("_cil") > -1 && event.target.value !== "") {
            //if(isNaN(_receita[id]) ||  _receita[id] === "NaN" ||  _receita[id] === "") return false;

            if(value < -8 || value > 8) msg += "Cilíndrico fora do intervalo válido (-8 a 8) <br />";
            if(value > 0 && value < 0.25) msg += "Cilíndrico não pode ser maior que zero e menor que 0,25 <br />";
            if(value < 0 && value > -0.25) msg += "Cilíndrico não pode ser menor que zero e maior que -0,25 <br />";
            let decArr = event?.target?.value?.toString()?.split(',');

            if(decArr.length > 0 ) {
                let dec = decArr[1];
                if((dec!=="00")&&(dec!=="12")&&(dec!=="25")&&(dec!=="37")&&(dec!=="50")&&(dec!=="62")&&(dec!=="75")&&(dec!=="87")) {
                    msg += "Medida de Cilíndrico inválida. Parte decimal diferente de 00, 12, 25, 37, 50, 62, 75, 87 <br />";
                }
            }
          
            if(msg !== "") {
                setTimeout(() => {
                    try {
                        event.target.focus();
                    } catch(e) {
                        window.$("#" + id).focus();
                    }
                }, 200);
                receita_block = true;
                window.swal({html: true, title:'Alerta', text: msg, type: "error"});

                current_atend.receita = {...receita, [event.target.id]: ""};
                Hub.Update("current_atend", "receita", current_atend.receita, true);
                setReceita(current_atend.receita);
                return false;
            } else {
                if(event.target.addction !== 'none') Adicao(id);
            }
        } else if(id.indexOf("_eixo") > -1 && event.target.value !== "") {
            if(value < 0 || value > 180) msg += "Eixo fora do intervalo válido (000 a 180) <br />";
            if(msg !== "") {
                setTimeout(() => {
                    try {
                        event.target.focus();
                    } catch(e) {
                        window.$("#" + id).focus();
                    }
                }, 200);
                receita_block = true;
                window.swal({html: true, title:'Alerta', text: msg, type: "error"});

                current_atend.receita = {...receita, [event.target.id]: ""};
                Hub.Update("current_atend", "receita", current_atend.receita, true);
                setReceita(current_atend.receita);
                return false;
            } else {
                Adicao(id);
            }
        } else if(id.indexOf("_dnp") > -1 && event.target.value !== "") {
            if(value < 23 || value > 40) msg += "DNP fora do intervalo válido (23,0 a 40,0)";
            if(msg !== "") {
                setTimeout(() => {
                    try {
                        event.target.focus();
                    } catch(e) {
                        window.$("#" + id).focus();
                    }
                }, 200);
                receita_block = true;
                window.swal({html: true, title:'Alerta', text: msg, type: "error"});

                current_atend.receita = {...receita, [event.target.id]: ""};
                Hub.Update("current_atend", "receita", current_atend.receita, true);
                setReceita(current_atend.receita);
                return false;
            } else {
                if(event.target.addction !== 'none') Adicao(id);
            }
        } else if(id.indexOf("_adc") > -1 && event.target.value !== "") {
            Adicao(id);
        }
    }
    //#endregion Handlers


    //#regions Functions
    const Adicao = (id) => {
        let r = {...receita};


        if(r.longe_od_adc !== "") {
            let v = parseFloat(r?.longe_od_adc?.toString()?.replace(",","."));
            if(v<0.75 && v>3.5) {
                window.swal({html: true, title:'Alerta', text: "Adição deve ter valor entre 0,75 e 3,5", type: "error"});
                return;
            }

            if(r.longe_od_esf !== "" /*&& r.perto_od_esf === ""*/) {
                //if((id.indexOf("longe") > -1 && id.indexOf("esf") > -1) || id.indexOf("_adc") > -1) {
                    let esf = r?.longe_od_esf !== "" ? parseFloat(r?.longe_od_esf?.toString()?.replace(",",".")) : '';
                    if(esf !== '') {
                        r.perto_od_esf = Number(((esf+v)*1), undefined, 2, true);
                        if(r.perto_od_esf) _HandleBlur({target: {id: "perto_od_esf", value: r.perto_od_esf, addction: 'none'}});
                    }
                //}
            }

            
            if(r.longe_od_cil !== "" /*&& r.perto_od_cil === ""*/) {
                //if((id.indexOf("longe") > -1 && id.indexOf("cil") > -1) || id.indexOf("_adc") > -1) {
                    let cil = r?.longe_od_cil !== "" ? parseFloat(r?.longe_od_cil?.toString()?.replace(",",".")) : '';
                    if(cil !== '') {
                        r.perto_od_cil = r.longe_od_cil; //Number((cil+v)*1);
                        _HandleBlur({target: {id: "perto_od_cil", value: r.perto_od_cil, addction: 'none'}});
                    }
                //}
            }

            
            if(r.longe_od_esf === "" /*&& r.perto_od_esf !== ""*/ ) {
            //if(id.indexOf("perto") > -1 || id.indexOf("_adc") > -1) {
                if(r?.perto_od_esf && r?.perto_od_esf !== "") {
                    let esf1 = parseFloat(r?.perto_od_esf?.toString()?.replace(",","."));
                    r.longe_od_esf = Number((esf1-v)*1, undefined, 2, true);
                    _HandleBlur({target: {id: "longe_od_esf", value: r.longe_od_esf, addction: 'none'}});
                }
            }

            
            if(r.longe_od_cil === "" /*&& r.perto_od_cil !== ""*/ ) {
            //if(id.indexOf("perto") > -1 || id.indexOf("_adc") > -1) {
                if(r?.perto_od_cil && r?.perto_od_cil !== "") {
                    let cil1 = parseFloat(r?.perto_od_cil?.toString()?.replace(",","."));
                    r.longe_od_cil = cil1; //Number((cil1-v)*1);
                    _HandleBlur({target: {id: "longe_od_cil", value: r.longe_od_cil, addction: 'none'}});
                }
            }

            
            if(r.longe_od_eixo !== "") {
                r.perto_od_eixo = r.longe_od_eixo;
            }

            
            if(r.perto_od_eixo !== "") {
                r.longe_od_eixo = r.perto_od_eixo;
            }




            if(r.longe_od_dnp !== "" /*&& r.perto_od_dnp === ""*/) {
                if(id.indexOf("longe") > -1 || id.indexOf("_adc") > -1) {
                    let dnp = parseFloat(r?.longe_od_dnp?.toString()?.replace(",","."));
                    r.perto_od_dnp = Number((dnp-adicao_dnp)*1, undefined, 1, true);

                    console.log(dnp, adicao_dnp, r.perto_od_dnp);
                    _HandleBlur({target: {id: "perto_od_dnp", value: r.perto_od_dnp, addction: 'none'}});
                }
            }


            if(r.perto_od_dnp !== "" /*&& r.longe_od_dnp === ""*/) {
                if(id.indexOf("perto") > -1 || id.indexOf("_adc") > -1) {
                    let dnp = parseFloat(r?.perto_od_dnp?.toString()?.replace(",","."));
                    r.longe_od_dnp = Number((dnp+adicao_dnp)*1, undefined, 1, true);
                    _HandleBlur({target: {id: "longe_od_dnp", value: r.longe_od_dnp, addction: 'none'}});
                }
            }
        }


        if(r.longe_oe_adc !== "") {
            let v1 = parseFloat(r?.longe_oe_adc?.toString()?.replace(",","."));
            if(v1<0.75 && v1>3.5) {
                window.swal({html: true, title:'Alerta', text: "Adição deve ter valor entre 0,75 e 3,5", type: "error"});
                return;
            }

            if(r.longe_oe_esf !== "" /*&& r.perto_oe_esf === ""*/) {
            //if((id.indexOf("longe") > -1 && id.indexOf("esf") > -1) || id.indexOf("_adc") > -1) {
                let esf = r.longe_oe_esf !== "" ? parseFloat(r?.longe_oe_esf?.toString()?.replace(",",".")) : '';
                if(esf !== '') {
                    r.perto_oe_esf = Number((esf+v1)*1, undefined, 2, true);
                    _HandleBlur({target: {id: "perto_oe_esf", value: r.perto_oe_esf, addction: 'none'}});
                } 
            }


            if(r.longe_oe_cil !== "" /*&& r.perto_oe_cil === ""*/) {
            ///if((id.indexOf("longe") > -1 && id.indexOf("cil") > -1) || id.indexOf("_adc") > -1) {
                let cil = r.longe_oe_cil !== "" ? parseFloat(r?.longe_oe_cil?.toString()?.replace(",",".")) : '';
                if(cil !== '') {
                   r.perto_oe_cil = r.longe_oe_cil; //Number((cil+v1)*1);
                   //_HandleBlur({target: {id: "perto_oe_cil", value: r.perto_oe_cil, addction: 'none'}});
                }

                r.perto_oe_cil = r.longe_oe_cil;
            }

            if(/*r.longe_oe_esf === "" &&*/ r.perto_oe_esf !== "" ) {
            //if((id.indexOf("perto") > -1 && id.indexOf("esf") > -1) || id.indexOf("_adc") > -1) {
                let esf1 = parseFloat(r?.perto_oe_esf?.toString()?.replace(",","."));
                r.longe_oe_esf = Number((esf1-v1)*1, undefined, 2, true);
                _HandleBlur({target: {id: "longe_oe_esf", value: r.longe_oe_esf, addction: 'none'}});
            }


            if(/*r.longe_oe_cil === "" &&*/ r.perto_oe_cil !== "" ) {
            //if((id.indexOf("perto") > -1 && id.indexOf("cil") > -1) || id.indexOf("_adc") > -1) {
                let cil1 = parseFloat(r?.perto_oe_cil?.toString()?.replace(",","."));
                r.longe_oe_cil = r.longe_oe_cil; //Number((cil1-v1)*1);
                _HandleBlur({target: {id: "longe_oe_cil", value: r.longe_oe_cil, addction: 'none'}});
            }
            

            if(r.longe_oe_eixo !== "") {
                r.perto_oe_eixo = r.longe_oe_eixo;
            }


            if(r.perto_oe_eixo !== "") {
                r.longe_oe_eixo = r.perto_oe_eixo;
            }


            if(r.longe_oe_dnp !== "" /*&& r.perto_oe_dnp === ""*/) {
                if(id.indexOf("longe") > -1 || id.indexOf("_adc") > -1) {
                    let dnp = parseFloat(r?.longe_oe_dnp?.toString()?.replace(",","."));
                    r.perto_oe_dnp = Number((dnp-adicao_dnp)*1, undefined, 1, true);
                    _HandleBlur({target: {id: "perto_oe_dnp", value: r.perto_oe_dnp, addction: 'none'}});
                }
            }
            

            if(r.perto_oe_dnp !== "" /*&& r.longe_oe_dnp === ""*/) {
                if(id.indexOf("perto") > -1 || id.indexOf("_adc") > -1) {
                    let dnp = parseFloat(r?.perto_oe_dnp?.toString()?.replace(",","."));
                    r.longe_oe_dnp = Number((dnp+adicao_dnp)*1, undefined, 1, true);
                    _HandleBlur({target: {id: "longe_oe_dnp ", value: r.longe_oe_dnp, addction: 'none'}});
                }
            }
        }

        setReceita(prev => prev = r);
        Hub.Update("current_atend", "receita", r, true);
    }


    const Validate = () => {
        let r = {...receita};
        let result = true;
        let msg = "";

        if(r.longe_od_esf !== "" && r.longe_od_dnp === "") {
            msg += "DNP do olho direito ausente para longe! <br />";
        }


        if(r.perto_od_esf !== "" && r.perto_od_dnp === "") {
            msg += "DNP do olho direito ausente para perto! <br />";
        }


        if(r.longe_oe_esf !== "" && r.longe_oe_dnp === "") {
            msg += "DNP do olho esquerdo ausente para longe! <br />";
        }


        if(r.perto_oe_esf !== "" && r.perto_oe_dnp === "") {
            msg += "DNP do olho esquerdo ausente para perto! <br />";
        }


        if(r.longe_od_cil !== "" && r.longe_od_eixo === "") {
            msg += "Eixo do olho direito ausente para perto! <br />";
        }

        if(r.longe_oe_cil !== "" && r.longe_oe_eixo === "") {
            msg += "Eixo do olho esquerdo ausente para perto! <br />";
        }


        if(r.longe_od_esf === "" && r.longe_oe_esf === "" && r.perto_od_esf === "" && r.perto_oe_esf === "") {
            msg += "Preencha os campos corretamente! <br />";
        }




        if(msg !== "") {
            result = false;
            window.swal({html: true, title:'Alerta', text: msg, type: "error"});
        }

        receita_block = receita_block || false;
        return result && !receita_block;
    }



    const IsPertoEmpty = () => {
        let r = true;
        if(receita.perto_od_esf !== "" && receita.perto_od_cil !== "" && receita.perto_od_eixo !== "" && receita.perto_od_dnp !== "") r = false;
        if(receita.perto_oe_esf !== "" && receita.perto_oe_cil !== "" && receita.perto_oe_eixo !== "" && receita.perto_oe_dnp !== "") r = false;
        return r;
    }


    const IsLongeEmpty = () => {
        let r = true;
        if(receita.longe_od_esf !== "" && receita.longe_od_cil !== "" && receita.longe_od_eixo !== "" && receita.longe_od_dnp !== "") r = false;
        if(receita.longe_oe_esf !== "" && receita.longe_oe_cil !== "" && receita.longe_oe_eixo !== "" && receita.longe_oe_dnp !== "") r = false;
        return r;
    }
    //#endregions Functions



    return(
        <div className="Receita">
            <div className={'ibox-content ibox-heading separatorbar '} style={{ padding: '10px', fontSize: '16px' }}>
                <div className="separatorbar_title"><i className="mdi mdi-open-in-new"></i> Receita </div>
                <div className="separatorbar_content">
                    <select className="form-control" name="receita_id" id="receita_id" value={receita.receita_id} onChange={_HandleChange}>
                        <option value="">Selecione receitas recentes</option>
                        {
                            Array.isArray(receitas) && receitas?.map((item, i) => (
                                <option key={'receita_'+i} value={item.id}>{item.name}</option>
                            ))
                        }
                    </select>
                </div>

                <div className="separatorbar_actions">
                    <span className="bg-primary badge" onClick={ClearReceita}>Limpar receita</span>
                </div>

                <div className="separatorbar_desc">
                    Verifique se o seu cliente já tem a receita do médico <b>{medico}</b> cadastrada no sistema. Se não encontrar inclua a receita.
                </div>

                <div className="clear">

                </div>
            </div>

            <br />

            <div className="row">
                <div className="col-xl-9">
                    <div className="alert-olhos">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>ESF</th>
                                    <th>CIL</th>
                                    <th>EIXO</th>
                                    <th>DNP</th>
                                    <th>ADC</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td rowSpan="2" className="col-1">
                                        LONGE
                                    </td>
                                    <td className="col-2">OD</td>
                                    <td><input type="text" className="form-input" id="longe_od_esf" value={receita.longe_od_esf} onChange={_HandleChange} onDoubleClick={ _HandleReset} onBlur={_HandleBlur} tabIndex={0} autoComplete='false'/></td>
                                    <td><input type="text" className="form-input" id="longe_od_cil" value={receita.longe_od_cil} onChange={_HandleChange} onDoubleClick={ _HandleReset} onBlur={_HandleBlur} tabIndex={1} autoComplete='false'/></td>
                                    <td><input type="text" className="form-input" id="longe_od_eixo" value={receita.longe_od_eixo} onChange={(event) =>_HandleChange(event, undefined, 0)} onDoubleClick={ _HandleReset} onBlur={_HandleBlur} tabIndex={2} autoComplete='false'/></td>
                                    <td><input type="text" className="form-input" id="longe_od_dnp" value={receita.longe_od_dnp} onChange={(event) =>_HandleChange(event, undefined, 1)} onDoubleClick={ _HandleReset} onBlur={_HandleBlur} tabIndex={3} autoComplete='false'/></td>
                                    <td><input type="text" className="form-input" id="longe_od_adc" value={receita.longe_od_adc} onChange={_HandleChange} onDoubleClick={ _HandleReset} onBlur={_HandleBlur} tabIndex={4} autoComplete='false'/></td>
                                </tr>

                                <tr>
                                    <td className="col-2">OE</td>
                                    <td><input type="text" className="form-input" id="longe_oe_esf" value={receita.longe_oe_esf} onChange={_HandleChange} onDoubleClick={ _HandleReset} onBlur={_HandleBlur} tabIndex={5} autoComplete='false'/></td>
                                    <td><input type="text" className="form-input" id="longe_oe_cil" value={receita.longe_oe_cil} onChange={_HandleChange} onDoubleClick={ _HandleReset} onBlur={_HandleBlur} tabIndex={6} autoComplete='false'/></td>
                                    <td><input type="text" className="form-input" id="longe_oe_eixo" value={receita.longe_oe_eixo} onChange={(event) =>_HandleChange(event, undefined, 0)} onDoubleClick={ _HandleReset} onBlur={_HandleBlur} tabIndex={6} autoComplete='false'/></td>
                                    <td><input type="text" className="form-input" id="longe_oe_dnp" value={receita.longe_oe_dnp} onChange={(event) =>_HandleChange(event, undefined, 1)} onDoubleClick={ _HandleReset} onBlur={_HandleBlur} tabIndex={8} autoComplete='false'/></td>
                                    <td><input type="text" className="form-input" id="longe_oe_adc" value={receita.longe_oe_adc} onChange={_HandleChange} onDoubleClick={ _HandleReset} onBlur={_HandleBlur} tabIndex={9} autoComplete='false'/></td>
                                </tr>

                                <tr>
                                    <td rowSpan="2" className="col-1">
                                        PERTO
                                    </td>
                                    <td className="col-2">OD</td>
                                    <td><input type="text" className="form-input" id="perto_od_esf" value={receita.perto_od_esf} onChange={_HandleChange} onDoubleClick={ _HandleReset} onBlur={_HandleBlur} tabIndex={10} autoComplete='false'/></td>
                                    <td><input type="text" className="form-input" id="perto_od_cil" value={receita.perto_od_cil} onChange={_HandleChange} onDoubleClick={ _HandleReset} onBlur={_HandleBlur} tabIndex={11} autoComplete='false'/></td>
                                    <td><input type="text" className="form-input" id="perto_od_eixo" value={receita.perto_od_eixo} onChange={(event) =>_HandleChange(event, undefined, 0)} onDoubleClick={ _HandleReset} onBlur={_HandleBlur} tabIndex={12} autoComplete='false'/></td>
                                    <td><input type="text" className="form-input" id="perto_od_dnp" value={receita.perto_od_dnp} onChange={(event) =>_HandleChange(event, undefined, 1)} onDoubleClick={ _HandleReset} onBlur={_HandleBlur} tabIndex={13} autoComplete='false'/></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td className="col-2">OE</td>
                                    <td><input type="text" className="form-input" id="perto_oe_esf" value={receita.perto_oe_esf} onChange={_HandleChange} onDoubleClick={ _HandleReset} onBlur={_HandleBlur} tabIndex={14} autoComplete='false'/></td>
                                    <td><input type="text" className="form-input" id="perto_oe_cil" value={receita.perto_oe_cil} onChange={_HandleChange} onDoubleClick={ _HandleReset} onBlur={_HandleBlur} tabIndex={15} autoComplete='false'/></td>
                                    <td><input type="text" className="form-input" id="perto_oe_eixo" value={receita.perto_oe_eixo} onChange={(event) =>_HandleChange(event, undefined, 0)} onDoubleClick={ _HandleReset} onBlur={_HandleBlur} tabIndex={16} autoComplete='false'/></td>
                                    <td><input type="text" className="form-input" id="perto_oe_dnp" value={receita.perto_oe_dnp} onChange={(event) =>_HandleChange(event, undefined, 1)} onDoubleClick={ _HandleReset} onBlur={_HandleBlur} tabIndex={17} autoComplete='false'/></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <h5>Observação</h5>
                        <textarea id="obs" className="form-control" style={{height:'130px'}} value={receita.obs} onChange={_HandleChange} tabIndex={18}>{receita.obs}</textarea>
                     </div>
                </div>


                <div className="col-xl-3">
                    <div className="alert alert-success alert-dismissible fade show px-3 mb-0" role="alert">
                        <button type="button" className="btn-close hide" data-bs-dismiss="alert" aria-label="Close"></button>
                        

                        <div>
                            <h5 className="text-success">Tipo de Lente</h5>
                            <p>Selecione a opção que será utilizada na escolha das lentes desse atendimento</p>
                          

                            <div className="card border shadow-none mb-2">
                                <a className="text-body">
                                    <div className="p-2">
                                        <div className="_d-flex align-items-center">
                                            <div className="overflow-hidden me-auto">
                                                <select id="distancia_focal" className="form-control" value={receita.distancia_focal} onChange={_HandleChange} tabIndex={19}>
                                                    {
                                                        distancia_focal_data?.map((item, i) => (
                                                            <option key={'dfocal_'+i} value={item.id}>{item.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="card border shadow-none mb-2">
                                <a className="text-body">
                                    <div className="p-2">
                                        <div className="_d-flex align-items-center">
                                            <div className="overflow-hidden me-auto">
                                                <select id="focal" className="form-control" value={receita.focal} onChange={_HandleChange} disabled={receita.distancia_focal!==3} tabIndex={20}>
                                                    {
                                                        receita.distancia_focal===3 && focal_data?.map((item, i) => (
                                                            <option key={'focal_'+i} value={item.id}>{item.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div> 
                        </div>
                    </div>

                    <br />                            

                    {/*<div className={'alert alert-info alert-dismissible fade show px-3 mb-0 ' + (receita.distancia_focal===3 && receita.focal===1 ? '' : 'hide')} role="alert">
                        <button type="button" className="btn-close hide" data-bs-dismiss="alert" aria-label="Close"></button>
                        

                        <div>
                            <p>Experimente a armação no cliente e informe a altura da(s) pupila(s) na armação</p>

                            <div className="card border shadow-none mb-2">
                                <a className="text-body">
                                    <div className="p-2">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar align-self-center me-3">
                                                <div className="avatar-title rounded bg-soft-info text-info font-size-18">
                                                    OD
                                                </div>
                                            </div>

                                            <div className="overflow-hidden me-auto">
                                                <input type="text" className="form-control" id="altura_od" value={receita.altura_od} onChange={(event) =>_HandleChange(event, undefined, 0)} />
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>   

                            <div className="card border shadow-none mb-2 ">
                                <a className="text-body">
                                    <div className="p-2">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar align-self-center me-3">
                                                <div className="avatar-title rounded bg-soft-info text-info font-size-18">
                                                    OE
                                                </div>
                                            </div>

                                            <div className="overflow-hidden me-auto">
                                                <input type="text" className="form-control" id="altura_oe" value={receita.altura_oe} onChange={(event) =>_HandleChange(event, undefined, 0)} />
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div> 
                        </div>
                    </div>*/}
                </div>


                <div style={{clear:'both'}}></div>
                <center>
                    <hr />
                    <button id="btn_sv" className={'btn btn-primary btn-lg btn-rounded mb-2 ' + (formChanged?'': 'hide')} type="button" onClick={_HandleSave}>Salvar receita</button>
                    <button id="btn_next" className={'btn btn-info btn-lg btn-rounded mb-2 '+ (receitaId!==''&&receita.distancia_focal!==0&&receita.distancia_focal!==''&&!formChanged?'': 'hide')} type="button" onClick={_HandleNext}>Avançar para escolha de armações</button>
                </center>
                <br />
            </div>
        </div> 
    )
});


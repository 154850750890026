/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useImperativeHandle, useEffect, useRef, forwardRef} from 'react';
import { Context } from '../../../../Context/AuthContext';
import { HubContext } from '../../../../Context/HubContext.js';
import { Request, Req } from '../../../../scripts/Request';
import { WaitObject } from '../../../../scripts/Wait';
import { Number, UnCurrency } from '../../../../scripts/StringUtils';
import { FormGenerator } from '../../../Form/FormGenerator';
import { Button } from "react-bootstrap";
import ReactGA from 'react-ga';
import './index.css';


import { MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';


let current_element;
let timer_marca;
let escolha_source = {escolha_1: false, escolha_2: false, escolha_3: false};
let selecao_source = {infantil:"", jovem:"", adulto:"", jovpres:"", presb:"", superior:"", intermediario:"", medio:"", inferior:"", masculino:"", feminino:"", unissex:"", fechada:"", nylon:"",parafusada:"", solar:"", formato: [], metal:"", acetato:"" };
let armacao_source = {marca_id: "", armacao_id:"", tamanho_sugerido: "", tamanho_alert: "", escolha: "", selecao: {...selecao_source}, form:"", altura_od: "", altura_oe: ""};


export const Armacoes = forwardRef((props, ref) => {
    const component_name = "admin/armacoes_cadastro";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [marcas, setMarcas] = useState([]);
    const [armacoes, setArmacoes] = useState([]);
    const [escolha, setEscolha] = useState({...escolha_source});
    const [selecao, setSelecao] = useState({...selecao_source});
    const [armacao, setArmacao] = useState({...armacao_source});
    const [tolerancia, setTolerancia] = useState(0);
    const [isMultifocal, setIsMultifocal] = useState(false);
    const [step, setStep] = useState(0); //0-hideall, 1-armacao_sem_cadastro, 9-btn_next
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState(false);
    const escolhidaRef = useRef();

    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const _formRef = useRef();

   

    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(current_element? current_element :".main-content")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects


    //#region Ref
    useImperativeHandle(ref, (url, args, token) => ({
        async Init() { return Init() },
        async Clear() { return ClearComponent() }
    }));
    //#endregion Ref


    //#region Init
    const Init = async() => {
        await ClearComponent();

        let current_atend = await Hub.Get("current_atend", true);
        if(!current_atend) return;

        //console.log(current_atend.cliente);
        if(current_atend && current_atend.cliente_id && current_atend.medico_id) {

        } else {
            if(current_atend.step >= 5) Hub.Exec("CLEAR_SESSION", component_name + "1");
            return;
        }


        if(!current_atend?.armacao) {
            current_atend = {...current_atend, armacao: {...armacao_source } }
        }
        

        if(!current_atend.armacao.selecao) {
            current_atend.armacao = { ...armacao_source, selecao: {...selecao_source}};
        }
        
        if(current_atend.receita) {
            setIsMultifocal(current_atend.receita.distancia_focal === 3 && current_atend.receita.focal === 1);
        }

   

        if(current_atend?.cliente?.formato) {
            switch(parseInt(current_atend?.cliente?.formato)){
                case 1:
                    current_atend.armacao.selecao.formato = [1,5,7];
                    break;
                case 2:
                    current_atend.armacao.selecao.formato = [1,2,3,5,6,7,8];
                    break;
                case 3:
                    current_atend.armacao.selecao.formato = [1,2,4,6,7,8];
                    break;
                case 4:
                    current_atend.armacao.selecao.formato = [1,2,3,4,6];
                    break;
                case 5:
                    current_atend.armacao.selecao.formato = [1,2,3,4,6,8];
                    break;
                case 6:
                    current_atend.armacao.selecao.formato = [1,2,4,6];
                    break;
            }
        }



        if(current_atend.cliente.categoria) {
            switch(parseInt(current_atend.cliente.categoria)){
                case 1:
                    current_atend.armacao.selecao.infantil = 1;
                    break;
                case 2:
                    current_atend.armacao.selecao.infantil = 1;
                    current_atend.armacao.selecao.jovem = 1;
                    break;
                case 3:
                    current_atend.armacao.selecao.jovem = 1;
                    break;
                case 4:
                    current_atend.armacao.selecao.adulto = 1;
                    break;
                case 5:
                    current_atend.armacao.selecao.jovpres = 1;
                    break;
                case 6:
                    current_atend.armacao.selecao.presb = 1;
                    break;
            }
        }



        if(current_atend.cliente.nasal) {
            switch(parseInt(current_atend.cliente.nasal)){
                case 1:
                    current_atend.armacao.selecao.superior = 1;
                    break;
                case 2:
                    current_atend.armacao.selecao.intermediario = 1;
                    break;
                case 3:
                    current_atend.armacao.selecao.medio = 1;
                    break;
                case 4:
                    current_atend.armacao.selecao.inferior = 1;
                    break;
            }
        }



        if(current_atend.cliente.genero) {
            switch(parseInt(current_atend.cliente.genero)){
                case 1:
                    current_atend.armacao.selecao.masculino = 1;
                    break;
                case 2:
                    current_atend.armacao.selecao.feminino = 1;
                    break;
                case 3:
                    current_atend.armacao.selecao.unissex = 1;
                    break;
            }
        }

        //console.log(current_atend.armacao.selecao);

        
        let {tamanho_sugerido, alerta} = await TamanhoSugerido();
        LoadTolerancia();
        LoadMarcas(current_atend.armacao.selecao, current_atend.armacao.escolha);

        
        //console.log(current_atend.receita);

        
        current_atend.armacao = { ...(current_atend.armacao?current_atend.armacao:armacao), tamanho_sugerido: tamanho_sugerido, tamanho_alert: alerta};
        setArmacao(current_atend.armacao);
        
        
        if(current_atend.armacao.escolha) {
            let _escolha = {...escolha, ["escolha_" + current_atend.armacao.escolha]: true};
            let _armacao = {...current_atend.armacao, armacao_id: current_atend.armacao.armacao_id};

        
            if(/*_escolha["escolha_1"] &&*/ current_atend.armacao.armacao_id && _armacao.armacao_id !== "") {
                if(_armacao.form && _armacao.form !== "") {
                    await _formRef.current.Load(_armacao.form);
                } else {
                    LoadArmacao(_armacao.armacao_id);
                }                   
                _formRef.current.DisableFields(undefined, false);
                setStep(1);
            }
            setEscolha(_escolha);
        }
        

        if(current_atend.armacao.marca_id) {
            LoadArmacoes(current_atend.armacao.marca_id);
        }

        if(current_atend.armacao.selecao) {
            setSelecao(current_atend.armacao.selecao);
        }
        
        
        Hub.Update("current_atend", "armacao", current_atend.armacao, true);
       
    }


    const ClearComponent = async() => {
        setArmacoes([]);
        setMarcas([]);
        setEscolha({...escolha_source});
        setSelecao({...selecao_source});
        setArmacao({...armacao_source})
        setStep(0);
        return;
    }
    //#endregion Init


    //#region Loaders
    const LoadMarcas = async(selecao, escolha) => {
        current_element = "#marca_id";
        setPageLoading(true);

        let current_atend = await Hub.Get("current_atend", true);

        let _armacao = {...armacao};
        //console.log(_armacao.escolha, escolha, selecao);
        let filter = Object.assign(((_armacao.escolha === 3 || escolha === 3) && selecao?selecao:(_armacao.selecao&&(_armacao.escolha !== 1 && escolha !== 1)?_armacao.selecao:{})), { formato1: current_atend.formato, nasal: current_atend.nasal, escolha: _armacao.escolha});
        //console.log(filter);

        let data = await Req("api/AtendimentoArmacoes/Marcas", "POST", filter, user.token);
        current_element = "";
        setPageLoading(false);
        if(data.toString() !== "error") {
            setMarcas(data);
        } else {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATARM001)", "error");
        }
    }


    const LoadArmacoes = async(id) => {
        if(!id) return;
        
        current_element = "#armacao_id";
        setPageLoading(true);

        let current_atend = await Hub.Get("current_atend", true);

        let _armacao = {...armacao};
        let filter = Object.assign((_armacao.escolha === 3 && _armacao.selecao?_armacao.selecao:{}), { formato1: current_atend.formato, nasal: current_atend.nasal, escolha: _armacao.escolha });

        let data = await Req("api/AtendimentoArmacoes/Armacoes?mid="+id, "POST", filter, user.token);
        current_element = "";
        setPageLoading(false);
        if(data.toString() !== "error") {
            setArmacoes(data);
        } else {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATARM002)", "error");
        }

    }


    const LoadArmacao = async(id) => {
        current_element = "#armacao_cadastro";
        setPageLoading(true);

        WaitObject(_formRef.current.IsLoaded()).then(async() => {
            if(id) {
                let _data = await Req("api/AtendimentoArmacoes/Armacao?id="+id, "GET", "", user.token);
                current_element = "";
                setPageLoading(false);
                if(_data !== undefined && _data.armacao_id !== null) {
                    await _formRef.current.Load(_data);

                    let current_atend = await Hub.Get("current_atend", true);
                    current_atend.armacao  = { ...current_atend.armacao, armacao_id: id, form: _data};
                    setArmacao(current_atend.armacao);
                    Hub.Update("current_atend", "armacao", current_atend.armacao, true);
                }
            }
        });
        
    }


    const LoadTolerancia = async() => {
        let current_atend = await Hub.Get("current_atend", true);

        if(current_atend.receita?.distancia_focal && current_atend.receita?.focal) {
            let tipo = (current_atend.receita.distancia_focal === 1 || current_atend.receita.distancia_focal === 2) ? 1 : 3;

            let filter = { d_tipo: tipo, d_esf: current_atend.receita.longe_od_esf||current_atend.receita.perto_od_esf, d_cil:current_atend.receita.longe_od_cil||current_atend.receita.perto_od_cil, e_tipo: tipo, e_esf:current_atend.receita.longe_oe_esf||current_atend.receita.perto_oe_esf, e_cil:current_atend.receita.longe_oe_cil||current_atend.receita.perto_oe_cil};
            let data = await Req("api/AtendimentoArmacoes/Tolerancias", "POST", filter, user.token);
            if(data.toString() !== "error") {
                let tld = "";
                let eixo = parseInt(current_atend.receita.longe_od_eixo||current_atend.receita.perto_od_eixo);
                let tle = "";
                let eixo1 = parseInt(current_atend.receita.longe_oe_eixo||current_atend.receita.perto_oe_eixo);
                let result = "";


                if(data.direito && data.direito.length > 0) {
                    if(eixo && eixo !== "") {
                        if(Math.abs(eixo) >= 70 && Math.abs(eixo) <= 110) {
                            tld = data.direito[0].tolerancia1;
                        } else {
                            tld = data.direito[0].tolerancia2;
                        }
                    } else {
                        tld = data.direito[0].tolerancia2;
                    }
                }  
                
                if(data.esquerdo && data.esquerdo.length > 0) {
                    if(eixo1 && eixo1 !== "") {
                        if(Math.abs(eixo1) >= 70 && Math.abs(eixo1) <= 110) {
                            tle = data.esquerdo[0].tolerancia1;
                        } else {
                            tle = data.esquerdo[0].tolerancia2;
                        }
                    } else {
                        tle = data.esquerdo[0].tolerancia2;
                    }
                }

    
                if(data.direito[0]?.forca >= data.esquerdo[0]?.forca) {
                    if(tld && tld !== "" && tld !== 0) {
                        result = tld;
                    }
                } else if(data.direito[0]?.forca < data.esquerdo[0]?.forca) {
                    if(tle && tle !== "" && tle !== 0) {
                        result = tle
                    }
                }

                //console.log(data.direito[0].forca, data.esquerdo[0].forca);
                //console.log("tld " + tld + " tle " + tle + " result " + result);

                setTolerancia(result);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATARM003)", "error");
            }
        }
    }


    //#endregion Loaders


    //#region Form Handlers
    const onFormGenerator = async() => {
        console.log("onFormGenerator");
        let current_atend = await Hub.Get("current_atend", true);
        let _form = _formRef.current.GetData();
        //_form.venda = UnCurrency(_form.venda);
        current_atend.armacao  = { ...current_atend.armacao, form: _form};
        console.log(current_atend.armacao);
        setArmacao(current_atend.armacao);
        await Hub.Update("current_atend", "armacao", current_atend.armacao, true);
        props.OnChange();
    }




    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleNext = async() => {
        //await Hub.DeleteKey("current_atend", "lentes", true);
        props.OnNext();
    }


    const _HandleChange = async(event) => {
        let current_atend = Hub.Get("current_atend", true);
        current_atend.armacao = { ...armacao, [event.target.id]: event.target.value};
        
        if(event.target.id === "marca_id") {
            current_atend.armacao = { ...current_atend.armacao, armacao_id: ""};
            LoadArmacoes(event.target.value);
        }

        setArmacao(current_atend.armacao);

        if(event.target.id === "armacao_id" && event.target.value !== "") {
            LoadArmacao(event.target.value);
            _formRef.current.DisableFields(undefined, false);
            setStep(1);
        } else {
            setStep(0);
        }

        Hub.Update("current_atend", "armacao", current_atend.armacao, true);
    }


    const _HandleAltChange = async(event, maxlength, decimals) => {
        /*if(event.target.id.indexOf("_cil") > -1) {
            if(event.target.value.indexOf("-") !== 0) {
                event.target.value = "-" + event.target.value;
            }
        }*/

        let val = Number(event.target.value, maxlength, decimals);
        let _armacao = {...armacao, [event.target.id]: val};
        setArmacao(_armacao);

        let current_atend = await Hub.Get("current_atend", true);
        current_atend.armacao = _armacao;
       
        await Hub.Update("current_atend", "armacao", current_atend.armacao, true);
        props.OnChange();
    }


    const EscolhaClick = async(event) => {
        let opt = parseInt(event.target.id.split("_")[1]);
        let _escolha = {...escolha, [event.target.id]: event.target.checked};
        
        let _chk = 0;
        [1,2,3].map(item => {
            _escolha["escolha_" + item] = (item !== opt ? false : event.target.checked);
            if(_escolha["escolha_" + item]) _chk = 1;
        });


        if(_escolha.escolha_2) {
            _handleOpenModal("Guia de Medições");
        }

      
        setEscolha(_escolha);
        _formRef.current.Clear();

        let current_atend = await Hub.Get("current_atend", true);
        /*if(!event.target.checked)*/ current_atend.armacao.armacao_id = "";
        current_atend.armacao.marca_id = "";

        let _step = 0;
        if((opt === 1 || opt === 3) && current_atend.armacao) {
           if(current_atend.armacao.armacao_id && current_atend.armacao.armacao_id !== "") {
                _step = 1;
                _formRef.current.DisableFields(undefined, false);
                if(event.target.checked) {
                    LoadArmacao(current_atend.armacao.armacao_id);
                } else {
                    _step = 0;
                }
           } else {
            //console.log(selecao_source);
            LoadMarcas(selecao_source, opt);
           }
        } else if(opt === 2) {
            await _formRef.current.DisableFields(undefined, true);
            await _formRef.current.DisableFields(["venda"], false);
        }

     
        if(opt === 3) { 
            current_atend.armacao = { ...armacao, escolha: event.target.checked?opt:'', form: "", armacao_id: current_atend.armacao.armacao_id, marca_id: current_atend.armacao.marca_id};
            AutoSelect(current_atend.armacao);
        } else {
            current_atend.armacao = { ...armacao, escolha: event.target.checked?opt:'', form: "", armacao_id: current_atend.armacao.armacao_id, marca_id: current_atend.armacao.marca_id};
            setArmacao(current_atend.armacao);
            Hub.Update("current_atend", "armacao", current_atend.armacao, true);
        }

        setStep(_step);

        props.OnChange();
    }


    const _HandleSemCadastro = () => {
        setStep(prev => prev = (prev === 1 ? 0 : 1));


        WaitObject(_formRef.current).then(async() => {
            _formRef.current.Clear();
            _formRef.current.DisableFields(undefined, true);
           
            let current_atend = await Hub.Get("current_atend", true);

            if(current_atend.armacao) {
                current_atend.armacao = { ...armacao, marca_id: "", armacao_id: "", form: ""};
                setArmacoes([]);
                setArmacao(current_atend.armacao);
                _handleOpenModal("Guia de Medições");
                Hub.Update("current_atend", "armacao", current_atend.armacao, true);
            } 
        }, 100);
    }


    const _HandleImageClick = async(index) => {
        let _s = {...selecao};
        let _formato = _s.formato;


        let _index = _formato.findIndex(f => f === index);
        if(_index > -1) {
            _formato.splice(_index, 1);
        } else {
            _formato.push(index);
        }

        let _selecao =  {...selecao, formato: _formato};
        //console.log(_selecao);
        setSelecao(_selecao);

        //console.log(_selecao);

        let current_atend = await Hub.Get("current_atend", true);

        if(current_atend.armacao) {
            current_atend.armacao = { ...armacao, selecao: _selecao };
        } 

        setArmacao(current_atend.armacao);
        Hub.Update("current_atend", "armacao", current_atend.armacao, true);
        TimerMarca(_selecao);
    }


    const _HandleSelect = async(event) => {
        let _selecao =  {...selecao, [event.target.id]: event.target.checked ? 1 : 0};
        setSelecao(_selecao);
        //console.log(_selecao);

        let current_atend = await Hub.Get("current_atend", true);

        if(current_atend.armacao) {
            current_atend.armacao = { ...armacao, selecao: _selecao };
           
        } 

        setArmacao(current_atend.armacao);
        //Hub.Update("current_atend", "armacao", current_atend.armacao, true);
        TimerMarca(_selecao);
        props.OnChange();
    }


    
    const TimerMarca = async(selecao) => {
        clearTimeout(timer_marca)

        timer_marca = setTimeout(async() => {
            let _armacao = { ...armacao};
            if(_armacao.escolha === 3) {
                let current_atend = await Hub.Get("current_atend", true);
    
                if(current_atend.armacao) {
                    current_atend.armacao = { ...armacao, selecao: selecao, marca_id: "", armacao_id: ""};
                    setArmacoes([]);
                    setArmacao(current_atend.armacao);

                    //console.log(current_atend.armacao);
                    LoadMarcas(selecao);
                    Hub.Update("current_atend", "armacao", current_atend.armacao, true);
                } 
            }
        }, 700);
    }


    const _HandleContinuar = async() => {
        let current_atend = await Hub.Get("current_atend", true);

        if(current_atend.armacao) {
            current_atend.armacao = { ...armacao, marca_id: "", armacao_id: ""};
            setArmacoes([]);
            setArmacao(current_atend.armacao);
            Hub.Update("current_atend", "armacao", current_atend.armacao, true);
        } 
    }
    //#endregion Handlers


    //#region AddCombo Handlers
    const _handleOpenModal = (title) => {
        setModalTitle(title);
        setShowModal(true);
    }


    const _handleCloseModal = () => {
        setShowModal(false);
    }
    //#endregion AddCombo Handlers



    //#region Functions
    const AutoSelect = async(armacao) => {
        let current_atend = await Hub.Get("current_atend", true);
        current_atend.armacao = armacao;

        if(current_atend.formato) {
            switch(parseInt(current_atend.formato)){
                case 1:
                    current_atend.armacao.selecao.formato = [1,5,7];
                    break;
                case 2:
                    current_atend.armacao.selecao.formato = [1,2,3,5,6,7,8];
                    break;
                case 3:
                    current_atend.armacao.selecao.formato = [1,2,4,6,7,8];
                    break;
                case 4:
                    current_atend.armacao.selecao.formato = [1,2,3,4,6];
                    break;
                case 5:
                    current_atend.armacao.selecao.formato = [1,2,3,4,6,8];
                    break;
                case 6:
                    current_atend.armacao.selecao.formato = [1,2,4,6];
                    break;
            }
        }


        if(current_atend.cliente.categoria) {
            switch(parseInt(current_atend.cliente.categoria)){
                case 1:
                    current_atend.armacao.selecao.infantil = 1;
                    break;
                case 2:
                    current_atend.armacao.selecao.infantil = 1;
                    current_atend.armacao.selecao.jovem = 1;
                    break;
                case 3:
                    current_atend.armacao.selecao.jovem = 1;
                    break;
                case 4:
                    current_atend.armacao.selecao.adulto = 1;
                    break;
                case 5:
                    current_atend.armacao.selecao.jovpres = 1;
                    break;
                case 6:
                    current_atend.armacao.selecao.presb = 1;
                    break;
            }
        }


        if(current_atend.cliente.nasal) {
            switch(parseInt(current_atend.cliente.nasal)){
                case 1:
                    current_atend.armacao.selecao.superior = 1;
                    break;
                case 2:
                    current_atend.armacao.selecao.intermediario= 1;
                    break;
                case 3:
                    current_atend.armacao.selecao.medio = 1;
                    break;
                case 4:
                    current_atend.armacao.selecao.inferior = 1;
                    break;
            }
        }

        LoadMarcas(current_atend.armacao.selecao, current_atend.armacao.escolha);

        setArmacao(current_atend.armacao);
        Hub.Update("current_atend", "armacao", current_atend.armacao, true);
    }


    const TamanhoSugerido = async() => {
        let current_atend = await Hub.Get("current_atend", true);

        //console.log(current_atend?.receita);

        if(!current_atend?.receita) {
            if(current_atend.step >= 5) Hub.Exec("CLEAR_SESSION", component_name + "2");
            return {tamanho_sugerido: 0, tamanho_alert: 0};
        }
        
        let r = current_atend?.receita;

        let sugerido = 0;
        let alerta = 0;

        //console.log(r);
       
        try {
            if(!r?.longe_od_dnp) return {tamanho_sugerido: 0, tamanho_alert: 0};

            //let arr_dnp = [parseFloat(r.longe_od_dnp||Infinity), parseFloat(r.longe_oe_dnp||Infinity), parseFloat(r.perto_od_dnp||Infinity), parseFloat(r.perto_oe_dnp||Infinity)];
            
            let arr_dnp = [parseFloat(r.longe_od_dnp||Infinity), parseFloat(r.longe_oe_dnp||Infinity)];

            if(parseFloat(r.longe_od_dnp||Infinity) === Infinity && parseFloat(r.perto_od_dnp||Infinity) !== Infinity) {
                arr_dnp = [parseFloat(r.perto_od_dnp||Infinity), parseFloat(r.perto_oe_dnp||Infinity)];
            }
            
            
           
            let ddnp = Math.min.apply(null, arr_dnp);
            
            //console.log(ddnp);

            if(ddnp >= 23 && ddnp < 25.5) {
                sugerido = 42;
                alerta = 0; //#FFFFFF
            } else if(ddnp >= 25.5 && ddnp < 27.5) {
                sugerido = 44;
                alerta = 0; //#FFFFFF
            } else if(ddnp >= 27.5 && ddnp < 29.5) {
                sugerido = 46;
                alerta = 1; //#009900
            } else if(ddnp >= 29.5 && ddnp < 31.5) {
                sugerido = 48;
                alerta = 2; //#FF0000
            } else if(ddnp >= 31.5 && ddnp < 33.5) {
                sugerido = 50;
                alerta = 3; //#FFFF00
            } else if(ddnp >= 33.5 && ddnp < 35.5) {
                sugerido = 52;
                alerta = 4; //#000000
            } else if(ddnp >= 35.5 && ddnp < 37.5) {
                sugerido = 54;
                alerta = 0; //#FFFFFF
            } else if(ddnp >= 37.5 && ddnp < 39.5) {
                sugerido = 56;
                alerta = 0; //#FFFFFF
            }
        } catch(e) { console.log(e)}


        //current_atend.armacao = { ...armacao, tamanho_sugerido: sugerido, tamanho_alert: alerta};
        //console.log(current_atend.armacao);
        //setArmacao(current_atend.armacao);
        //Hub.Update("current_atend", "armacao", current_atend.armacao, true);
        return {tamanho_sugerido: sugerido, tamanho_alert: alerta};
    }

    //#endregion Functions


    return (
        <div className="AtendimentoArmacoes">
            <br /> <br />

            <div className={'Escolha'}>
                <div className="grid">
                    <div className={'alert alert-danger alert-dismissible fade show px-3 mb-0 ' + (step === 1 && (parseFloat(armacao.form.aro||0)>(armacao.tamanho_sugerido+(tolerancia||0)) && [1,2,3].indexOf(armacao.escolha) > -1)? '': 'hide' )} role="alert">
                        <div>
                            <h5 className="text-danger">Tamanho sugerido: <b>{armacao.tamanho_sugerido} {tolerancia !== "" && tolerancia !== 0 && (' + ' + tolerancia)}</b></h5>
                            <p>O tamanho da armação é maior do que o sugerido com base nas informações da receita</p>
                        </div>
                    </div>

                    <div className={'alert alert-primary alert-dismissible fade show px-3 mb-0 ' + (step === 0 || (parseFloat(armacao.form.aro||0)<=(armacao.tamanho_sugerido+(tolerancia||0)) || [1,2,3].indexOf(armacao.escolha) === -1) ? '': 'hide' )} role="alert">
                        <div>
                            <h5 className="text-primary">Tamanho sugerido: <b>{armacao.tamanho_sugerido} {tolerancia !== "" && tolerancia !== 0 && (' + ' + tolerancia)}</b></h5>
                            <p>O tamanho sugerido é calculado com base na receita para melhor adaptação ao formato do rosto</p>
                        </div>
                    </div>

                    <div className={'alert alert-white px-3 mb-0 ' + '_hide'} role="alert">
                        <div>
                            <input id="escolha_1" type="checkbox" value="escolha_1" checked={escolha.escolha_1} onChange={EscolhaClick}/>
                        </div>

                        <div>
                            <div className="desc">
                                <span className="text-primary"><b>Armação já escolhida</b></span><br />
                                Encontre a armação escolhida no banco de dados da sua loja
                            </div>
                        </div>
                    </div>

                    <div className={'alert alert-white px-3 mb-0 ' + '_hide'} role="alert">
                        <div>
                            <input id="escolha_2" type="checkbox" value="escolha_2" checked={escolha.escolha_2} onChange={EscolhaClick}/>
                        </div>

                        <div>
                            <div className="desc">
                                <span className="text-primary"><b>Armação do cliente</b></span><br />
                                Entre com os dados da armação trazida pelo cliente
                            </div>
                        </div>
                    </div>



                    <div className={'alert alert-white px-3 mb-0 ' + '_hide'} role="alert">
                        <div>
                            <input id="escolha_3" type="checkbox" value="escolha_3" checked={escolha.escolha_3} onChange={EscolhaClick}/>
                        </div>

                        <div>
                            <div className="desc">
                                <span className="text-primary"><b>Escolher a armação</b></span><br />
                                Encontre armações com filtros determinados
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div className={'Selecao ' + (armacao.escolha===3?'':'hide')}>
                <hr />
                <br />
                <center><h5>Selecione abaixo as indicações que precisar para dar sequência ao atendimento escolhendo a armação</h5></center>
                <br />
                <div className="grid_selecao">
                    <div className="title">Categoria</div>
                    <div className="alert-white">
                        <div><input id="infantil" type="checkbox" value="1" checked={selecao.infantil===1} onChange={(event) => _HandleSelect(event)} /></div>
                        <div><div>Infantil</div></div>
                    </div>
                    <div className="alert-white">
                        <div><input id="jovem" type="checkbox" value="1" checked={selecao.jovem===1} onChange={(event) => _HandleSelect(event)} /></div>
                        <div><div>Jovem</div></div>
                    </div>
                    <div className="alert-white">
                        <div><input id="adulto" type="checkbox" value="1" checked={selecao.adulto===1} onClick={(event) => _HandleSelect(event)} /></div>
                        <div><div>Adulto</div></div>
                    </div>
                    <div className="alert-white">
                        <div><input id="jovpres" type="checkbox" value="1" checked={selecao.jovpres===1} onChange={(event) => _HandleSelect(event)} /></div>
                        <div><div>Jovem Présbita</div></div>
                    </div>
                    <div className="alert-white">
                        <div><input id="presb" type="checkbox" value="1" checked={selecao.presb===1} onChange={(event) => _HandleSelect(event)} /></div>
                        <div><div>Présbita</div></div>
                    </div>


                    <div className="title">Nasal</div>
                    <div className="alert-white">
                        <div><input id="superior" type="checkbox" value="1" checked={selecao.superior===1} onChange={(event) => _HandleSelect(event)} /></div>
                        <div><div>Superior</div></div>
                    </div>
                    <div className="alert-white">
                        <div><input id="intermediario" type="checkbox" value="1" checked={selecao.intermediario===1} onChange={(event) => _HandleSelect(event)} /></div>
                        <div><div>Intermediário</div></div>
                    </div>
                    <div className="alert-white">
                        <div><input id="medio" type="checkbox" value="1" checked={selecao.medio===1} onChange={(event) => _HandleSelect(event)} /></div>
                        <div><div>Médio</div></div>
                    </div>
                    <div className="alert-white">
                        <div><input id="inferior" type="checkbox" value="1" checked={selecao.inferior===1} onChange={(event) => _HandleSelect(event)} /></div>
                        <div><div>Inferior</div></div>
                    </div>
                    <div className="alert-white">
                    </div>


                    <div className="title">Gênero</div>
                    <div className="alert-white">
                        <div><input id="masculino" type="checkbox" value="1" checked={selecao.masculino===1} onChange={(event) => _HandleSelect(event)} /></div>
                        <div><div>Masculino</div></div>
                    </div>
                    <div className="alert-white">
                        <div><input id="feminino" type="checkbox" value="1" checked={selecao.feminino===1} onChange={(event) => _HandleSelect(event)} /></div>
                        <div><div>Feminino</div></div>
                    </div>
                    <div className="alert-white">
                        <div><input id="unissex" type="checkbox" value="1" checked={selecao.unissex===1} onChange={(event) => _HandleSelect(event)} /></div>
                        <div><div>Unissex</div></div>
                    </div>
                    <div className="alert-white">
                    </div>
                    <div className="alert-white">
                    </div>


                    <div className="title">Tipos de Armação</div>
                    <div className="alert-white">
                        <div><input id="fechada" type="checkbox" value="1" checked={selecao.fechada===1} onChange={(event) => _HandleSelect(event)} /></div>
                        <div><div>Fechada</div></div>
                    </div>
                    <div className="alert-white">
                        <div><input id="nylon" type="checkbox" value="1" checked={selecao.nylon===1} onChange={(event) => _HandleSelect(event)} /></div>
                        <div><div>Nylon</div></div>
                    </div>
                    <div className="alert-white">
                        <div><input id="parafusada" type="checkbox" value="1" checked={selecao.parafusada===1} onChange={(event) => _HandleSelect(event)} /></div>
                        <div><div>Parafusada</div></div>
                    </div>
                    <div className="alert-white">
                        <div><input id="solar" type="checkbox" value="1" checked={selecao.solar===1} onChange={(event) => _HandleSelect(event)} /></div>
                        <div><div>Solar</div></div>
                    </div>
                    <div className="alert-white">
                    </div>


                    <div className="title">Material</div>
                    <div className="alert-white">
                        <div><input id="metal" type="checkbox" value="1" checked={selecao.metal===1} onChange={(event) => _HandleSelect(event)} /></div>
                        <div><div>Metal</div></div>
                    </div>
                    <div className="alert-white">
                        <div><input id="acetato" type="checkbox" value="1" checked={selecao.acetato===1} onChange={(event) => _HandleSelect(event)} /></div>
                        <div><div>Acetato</div></div>
                    </div>
                    <div className="alert-white">
                        
                    </div>
                    <div className="alert-white">
                       
                    </div>
                    <div className="alert-white">
                    </div>
                </div>
                <br /><br />

                <div className="modelos">
                    
                    {
                        [1,2,3,4,5,6,7,8].map(item => (
                            <div>
                                <img className={(Array.isArray(selecao.formato) && selecao.formato.findIndex(f => f === item) > -1? 'img-selected':'')} src={"/images/modelos/"+item+".jpg"} alt="" onClick={() => _HandleImageClick(item)}/>
                            </div>
                        ))
                    }
                    {/*<div>
                        <button className="btn btn-dark" style={{width:'120px', height:'89px'}} onClick={_HandleContinuar}>Continuar</button>
                    </div>*/}
                    
                </div>

                <div style={{clear:'both'}}></div>
                <br />
            </div>

            



            <div className={'Filtros ' + (armacao.escolha===1||armacao.escolha===3?'':'hide')}>
                <hr />
                <br />
                <div className="grid_filtros">
                    <div className="card border shadow-none mb-2 btn_actions">
                        <a className="text-body">
                            <div className="p-2">
                                <div className="d-flex align-items-center">
                                    <div className="avatar align-self-center me-3">
                                        <div className="avatar-title rounded bg-soft-success text-success font-size-24">
                                            <i className="uil uil-tag-alt"></i>
                                        </div>
                                    </div>

                                    <div className="overflow-hidden me-auto">
                                        <select id="marca_id" className="form-control" value={armacao.marca_id} onChange={_HandleChange}>
                                            <option value="">Escolha uma marca </option>
                                            {
                                                Array.isArray(marcas) && marcas.map((item, i) => (<option key={'marca_'+i} value={item.id}>{item.name}</option>))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>


                    <div className="card border shadow-none mb-2 btn_actions">
                        <a className="text-body">
                            <div className="p-2">
                                <div className="d-flex align-items-center">
                                    <div className="avatar align-self-center me-3">
                                        <div className="avatar-title rounded bg-soft-success text-success font-size-24">
                                            <i className="mdi mdi-sunglasses md-24"></i>
                                        </div>
                                    </div>

                                    <div className="overflow-hidden me-auto">
                                        <select id="armacao_id" className="form-control" value={armacao.armacao_id} onChange={_HandleChange}>
                                            <option value="">Escolha uma armação</option>
                                            {
                                                Array.isArray(armacoes) && armacoes.map((item, i) => (<option key={'armacoes_'+i} value={item.id}>{item.name}</option>))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>


                    <div className="card border shadow-none mb-2 btn_actions">
                        <a className="text-body">
                            <div className="p-2">
                                <div className="d-flex align-items-center">
                                    <div className="avatar align-self-center me-3">
                                        <div className="avatar-title rounded bg-soft-dark text-dark font-size-24">
                                            <i className="mdi mdi-alert-circle-check md-24"></i>
                                        </div>
                                    </div>

                                    <div className="overflow-hidden me-auto">
                                        <button type="button" className="btn btn-dark form-control" onClick={_HandleSemCadastro}>Armação sem cadastro</button>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>


           

            <div className={'Cadastro ' + ((step===1||armacao.escolha===2)?'':'hide')}>
                <hr />
                <br />
                <div id="armacao_cadastro" className="">
                    <center className={(armacao.armacao_id && armacao.armacao_id!==''?'hide':'')}><h5>Complete abaixo as informações da armação que NÃO esta cadastrada para seguir o atendimento</h5></center>
                    <FormGenerator ref={_formRef} name={'unidade'} url={'/assets/forms/armacao_sem_cadastro.json'} execRef={Exec}></FormGenerator>
                    <div style={{clear:'both'}}></div>
                </div>                            
            </div>





            <div className={'Altura ' + ( isMultifocal && (step===1||armacao.escolha===2)?'':'hide') }>
                <hr />
                <br />
                <div className="grid_filtros">
                    <div className="card border shadow-none mb-2 altura_txt">
                        <a className="text-body" >
                            <div className="p-2">
                            Experimente a armação no cliente e informe a altura da(s) pupila(s) na armação:
                                
                            </div>
                        </a>
                    </div>

                    <div className="card border shadow-none mb-2 btn_actions">
                        <a className="text-body">
                            <div className="p-2">
                                <div className="d-flex align-items-center">
                                    <div className="avatar align-self-center me-3">
                                        <div className="avatar-title rounded bg-soft-info text-info font-size-18">
                                            OD
                                        </div>
                                    </div>

                                    <div className="overflow-hidden me-auto">
                                        <input type="text" className="form-control" id="altura_od" value={armacao.altura_od} onChange={(event) => _HandleAltChange(event, undefined, 0)} />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>


                    <div className="card border shadow-none mb-2 btn_actions">
                        <a className="text-body">
                            <div className="p-2">
                                <div className="d-flex align-items-center">
                                    <div className="avatar align-self-center me-3">
                                        <div className="avatar-title rounded bg-soft-info text-info font-size-18">
                                            OE
                                        </div>
                                    </div>

                                    <div className="overflow-hidden me-auto">
                                        <input type="text" className="form-control" id="altura_oe" value={armacao.altura_oe} onChange={(event) => _HandleAltChange(event, undefined, 0)} />
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
           </div>

                            

            <div className={'Resultado ' + ((((armacao.escolha===1||(armacao.escolha===3)) && ((armacao.armacao_id && armacao.armacao_id!=='') || step===1)) || armacao.escolha===2) && escolha &&armacao.form.marca!=='' && armacao.form.formato!==''?'':'hide')}>
                <hr />
                <br />
                <div className="grid_resultado">
               
                    <center>
                        <button id="btn_next" className={'btn btn-primary btn-lg btn-rounded mb-2 '+ (armacao.form===''||armacao.form.formato===''||armacao.form.material===''||armacao.form.tipo===''?'hide':'')} type="button" onClick={_HandleNext}> Avançar para Lentes </button>
                    </center>
                    <br />
                                       
                </div>
            </div>
          


            <MDBModal show={showModal} setShow={setShowModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle><h4>{modalTitle}</h4></MDBModalTitle>
                        <Button className='btn btn-primary' onClick={_handleCloseModal} style={{width:'25px',height:'25px', padding:0}}>x</Button>
                    </MDBModalHeader>
                    <MDBModalBody>
                            <img src="/assets/images/medicoes.jpg" />
                    </MDBModalBody>

                    <MDBModalFooter>
                            <Button variant="secondary" onClick={_handleCloseModal}>
                            Fechar
                            </Button>
                    </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    )
})
export const FilterObject = (obj, arr, action = 0) => {
    let _obj = {};
    if (action == 1) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                let index = arr.findIndex(v => v == key.toString());
                if (index == -1) {
                    let tmp = {
                        [key]: obj[key],
                    }
                    _obj = Object.assign(tmp, _obj);
                }
            }
        }
    } else if (action == 0) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                let index = arr.findIndex(v => v == key.toString());
                if (index > -1) {
                    let tmp = {
                        [key]: obj[key],
                    }
                    _obj = Object.assign(tmp, _obj);
                }
            }
        }
    }
    return _obj;
}


export const IsEmptyObject = (obj) => {
    for(let prop in obj) {
        if(obj[prop] && obj[prop] !== undefined && obj[prop] !== null && obj[prop] !== '') return false;
    }
    return true;
}

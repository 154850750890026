/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useImperativeHandle, useEffect, useRef, forwardRef} from 'react';
import { Context } from '../../../../Context/AuthContext';
import { HubContext } from '../../../../Context/HubContext.js';
import { Request, Req } from '../../../../scripts/Request';
import { WaitObject } from '../../../../scripts/Wait';
import { Number } from '../../../../scripts/StringUtils';
import { Button } from "react-bootstrap";
import ReactGA from 'react-ga';
import './index.css';

import { MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';


let current_element;


export const Anamnese = forwardRef((props, ref) => {
    const component_name = "admin/anamnese_cadastro";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [pageLoading, setPageLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [anamnese, setAnamnese] = useState({profissionais: [], particulares: [], tratamentos: []});
    const [filtros, setFiltros] = useState([]);
    const [filtrosLazer, setFiltrosLazer] = useState([]);
    const [tratamentos, setTratamentos] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState(false);


    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const treatsPrestineRef = useRef(false);


    


    //#region Effects
     useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


   
    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(current_element? current_element :".main-content")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);


    useEffect(() => {
        let _t1 = [...tratamentos];
        let _f1 = [...filtros];
        let _f2 = [...filtrosLazer];

        if(_t1 && _t1.length > 0 && _f1 && _f1.length > 0 && _f2 && _f2.length > 0 && !treatsPrestineRef.current) {
            treatsPrestineRef.current = true;
            TratamentosRules(_t1, _f1, 0);
        } 
    }, [tratamentos, filtros, filtrosLazer])
    //#endregion Effects


    //#region Ref
    useImperativeHandle(ref, (url, args, token) => ({
        async Init() { return Init() },
        async Clear() { return ClearComponent() }
    }));
    //#endregion Ref


    //#region Init
    const Init = async() => {
        let current_atend = await Hub.Get("current_atend", true);
        //if(!current_atend) return;

        if(current_atend && current_atend.anamnese) {
            setAnamnese(current_atend.anamnese);
            LoadFiltros(current_atend.anamnese);
        }  else {
            Hub.Update("current_atend", "anamnese", {...anamnese}, true);
            LoadFiltros();
        }
        LoadTratamentos();
        
    }


    const ClearComponent = () => {
        setAnamnese({profissionais: [], particulares: [], tratamentos: []});

        let _profissionais = [...filtros];
        let _particulares = [...filtrosLazer];
        let _tratamentos = [...tratamentos];

        _profissionais.map(item => {
            item.selected = 0;
        });

        _particulares.map(item => {
            item.selected = 0;
        });

        _tratamentos.map(item => {
            item.selected = 0;
            item.show = 0;
        });


        //setFiltros([]);
        //setFiltrosLazer([]);
        //setTratamentos([]);


        
        setShowModal(false);
        setModalTitle(false);
    }
    //#endregion Init


    //#region Loaders
    const LoadTratamentos = () => {
        setPageLoading(true);
        Promise.resolve(new Request().Run("api/Anamnese/Tratamentos", "GET", "", user.token, 0*24*60*60*1000)).then(async(data) => {
            setPageLoading(false);

            if(data.toString() !== "error") {
                let current_atend = await Hub.Get("current_atend", true);

                data.map((item, i) => {
                    if(current_atend && current_atend.anamnese && current_atend.anamnese.tratamentos) {
                        //item.show = current_atend.anamnese.tratamentos.findIndex(a => a === item.tipo_tratamento_id) > -1 ? 1 : 0;
                        item.selected = current_atend.anamnese.tratamentos.findIndex(a => a === item.tipo_tratamento_id) > -1 ? 1 : 0;
                    }
                    item.filtros = item.filtros.split(',').reduce( (acc, x ) => acc.concat(+x), [])
                });

                Hub.Set("tratamentos", data, undefined, true);
                setTratamentos(data);
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATANA001)", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATANA002)", "error");
        });
    }


    const LoadFiltros = (arr) => {
        Promise.resolve(new Request().Run("/assets/json/tratamentos_filtros.json", "GET", "", "", 0*24*60*60*1000)).then(async(data) => {
            setPageLoading(false);
            
            if(data.toString() !== "error") {
                //let dataLazer = data.map(i => ({ ...i }));

                let current_atend = await Hub.Get("current_atend", true);

                if(current_atend && current_atend.anamnese && current_atend.anamnese.profissionais) {
                    data.profissionais.map((item, i) => {
                        item.selected = current_atend.anamnese.profissionais.findIndex(a => a.filtro_id === item.filtro_id) > -1 ? 1 : 0;
                    });
                }

                if(current_atend && current_atend.anamnese && current_atend.anamnese.particulares) {
                    data.particulares.map((item, i) => {
                        item.selected = current_atend.anamnese.particulares.findIndex(a => a.filtro_id === item.filtro_id) > -1 ? 1 : 0;
                    });
                }
                setFiltros(data.profissionais);
                setFiltrosLazer(data.particulares);
            } else {
                //console.log(1);
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATANA003)", "error");
            }
        }).catch((e) => {
            //console.log(2, e.message);
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (ATANA004)", "error");
        });
    }
    //#endregion Loaders


    //#region Handlers
    const _HandleFiltroClick = async(event, item, origin) => {
        let _checked = event.target.checked;
        let _anamnese = {...anamnese};
        let _profissionais;
        let _particulares;
        let _filtros;
        //let _filtros1;

        if(origin === 0) {
            _filtros =  [...filtros];
            //_filtros1 =  [...filtrosLazer];

            let _findex = _filtros.findIndex(f => f.filtro_id === item.filtro_id);
            if(_findex > -1) {
                _filtros[_findex].selected = (_checked ? 1 : 0);
            }
            setFiltros(_filtros);

            _profissionais = _anamnese.profissionais;
            let _index = _profissionais.findIndex(p => p.filtro_id === item.filtro_id);

            if(_checked && _index === -1) {
                _profissionais.push({filtro_id: item.filtro_id});  
            } else if(!event.target.checked && _index > -1) {
                _profissionais.splice(_index, 1);
            }

            _anamnese = {...anamnese, profissionais: _profissionais};
        } else  {
            _filtros =  [...filtrosLazer];
            //_filtros1 =  [...filtros];

            let _findex = _filtros.findIndex(f => f.filtro_id === item.filtro_id);
            if(_findex > -1) {
                _filtros[_findex].selected = (_checked ? 1 : 0);
            }
            setFiltrosLazer(_filtros);

            _particulares = _anamnese.particulares;
            let _index = _particulares.findIndex(p => p.filtro_id === item.filtro_id);

            if(_checked && _index === -1) {
                _particulares.push({filtro_id: item.filtro_id});  
            } else if(!event.target.checked && _index > -1) {
                _particulares.splice(_index, 1);
            }

            _anamnese = {...anamnese, particulares: _particulares};
        }
       
        setAnamnese(_anamnese);
        let current_atend = await Hub.Get("current_atend", true);
        Hub.Update("current_atend", "anamnese", _anamnese, true);


        TratamentosRules(undefined, _filtros, origin);
    }


    const _HandleTratamentoClick = async(event, item) => {
        let _tratamentos =  [...tratamentos];
        let _index = _tratamentos.findIndex(f => f.tipo_tratamento_id === item.tipo_tratamento_id);
        if(_index > -1) {
            _tratamentos[_index].selected = event.target.checked ? 1 : 0;
        }
        setTratamentos(_tratamentos);

        let current_atend = await Hub.Get("current_atend", true);
        let _anamnese = {...anamnese};

        let _selected_tratamentos = [];
        _tratamentos.map(item => {
            if(item.selected === 1) _selected_tratamentos.push(item.tipo_tratamento_id);
        });
        _anamnese = {...anamnese, tratamentos: _selected_tratamentos};
        await Hub.Update("current_atend", "anamnese", _anamnese, true);
        props.OnChange();
    }


    const _HandleNext = () => {
        props.OnNext();
    }
    //#endregion Handlers


    //#region AddCombo Handlers
    const _handleOpenModal = (title) => {
        setModalTitle(title);
        setShowModal(true);
    }


    const _handleCloseModal = () => {
        setShowModal(false);
    }
    //#endregion AddCombo Handlers


    //#region Function
    const TratamentosRules = (data, filtres, origin) => {
        let _data = data ? data : [...tratamentos];

        let _f = [...filtrosLazer, ...filtros];
        _f = _f.filter( (f, i) => { return  f.selected === 1 });
        _f = _f.filter( (f, i) => { return  i === _f.findIndex(o => f.filtro_id === o.filtro_id) } );



        _data.map(item => {
            if(item.filtros.length > 0) {
                item.show = false;

                item.filtros.map(filtro => {
                    let index1 = _f.findIndex(f => f.filtro_id === filtro && f.selected === 1);
                    if(index1 > -1) {
                        item.show = true;
                    }
                })

                if(!item.show) item.selected = 0;
            }
        });

        setTratamentos(_data);
    }
    //#endregion Function


    return (
        <div className="Anamnese">
            <br /> <br />
            <div className="row">
                <div className="col-sm-4">
                    <h5>Atividades Profissionais</h5>
                    <div className="table-responsive">
                        <table className="table mb-0">
    
                            <tbody>
                            {
                                    filtros.length > 0 && filtros.map((item, i) => (
                                        <tr key={'prof'+i} style={{width:'40px'}}>
                                            <td style={{ padding:'11px 0 0 3px'}}>
                                                <input id={"prof_i"+i} type="checkbox" style={{width:'22px', height: '22px', padding:'0 0 0 0', margin:'0'}} value={item.filtro_id} checked={(item.selected === 1)} onClick={(event) => _HandleFiltroClick(event, item, 0)} />
                                            </td>
                                            <td>
                                                {item.name}
                                            </td>
                                           
                                        </tr>
                                    ))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="col-sm-4">
                    <h5>Atividades Particulares / Lazer</h5>
                    <div className="table-responsive">
                        <table className="table mb-0">
    
                            <tbody>
                            {
                                    filtrosLazer.length > 0 && filtrosLazer.map((item, i) => (
                                        <tr key={'part'+i} style={{width:'40px'}}>
                                            <td style={{ padding:'11px 0 0 3px'}}>
                                                <input id={"part_i"+i} type="checkbox" style={{width:'22px', height: '22px', padding:'0 0 0 0', margin:'0'}} value={item.filtro_id} checked={(item.selected === 1)} onClick={(event) => _HandleFiltroClick(event, item, 1)} />
                                            </td>
                                            <td>
                                                {item.name}
                                            </td>
                                           
                                        </tr>
                                    ))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="col-sm-4">
                    <h5>Tratamentos / Benefícios</h5>
                    <div className="table-responsive">
                        <table className="table tratamentos mb-0">
                            <tbody>
                            {
                                    Array.isArray(tratamentos) && tratamentos.length > 0 && tratamentos.map((item, i) => (
                                        <tr key={'trat'+i}>
                                            <td style={{ padding:'11px 0 0 3px', width:'40px'}}>
                                                <input id={"trata_i"+i} type="checkbox" className={(item.show?'':'hide')}  style={{width:'22px', height: '22px', padding:'0 0 0 0', margin:'0'}} value={item.tipo_tratamento_id} checked={item.selected} onClick={(event) => _HandleTratamentoClick(event, item)} />
                                            </td>
                                            <td>
                                                {item.titulo}
                                            </td>
                                            <td style={{padding:'5px 5px 0 0'}}>
                                                <span className="tratamento_info bg-primary badge right pointer" onClick={() =>_handleOpenModal(item.titulo)}> ? </span>
                                            </td>
                                           
                                        </tr>
                                    ))
                            }
                            </tbody>
                        </table>
                    </div>

                    <div style={{clear:'both'}}></div>
                        <center>
                            <hr />
                            <button id="btn_next" className={'btn btn-info btn-lg btn-rounded mb-2 '} type="button" onClick={_HandleNext}>Avançar para cadastro de médico</button>
                        </center>
                        <br />
                </div>
            </div>


            <MDBModal show={showModal} setShow={setShowModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle><h4>{modalTitle}</h4></MDBModalTitle>
                        <Button className='btn btn-primary' onClick={_handleCloseModal} style={{width:'25px',height:'25px', padding:0}}>x</Button>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <img src={'/images/tratamentos/' + modalTitle.toString().replace('-','').replace(' ','') +'.jpg'} alt="" />
                    </MDBModalBody>

                    <MDBModalFooter>
                            <Button variant="secondary" onClick={_handleCloseModal}>
                            Fechar
                            </Button>
                    </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    )
});
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../Context/AuthContext';
import { Request } from '../../scripts/Request';
import { HubContext } from '../../Context/HubContext.js';
import { WaitObject } from '../../scripts/Wait';
import { LinkContainer } from 'react-router-bootstrap';
import $ from 'jquery';
import Menu from '../Menu';
import { useNavigate } from 'react-router-dom';

import './index.css'


let TimerIdleInterval;
let TimerIdle = 0;


const niveis = [
    "Master Admin",
    "Admin de Rede",
    "Gerente de Loja",
    "Gerente Local",
    "Vendedor Qualificado",
    "Vendedor"
]


export default function Header() {
    const component_name = "admin/header";
    const { user, handleLogout, Update } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [info, setInfo] = useState({name: ""});
    const [unidade, setUnidade] = useState(0);
    const [unidades, setUnidades] = useState([]);
    const unidadesRef = useRef([]);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const navigate = useNavigate();


    //#region Effects
    useEffect(() => {

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                $("body").attr("data-layout", "horizontal");
                $("body").attr("data-topbar", "dark");
                $("body").removeClass("bg-white");

                /*
                document.getElementById("right-bar-toggle").addEventListener("click", function(e) {
                    document.body.classList.toggle("right-bar-enabled")
                });

                document.body.addEventListener("click", function(e) {
                    !e.target.parentElement.classList.contains("right-bar-toggle-close") && e.target.closest(".right-bar-toggle, .right-bar") || document.body.classList.remove("right-bar-enabled")
                })
                */

                WaitObject(user.nome).then(() => {
                    Init();
                    RegisterHub();
                });
            }
        }, 200);


        return () => {
            clearInterval(TimerIdleInterval);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        let _user = Object.assign({}, user);
        let _info = Object.assign({}, info);
        let _name = _user.nome.split(' ');
        if(_name.length > 1) {
            _info.nome = _name[0] + " " + _name[1];
        }else {
            _info.nome = _name[0];
        }
        setInfo(_info);
        LoadUnidades();
        Idle();
    }
    //#endregion Init



    //#region Loaders
    const LoadUnidades = async() => {
        await Promise.resolve(new Request().Run("api/Home/Unidades", "GET", "", user.token, 0*1000))
            .then((data) => {
                unidadesRef.current = data;
                setUnidades(unidadesRef.current);
            }).catch(() => {
            
            }).finally(() => {
                setTimeout(() => {
                    setUnidade(user.unidade_id);
                }, 100);
            })
    }
    //#endregion Loaders


    //#region HubObjects
    const RegisterHub = () => {
        Hub.Set("HEADER_UNIDADES", () => {
            return unidadesRef.current;
        }, component_name);
    }
    //#endregion HubObjects



    //#region Handlers
    const _FullScreen = () => {
        let elem =  document.documentElement;

        if (!$(elem).is(":fullscreen")) {
            
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
        }
    }



    const _Exit = () => {
        handleLogout();
    }


    const _HandleUnidadeChange = async(e) => {
        setUnidade(e.target.value);
        await Promise.resolve(new Request().Run("api/Home/Unidade", "POST", {unidade_id: e.target.value}, user.token, 0*1000))
            .then(async(data) => {
                //console.log(e.target.value);
                localStorage.clear();
                setUnidade(e.target.value);
                Update(data);

                if (data.nivel === 7) {
                    navigate('/Espelho');
                } else {
                    navigate('/Home');
                }   
            }).catch(() => {
            
            }).finally(() => {
                setTimeout(() => {
                    Hub.Exec("HOME_INIT_DRAW");
                }, 300);
            })

       
    }


    const _ClearDb = () => {
        localStorage.clear();
        handleLogout();
    }
    //#endregion Handlers


    const Idle = () => {
        TimerIdleInterval = setInterval(() => {
            TimerIdle++;
            //console.log("TimerIdleInterval", TimerIdle);
            if(TimerIdle === 900) {
                clearInterval(TimerIdleInterval);
                handleLogout();
            }
        }, 1000);

        document.addEventListener("mousemove", (event) => {TimerIdle=0});
        document.addEventListener("click", (event) => {TimerIdle=0});
    }


    return (
        <>
       <header id="page-topbar">
            <div className="navbar-header">
                <div className="d-flex">
                    <div className="navbar-brand-box">
                        <a className="logo">
                            <span className="logo-lg">
                                <img src="/assets/images/logo1.png" alt="" height="49" />
                            </span>
                        </a>
                    </div>

                    <button type="button" className="btn btn-sm px-3 font-size-16 d-lg-none header-item" data-bs-toggle="collapse" id="horimenu-btn" data-bs-target="#topnav-menu-content">
                        <i className="fa fa-fw fa-bars"></i>
                    </button>

                    <div className="topnav active">
                        <Menu />
                    </div>

                </div>

              

                <div className="d-flex">    
                    <div className="d-inline-block">
                        <select id="unidade" value={unidade} className="btn header-item align-items-center" onChange={_HandleUnidadeChange}>
                            {
                                Array.isArray(unidades) && unidades.length > 0 && unidades.map((item,i) => (
                                    <option key={'unidade'+i} value={item.id}>{item.rede} {item.rede!==''?'-':''} {item.name}</option>
                                ))
                                
                            }
                        </select>
                    </div>

                    <div className="dropdown d-inline-block">
                        <button type="button" className="btn header-item user text-start d-flex align-items-center" id="page-header-user-dropdown"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img className="rounded-circle header-profile-user" src="/assets/images/users/avatar-3.jpg" alt="Header Avatar" />
                            <span className="ms-2 d-none d-xl-inline-block user-item-desc">
                                <span className="user-name">{user.nome} <i className="mdi mdi-chevron-down"></i></span>
                            </span>
                        </button>
                        <span class="user_nivel">{niveis[user?.nivel]} </span>
                        <div className="dropdown-menu dropdown-menu-end pt-0">
                            <LinkContainer to={'/Perfil'}  exact>
                                <a className="dropdown-item pointer" ><i className="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">Meu perfil</span></a>
                            </LinkContainer>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item pointer" onClick={_FullScreen}><i className="mdi mdi-vibrate text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">Fullscreen</span></a>
                            <a className="dropdown-item pointer" onClick={_ClearDb}><i className="mdi mdi-database-sync text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">Limpar Base</span></a>
                            <a className="dropdown-item pointer" onClick={_Exit}><i className="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">Sair</span></a>
                        </div>
                    </div>
                </div>
            </div>

            {/*
            <div className="collapse _show dash-content" id="dashtoggle">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                        

                            </div>
                        </div>
                    </div>

               
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card dash-header-box shadow-none border-0">
                                <div className="card-body p-0">
                                    <div className="row row-cols-xxl-6 row-cols-md-3 row-cols-1 g-0">
                                        <div className="col">
                                            <div className="mt-md-0 py-3 px-4 mx-2">
                                                <p className="text-white-50 mb-2 text-truncate">Campaign Sent </p>
                                                <h3 className="text-white mb-0">197</h3>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="mt-3 mt-md-0 py-3 px-4 mx-2">
                                                <p className="text-white-50 mb-2 text-truncate">Annual Profit</p>
                                                <h3 className="text-white mb-0">$489.4k</h3>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="mt-3 mt-md-0 py-3 px-4 mx-2">
                                                <p className="text-white-50 mb-2 text-truncate">Lead Coversation</p>
                                                <h3 className="text-white mb-0">32.89%</h3>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="mt-3 mt-md-0 py-3 px-4 mx-2">
                                                <p className="text-white-50 mb-2 text-truncate">Sales Forecast</p>
                                                <h3 className="text-white mb-0">75.35%</h3>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="mt-3 mt-lg-0 py-3 px-4 mx-2">
                                                <p className="text-white-50 mb-2 text-truncate">Daily Average Income</p>
                                                <h3 className="text-white mb-0">$1,596.5</h3>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="mt-3 mt-lg-0 py-3 px-4 mx-2">
                                                <p className="text-white-50 mb-2 text-truncate">Annual Deals</p>
                                                <h3 className="text-white mb-0">2,659</h3>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

              <div>
                <a className="dash-troggle-icon" id="dash-troggle-icon" data-bs-toggle="collapse" href="#dashtoggle" aria-expanded="true" aria-controls="dashtoggle">
                    <i className="bx bx-up-arrow-alt"></i>
                </a>
            </div>
            */}                

        </header>

        <div className="hori-overlay"></div>
        </>
    );
}